import { debounce, map, size } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { deserializeCertificateTemplate } from '../store/certificateTemplates/helpers';
import { CertificateTemplate } from '../store/certificateTemplates/types';
import { deserializeUser } from '../store/users/helpers';
import { User } from '../store/users/types';
import { api, getUrlWithFilters } from './helpers';

const SIZE_PER_PAGE = 10;

interface UsersSelectProps {
  onFilter: (value: string | undefined) => void;
  onMore: () => void;
  fetchUser: (uuid: string) => Promise<User | undefined>;
  users: Array<User>;
  loading: boolean;
}

interface TemplateSelectProps {
  onFilter: (value: string | undefined) => void;
  onMore: () => void;
  fetchTemplates: (uuid: string) => Promise<CertificateTemplate | undefined>;
  templates: Array<CertificateTemplate>;
  loading: boolean;
}

export const useUsersSelect = (): UsersSelectProps => {
  const [users, setUsers] = useState<Array<User>>([]);
  const page = useRef(1);
  const lastPage = useRef(false);
  const filter = useRef('');
  const [loading, setLoading] = useState(false);

  const fetchUser = async (uuid: string) => {
    const url = getUrlWithFilters('user', {
      sizePerPage: SIZE_PER_PAGE,
      page: page.current,
      query: [
        {
          label: 'uuid',
          key: 'uuid',
          operator: '=',
          type: 'filter',
          value: uuid,
        },
      ],
    });

    const response = await api().get(url);

    if (response.data && response.data.length > 0) {
      return deserializeUser(response.data[0]);
    }

    return undefined;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilter = useCallback(
    debounce(async (value) => {
      page.current = 1;
      filter.current = value;

      setLoading(true);
      setUsers([]);

      const url = getUrlWithFilters('user', {
        sizePerPage: SIZE_PER_PAGE,
        page: page.current,
        query: [
          {
            label: 'username',
            key: 'username',
            operator: '~',
            type: 'like',
            value: filter.current,
          },
        ],
      });

      const response = await api().get(url);

      if (size(response.data) < SIZE_PER_PAGE) {
        lastPage.current = true;
      } else {
        lastPage.current = false;
      }

      setUsers(map(response.data, (rawUser) => deserializeUser(rawUser)));
      setLoading(false);
    }, 500),
    []
  );

  const onMore = () => {
    if (!loading && !lastPage.current) {
      page.current += 1;
      setLoading(true);

      const url = getUrlWithFilters('user', {
        sizePerPage: SIZE_PER_PAGE,
        page: page.current,
        query: [
          {
            label: 'username',
            key: 'username',
            operator: '~',
            type: 'like',
            value: filter.current,
          },
        ],
      });

      api()
        .get(url)
        .then((response) => {
          setUsers((prev) => [
            ...prev,
            ...map(response.data, (rawUser) => deserializeUser(rawUser)),
          ]);
          setLoading(false);

          if (size(response.data) < SIZE_PER_PAGE) {
            lastPage.current = true;
          }
        });
    }
  };

  useEffect(() => {
    onFilter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { onFilter, onMore, users, loading, fetchUser };
};

export const useTemplatesSelect = (): TemplateSelectProps => {
  const [templates, setTemplates] = useState<Array<CertificateTemplate>>([]);
  const page = useRef(1);
  const lastPage = useRef(false);
  const filter = useRef('');
  const [loading, setLoading] = useState(false);

  const fetchTemplates = async (uuid: string) => {
    const url = getUrlWithFilters('certificate/template', {
      sizePerPage: SIZE_PER_PAGE,
      sortBy: 'date_updated',
      sortDir: 'desc',
      page: page.current,
      query: [
        {
          label: 'name',
          key: 'name',
          operator: '~',
          type: 'like',
          value: filter.current,
        },
      ],
    });
    const response = await api().get(url);

    if (response.data && response.data.length > 0) {
      return deserializeCertificateTemplate(response.data[0]);
    }

    return undefined;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilter = useCallback(
    debounce(async (value) => {
      page.current = 1;
      filter.current = value;

      setLoading(true);
      setTemplates([]);

      const url = getUrlWithFilters('certificate/template', {
        sizePerPage: SIZE_PER_PAGE,
        page: page.current,
        sortBy: 'date_updated',
        sortDir: 'desc',
        query: [
          {
            label: 'name',
            key: 'name',
            operator: '~',
            type: 'like',
            value: filter.current,
          },
        ],
      });

      const response = await api().get(url);

      if (size(response.data) < SIZE_PER_PAGE) {
        lastPage.current = true;
      } else {
        lastPage.current = false;
      }

      setTemplates(map(response.data, deserializeCertificateTemplate));
      setLoading(false);
    }, 500),
    []
  );

  const onMore = () => {
    if (!loading && !lastPage.current) {
      page.current += 1;
      setLoading(true);

      const url = getUrlWithFilters('certificate/template', {
        sizePerPage: SIZE_PER_PAGE,
        page: page.current,
        sortBy: 'date_updated',
        sortDir: 'desc',
        query: [
          {
            label: 'name',
            key: 'name',
            operator: '~',
            type: 'like',
            value: filter.current,
          },
        ],
      });

      api()
        .get(url)
        .then((response) => {
          setTemplates((prev) => [
            ...prev,
            ...map(response.data, deserializeCertificateTemplate),
          ]);
          setLoading(false);

          if (size(response.data) < SIZE_PER_PAGE) {
            lastPage.current = true;
          }
        });
    }
  };

  useEffect(() => {
    onFilter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { onFilter, onMore, templates, loading, fetchTemplates };
};

export const useMounted = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};
