/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { DeviceProvisioning } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeDeviceProvisioning = (raw: any): DeviceProvisioning => {
  return {
    certificateProfileUuid: raw.certificate_profile_uuid,
    certificateProfileName: raw.certificate_profile_name,
    count: raw.count,
    date: moment(raw.date_issued).format('x'),
  };
};
