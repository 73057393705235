/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoginStats, HSMStats } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeLoginStat = (raw: any): LoginStats => ({
  date: raw.event_date,
  count: raw.event_count,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeHSMStat = (raw: any): HSMStats => ({
  available: raw.hsm.available,
  active: raw.hsm.active,
  hsmUtcTime: raw.hsm.hsm_utc_time,
});
