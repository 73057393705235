import {
  Button,
  Row,
  Col,
  Input,
  UncontrolledPopover,
  Alert,
} from 'reactstrap';
import { filter, find, isEmpty, isNil, map, get, includes, size } from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import SmartSelect, { InputActionMeta } from 'react-select';
import ButtonDropdown from '../ButtonDropdown';
import { Filter, Operator } from './types';
import { ServerSideSelect } from '../ServerSideSelect/ServerSideSelect';
import './FilterBar.scss';

interface Props {
  filters: Filter[];
  initialFilters?: Filter[];
  onFiltersChange: Function;
}

const FilterBar: FC<Props> = ({
  filters,
  initialFilters = [],
  onFiltersChange = (): null => null,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [autoSelectFilter, setAutoSelectFilter] = useState<boolean>(true);
  const [currentFilter, setCurrentFilter] = useState<Filter>();
  const [currentOperator, setCurrentOperator] = useState<Operator>();
  const [filtersApplied, setFiltersApplied] = useState<Filter[]>(
    initialFilters
  );

  const availableFilters = filter(
    filters,
    (filterItem) =>
      !find(
        filtersApplied,
        (filterApplied) => filterApplied.key === filterItem.key
      )
  );

  useEffect(() => {
    if (availableFilters[0] && autoSelectFilter) {
      const operator = availableFilters[0].operators?.includes('~')
        ? '~'
        : get(availableFilters[0], 'operators[0]', '=');
      setCurrentFilter(availableFilters[0]);
      setCurrentOperator(operator);
      setAutoSelectFilter(false);
    }
    if (!availableFilters[0] && autoSelectFilter) {
      setCurrentFilter(undefined);
      setAutoSelectFilter(false);
    }
  }, [autoSelectFilter, availableFilters]);

  useEffect(() => {
    if (currentFilter?.key) {
      const findFilter = find(filters, { key: currentFilter?.key });

      if (findFilter) {
        setCurrentFilter(findFilter);
      }
    }
  }, [filters, currentFilter]);

  useEffect(() => {
    onFiltersChange(filtersApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersApplied]);

  const applyFilter = (): void => {
    const newValue = [
      ...filtersApplied,
      {
        key: currentFilter?.key || '',
        label: currentFilter?.label || '',
        value: inputValue,
        operator: currentOperator || '=',
        type: currentFilter?.type || 'filter',
      },
    ];
    setFiltersApplied(newValue);
    setAutoSelectFilter(true);
    setInputValue('');
  };

  return (
    <div className="FilterBar">
      <Row>
        <Col md={2}>
          <ButtonDropdown
            value={currentFilter?.label || 'Select Filter'}
            options={map(availableFilters, (item) => ({
              ...item,
              value: item.label,
            }))}
            idPrefix={'filter-bar'}
            disabled={isEmpty(availableFilters)}
            onClick={(filterSelected: Filter): void => {
              const operator = filterSelected.operators?.includes('~')
                ? '~'
                : get(filterSelected, 'operators[0]', '=');
              setAutoSelectFilter(false);
              setInputValue('');
              setCurrentOperator(operator);
              setCurrentFilter(filterSelected);
            }}
          />
        </Col>
        <Col md={1}>
          <ButtonDropdown
            value={
              // eslint-disable-next-line no-nested-ternary
              includes(currentFilter?.operators, currentOperator)
                ? currentOperator
                : currentFilter?.operators?.includes('~')
                ? '~'
                : get(currentFilter, 'operators[0]', '=')
            }
            options={map(
              currentFilter?.operators,
              (item) =>
                ({
                  key: item,
                  value: item,
                } || [{ key: '=', value: '=' }])
            )}
            disabled={
              isNil(currentFilter?.operators) ||
              size(currentFilter?.operators) === 1
            }
            onClick={(operatorSelected: {
              key: string;
              value: '>' | '<' | '=';
            }): void => {
              setCurrentOperator(operatorSelected.value);
            }}
          />
        </Col>
        <Col md={7}>
          <div id="filter-input">
            {!currentFilter?.serverSide && (
              <>
                {(!isEmpty(currentFilter?.options) ||
                  currentFilter?.onSelectInput) && (
                  <SmartSelect
                    isClearable
                    isSearchable
                    placeholder={currentFilter?.placeholder || 'Select...'}
                    onKeyDown={(event: unknown): void => {
                      if (get(event, 'key') === 'Enter' && inputValue) {
                        applyFilter();
                      }
                    }}
                    onInputChange={(value: string, action: InputActionMeta) => {
                      if (
                        action.action === 'input-change' &&
                        currentFilter?.onSelectInput
                      ) {
                        currentFilter?.onSelectInput &&
                          currentFilter?.onSelectInput(value);
                      }
                    }}
                    onMenuScrollToBottom={() =>
                      currentFilter?.onMore && currentFilter?.onMore()
                    }
                    isLoading={currentFilter?.loading}
                    options={currentFilter?.options}
                    onChange={(selectedValue): void => {
                      const value = selectedValue?.value;
                      if (value) {
                        setInputValue(value);
                      }
                    }}
                  />
                )}
                {isEmpty(currentFilter?.options) &&
                  !currentFilter?.serverSide &&
                  !currentFilter?.onSelectInput && (
                    <Input
                      placeholder="Type..."
                      value={inputValue}
                      onKeyPress={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ): void => {
                        if (event.key === 'Enter' && inputValue) {
                          applyFilter();
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        setInputValue(event.target.value);
                      }}
                      type="text"
                    />
                  )}
              </>
            )}
            {currentFilter?.serverSide && (
              <>
                {currentFilter?.serverSideConfig ? (
                  <ServerSideSelect
                    onSelectEntity={(entity: any) => {
                      setInputValue(entity.uuid);
                    }}
                    placeholder={currentFilter?.placeholder || ''}
                    {...currentFilter.serverSideConfig}
                  />
                ) : (
                  <Alert
                    color={'danger'}
                    className={'mt-2'}
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                  >
                    You forgot to configure the <code>serverSideConfig</code> on
                    the filter.
                  </Alert>
                )}
              </>
            )}
          </div>
          {currentFilter?.suggestion && (
            <UncontrolledPopover
              placement="bottom"
              trigger="hover"
              target="filter-input"
            >
              <div className="p-1">
                <small>{currentFilter?.suggestion}</small>
              </div>
            </UncontrolledPopover>
          )}
        </Col>
        <Col md={2}>
          <Button
            id="apply-filter-button"
            onClick={(): void => {
              applyFilter();
            }}
            disabled={isNil(currentFilter) || isEmpty(inputValue)}
            size="sm"
            className="filter-button w-100 float-right ml-auto p-1"
            outline
          >
            <FontAwesomeIcon className="mr-1" icon={faFilter} /> Apply Filter
          </Button>
        </Col>
      </Row>
      <div className="mt-3 d-flex flex-wrap">
        {map(filtersApplied, (item, index) => (
          <span key={index} className="mr-3 p-2">
            <strong>
              {item.label} {item.operator || '='} "{item.value}"
            </strong>
            <FontAwesomeIcon
              id={`${item.label}_filter_clear`.toLowerCase()}
              onClick={(): void => {
                const newValue = filter(
                  filtersApplied,
                  (filterItem, itemIndex) => itemIndex !== index
                );
                setFiltersApplied(newValue);
              }}
              className="ml-2 pki-ico"
              icon={faTimes}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default FilterBar;
