import React, { FC, MouseEvent, ReactNode } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import ButtonDropdown from '../ButtonDropdown';
import './TableActions.scss';

interface TableActionsI {
  rowId: string;
  options: {
    label?: string;
    hidden?: boolean;
    disabled?: boolean;
    ico: ReactNode;
    onClick: Function;
  }[];
}

const TableActions: FC<TableActionsI> = ({ rowId, options = [] }) => {
  return (
    <div className="TableActions">
      <ButtonDropdown
        id={`target-${rowId}`}
        value={<FontAwesomeIcon icon={faCogs} />}
        options={options
          .filter((item) => !item.hidden)
          .map(
            ({ label, ico, disabled, onClick = (): null => null }, index) => ({
              disabled,
              key: _.kebabCase(String(label)),
              value: (
                <div
                  key={index}
                  className={classNames({
                    'action-item px-3 py-2': true,
                    'not-allowed': disabled,
                  })}
                  id={`action-${_.kebabCase(label)}-${rowId}`}
                  onClick={(e: MouseEvent): void => {
                    if (!disabled) {
                      onClick(e);
                    }
                  }}
                >
                  {ico}
                  <span className="ml-2">{label}</span>
                </div>
              ),
            })
          )}
      />
    </div>
  );
};

export default TableActions;
