import React, { useState, useEffect, useRef, FC } from 'react';
import useCopy from '@react-hook/copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faTrash,
  faUnlock,
  faLock,
  faDownload,
  faInfoCircle,
  faClipboard,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Button,
  Collapse,
  UncontrolledPopover,
} from 'reactstrap';
import {
  isNil,
  isEmpty,
  size,
  includes,
  find,
  chain,
  map,
  set,
  each,
  replace,
  keys,
} from 'lodash';
import { useDispatch } from 'react-redux';
import { KeyNInput } from '../../components';
import { CertificateRequest } from '../../store/requests/types';
import { CertificateProfile } from '../../store/certificateProfiles/types';
import { Certificate } from '../../store/certificates/types';
import './CertificateRequestForm.scss';

import {
  formatBytes,
  readFileAsText,
  getVariablesFromSubject,
  api,
} from '../../libs/helpers';

import { defaultValuesCertificateRequest } from './constants';
import { combineAndDestructSubjectVars } from '../ViewsComponents/helpers';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificateProfile } from '../../store/certificateProfiles/helpers';
import { sendNotification } from '../../store/notifications/actions';

const { saveAs } = require('file-saver');

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onDownloadBatchCertificates?: Function;
  onChangeMode?: Function;
  defaultValues?: CertificateRequest;
  readOnly: boolean;
}

const CertificateRequestForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onDownloadBatchCertificates = (): null => null,
  onChangeMode = (): null => null,
  defaultValues = defaultValuesCertificateRequest,
  readOnly = false,
}) => {
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );

  const editMode = !isEmpty(defaultValues.uuid);
  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);

  const [currentCertificateProfile, setCurrentCertificateProfile] = useState<
    CertificateProfile | undefined
  >(undefined);
  const [requestForm, setRequestForm] = useState<CertificateRequest>(
    defaultValues
  );
  const [currentFile, setCurrentFile] = useState<File>();
  const [selectProfileFlag, setSelectProfileFlag] = useState<boolean>(
    editMode // true when to component loads the first time, then set to false
  );
  const inputEl = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const {
    uuid,
    notes,
    quantity,
    issuingCAUuid,
    certificateProfile,
    csr,
    csrFilename,
    certificateSubjectVars,
    certificateBatchId,
  } = requestForm;

  const opensslFirstTip =
    'openssl req -new -newkey rsa:2048 -subj /CN=xyz -keyout priv_key.pem -out csr.pem';
  const {
    copied: firstTipCopied,
    copy: copyFirstTip,
    reset: resetFirstTip,
  } = useCopy(opensslFirstTip);
  const opensslSecondTip =
    'openssl req -new -newkey ec:<(openssl ecparam -name secp384r1) -subj /CN=xyz -keyout cert.key -out csr.pem';
  const {
    copied: secondTipCopied,
    copy: copySecondTip,
    reset: resetSecondTip,
  } = useCopy(opensslSecondTip);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setRequestForm((current: CertificateRequest) => ({
      ...current,
      [property]: value,
    }));
  };

  const fetchCurrentIssuingCA = async (idToFetch: string) => {
    try {
      const { data: ca } = await api().get(
        `certificate/authority/${idToFetch}`
      );
      if (ca) {
        setCurrentIssuingCA(deserializeCertificate(ca));
      }
    } catch (e) {
      sendNotification({ text: 'Failed to fetch Issuing CA', success: false })(
        dispatch
      );
    }
  };

  useEffect(() => {
    if (issuingCAUuid && !currentIssuingCA) {
      fetchCurrentIssuingCA(issuingCAUuid);
    }
    if (certificateProfile && !currentCertificateProfile) {
      setCurrentCertificateProfile(certificateProfile);
    }
  }, [readOnly]);

  useEffect(() => {
    if (certificateProfile) {
      const { subject = [] } = certificateProfile;
      const { subjectAlternativeNames = [] } = certificateProfile;
      const combinedVars = combineAndDestructSubjectVars(
        subject,
        subjectAlternativeNames
      );
      const value = certificateProfile
        ? getVariablesFromSubject(combinedVars, certificateSubjectVars)
        : {};
      setProfileFormProperty({
        property: 'certificateSubjectVars',
        value,
      });

      setCurrentFile(undefined);
      set(inputEl, 'current.value', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateProfile]);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  const invalidCertificateSubjectVars = !isNil(
    find(keys(certificateSubjectVars), (key) => {
      if (key.includes('required') && certificateSubjectVars) {
        return chain(certificateSubjectVars[key]).trim().isEmpty().value();
      }

      return false;
    })
  );

  const isEditable =
    includes((defaultValues as CertificateRequest).actions, 'update') &&
    (defaultValues as CertificateRequest).status === 'Awaiting Approval';

  const isValid =
    !isEmpty(issuingCAUuid) &&
    !isEmpty(certificateProfile) &&
    !invalidCertificateSubjectVars &&
    ((currentCertificateProfile?.batchEnabled && quantity && quantity >= 1) ||
      !currentCertificateProfile?.batchEnabled);
  const canDownloadBatch = includes(requestForm.actions, 'download_batch');

  return (
    <div id="certificate-request-form" className="CertificateRequestForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3 className="text-muted">Certificate Request</h3>
          <span
            onClick={(): void => {
              if (requestForm.status === 'Awaiting Approval') {
                onChangeMode();
              }
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Label className="pki-label">Notes</Label>
        <FormGroup>
          <Input
            value={isEmpty(notes) && readOnly ? 'N/A' : notes}
            readOnly={readOnly}
            plaintext={readOnly}
            type="textarea"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setProfileFormProperty({
                property: 'notes',
                value: event.target.value,
              });
            }}
            name="notes"
            id="notes"
          />
        </FormGroup>
        {readOnly && certificateBatchId && (
          <>
            <Label className="pki-label">Certificate Batch Id</Label>
            <FormGroup>
              <Input
                value={certificateBatchId}
                readOnly={readOnly}
                plaintext={readOnly}
                type="text"
                name="certificateBatchId"
                id="certificateBatchId"
              />
            </FormGroup>
          </>
        )}
        <Row>
          <Col>
            <Label className="pki-label">Issuing CA</Label>
            {readOnly && (
              <Input
                id="issuer-ca-uuid"
                readOnly
                value={currentIssuingCA?.commonName || ''}
                plaintext
              />
            )}
            {!readOnly && (
              <ServerSideSelect
                onSelectEntity={(ca) => {
                  setProfileFormProperty({
                    property: 'issuingCAUuid',
                    value: ca?.uuid,
                  });
                  setCurrentIssuingCA(() => {
                    setCurrentCertificateProfile(undefined);
                    setProfileFormProperty({
                      property: 'certificateProfile',
                      value: null,
                    });
                    return deserializeCertificate(ca);
                  });
                }}
                id="issuer-ca-uuid"
                formatter={(ca) => ca.cn}
                disabled={readOnly}
                fetchUrl={`certificate/authority`}
                defaultFilters={'is_key_online=true'}
                searchParam={`cn`}
                value={currentIssuingCA?.commonName || ''}
              />
            )}
          </Col>
          <Col>
            <Label className="pki-label">Certificate Profile</Label>
            {readOnly && (
              <Input
                id="certificate-profile"
                readOnly
                value={certificateProfile?.name || ''}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(profile) => {
                    setProfileFormProperty({
                      property: 'certificateProfile',
                      value: deserializeCertificateProfile(profile),
                    });
                    setCurrentCertificateProfile(
                      deserializeCertificateProfile(profile)
                    );
                  }}
                  formatter={(profile) => profile?.certificate_profile?.name}
                  fetchUrl={`certificate/profile`}
                  defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
                  searchParam={`certificate_profile.name`}
                  wait={!currentIssuingCA}
                  id="certificate-profile"
                  value={
                    currentCertificateProfile?.name ||
                    'Select a certificate profile'
                  }
                  urlParams={[
                    ['resource', 'certificate_request'],
                    ['action', 'create'],
                  ]}
                  error={
                    !certificateProfile?.profileId && highlightMandatoryFields
                      ? 'Cannot be empty, please select one!'
                      : undefined
                  }
                />
              </>
            )}
          </Col>
          {readOnly &&
            !currentCertificateProfile?.batchEnabled &&
            !isEmpty(csr) && (
              <Col md={3}>
                <FormGroup>
                  <Label className="pki-label">CSR</Label>
                  <div className="d-flex">
                    {!isEmpty(csrFilename) && (
                      <Input
                        id="csr-filename"
                        readOnly
                        required={false}
                        value={csrFilename}
                        plaintext
                      />
                    )}
                    <span
                      onClick={(): void => {
                        const csrBlob = new Blob([String(csr)]);
                        saveAs(csrBlob, csrFilename);
                      }}
                      className="form-control-plaintext ml-3"
                    >
                      <FontAwesomeIcon className="pki-ico" icon={faDownload} />
                    </span>
                  </div>
                </FormGroup>
              </Col>
            )}
        </Row>
        {currentCertificateProfile?.batchEnabled && (
          <Row>
            <Col md={6}>
              <div className="mt-4">
                <Label className="pki-label">Quantity</Label>
                <div className="d-flex">
                  <Input
                    id="quantity"
                    readOnly={readOnly}
                    type="number"
                    min={1}
                    required
                    value={quantity}
                    plaintext={readOnly}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      setProfileFormProperty({
                        property: 'quantity',
                        value: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {(!quantity || quantity < 1) && highlightMandatoryFields && (
                <div className="invalid-text">Quantity needs to be &gt;= 1</div>
              )}
            </Col>
          </Row>
        )}
        <Collapse isOpen={!!size(certificateSubjectVars)}>
          <KeyNInput
            readOnly={readOnly}
            className="mt-3"
            label="Variables"
            highlightMandatoryFields={highlightMandatoryFields}
            keys={certificateSubjectVars || {}}
            onChange={({
              key,
              value,
            }: {
              key: string;
              value: string;
            }): void => {
              setProfileFormProperty({
                property: 'certificateSubjectVars',
                value: { ...certificateSubjectVars, [key]: value },
              });
            }}
            labelPopover="These variables are defined in either the Subject and/or Subject Alternative Names for the selected profile."
          />
        </Collapse>
        {!readOnly && (
          <div className="mt-1">
            <Label className="pki-label">
              CSR
              <>
                <FontAwesomeIcon
                  id="tips"
                  className="pki-ico tips-ico mt-2 ml-1"
                  icon={faInfoCircle}
                />
                <UncontrolledPopover
                  className="PKIApp"
                  trigger="hover"
                  target="tips"
                >
                  <div className="p-3">
                    <p>CSRs can be generated using OpenSSL; for example:</p>
                    <p className="d-flex">
                      <code>{opensslFirstTip}</code>{' '}
                      <FontAwesomeIcon
                        className="pki-ico my-auto"
                        onClick={(): void => {
                          copyFirstTip();
                          setTimeout(() => {
                            resetFirstTip();
                          }, 500);
                        }}
                        icon={faClipboard}
                      />
                    </p>
                    <p>or</p>
                    <p className="d-flex">
                      <code>{opensslSecondTip}</code>
                      <FontAwesomeIcon
                        className="pki-ico my-auto"
                        onClick={(): void => {
                          copySecondTip();
                          setTimeout(() => {
                            resetSecondTip();
                          }, 500);
                        }}
                        icon={faClipboard}
                      />
                    </p>
                    {(firstTipCopied || secondTipCopied) && 'Copied!'}
                  </div>
                </UncontrolledPopover>
              </>
            </Label>
            <Row>
              <Col className="d-flex" md={3}>
                <Button
                  outline
                  disabled={currentCertificateProfile?.batchEnabled}
                  id="upload-csr-button"
                  onClick={(): void => {
                    if (inputEl && inputEl.current) {
                      inputEl.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon className="mr-1" icon={faUpload} />
                  Select
                </Button>
                <div className="mt-auto ml-1">
                  <code className="black-code"> (PEM)</code>
                </div>
              </Col>
              {currentFile && (
                <Col className="d-flex" md={8}>
                  <div id="file-description" className="text-truncate mt-auto">
                    {currentFile.name} ({formatBytes(currentFile.size)})
                  </div>
                </Col>
              )}
              {currentFile && (
                <Col>
                  <FontAwesomeIcon
                    className="pki-ico file-delete mt-4"
                    onClick={(): void => {
                      setCurrentFile(undefined);
                      set(inputEl, 'current.value', null);
                    }}
                    icon={faTrash}
                  />
                </Col>
              )}
            </Row>
            <input
              type="file"
              id="file-input"
              className="d-none"
              ref={inputEl}
              onChange={({ target: { files } }): void => {
                if (files && files.length > 0) {
                  setCurrentFile(files[0]);
                  set(inputEl, 'current.value', null);
                }
              }}
            />
          </div>
        )}
        {readOnly &&
          requestForm.batch &&
          canDownloadBatch &&
          requestForm.status === 'Completed' && (
            <div className="float-right mt-5 pb-5">
              <span>
                <Button
                  id="confirm-form-button"
                  outline
                  disabled={false}
                  onClick={(): void => {
                    onDownloadBatchCertificates();
                  }}
                >
                  Download Batch Certificates
                </Button>
              </span>
            </div>
          )}
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-form-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-form-button"
                outline
                disabled={false}
                onClick={async (): Promise<void> => {
                  let profile = { ...requestForm.certificateProfile };
                  if (profile) {
                    if (!isNil(certificateSubjectVars)) {
                      const currentSubject = profile.subject;
                      const varsKey = map(
                        certificateSubjectVars,
                        (value, key) => key
                      );
                      const newSubject = map(currentSubject, (item) => {
                        let output = item.value;
                        each(varsKey, (varKey) => {
                          output = replace(
                            output,
                            `{${varKey}}`,
                            certificateSubjectVars[varKey]
                          );
                        });
                        return { ...item, value: output };
                      });
                      profile = { ...profile, subject: newSubject };
                    }
                    if (currentFile) {
                      const content = await readFileAsText(currentFile);
                      onSubmit({
                        values: {
                          ...requestForm,
                          certificateProfile: profile,
                          csrFilename: currentFile.name,
                          csr: content,
                        },
                        isValid,
                      });
                    } else {
                      onSubmit({
                        values: { ...requestForm, certificateProfile: profile },
                        isValid,
                      });
                    }
                    if (!isValid) {
                      setHighlightMandatoryFields(true);
                    }
                  } else {
                    setHighlightMandatoryFields(true);
                  }
                }}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CertificateRequestForm;
