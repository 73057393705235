import React, { FC } from 'react';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const FileViolations: FC<{ violations: { [violations: string]: string } }> = ({
  violations,
}) => {
  return (
    <div className="FileViolations">
      {map(violations, (violationMessage, violationKey) => (
        <div key={violationKey} className="invalid-text">
          <FontAwesomeIcon className="mr-1" icon={faExclamationTriangle} />
          {violationMessage}
        </div>
      ))}
    </div>
  );
};

export default FileViolations;
