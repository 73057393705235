import React, { FC } from 'react';
import _ from 'lodash';
import {
  Label,
  FormGroup,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface GenericListI {
  id?: string;
  data: string[];
  elementsPerCol?: number;
  label?: string;
  readOnly?: boolean;
  onDelete?: Function;
}

const GenericList: FC<GenericListI> = ({
  data = [],
  elementsPerCol = 4,
  label,
  id,
  onDelete = (): null => null,
  readOnly = false,
}) => {
  const cols = _.ceil(_.size(data) / elementsPerCol);
  const outputCols = [];
  for (let col = 0, index = 0; col < cols; col += 1) {
    const start = col * elementsPerCol;
    const end = start + elementsPerCol;
    outputCols.push(
      <Col key={col}>
        {_.map(_.slice(data, start, end), (value: string) => {
          const auxIndex = index;
          index += 1;
          return (
            <ListGroup flush key={index}>
              <ListGroupItem className="py-1 px-0">
                <div className="d-flex">
                  <span className="value">{value}</span>
                  {!readOnly && (
                    <span
                      className="mt-1 ml-auto"
                      onClick={(): void => {
                        onDelete({ value, index: auxIndex });
                      }}
                    >
                      <FontAwesomeIcon className="pki-ico" icon={faTrash} />
                    </span>
                  )}
                </div>
              </ListGroupItem>
            </ListGroup>
          );
        })}
      </Col>
    );
  }
  return (
    <div id={id} className="GenericList">
      <FormGroup>
        {label && <Label className="pki-label">{label}</Label>}
        <Row>{outputCols}</Row>
      </FormGroup>
    </div>
  );
};

export default GenericList;
