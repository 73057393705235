import { Reducer } from 'redux';
import { ClientsState, ClientsActionTypes } from './types';

export const initialState: ClientsState = {
  isGettingClientsList: false,
  isEditingClient: false,
  isDeletingClient: false,
  isLoadedClientsList: false,
  isGettingClientEntitlements: false,
  currentClientEntitlements: [],
  isCreatingClient: false,
  clientsList: [],
  clientsErrors: undefined,
};

const reducer: Reducer<ClientsState> = (
  state = initialState,
  action
): ClientsState => {
  switch (action.type) {
    case ClientsActionTypes.GET_CLIENTS_LIST_REQUEST: {
      return {
        ...state,
        isGettingClientsList: true,
        isLoadedClientsList: false,
        clientsErrors: undefined,
      };
    }
    case ClientsActionTypes.GET_CLIENTS_LIST_SUCCESS: {
      return {
        ...state,
        isGettingClientsList: false,
        isLoadedClientsList: true,
        clientsList: action.payload,
      };
    }
    case ClientsActionTypes.GET_CLIENTS_LIST_ERROR: {
      return {
        ...state,
        isGettingClientsList: false,
        isLoadedClientsList: false,
        clientsErrors: action.payload,
      };
    }

    case ClientsActionTypes.POST_CLIENT_REQUEST: {
      return {
        ...state,
        isCreatingClient: true,
      };
    }
    case ClientsActionTypes.POST_CLIENT_SUCCESS: {
      return {
        ...state,
        isCreatingClient: false,
      };
    }
    case ClientsActionTypes.POST_CLIENT_ERROR: {
      return {
        ...state,
        isCreatingClient: false,
      };
    }

    case ClientsActionTypes.PUT_CLIENT_REQUEST: {
      return {
        ...state,
        isEditingClient: true,
      };
    }
    case ClientsActionTypes.PUT_CLIENT_SUCCESS: {
      return {
        ...state,
        isEditingClient: false,
      };
    }
    case ClientsActionTypes.PUT_CLIENT_ERROR: {
      return {
        ...state,
        isEditingClient: false,
      };
    }

    case ClientsActionTypes.DELETE_CLIENT_REQUEST: {
      return {
        ...state,
        isDeletingClient: true,
      };
    }
    case ClientsActionTypes.DELETE_CLIENT_SUCCESS: {
      return {
        ...state,
        isDeletingClient: false,
      };
    }
    case ClientsActionTypes.DELETE_CLIENT_ERROR: {
      return {
        ...state,
        isDeletingClient: false,
      };
    }

    case ClientsActionTypes.GET_CLIENT_ENTITLEMENTS_REQUEST: {
      return {
        ...state,
        isGettingClientEntitlements: true,
      };
    }
    case ClientsActionTypes.GET_CLIENT_ENTITLEMENTS_SUCCESS: {
      return {
        ...state,
        isGettingClientEntitlements: false,
        currentClientEntitlements: action.payload,
      };
    }
    case ClientsActionTypes.GET_CLIENT_ENTITLEMENTS_ERROR: {
      return {
        ...state,
        isGettingClientEntitlements: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as clientsReducer };
