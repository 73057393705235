/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import moment from 'moment';
import { CommonKey } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeKey = (raw: any): CommonKey => {
  return {
    uuid: raw.uuid,
    sha1: raw.sha1,
    name: raw.name,
    createdBy: raw.created_by_user?.email || raw.created_by_user?.username,
    isCreatedByUserDeleted: raw.created_by_user?.deleted,
    issuedAt: moment(_.get(raw, 'date_created')).format('x'),
    updatedAt: moment(
      _.get(raw, 'date_updated') || _.get(raw, 'date_created')
    ).format('x'),
  };
};
