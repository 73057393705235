import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';

const debug = require('debug')('pki-app:libs:provisioning');

export interface Customer {
  code: string;
  uuid: string;
  name: string;
  settings: {
    attribute: string;
    value: string;
  }[];
}
interface Vars {
  PKI_API_URL: string;
  PKI_SELECTED_CUSTOMER_CODE: string;
  PKI_CUSTOMERS: Customer[];
  KEYCLOAK_REALM: string;
  KEYCLOAK_URL: string;
  KEYCLOAK_CLIENT_ID: string;
  ENV: string;
  DOCUMENTATION_URL: string;
}

let PKI_API_URL: string;
let PKI_SELECTED_CUSTOMER_CODE: string;
let KEYCLOAK_REALM: string;
let KEYCLOAK_URL: string;
let KEYCLOAK_CLIENT_ID: string;
let DOCUMENTATION_URL: string;
let ENV: string;
let PKI_CUSTOMERS: Customer[];

export const initVars = async (): Promise<boolean> => {
  const {
    REACT_APP_PKI_API_URL,
    REACT_APP_KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_URL,
    REACT_APP_KEYCLOAK_CLIENT_ID,
    REACT_APP_DOCUMENTATION_URL,
    REACT_APP_ENV,
  } = process.env;

  if (
    REACT_APP_PKI_API_URL &&
    REACT_APP_KEYCLOAK_REALM &&
    REACT_APP_KEYCLOAK_URL &&
    REACT_APP_KEYCLOAK_CLIENT_ID &&
    REACT_APP_DOCUMENTATION_URL &&
    REACT_APP_ENV
  ) {
    PKI_API_URL = REACT_APP_PKI_API_URL;
    KEYCLOAK_REALM = REACT_APP_KEYCLOAK_REALM;
    KEYCLOAK_URL = REACT_APP_KEYCLOAK_URL;
    KEYCLOAK_CLIENT_ID = REACT_APP_KEYCLOAK_CLIENT_ID;
    DOCUMENTATION_URL = REACT_APP_DOCUMENTATION_URL;
    ENV = REACT_APP_ENV;
    debug('Variables provided by .env');
    return true;
  }
  try {
    const response = await axios.get('/vars.json');
    const {
      PKI_API_URL: _PKI_API_URL,
      KEYCLOAK_REALM: _KEYCLOAK_REALM,
      KEYCLOAK_URL: _KEYCLOAK_URL,
      KEYCLOAK_CLIENT_ID: _KEYCLOAK_CLIENT_ID,
      DOCUMENTATION_URL: _DOCUMENTATION_URL,
      ENV: _ENV,
    } = response.data;
    PKI_API_URL = _PKI_API_URL;
    KEYCLOAK_REALM = _KEYCLOAK_REALM;
    KEYCLOAK_URL = _KEYCLOAK_URL;
    KEYCLOAK_CLIENT_ID = _KEYCLOAK_CLIENT_ID;
    DOCUMENTATION_URL = _DOCUMENTATION_URL;
    ENV = _ENV;
    debug('Variables provided and set by vars.json');
    return true;
  } catch (e) {
    debug('Error fetching variables');
    return false;
  }
};

const getCustumers = async (): Promise<Customer[]> => {
  try {
    const ID_TOKEN = Cookies.get('id_token');
    const response = await axios.get(`${PKI_API_URL}/user/customer`, {
      headers: {
        Authorization: `Bearer ${ID_TOKEN}`,
      },
    });
    const customers = response.data;
    return customers;
  } catch (err) {
    debug(`getCustumers: ${err}`);
    if (err.response.status === 403) {
      throw new Error('There are no customers for the selected user');
    }
    throw new Error(err);
  }
};

export const initCustomers = async (): Promise<
  {
    code: string;
    name: string;
  }[]
> => {
  const customerCode = localStorage.getItem('pki_customer');
  try {
    const customers: Customer[] = await getCustumers();
    PKI_CUSTOMERS = customers;
    const isCustomerAvailable = !!_.find(
      customers,
      (customer) => customer.code === customerCode
    );
    if (!isCustomerAvailable || _.isNil(customerCode)) {
      if (!_.isEmpty(customers)) {
        const cutomerCode = customers[0].code;
        localStorage.setItem('pki_customer', cutomerCode);
        debug(
          `Default customer has been set. Current customer-code is: ${cutomerCode}`
        );
        PKI_SELECTED_CUSTOMER_CODE = cutomerCode;
        return customers;
      }
      debug(`Customers array is empty. Customer has not been set`);
      return [];
    }
    debug(
      `Customer has been found in previous session. Current customer-code is: ${customerCode}`
    );
    PKI_SELECTED_CUSTOMER_CODE = customerCode;
    return customers;
  } catch (err) {
    debug(`initCustomer: ${err}`);
    throw new Error(err);
  }
};

export const getVars = (): Vars => {
  return {
    PKI_API_URL,
    PKI_CUSTOMERS,
    PKI_SELECTED_CUSTOMER_CODE,
    KEYCLOAK_REALM,
    KEYCLOAK_URL,
    KEYCLOAK_CLIENT_ID,
    DOCUMENTATION_URL,
    ENV,
  };
};
