import React, { FC } from 'react';
import { Label } from 'reactstrap';
import { Moment } from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import './DateRangePicker.scss';

const { DatetimeRangePickerTrigger } = require('rc-datetime-picker');

interface Props {
  id?: string;
  value: { start: Moment | undefined; end: Moment | undefined };
  label?: string;
  format?: string;
  readOnly?: boolean;
  onChange: Function;
  className?: string;
  showTimePicker?: boolean;
  closeOnSelectDay?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
}

const DateRangePicker: FC<Props> = ({
  value,
  id,
  label,
  format = 'L',
  onChange = (): null => null,
  readOnly = false,
  className,
  showTimePicker = false,
  closeOnSelectDay = false,
  minDate,
  maxDate,
}) => {
  return (
    <div id={id} className={`DateRangePicker ${className}`}>
      <DatetimeRangePickerTrigger
        moment={value}
        showTimePicker={showTimePicker}
        onChange={(newValue: Moment): void => {
          onChange(newValue);
        }}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        dateLimit={{ month: 2 }}
      >
        {label && <Label className="pki-label">{label}</Label>}
        <div className="date-value cursor-pointer px-2 h-100">
          {value?.start?.format(format)} - {value?.end?.format(format)}
        </div>
      </DatetimeRangePickerTrigger>
    </div>
  );
};

export default DateRangePicker;
