export const keySpecOptions = [
  { key: undefined, value: 'No Value' },
  { key: 'RSA2048', value: 'RSA2048' },
  { key: 'RSA3072', value: 'RSA3072' },
  { key: 'RSA4096', value: 'RSA4096' },
  { key: 'secp192r1', value: 'NIST Curve P-192' },
  { key: 'secp224r1', value: 'NIST Curve P-224' },
  { key: 'secp256r1', value: 'NIST Curve P-256' },
  { key: 'secp384r1', value: 'NIST Curve P-384' },
  { key: 'secp521r1', value: 'NIST Curve P-521' },
];

export const signatureAlgorithmCodeSignProfileOptions = [
  { key: 'pkcsv15', value: 'RSA-PKCSV15' },
];

export const signatureAlgorithmNoDigestInfoKey = 'pkcsv15_no_digest_info';
export const signatureAlgorithmNoPadding = 'rsa_nopadding';
export const signatureAlgorithmPss = 'rsassa_pss';

export const outputFormatOptionsForNoDigestInfo = [
  { key: 'raw', value: 'RAW' },
];
export const outputFormatOptionsForNoPadding = [{ key: 'raw', value: 'RAW' }];
export const outputFormatOptionsForPss = [{ key: 'raw', value: 'RAW' }];

export const outputFormatOptions = [
  ...outputFormatOptionsForNoDigestInfo,
  { key: 'pkcs7cms', value: 'PKCS7 CMS' },
];

export const keyUsageOptions = [
  { key: 'digital_signature', value: 'Digital Signature' },
  { key: 'content_commitment', value: 'Content Commitment' },
  { key: 'key_encipherment', value: 'Key Encipherment' },
  { key: 'data_encipherment', value: 'Data Encipherment' },
  { key: 'key_agreement', value: 'Key Agreement' },
  { key: 'key_cert_sign', value: 'Key Cert. Sign.' },
  { key: 'crl_sign', value: 'CRL Sign.' },
  { key: 'encipher_only', value: 'Encipher Only' },
  { key: 'decipher_only', value: 'Decipher Only' },
];
export const extendedKeyUsageOptions = [
  { key: 'server_auth', value: 'Server Authentication' },
  { key: 'client_auth', value: 'Client Authentication' },
  { key: 'code_signing', value: 'Code Signing' },
  { key: 'email_protection', value: 'Email Protection' },
  { key: 'time_stamping', value: 'Time Stamping' },
  { key: 'ocsp_signing', value: 'OCSP Signing' },
];

export const subjectOptions = [
  { key: 'country_name', value: 'C' },
  { key: 'state_or_province_name', value: 'S' },
  { key: 'locality_name', value: 'L' },
  { key: 'organization_name', value: 'O' },
  { key: 'organizational_unit_name', value: 'OU' },
  { key: 'common_name', value: 'CN' },
  { key: 'serial_number', value: 'SERIALNUMBER' },
  { key: 'domain_component', value: 'DC' },
];

export const subjectOptionsDropdown = [
  { key: 'country_name', value: 'Country (C)' },
  { key: 'state_or_province_name', value: 'State/Province (S)' },
  { key: 'locality_name', value: 'Locality (L)' },
  { key: 'organization_name', value: 'Organization (O)' },
  { key: 'organizational_unit_name', value: 'Organizational Unit (OU)' },
  { key: 'common_name', value: 'Common Name (CN)' },
  { key: 'serial_number', value: 'Serial Number (SERIALNUMBER)' },
  { key: 'domain_component', value: 'Domain Component (DC)' },
];

export const keyPolicyOptions = [
  { key: 'kms', value: 'Protected by KMS' },
  { key: 'hsm', value: 'Protected by HSM' },
];

export const subjectKeysSorted = [
  'country_name',
  'state_or_province_name',
  'locality_name',
  'organization_name',
  'organizational_unit_name',
  'common_name',
  'serial_number',
  'domain_component',
];

export const subjectPreviewKeys = {
  country_name: 'C',
  state_or_province_name: 'S',
  locality_name: 'L',
  organization_name: 'O',
  organizational_unit_name: 'OU',
  common_name: 'CN',
  serial_number: 'SERIALNUMBER',
  domain_component: 'DC',
};

export const subjectAlternativeNamesOptions = [
  { key: 'dns_name', value: 'DNS' },
  { key: 'uniform_resource_identifier', value: 'URI' },
  { key: 'rfc822_name', value: 'RFC822' },
  { key: 'ip_address', value: 'IP' },
];

export const certificateRevokeOptions = [
  { key: '0', value: 'Unspecified' },
  { key: '1', value: 'Key Compromise' },
  { key: '2', value: 'CA Compromise' },
  { key: '3', value: 'Affiliation Changed' },
  { key: '4', value: 'Superseded' },
  { key: '5', value: 'Cessation Of Operation' },
  { key: '9', value: 'Privilege Withdrawn' },
];

export const customExtensionEncodingOptions = [
  { key: 'hex_der_asn1', value: 'Hex Encoded DER (ASN.1)' },
  { key: 'integer', value: 'Integer' },
  { key: 'utf8_string', value: 'UTF8 String' },
  { key: 'printable_string', value: 'Printable String' },
  { key: 'ia5_string', value: 'IA5 String' },
];

export const generalDefaultValues = {
  name: '',
  profileId: undefined,
  description: '',
  issuingCaUuid: '',
  issuerCN: '',
  commonKeyUuid: undefined,
  validity: 1,
  keySpec: keySpecOptions[0].key,
  signatureAlgorithm: '',
  signatureHashAlgorithm: '',
  subject: [],
};

export const policiesDefaultValues = {
  keyPolicy: 'kms',
  approvalPolicy: {
    approverUuids: [],
  },
  protocolsPolicy: [],
};

export const extensionsDefaultValues = {
  keyUsage: [],
  extendedKeyUsage: [],
  subjectAlternativeNames: [],
  CRLDistributionPointURIs: [],
  authorityInformationAccess: [],
};

export const customExtensions = [];

export const certificatePolicies = {
  certificatePolicies: [],
  critical: false,
};

export const AIAAccessType: { [type: string]: string } = {
  CA_ISSUER: '1.3.6.1.5.5.7.48.2',
  OCSP: '1.3.6.1.5.5.7.48.1',
};

export const AIAAccessOptions = [
  { key: 'CA_ISSUER', value: 'CA_ISSUER' },
  { key: 'OCSP', value: 'OCSP' },
];

export const colors = [
  '#e57373',
  '#ba68c8',
  '#90caf9',
  '#4db6ac',
  '#dce775',
  '#ffb74d',
  '#b0bec5',
  '#81c784',
];

export enum Resource {
  CertificateRequest = 'certificate_request',
  CertificateAuthority = 'certificate_authority',
  Certificate = 'certificate',
  Client = 'client',
  CodeSigningCertificateRequest = 'code_signing_key',
  CertificateProfile = 'certificate_profile',
  ProvisioningProfile = 'manufacturing_station_provisioning_profile',
}

export enum Role {
  CAAdmin = 'ca_admin',
}
