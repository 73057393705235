import React, { FC, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { includes, pick } from 'lodash';
import { IPatchProductProfile, ProductProfile } from '../../../types';
import { serializeProductProfile } from '../../../helpers';

interface GeneralInfoProps {
  editMode?: boolean;
  isLoading?: boolean;
  profileId: string;
  onSubmit: IPatchProductProfile;
  profile: ProductProfile;
}

export const GeneralInfo: FC<GeneralInfoProps> = ({
  editMode = false,
  profile,
  onSubmit = () => undefined,
  isLoading,
}) => {
  const [formValues, setFormValues] = useState<Partial<ProductProfile>>(
    profile
  );
  const [editingMode, setEditingMode] = useState(editMode);

  const onGeneralValueChange: React.ChangeEventHandler<HTMLInputElement> = (
    ev
  ) => {
    if (formValues) {
      setFormValues({
        ...formValues,
        [ev.target.name]: ev.target.value,
      });
    }
  };

  const onCancelClick = () => {
    setFormValues(profile);
    setEditingMode(false);
  };

  const onConfirmClick = async () => {
    const requestBody = pick(serializeProductProfile(formValues), [
      'name',
      'notes',
      'product_profile_id',
    ]);
    onSubmit(requestBody, () => setEditingMode(false));
  };

  const canEdit = includes(profile?.actions, 'update');

  return (
    <Collapse isOpen={!isLoading && formValues !== undefined}>
      <div id={'product-profile-general-info'}>
        <Row>
          <Col xs={6}>
            <Label className="pki-label" for={'name'}>
              Name
            </Label>
            <FormGroup>
              {editingMode && canEdit ? (
                <Input
                  value={formValues?.name || ''}
                  type="text"
                  onChange={onGeneralValueChange}
                  name="name"
                  id="name"
                />
              ) : (
                <span>{profile?.name || ''}</span>
              )}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <Label className="pki-label" for={'productProfileId'}>
              REST API ID
            </Label>
            <FormGroup>
              {editingMode && canEdit ? (
                <Input
                  value={formValues?.productProfileId || ''}
                  type="text"
                  onChange={onGeneralValueChange}
                  name="productProfileId"
                  id="productProfileId"
                />
              ) : (
                <span>{profile?.productProfileId}</span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label className="pki-label" for={'notes'}>
              Notes
            </Label>
            <FormGroup>
              {editingMode && canEdit ? (
                <Input
                  value={formValues?.notes || ''}
                  type="textarea"
                  onChange={onGeneralValueChange}
                  name="notes"
                  id="notes"
                />
              ) : (
                <span>{profile?.notes || 'N/A'}</span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6} className="mb-2">
            <div className="pki-label d-block">Created At</div>
            {profile?.createdAt}
          </Col>
          <Col xs={6} className="mb-2">
            <div className="pki-label d-block">Created By</div>
            {profile?.createdBy?.email}
          </Col>
        </Row>
        <Row>
          <Col xs={6} className="mb-2">
            <div className="pki-label d-block">Updated At</div>
            {profile?.updatedAt}
          </Col>
          <Col xs={6} className="mb-2">
            <div className="pki-label d-block">Updated By</div>
            {profile?.updatedBy?.email}
          </Col>
        </Row>
        <div className="product-profile-buttons-container d-flex justify-content-end">
          {!editingMode && (
            <Button
              outline
              id={'edit-product-profile'}
              disabled={!canEdit}
              onClick={() => setEditingMode(true)}
            >
              Edit
            </Button>
          )}
          {editingMode && canEdit && (
            <>
              <Button
                outline
                onClick={onCancelClick}
                id={'cancel-edit-product-profile'}
                className="mr-1"
              >
                Cancel
              </Button>
              <Button
                outline
                onClick={onConfirmClick}
                id={'submit-edit-product-profile'}
              >
                Confirm
              </Button>
            </>
          )}
        </div>
      </div>
    </Collapse>
  );
};
