import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import _, { keys, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Spinner } from '../../../components';
import { ApplicationState } from '../../../store';
import { getRequestsCounter } from '../../../store/requests/actions';
import { RequestsState } from '../../../store/requests/types';
import { colors } from '../../../libs/constants';

const NOW = moment();
const oneMonthAgo = NOW.subtract(1, 'month').format('LL');
const today = moment().format('LL');

const RequestsStatus: FC = () => {
  const {
    isGettingRequestCounter,
    isLoadedRequestCounter,
    requestCounter,
  } = useSelector<ApplicationState, RequestsState>((pki) => ({
    ...pki.requests,
  }));

  const [showChartSpinner, setShowChartSpinner] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingRequestCounter) {
      setShowChartSpinner(true);
    }
    if (isLoadedRequestCounter) {
      setShowChartSpinner(false);
    }
  }, [isGettingRequestCounter, isLoadedRequestCounter]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    dispatch(getRequestsCounter(tokenSource));
    return function cleanup(): void {
      tokenSource.cancel('RequestStatus::getRequestsCounter');
    };
  }, [dispatch]);

  let counter = 0;
  const requestPieChartData = _.map(requestCounter, (value, key) => ({
    title: key,
    value,
    // eslint-disable-next-line no-plusplus
    color: colors[counter++],
  }));

  const data = {
    labels: keys(requestCounter),
    datasets: [
      {
        data: values(requestCounter),
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        backgroundColor: colors,
      },
    ],
  };

  return (
    <div className="RequestsStatus overflow-auto">
      <div className="header-contanier d-flex">
        <h5 className="text-muted">Requests Status</h5>
        {showChartSpinner && (
          <Spinner className="mt-1 ml-2" size="sm" type="border" />
        )}
      </div>
      <small>
        Request status counter from {oneMonthAgo} to {today}
      </small>
      <div className="content mt-5">
        {!_.size(requestPieChartData) && isLoadedRequestCounter && (
          <div className="text-center mt-5">No Requests Yet</div>
        )}
        <div className="text-center align-middle h-100">
          <Pie
            data={data}
            options={{ responsive: true }}
            legend={{ position: 'bottom' }}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestsStatus;
