import _ from 'lodash';
import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { TableActions } from '../../components';
import {
  areThereNestedBrackets,
  balancedBrackets,
  extractOptionalDefault,
  extractVariable,
  oppositeRequirement,
  DEFAULT_VARS_PATTERN,
  AUTOGEN_VARS_PATTERN,
} from '../../libs/helpers';
import { countryCodes } from '../../libs/countryCodes';

export const getTableColumns = (
  propertyName: string,
  propertyValues: { key: string; values: string[] }[],
  labels: string[],
  onClick: Function,
  readOnly = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => [
  { dataField: 'id', text: 'Id', hidden: true },
  {
    dataField: 'key',
    style: { width: '338px' },
    text: labels[0],
    formatter: (key: string): ReactNode => (
      <strong>
        <span className="value">{key}</span>
      </strong>
    ),
  },
  {
    dataField: 'values',
    text: labels[1],
    formatter: (values: string): ReactNode => <>{_.join(values, ',  ')}</>,
  },
  {
    dataField: '',
    text: labels[2],
    formatExtraData: propertyValues && readOnly, // this is needed due a bug into data table
    formatter: (
      key: string,
      row: { id: string; key: string; values: string[] }
    ): ReactNode =>
      readOnly ? (
        ''
      ) : (
        <TableActions
          rowId={row.id}
          options={[
            {
              label: 'Delete',
              ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
              onClick: (): void => {
                onClick(row);
              },
            },
          ]}
        />
      ),
  },
];

export const keyToValue = (
  input: { key: string; value: string }[]
): { [index: string]: string } => {
  return _.transform(
    input,
    (output, { value, key }: { value: string; key: string }) => {
      _.set(output, key, value);
    },
    {}
  );
};

export const validateTemplateVariable = (value: string) => {
  if (!balancedBrackets(value)) {
    return {
      isInvalid: true,
      message: 'Brackets are not balanced.',
    };
  }
  return { message: '', isInvalid: false };
};

export const certSubjectValidation = (
  subject: { key: string; value: string },
  mainSubjectSorted: { key: string; value: string }[],
  secondarySubjectSorted: { key: string; value: string }[]
): {
  isInvalid: boolean;
  message: string;
} => {
  if (!balancedBrackets(subject.value)) {
    return {
      isInvalid: true,
      message: 'Brackets are not balanced.',
    };
  }
  const flatSingleVarItem = destructSingleVarItem(subject);
  const variable = extractVariable(subject.value);
  const optionalDefault = extractOptionalDefault(subject.value);
  const isOppositeVarInSubject = (
    subjectList: { key: string; value: string }[]
  ) => {
    if (
      variable &&
      subjectList.filter((item) =>
        item.value.includes(oppositeRequirement(variable))
      ).length > 0
    )
      return true;
    return false;
  };

  if (
    isOppositeVarInSubject(mainSubjectSorted) ||
    isOppositeVarInSubject(secondarySubjectSorted) ||
    isOppositeVarInSubject(flatSingleVarItem)
  ) {
    return {
      isInvalid: true,
      message: variable?.includes('required')
        ? 'Variable name exists with "optional" and cannot change to "required"'
        : 'Variable name exists with "required" and cannot change to "optional"',
    };
  }

  if (
    optionalDefault &&
    mainSubjectSorted.filter((item) => {
      const varName = extractVariable(item.value);
      const optionalVal = extractOptionalDefault(item.value);

      return !!(
        optionalVal &&
        varName &&
        varName === variable &&
        optionalVal !== optionalDefault
      );
    }).length > 0
  ) {
    return {
      isInvalid: true,
      message: 'Variable name exists with different default value.',
    };
  }

  if (areThereNestedBrackets(subject.value)) {
    return {
      isInvalid: true,
      message: 'Nested brackets are not allowed.',
    };
  }
  if (subject.key === 'Country (C)') {
    let isInvalid;
    let message = 'It must be a valid two-character country code.';
    if (subject.value.startsWith('{') && subject.value.endsWith('}')) {
      isInvalid = true;
      [
        ':length2:',
        ':length2}',
        ':country_code:',
        ':country_code}',
        ':country_code2:',
        ':country_code2}',
      ].every((delimiter) => {
        if (subject.value.toLowerCase().includes(delimiter)) {
          isInvalid = false;
          return false;
        }
        return true;
      });
      message =
        'Invalid variable expression; refer to the documentation for valid examples.';
    } else {
      isInvalid = !countryCodes.includes(subject.value.toUpperCase().trim());
    }
    return {
      isInvalid,
      message,
    };
  }

  return { message: '', isInvalid: false };
};

export const formatSanValueArrayToKeyValuePairsArray = (
  sanKeyPair: { key: string; values: string[] }[]
): Array<{ key: string; value: string }> => {
  const output: Array<{ key: string; value: string }> = [];
  sanKeyPair.forEach((sanItem) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sanItem.values.length; i++) {
      output.push({ key: sanItem.key, value: sanItem.values[i] });
    }
  });
  return output;
};

export const splitVariables = (splitVariablesValue: string) => {
  // Two REGEX expressions used depending if autogen is used and may contain nested braces
  if (splitVariablesValue.toLowerCase().includes('autogen')) {
    return splitVariablesValue.match(AUTOGEN_VARS_PATTERN);
  }
  return splitVariablesValue.match(DEFAULT_VARS_PATTERN);
};

export const destructSingleVarItem = (subject: {
  key: string;
  value: string;
}): Array<{ key: string; value: string }> => {
  const splitValueVariable = splitVariables(subject.value);
  const output: Array<{ key: string; value: string }> = [];
  splitValueVariable?.forEach((item) => {
    const bracketItem = `${item}`;
    output.push({ key: subject.key, value: bracketItem });
  });
  return output;
};

export const combineAndDestructSubjectVars = (
  subjectVars: { key: string; value: string }[],
  certificateSubjectAltNameVars: { key: string; values: string[] }[]
): { key: string; value: string }[] => {
  const spliceItemsArray: number[] = [];
  const combinedVars = _.cloneDeep(subjectVars) || [];
  // Add san vars to combined var
  certificateSubjectAltNameVars.forEach((sanItem) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sanItem.values.length; i++) {
      combinedVars.push({ key: sanItem.key, value: sanItem.values[i] });
    }
  });
  // Split out any item that has multiple vars
  combinedVars.forEach((combinedItem) => {
    const destructedItem = destructSingleVarItem(combinedItem);
    if (destructedItem.length > 1) {
      spliceItemsArray.push(combinedVars.indexOf(combinedItem));
      destructedItem.forEach((destruct) => {
        combinedVars.push(destruct);
      });
    }
  });
  // Clean combined var list of original destructed items
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < spliceItemsArray.length; index++) {
    combinedVars.splice(spliceItemsArray[index] - index, 1);
  }
  return combinedVars;
};
