import { Reducer } from 'redux';
import {
  ManufacturingStationsState,
  ManufacturingStationsActionTypes,
} from './types';

export const initialState: ManufacturingStationsState = {
  manufacturingStationsList: [],
  isGettingManufacturingStationList: false,
  isLoadedManufacturingStationList: false,
  isCreatingManufacturingStation: false,
  isEditingManufacturingStation: false,
  manufacturingStationErrors: undefined,
  manufacturingStation: undefined,
  isGettingOnlineCAList: false,
  OnlineCAList: [],
  isGettingCertificateProfileList: false,
  certificateProfileList: [],
  currentProvisioningProfiles: [],
  isGettingProvisioningProfiles: false,
  isLoadedProvisioningProfiles: false,
  isEditingProvisioningProfiles: false,
  currentSecureTokens: [],
  isGettingSecureTokens: false,
  isLoadedSecureTokens: false,
  isEditingSecureTokens: false,
  isGettingManufacturingStation: false,
  isLoadedManufacturingStation: false,
};

const reducer: Reducer<ManufacturingStationsState> = (
  state = initialState,
  action
): ManufacturingStationsState => {
  switch (action.type) {
    case ManufacturingStationsActionTypes.POST_MANUFACTURING_STATION_REQUEST: {
      return {
        ...state,
        isCreatingManufacturingStation: true,
      };
    }
    case ManufacturingStationsActionTypes.POST_MANUFACTURING_STATION_SUCCESS: {
      return {
        ...state,
        isCreatingManufacturingStation: false,
      };
    }
    case ManufacturingStationsActionTypes.POST_MANUFACTURING_STATION_ERROR: {
      return {
        ...state,
        isCreatingManufacturingStation: false,
      };
    }

    case ManufacturingStationsActionTypes.PUT_MANUFACTURING_STATION_REQUEST: {
      return {
        ...state,
        isEditingManufacturingStation: true,
      };
    }
    case ManufacturingStationsActionTypes.PUT_MANUFACTURING_STATION_SUCCESS: {
      return {
        ...state,
        isEditingManufacturingStation: false,
        manufacturingStation: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.PUT_MANUFACTURING_STATION_ERROR: {
      return {
        ...state,
        isEditingManufacturingStation: false,
      };
    }

    case ManufacturingStationsActionTypes.GET_MANUFACTURING_STATIONS_LIST_REQUEST: {
      return {
        ...state,
        isGettingManufacturingStationList: true,
        isLoadedManufacturingStationList: false,
        manufacturingStationErrors: undefined,
      };
    }
    case ManufacturingStationsActionTypes.GET_MANUFACTURING_STATIONS_LIST_SUCCESS: {
      return {
        ...state,
        isGettingManufacturingStationList: false,
        isLoadedManufacturingStationList: true,
        manufacturingStationsList: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.GET_MANUFACTURING_STATIONS_LIST_ERROR: {
      return {
        ...state,
        isGettingManufacturingStationList: false,
        isLoadedManufacturingStationList: false,
        manufacturingStationErrors: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.GET_MANUFACTURING_STATION_REQUEST: {
      return {
        ...state,
        isGettingManufacturingStation: true,
        isLoadedManufacturingStation: false,
        manufacturingStationErrors: undefined,
      };
    }
    case ManufacturingStationsActionTypes.GET_MANUFACTURING_STATION_SUCCESS: {
      return {
        ...state,
        isGettingManufacturingStation: false,
        isLoadedManufacturingStation: true,
        manufacturingStation: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.GET_MANUFACTURING_STATION_ERROR: {
      return {
        ...state,
        isGettingManufacturingStation: false,
        isLoadedManufacturingStation: false,
        manufacturingStationErrors: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.GET_PROVISIONING_PROFILES_REQUEST: {
      return {
        ...state,
        isGettingProvisioningProfiles: true,
        isLoadedProvisioningProfiles: false,
      };
    }
    case ManufacturingStationsActionTypes.GET_PROVISIONING_PROFILES_SUCCESS: {
      return {
        ...state,
        isGettingProvisioningProfiles: false,
        isLoadedProvisioningProfiles: true,
        currentProvisioningProfiles: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.GET_PROVISIONING_PROFILES_ERROR: {
      return {
        ...state,
        isGettingProvisioningProfiles: false,
        isLoadedProvisioningProfiles: false,
      };
    }
    case ManufacturingStationsActionTypes.POST_PROVISIONING_PROFILE_REQUEST: {
      return {
        ...state,
        isEditingProvisioningProfiles: true,
      };
    }
    case ManufacturingStationsActionTypes.POST_PROVISIONING_PROFILE_SUCCESS: {
      return {
        ...state,
        isEditingProvisioningProfiles: false,
      };
    }
    case ManufacturingStationsActionTypes.POST_PROVISIONING_PROFILE_ERROR: {
      return {
        ...state,
        isEditingProvisioningProfiles: false,
      };
    }
    case ManufacturingStationsActionTypes.DELETE_PROVISIONING_PROFILE_REQUEST: {
      return {
        ...state,
        isEditingProvisioningProfiles: true,
      };
    }
    case ManufacturingStationsActionTypes.DELETE_PROVISIONING_PROFILE_SUCCESS: {
      return {
        ...state,
        isEditingProvisioningProfiles: false,
      };
    }
    case ManufacturingStationsActionTypes.DELETE_PROVISIONING_PROFILE_ERROR: {
      return {
        ...state,
        isEditingProvisioningProfiles: false,
      };
    }
    case ManufacturingStationsActionTypes.GET_SECURE_TOKENS_REQUEST: {
      return {
        ...state,
        isGettingSecureTokens: true,
        isLoadedSecureTokens: false,
      };
    }
    case ManufacturingStationsActionTypes.GET_SECURE_TOKENS_SUCCESS: {
      return {
        ...state,
        isGettingSecureTokens: false,
        isLoadedSecureTokens: true,
        currentSecureTokens: action.payload,
      };
    }
    case ManufacturingStationsActionTypes.GET_SECURE_TOKENS_ERROR: {
      return {
        ...state,
        isGettingSecureTokens: false,
        isLoadedSecureTokens: false,
      };
    }
    case ManufacturingStationsActionTypes.POST_SECURE_TOKEN_REQUEST: {
      return {
        ...state,
        isEditingSecureTokens: true,
      };
    }
    case ManufacturingStationsActionTypes.POST_SECURE_TOKEN_SUCCESS: {
      return {
        ...state,
        isEditingSecureTokens: false,
      };
    }
    case ManufacturingStationsActionTypes.POST_SECURE_TOKEN_ERROR: {
      return {
        ...state,
        isEditingSecureTokens: false,
      };
    }
    case ManufacturingStationsActionTypes.PUT_SECURE_TOKEN_REQUEST: {
      return {
        ...state,
        isEditingSecureTokens: true,
      };
    }
    case ManufacturingStationsActionTypes.PUT_SECURE_TOKEN_SUCCESS: {
      return {
        ...state,
        isEditingSecureTokens: false,
      };
    }
    case ManufacturingStationsActionTypes.PUT_SECURE_TOKEN_ERROR: {
      return {
        ...state,
        isEditingSecureTokens: false,
      };
    }
    case ManufacturingStationsActionTypes.CLEAR_MANUFACTURING_STATION: {
      return {
        ...state,
        currentSecureTokens: [],
        currentProvisioningProfiles: [],
        manufacturingStation: action.manufacturingStation,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as manufacturingStationsReducer };
