import { AnyAction, Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { NotificationsActionTypes } from './types';

export const sendNotification = ({
  text,
  success = true,
}: {
  text: string;
  success?: boolean;
}) => (dispatch: Dispatch<AnyAction>): void => {
  toast[success ? 'success' : 'error'](text, {
    autoClose: success ? 5000 : false,
  });
  dispatch({
    type: NotificationsActionTypes.SEND_NOTIFICATION,
    payload: { text, success },
  });
};
