import _, { map } from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios, { CancelTokenSource } from 'axios';
import { AnyAction } from 'redux';
import { sendNotification } from '../notifications/actions';
import {
  deserializeCertificateTemplate,
  serializeCertificateTemplate,
} from './helpers';

import {
  CertificateTemplate,
  CertificateTemplatesState,
  CertificateTemplateActionTypes,
} from './types';

import { api, getUrlWithFilters } from '../../libs/helpers';
import { Filter } from '../../components/FilterBar/types';

const error = require('debug')('pki-app:error:certificateTemplates');

interface RequestParams {
  tokenSource?: CancelTokenSource;
  action?: string;
  resource?: string;
  removeActions?: boolean;
  page?: number | undefined;
  sizePerPage?: number | undefined;
  uuid?: string | undefined;
  query?: Filter[];
  sortBy?: string;
  sortDir?: string;
}

export const getCertificateTemplates = (
  {
    tokenSource,
    sizePerPage,
    page,
    query,
    uuid,
    sortBy,
    sortDir,
  } = {} as RequestParams
): ThunkAction<Promise<boolean>, CertificateTemplatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateTemplatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificateTemplateActionTypes.GET_TEMPLATE_LIST_REQUEST,
    });
    let url = 'certificate/template';
    url = getUrlWithFilters(url, {
      sizePerPage,
      page,
      sortBy,
      sortDir,
      query,
      uuid,
    });
    try {
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificateTemplate(item)
      );
      dispatch({
        type: CertificateTemplateActionTypes.GET_TEMPLATE_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificateTemplateActionTypes.GET_TEMPLATE_LIST_ERROR,
        payload: err,
      });
      if (!axios.isCancel(err)) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Certificate Templates list!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getCertificateTemplates: ${err}`);
      return false;
    }
  };
};

export const createCertificateTemplate = (
  certificateTemplate: CertificateTemplate
): ThunkAction<Promise<boolean>, CertificateTemplatesState, {}, AnyAction> => {
  const certificateTemplateSerialized = serializeCertificateTemplate(
    certificateTemplate
  );
  return async (
    dispatch: ThunkDispatch<CertificateTemplatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateTemplateActionTypes.POST_TEMPLATE_REQUEST });
    try {
      const payload = await api().post('certificate/template', {
        ...certificateTemplateSerialized,
      });
      dispatch({
        type: CertificateTemplateActionTypes.POST_TEMPLATE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Certificate Template has been successfully created!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateTemplateActionTypes.POST_TEMPLATE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the Certificate Template!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createCertificateTemplate: ${err}`);
      return false;
    }
  };
};

export const editCertificateTemplate = (
  certificateTemplate: CertificateTemplate
): ThunkAction<Promise<boolean>, CertificateTemplatesState, {}, AnyAction> => {
  const { uuid } = certificateTemplate;
  const certificateTemplateSerialized = serializeCertificateTemplate(
    certificateTemplate
  );
  return async (
    dispatch: ThunkDispatch<CertificateTemplatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateTemplateActionTypes.PUT_TEMPLATE_REQUEST });
    try {
      const payload = await api().put(`certificate/template/${uuid}`, {
        ...certificateTemplateSerialized,
      });
      dispatch({
        type: CertificateTemplateActionTypes.PUT_TEMPLATE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Certificate Template has been successfully edited!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateTemplateActionTypes.PUT_TEMPLATE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong editing the Certificate Template!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`editCertificateTemplate: ${err}`);
      return false;
    }
  };
};

export const deleteCertificateTemplates = (
  uuids: string[]
): ThunkAction<Promise<boolean>, CertificateTemplatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateTemplatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateTemplateActionTypes.DELETE_TEMPLATE_REQUEST });
    try {
      const arrayOfPromises = map(uuids, (uuid) =>
        api().delete(`certificate/template/${uuid}`)
      );
      await Promise.all(arrayOfPromises);
      dispatch({
        type: CertificateTemplateActionTypes.DELETE_TEMPLATE_SUCCESS,
      });
      sendNotification({
        text: 'The Certificate Template has been successfully deleted!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateTemplateActionTypes.DELETE_TEMPLATE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong deleting the Certificate Template!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`deleteCertificateTemplates: ${err}`);
      return false;
    }
  };
};
