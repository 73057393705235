import { Reducer } from 'redux';
import { CommonKeysState, CommonKeysActionTypes } from './types';

export const initialState: CommonKeysState = {
  commonKeyList: [],
  isGettingCommonKeyList: false,
  isLoadedCommonKeyList: false,
  isUploadingKey: false,
  commonKeysErrors: undefined,
};

const reducer: Reducer<CommonKeysState> = (
  state = initialState,
  action
): CommonKeysState => {
  switch (action.type) {
    case CommonKeysActionTypes.GET_KEY_LIST_REQUEST: {
      return {
        ...state,
        isGettingCommonKeyList: true,
        isLoadedCommonKeyList: false,
        commonKeysErrors: undefined,
      };
    }
    case CommonKeysActionTypes.GET_KEY_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCommonKeyList: false,
        isLoadedCommonKeyList: true,
        commonKeyList: action.payload,
      };
    }
    case CommonKeysActionTypes.GET_KEY_LIST_ERROR: {
      return {
        ...state,
        isGettingCommonKeyList: false,
        isLoadedCommonKeyList: false,
        commonKeysErrors: action.payload,
      };
    }

    case CommonKeysActionTypes.POST_KEY_REQUEST: {
      return {
        ...state,
        isUploadingKey: true,
      };
    }
    case CommonKeysActionTypes.POST_KEY_SUCCESS: {
      return {
        ...state,
        isUploadingKey: false,
      };
    }
    case CommonKeysActionTypes.POST_KEY_ERROR: {
      return {
        ...state,
        isUploadingKey: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as commonKeysReducer };
