import _ from 'lodash';
import moment from 'moment';
import { mapKeysWithValues } from '../../libs/helpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deserializeCertificateProfile,
  serializeCertificateProfile,
} from '../certificateProfiles/helpers';
import {
  CertificateRequest,
  CodeSigningRequest,
  CodeSigningCertificateRequest,
  RequestType,
} from './types';

export const serializeCertificateRequest = (
  request: CertificateRequest
): any => {
  return {
    certificate_profile: {
      ...serializeCertificateProfile(request.certificateProfile)
        .certificate_profile,
      uuid: request.certificateProfile.uuid,
    },
    certificate_profile_id: request.certificateProfile.uuid,
    issuing_ca_id: request.issuingCAUuid,
    csr: request.csr,
    csr_filename: request.csrFilename,
    description: request.notes,
    request_type: 'cert',
    quantity: request.certificateProfile.batchEnabled
      ? request.quantity
      : undefined,
    certificate_subject_vars: mapKeysWithValues(request.certificateSubjectVars),
  };
};

export const deserializeCertificateRequest = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): CertificateRequest => ({
  certificateProfile: {
    ...deserializeCertificateProfile({
      certificate_profile: raw.certificate_profile,
    }),
    uuid: raw.certificate_profile.uuid,
  },
  actions: raw.actions,
  batch: raw.batch,
  issuingCAUuid: raw.issuing_ca_id,
  notes: raw.description,
  uuid: raw.certificate_request_id,
  csr: raw.csr,
  quantity: raw.quantity,
  certificateSubjectVars: raw.certificate_subject_vars,
  certificateUuid: raw.certificate_id,
  certificateRequestId: raw.certificate_request_id,
  certificateBatchId: raw.certificate_batch_id,
  certificateProfileId: raw.certificate_profile_id,
  csrFilename: raw.csr_filename,
  createdBy: raw.created_by_user?.email || raw.created_by_user?.username,
  isCreatedByUserDeleted: raw.created_by_user?.deleted,
  updatedBy:
    raw.updated_by_user?.email ||
    raw.created_by_user?.email ||
    raw.created_by_user?.username,
  requestorUuid: raw.created_by_user?.uuid,
  issuedAt: moment(_.get(raw, 'date_created')).format('x'),
  updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format('x'),
  status: _.startCase(raw.status),
  type: RequestType.Certificate,
});

export const serializeCodeSigningKeyRequest = (
  request: CodeSigningCertificateRequest
): any => ({
  certificate_profile_uuid: request.certificateProfileUuid,
  certificate_subject_vars: mapKeysWithValues(request.certificateSubjectVars),
  notes: request.notes,
});

export const deserializeCodeSigningKeyRequest = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): CodeSigningCertificateRequest => ({
  certificateProfileUuid: raw.certificate_profile_uuid,
  certificateUuid: raw.certificate_uuid,
  certificateSubjectVars: raw.certificate_subject_vars || {},
  actions: raw.actions,
  issuingCAUuid: raw.issuing_ca_uuid,
  notes: raw.notes,
  approverNotes: raw?.approver_notes,
  uuid: raw.uuid,
  certificateCN: raw.certificate_cn,
  createdBy: raw.created_by_user?.email || raw.created_by_user?.username,
  isCreatedByUserDeleted: raw.created_by_user?.deleted,
  requestorUuid: raw.created_by_user?.uuid,
  updatedBy:
    raw.updated_by_user?.email ||
    raw.created_by_user?.email ||
    raw.created_by_user?.username,
  issuedAt: moment(_.get(raw, 'date_created')).format('x'),
  updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format('x'),
  status: _.startCase(raw.status),
  type: RequestType.CodeSigningCertificate,
});

export const serializeCodeSigningRequest = (
  request: CodeSigningRequest
): any => ({
  code_signing_profile_uuid: request.codeSigningProfileUuid,
  tbs_digest: request.digest,
  notes: request.notes,
});

export const deserializeCodeSigningRequest = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): CodeSigningRequest => ({
  codeSigningProfileUuid: raw.code_signing_profile_uuid,
  certificateUuid: raw.certificate_uuid,
  actions: raw.actions,
  digest: raw.tbs_digest,
  signature: raw.signature,
  outputFormat: raw.output_format,
  notes: raw.notes,
  approverNotes: raw?.approver_notes,
  uuid: raw.uuid,
  createdBy: raw.created_by_user?.email || raw.created_by_user?.username,
  isCreatedByUserDeleted: raw.created_by_user?.deleted,
  updatedBy:
    raw.updated_by_user?.email ||
    raw.created_by_user?.email ||
    raw.created_by_user?.username,
  requestorUuid: raw.created_by_user?.uuid,
  issuedAt: moment(_.get(raw, 'date_created')).format('x'),
  updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format('x'),
  status: _.startCase(raw.status),
  type: RequestType.CodeSigning,
});
