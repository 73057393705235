import { Reducer } from 'redux';
import { DeviceProvisioningTypes, DeviceProvisioningState } from './types';

export const initialState: DeviceProvisioningState = {
  deviceProvisioningList: [],
  isGettingDeviceProvisioningList: false,
  isLoadedDeviceProvisioningList: false,
  deviceProvisioningErrors: undefined,
};

const reducer: Reducer<DeviceProvisioningState> = (
  state = initialState,
  action
): DeviceProvisioningState => {
  switch (action.type) {
    case DeviceProvisioningTypes.GET_DEVICE_PROVISIONING_REQUEST: {
      return {
        ...state,
        isGettingDeviceProvisioningList: true,
        isLoadedDeviceProvisioningList: false,
        deviceProvisioningErrors: undefined,
      };
    }
    case DeviceProvisioningTypes.GET_DEVICE_PROVISIONING_SUCCESS: {
      return {
        ...state,
        isGettingDeviceProvisioningList: false,
        isLoadedDeviceProvisioningList: true,
        deviceProvisioningList: action.payload,
      };
    }
    case DeviceProvisioningTypes.GET_DEVICE_PROVISIONING_ERROR: {
      return {
        ...state,
        isGettingDeviceProvisioningList: false,
        isLoadedDeviceProvisioningList: false,
        deviceProvisioningErrors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as deviceProvisioningReducer };
