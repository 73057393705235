/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import moment from 'moment';
import { AuditLog } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeAuditLog = (raw: any): AuditLog => ({
  id: _.uniqueId(raw.timestamp),
  eventName: _.startCase(_.lowerCase(raw.event)),
  description: raw.event_description,
  timestamp: moment.utc(String(raw.timestamp).split('.')[0]),
  eventOutcome: _.startCase(_.lowerCase(raw.event_outcome)),
  authorizedUser: raw?.authorized_user?.email || raw?.authorized_user?.username,
  details: raw.details,
});
