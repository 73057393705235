import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faFile } from '@fortawesome/free-solid-svg-icons';
import {
  ErrorHandler,
  FilterBar,
  Spinner,
  Table,
  TableActions,
} from '../../components';
import { sendNotification } from '../../store/notifications/actions';
import { api, getUrlWithFilters } from '../../libs/helpers';
import { Filter } from '../../components/FilterBar/types';
import { deserializeDevice } from './helpers';
import { Device } from './types';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'updatedAt':
      return 'date_updated';
    case 'createdAt':
      return 'date_created';
    case 'updatedBy':
      return 'updated_by_user_uuid';
    case 'createdBy':
      return 'created_by_user_uuid';
    case 'productProfileName':
      return 'product_profile.name';
    case 'deviceId':
      return 'device_id';
    case 'reportedAt':
      return 'date_reported';
    case 'activatedAt':
      return 'date_activated';
    default:
      return sortField;
  }
};

const Devices: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [devices, setDevices] = useState<Device[]>([]);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [showContent, setShowContent] = useState<boolean>(false);
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uuidParam = params.get('uuid');
  const deviceRequestUuidParam = params.get('device_request_uuid');
  const initialQuery: Filter[] = useMemo(() => {
    const result: any[] = [];
    if (uuidParam)
      result.push({
        key: 'uuid',
        label: 'UUID',
        operator: '=',
        type: 'filter',
        value: uuidParam,
      });
    if (deviceRequestUuidParam)
      result.push({
        key: 'device_request_uuid',
        label: 'Device Batch ID',
        operator: '=',
        type: 'filter',
        value: deviceRequestUuidParam,
      });
    return result;
  }, [uuidParam, deviceRequestUuidParam]);
  const [query, setQuery] = useState<Filter[]>(initialQuery);

  const viewLocation = location.pathname;

  useEffect(() => {
    if (!isLoading) {
      setShowContent(true);
    }
    return function cleanup(): void {
      if (location.pathname !== viewLocation) {
        setShowContent(false);
      }
    };
  }, [location.pathname, viewLocation]);

  const fetchDevices = async () => {
    try {
      setShowContent(false);
      setIsLoading(true);
      const urlWithParams = getUrlWithFilters('device', {
        page: tablePage,
        sizePerPage: tableSizePerPage,
        sortBy: tableSortField,
        sortDir: tableSortOrder,
        query,
      });
      const { data: newDevices } = await api().get(urlWithParams);
      setDevices(newDevices.map((device: any) => deserializeDevice(device)));
      setShowContent(true);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Devices!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    fetchDevices();
    return function cleanup(): void {
      tokenSource.cancel(`Device::getDevicesRequest`);
    };
  }, [
    dispatch,
    query,
    uuidParam,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
  ]);

  const columns = [
    {
      dataField: 'uuid',
      text: '',
      hidden: true,
    },
    {
      dataField: 'deviceId',
      text: 'Device ID',
    },
    {
      dataField: 'productProfileName',
      text: 'Product Profile',
    },
    {
      dataField: 'createdAt',
      text: 'Generated Date',
    },
    {
      dataField: 'reportedAt',
      text: 'Report Date',
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      style: { width: '100px' },
      formatter: (valueNotImportant: null, current: Device): ReactNode => {
        const options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        options.push(
          {
            label: 'Related Product Profile',
            onClick: () => {
              history.push(
                `/management/product-profiles/${current.productProfileUuid}`
              );
            },
            ico: <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />,
          },
          {
            label: 'Related Device Batch Request',
            onClick: () => {
              history.push({
                pathname: '/operations/device-batch-requests',
                search: `?uuid=${current.deviceRequestUuid}`,
              });
            },
            ico: <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />,
          }
        );

        return <TableActions rowId={String(current.uuid)} options={options} />;
      },
    },
  ];

  return (
    <div className="Devices">
      <Card className="rounded p-5">
        <div className="header-container d-flex">
          <h3 className="text-muted">Devices</h3>
          {isLoading && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              initialFilters={initialQuery}
              filters={[
                {
                  key: 'device_id',
                  label: 'Device ID',
                  type: 'filter',
                },
                {
                  key: 'device_request_uuid',
                  label: 'Device Batch ID',
                  type: 'filter',
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Generated Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Report Date',
                  type: 'filter',
                },
              ]}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {hasErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                search={false}
                remote={true}
                keyField="uuid"
                noDataIndication={
                  tablePage > 1
                    ? 'No more Devices available'
                    : 'No Devices available'
                }
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                sort={{
                  dataField: tableSortField,
                  order: tableSortOrder as SortOrder,
                }}
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                  setTableSortOrder(sortOrder);
                  setTableSortField(mapSortField(sortField));
                }}
                data={devices}
                columns={columns}
                rowEvents={{
                  onClick: (notUsedValue: null, current: Device): void => {
                    history.push(`/inventory/devices/${current.uuid}`);
                  },
                }}
              />
            )}
          </div>
        </Collapse>
      </Card>
    </div>
  );
};
export default Devices;
