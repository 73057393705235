import React, { FC } from 'react';
import './FilesTable.scss';
import { Button } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FilesTableProps {
  files: File[];
  onDeleteFile: (index: number) => void;
}

export const FilesTable: FC<FilesTableProps> = ({ files, onDeleteFile }) => {
  return files.length > 0 ? (
    <div className={'FilesTable mb-3'}>
      <p className={'pki-label'}>Keys To Upload</p>
      <div className="FilesTable-file-list">
        {files.map((file, index) => (
          <div
            key={index}
            className={
              'asymmetric-key-to-upload d-flex justify-content-between align-items-center'
            }
          >
            <span>{file.name}</span>
            <Button
              color={'danger'}
              onClick={() => onDeleteFile(index)}
              outline
              size={'sm'}
              className={'delete-single-key-file'}
              title={'Remove file'}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <p className={'text-center'}>No File(s) Selected!</p>
  );
};
