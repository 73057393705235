import { initVars, initCustomers, getVars } from '../libs/provisioning';
import { initKeycloak } from '../libs/auth';

const debug = require('debug')('pki-app:init');

export const initApp = async (
  renderApp: Function,
  renderErrorPage: Function
): Promise<void> => {
  try {
    await initVars(); // this doesn't fill PKI_CUSTOMERS
    await initKeycloak();
    await initCustomers(); // this does fill PKI_CUSTOMERS
    const { PKI_CUSTOMERS: customers } = getVars();
    renderApp(customers);
  } catch (e) {
    debug(`initApp: ${e}`);
    renderErrorPage(e.message);
  }
};
