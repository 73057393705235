import React, { FC, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { includes } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Certificate } from '../../../store/certificates/types';
import { CertificateProfile } from '../../../store/certificateProfiles/types';
import { deserializeCertificate } from '../../../store/certificates/helpers';
import { ServerSideSelect } from '../../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificateProfile } from '../../../store/certificateProfiles/helpers';

interface Props {
  onCancel: Function;
  onCreateProvisioningProfile: Function;
  manufacturingStationUuid: string;
}

export const ProvisioningProfilesModal: FC<Props> = ({
  onCancel = (): null => null,
  onCreateProvisioningProfile = (): null => null,
  manufacturingStationUuid,
}) => {
  const [currentIssuingCA, setCurrentIssuingCA] = useState<Certificate>();
  const [
    currentCertificateProfile,
    setCurrentCertificateProfile,
  ] = useState<CertificateProfile>();

  return (
    <div
      id="pki-roles-form"
      className="ManufacturingStationsProvisioningProfiles"
    >
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <span>
            <h3 className="text-muted">Provisioning Profile</h3>
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {
          <div className="mt-2">
            <>
              <Row>
                <Col>
                  <>
                    <Label className="pki-label">Issuing CA</Label>
                    <ServerSideSelect
                      onSelectEntity={(ca) => {
                        setCurrentIssuingCA(() => {
                          setCurrentCertificateProfile(undefined);
                          return deserializeCertificate(ca);
                        });
                      }}
                      formatter={(ca) => ca.cn}
                      fetchUrl={`certificate/authority`}
                      defaultFilters={'is_key_online=true'}
                      searchParam={`cn`}
                      filterFrontend={(ca) => includes(ca.actions, 'read')}
                      value={
                        currentIssuingCA?.commonName || 'Select Issuing CA...'
                      }
                    />
                  </>
                </Col>
                <Col>
                  <Label className="pki-label">Certificate Profile</Label>
                  <ServerSideSelect
                    onSelectEntity={(profile) => {
                      setCurrentCertificateProfile(
                        deserializeCertificateProfile(profile)
                      );
                    }}
                    formatter={(profile) => profile?.certificate_profile?.name}
                    fetchUrl={`certificate/profile`}
                    defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
                    searchParam={`certificate_profile.name`}
                    value={
                      currentCertificateProfile?.name ||
                      'Select a certificate profile'
                    }
                    urlParams={[
                      [
                        'resource',
                        'manufacturing_station_provisioning_profile',
                      ],
                      ['action', 'create'],
                      ['with_actions', 'false'],
                    ]}
                    wait={!currentIssuingCA}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mt-3 button-container float-right">
                    <Button
                      id="add-button"
                      outline
                      disabled={!currentIssuingCA || !currentCertificateProfile}
                      onClick={(): void => {
                        onCreateProvisioningProfile({
                          manufacturingStationUuid,
                          certificateProfileUuid:
                            currentCertificateProfile?.uuid,
                        });
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </div>
        }
      </div>
    </div>
  );
};
