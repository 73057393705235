import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { find, get, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Table, TableActions } from '../../../components';
import {
  ManufacturingStationsState,
  ProvisioningProfile,
} from '../../../store/manufacturingStations/types';
import { CertificateProfilesState } from '../../../store/certificateProfiles/types';
import { getCertificateProfilesFromProvisioningProfiles } from '../helpers';
import { ProvisioningProfilesModal } from './ProvisioningProfilesModal';
import {
  createProvisioningProfile,
  getProvisioningProfiles,
} from '../../../store/manufacturingStations/actions';
import { ApplicationState } from '../../../store';
import { CertificatesState } from '../../../store/certificates/types';
import { UsersState } from '../../../store/users/types';

interface Props {
  onDeleteProvisioningProfile: Function;
  onCreateProvisioningProfile: Function;
  manufacturingStationUuid: string;
  isLoading?: boolean;
  provisioningProfiles: ProvisioningProfile[];
}

const ProvisioningProfilesList: FC<Props> = ({
  onDeleteProvisioningProfile = (): null => null,
  manufacturingStationUuid,
  onCreateProvisioningProfile,
  isLoading,
  provisioningProfiles,
}) => {
  const {
    userProfile: { resources },
  } = useSelector<ApplicationState, ManufacturingStationsState & UsersState>(
    (pki) => ({
      ...pki.manufacturingStations,
      ...pki.users,
    })
  );
  const dispatch = useDispatch();
  const [
    provisioningProfilesModal,
    setProvisioningProfilesModal,
  ] = useState<boolean>(false);

  const manufacturingStationResources = find(
    resources,
    (item) => item.name === 'manufacturing_station_provisioning_profile'
  );

  const actions = get(manufacturingStationResources, 'actions', []);
  const canCreate = includes(actions, 'create');

  const tableData = provisioningProfiles;

  const toggleProvisioningProfilesModal = (): void => {
    setProvisioningProfilesModal((previousValue) => {
      return !previousValue;
    });
  };

  const columns = [
    { dataField: 'certificateProfileUuid', text: '', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      style: { width: '90%' },
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (
        uuid: string,
        row: { certificateProfileUuid: string; actions: Array<string> }
      ): ReactNode => {
        const canDelete = includes(row.actions, 'delete');

        return (
          <TableActions
            rowId={row.certificateProfileUuid}
            options={[
              {
                label: 'Delete',
                disabled: !canDelete,
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: () => {
                  onDeleteProvisioningProfile({
                    manufacturingStationUuid,
                    certificateProfileUuid: row.certificateProfileUuid,
                  });
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div
      id="pki-roles-form"
      className="ManufacturingStationsProvisioningProfiles"
    >
      <div className="mt-5">
        <Table
          noDataIndication="No Provisioning Profiles yet"
          keyField="certificateProfileUuid"
          columns={columns}
          data={tableData}
          loading={isLoading}
          toolbar={
            <Button
              id="add-provisioning-profile-button"
              outline
              disabled={!canCreate}
              style={{ marginRight: 10 }}
              onClick={toggleProvisioningProfilesModal}
            >
              Add provisioning profile
            </Button>
          }
        />
      </div>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={provisioningProfilesModal}
        toggle={toggleProvisioningProfilesModal}
      >
        <ProvisioningProfilesModal
          manufacturingStationUuid={manufacturingStationUuid}
          onCancel={toggleProvisioningProfilesModal}
          onCreateProvisioningProfile={({
            certificateProfileUuid,
          }: {
            certificateProfileUuid: string;
          }): void => {
            onCreateProvisioningProfile(certificateProfileUuid);
            toggleProvisioningProfilesModal();
          }}
        />
      </Modal>
    </div>
  );
};

export default ProvisioningProfilesList;
