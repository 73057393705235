import moment from 'moment/moment';
import { deserializeUserOutput } from '../../../../store/users/helpers';
import { AsymmetricKey, Key, ProductKey, ProductKeyUsage } from '../../types';
import { UserOutput } from '../../../../store/users/types';

export const serializeProductKey = (key: ProductKey) => {
  return {
    uuid: key.uuid,
    label: key.label,
    key_usage: key.keyUsage,
    notes: key.notes,
    product_profile_uuid: key.productProfileUuid,
  };
};

export const deserializeProductKey = (raw: any): ProductKey => {
  const updatedDate = raw?.date_updated || raw?.date_created;
  const updatedByUser = raw?.date_updated || raw?.date_created;
  return {
    label: raw.label,
    uuid: raw.uuid,
    productProfileUuid: raw.product_profile_uuid,
    keyUsage: raw.key_usage,
    createdAt: moment(raw.date_created).format('LLL'),
    createdBy: deserializeUserOutput(raw.created_by_user),
    notes: raw.notes || '',
    actions: raw.actions,
    keyFile: raw.key_file || '',
    keyId: raw.key_id || '',
    ...(updatedDate && {
      updatedAt: moment(raw?.date_updated || raw.date_created).format('LLL'),
    }),
    ...(updatedByUser && {
      updatedBy: deserializeUserOutput(
        raw?.updated_by_user || raw?.created_by_user
      ),
    }),
    keyDigest: raw.key_digest,
  };
};

export const serializeKey = (key: Key) => {
  return {
    uuid: key.uuid,
    key_id: key.keyId,
    label: key.label,
    key_file: key.keyFile,
    key_type: key.keyType,
    notes: key.notes,
    key_digest: key.keyDigest,
  };
};
export const deserializeKey = (raw: any): Key => {
  const updatedDate = raw?.date_updated || raw?.date_created;
  const updatedByUser = raw?.date_updated || raw?.date_created;
  return {
    uuid: raw.uuid,
    keyId: raw.key_id,
    label: raw.label,
    keyFile: raw.key_file,
    keyType: raw.key_type,
    notes: raw.notes || '',
    keyDigest: raw.key_digest,
    actions: raw.actions,
    ...(updatedDate && {
      updatedAt: moment(raw?.date_updated || raw.date_created).format('LLL'),
    }),
    ...(raw?.date_created && {
      createdAt: moment(raw.date_created).format('LLL'),
    }),
    ...(updatedByUser && {
      updatedBy: deserializeUserOutput(
        raw?.updated_by_user || raw?.created_by_user
      ),
    }),
    ...(raw?.created_by_user && {
      createdBy: deserializeUserOutput(raw.created_by_user),
    }),
  };
};

export const serializeAsymmetricKey = (key: AsymmetricKey) => {
  return {
    uuid: key.uuid,
    label: key.label,
    asymmetric_key_id: key.asymmetricKeyId || null,
    key_spec: key.keySpec,
    notes: key.notes,
    private_key: key?.privateKey ? serializeKey(key.privateKey) : null,
    public_key: key?.publicKey ? serializeKey(key.publicKey) : null,
  };
};

export const deserializeAsymmetricKey = (raw: any): AsymmetricKey => {
  return {
    uuid: raw.uuid,
    asymmetricKeyId: raw?.asymmetric_key_id || null,
    label: raw.label,
    keySpec: raw.key_spec,
    actions: raw.actions,
    notes: raw.notes || '',
    alreadyExists: raw?.already_exists,
    privateKey: raw?.private_key ? deserializeKey(raw?.private_key) : undefined,
    publicKey: raw?.public_key ? deserializeKey(raw?.public_key) : undefined,
    updatedAt: moment(raw?.date_updated || raw.date_created).format('LLL'),
    createdAt: moment(raw.date_created).format('LLL'),
    updatedBy: deserializeUserOutput(
      raw?.updated_by_user || raw.created_by_user
    ),
    createdBy: deserializeUserOutput(raw.created_by_user),
  };
};
