import React, { FC, MouseEvent, ReactNode } from 'react';
import _, { startCase } from 'lodash';
import { UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { AsymmetricKey, Key } from '../../../types';
import { Table, TableActions } from '../../../../../components';
import { UserOutput } from '../../../../../store/users/types';
import { LabelValue } from '../../../../../components/LabelValue/LabelValue';

interface AsymmetricDetailsTableProps {
  asymmetricKey: AsymmetricKey;
  onRowClick: (key: Key) => void;
  onEditActionClick: (key: Key) => void;
}

export const AsymmetricDetailsTable: FC<AsymmetricDetailsTableProps> = ({
  asymmetricKey,
  onRowClick,
  onEditActionClick,
}) => {
  const data: Key[] = [];
  [asymmetricKey?.privateKey, asymmetricKey?.publicKey].forEach((key) => {
    if (key) data.push(key);
  });

  const columnsKey = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'label',
      text: 'Label',
      formatter: (
        label: string,
        { notes, uuid }: { uuid: string; notes: string }
      ): ReactNode => {
        return (
          <>
            <div>{label}</div>
            {!_.isEmpty(notes) && (
              <div
                id={`key-${uuid}`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small>{notes}</small>
                <UncontrolledPopover trigger="hover" target={`key-${uuid}`}>
                  <div className="p-2">
                    <small>{notes}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'keyType',
      text: 'Key Type',
      formatter: (type: string) => startCase(type),
    },
    {
      dataField: 'keyId',
      text: 'Key ID',
    },
    {
      dataField: 'keyDigest',
      text: 'Digest',
      formatter: (digest: string) => {
        return <LabelValue value={digest} plain={true} maxChars={6} copyable />;
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (updatedAt: string, current: AsymmetricKey): ReactNode => {
        return updatedAt || 'N/A';
      },
    },
    {
      dataField: 'updatedBy',
      text: 'Updated by',
      formatter: (updatedBy: UserOutput, current: AsymmetricKey): ReactNode => {
        return updatedBy ? updatedBy.email : 'N/A';
      },
    },
    {
      dataField: 'noData',
      sort: false,
      text: 'Actions',
      formatter: (noData: string, current: Key): ReactNode => {
        const { uuid } = current;
        const { actions: asymmetricKeyActions } = asymmetricKey;
        const canUpdate = _.includes(asymmetricKeyActions, 'update');
        return (
          <TableActions
            rowId={String(uuid)}
            options={[
              {
                label: 'Edit',
                disabled: !canUpdate,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canUpdate && current) {
                    onEditActionClick(current);
                  }
                },
              },
            ]}
          />
        );
      },
    },
  ];
  return (
    <Table
      striped={false}
      pagination={false}
      data={data}
      classes={'asymmetric-key-details-table'}
      columns={columnsKey}
      keyField={'uuid'}
      search={false}
      remote={false}
      rowEvents={{
        onClick: (notUsedValue: null, current: Key): void => {
          onRowClick(current);
        },
      }}
      id={`asymmetric-key-details-${asymmetricKey.uuid}`}
    />
  );
};
