import { startCase } from 'lodash';
import { AGGREGATION } from '../constants';

export const aggregationSelectOptions = Object.entries(AGGREGATION).map(
  ([key, value]) => {
    return {
      key,
      value: startCase(value.toLowerCase()),
    };
  }
);
