import _ from 'lodash';
import React, { useState, FC } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  Label,
  Input,
  FormGroup,
  Row,
  Col,
  FormFeedback,
  Button,
} from 'reactstrap';
import Select from '../Select';

interface SelectNInputI {
  id?: string;
  label?: string;
  options: {
    key: string;
    value: string;
  }[];
  invalidInput?: {
    condition: boolean;
    message?: string;
  };
  onInputChange?: Function;
  onAdd?: Function;
  placeholder?: string;
  disabled?: boolean;
}

const SelectNInput: FC<SelectNInputI> = ({
  id = '',
  label,
  options = [{ key: 'noValue', value: 'No Values' }],
  onInputChange = (): null => null,
  onAdd = (): null => null,
  placeholder,
  disabled = false,
  invalidInput = { condition: false, message: '' },
}) => {
  const [selectKey, setSelectKey] = useState<string>(
    options[0] ? options[0].key : 'No Values'
  );
  const [selectValue, setSelectValue] = useState<string>(
    options[0] ? options[0].value : ''
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [showValidation, setShowValidation] = useState<boolean>(false);

  useDeepCompareEffect(() => {
    if (_.size(options) > 0) {
      setSelectKey(options[0].key);
      setSelectValue(options[0].value);
    }
  }, [options]);

  const addValue = (): void => {
    onAdd({ selectKey, selectValue, inputValue });
    setInputValue('');
  };

  const isNotValid =
    (invalidInput && invalidInput.condition) || !(selectValue && inputValue);
  return (
    <div id={id} className="SelectNInput">
      {label && <Label className="pki-label">{label}</Label>}
      <Row>
        <Col md={2}>
          <FormGroup>
            <Select
              disabled={disabled}
              selectedKey={selectKey}
              id={`select-${id}`}
              onChange={({
                value: newValue,
                key: newKey,
              }: {
                value: string;
                key: string;
              }): void => {
                setShowValidation(false);
                onInputChange({
                  selectKey: newKey,
                  selectValue: newValue,
                  inputValue: '',
                });
                setSelectValue(newValue);
                setSelectKey(newKey);
                setInputValue('');
              }}
              options={options}
            />
          </FormGroup>
        </Col>
        <Col md={9}>
          <FormGroup>
            <Input
              disabled={disabled}
              id={selectKey}
              invalid={showValidation && invalidInput && invalidInput.condition}
              onKeyPress={(
                event: React.KeyboardEvent<HTMLInputElement>
              ): void => {
                if (event.key === 'Enter' && !isNotValid) {
                  addValue();
                }
              }}
              value={inputValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                const {
                  target: { value: newValue },
                } = event;
                setInputValue(newValue);
                onInputChange({ selectKey, selectValue, inputValue: newValue });
              }}
              type="text"
              placeholder={placeholder}
            />
            {invalidInput &&
              showValidation &&
              invalidInput.condition &&
              invalidInput.message && (
                <FormFeedback>{invalidInput.message}</FormFeedback>
              )}
          </FormGroup>
        </Col>
        <Col md={1}>
          <div className="button-container float-right">
            <Button
              id="add-button"
              outline
              // disabled={isNotValid}
              onClick={(): void => {
                if (isNotValid) {
                  setShowValidation(true);
                } else {
                  addValue();
                }
              }}
            >
              Add
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SelectNInput;
