import React, { useImperativeHandle, useRef } from 'react';
import {
  DragSource,
  DropTarget,
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DropTargetConnector,
  DragSourceConnector,
  DragSourceMonitor,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faTrash } from '@fortawesome/free-solid-svg-icons';
import SmartSelect from 'react-select';
import { ProductProfile, ProductProfileOutput } from '../../../../../types';

const DRAG_IDENTIFIER = 'outputItem';

export interface OutputItemProps {
  id?: any;
  index: number;
  item: ProductProfileOutput & { id: string };
  availableSuggestions: ProductProfile['output'];
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  onChangeOutput: (output: ProductProfileOutput & { id: string }) => void;
  onDeleteItem: (id: string) => void;
  editMode?: boolean;
}

interface ItemInstance {
  getNode(): HTMLDivElement | null;
}

const style = {
  border: '1px solid #B2B4B3',
  borderRadius: '5px',
  display: 'table-row',
  padding: '1rem',
  marginBottom: '.5rem',
  cursor: 'default',
};

const cellStyle = {
  display: 'table-cell',
  padding: '.75rem',
  verticalAlign: 'middle',
};

const OutputItem = React.forwardRef<HTMLDivElement, OutputItemProps>(
  (
    {
      index,
      item: { name, value, id },
      moveItem,
      availableSuggestions,
      isDragging,
      connectDragSource,
      connectDropTarget,
      onChangeOutput,
      onDeleteItem,
      editMode = true,
    },
    ref
  ) => {
    const elementRef = useRef(null);
    const selectEl = useRef<HTMLInputElement>(null);

    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0 : 1;
    useImperativeHandle<{}, ItemInstance>(ref, () => ({
      getNode: () => elementRef.current,
    }));

    if (editMode) style.cursor = 'grab';

    return (
      <div
        className={'output-element'}
        ref={elementRef}
        style={{ ...style, opacity }}
      >
        {editMode && (
          <div style={cellStyle}>
            <FontAwesomeIcon icon={faGripVertical} />
          </div>
        )}
        <div
          style={cellStyle}
          draggable
          onDragStart={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          {!editMode && (
            <Input
              value={name || ''}
              disabled={!editMode}
              readOnly={!editMode}
            />
          )}
          {editMode && (
            <SmartSelect
              isSearchable={true}
              filterOption={() => true}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref={selectEl as any}
              isMulti={false}
              disabled={!editMode}
              placeholder={'Name...'}
              isClearable={false}
              classNamePrefix={'output-item-name-'}
              value={{
                name,
                value,
              }}
              inputValue={name}
              escapeClearsValue={false}
              options={availableSuggestions}
              getOptionLabel={(suggestion) => suggestion.name}
              getOptionValue={(suggestion) => suggestion.name}
              onInputChange={(inputValue: string, { action }) => {
                if (!['input-blur', 'menu-close'].includes(action)) {
                  onChangeOutput({
                    id,
                    value,
                    name: inputValue || '',
                  });
                  return inputValue;
                }
                return name;
              }}
              onChange={(selectedValue): void => {
                onChangeOutput({
                  id,
                  value: selectedValue?.value || '',
                  name: selectedValue?.name || '',
                });
              }}
            />
          )}
        </div>
        <div
          style={cellStyle}
          draggable
          onDragStart={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          <Input
            value={value || ''}
            disabled={!editMode}
            readOnly={!editMode}
            onChange={(ev) =>
              onChangeOutput({
                id,
                value: ev.target.value || '',
                name,
              })
            }
          />
        </div>
        {editMode && (
          <div
            style={cellStyle}
            draggable
            onDragStart={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              cursor={'pointer'}
              onClick={() => {
                onDeleteItem(id);
              }}
            />
          </div>
        )}
      </div>
    );
  }
);

export default DropTarget(
  DRAG_IDENTIFIER,
  {
    hover(
      props: OutputItemProps,
      monitor: DropTargetMonitor,
      component: ItemInstance
    ) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return undefined;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return undefined;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return undefined;
      }

      // Time to actually perform the action
      props.moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().index = hoverIndex;
      return undefined;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    DRAG_IDENTIFIER,
    {
      beginDrag: (props: OutputItemProps) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(OutputItem)
);
