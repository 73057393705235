import { notificationsReducer } from './notifications/reducer';
import { NotificationsState } from './notifications/types';
import { certificateTemplatesReducer } from './certificateTemplates/reducer';
import { CertificateTemplatesState } from './certificateTemplates/types';
import { certificateProfilesReducer } from './certificateProfiles/reducer';
import { CertificateProfilesState } from './certificateProfiles/types';
import { CodeSigningProfilesState } from './codeSigningProfiles/types';
import { codeSigningProfilesReducer } from './codeSigningProfiles/reducer';
import { requestsReducer } from './requests/reducer';
import { RequestsState } from './requests/types';
import { certificatesReducer } from './certificates/reducer';
import { CertificatesState } from './certificates/types';
import { UsersState } from './users/types';
import { usersReducer } from './users/reducer';
import { ManufacturingStationsState } from './manufacturingStations/types';
import { manufacturingStationsReducer } from './manufacturingStations/reducer';
import { AuditLogsState } from './auditLogs/types';
import { auditLogsReducer } from './auditLogs/reducer';
import { CommonKeysState } from './commonKeys/types';
import { commonKeysReducer } from './commonKeys/reducer';
import { ClientsState } from './clients/types';
import { clientsReducer } from './clients/reducer';
import { LayoutState } from './layout/types';
import { layoutReducer } from './layout/reducer';
import { DeviceProvisioningState } from './deviceProvisioning/types';
import { deviceProvisioningReducer } from './deviceProvisioning/reducer';
import { StatisticsState } from './statistics/types';
import { statisticsReducer } from './statistics/reducer';

export interface ApplicationState {
  certificateTemplates: CertificateTemplatesState;
  certificateProfiles: CertificateProfilesState;
  requests: RequestsState;
  codeSigningProfiles: CodeSigningProfilesState;
  certificates: CertificatesState;
  notifications: NotificationsState;
  users: UsersState;
  manufacturingStations: ManufacturingStationsState;
  auditLogs: AuditLogsState;
  commonKeys: CommonKeysState;
  clients: ClientsState;
  layout: LayoutState;
  deviceProvisioning: DeviceProvisioningState;
  statistics: StatisticsState;
}

const store = {
  certificateTemplates: certificateTemplatesReducer,
  certificateProfiles: certificateProfilesReducer,
  requests: requestsReducer,
  codeSigningProfiles: codeSigningProfilesReducer,
  certificates: certificatesReducer,
  notifications: notificationsReducer,
  users: usersReducer,
  manufacturingStations: manufacturingStationsReducer,
  auditLogs: auditLogsReducer,
  commonKeys: commonKeysReducer,
  clients: clientsReducer,
  layout: layoutReducer,
  deviceProvisioning: deviceProvisioningReducer,
  statistics: statisticsReducer,
};

export default store;
