import {
  generalDefaultValues,
  extensionsDefaultValues,
  customExtensions,
  certificatePolicies,
} from '../../libs/constants';

export const navMenu = [
  'General',
  'Basic Extensions',
  'Custom Extensions',
  'Certificate Policy Extensions',
];

export const defaultState = {
  ...generalDefaultValues,
  ...extensionsDefaultValues,
  ...certificatePolicies,
  customExtensions,
};
