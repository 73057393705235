import {
  generalDefaultValues,
  policiesDefaultValues,
  extensionsDefaultValues,
  customExtensions,
  certificatePolicies,
} from '../../libs/constants';

export const navMenu = [
  'General',
  'Policies',
  'Basic Extensions',
  'Custom Extensions',
  'Certificate Policy Extensions',
  'Batch',
];

export const issuingPoliciesOptions = [
  { key: 'web_portal', value: 'Web Portal' },
  { key: 'rest_api ', value: 'REST API' },
];

export const templatesOptions = [{ key: 'noTemplate', value: 'No Template' }];

export const certificateProfileDefaultValues = {
  ...generalDefaultValues,
  ...extensionsDefaultValues,
  ...policiesDefaultValues,
  ...certificatePolicies,
  customExtensions,
  uuid: '',
};
