/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import _, { get, isNil, startCase } from 'lodash';
import moment from 'moment';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios, { CancelTokenSource } from 'axios';
import { sendNotification } from '../notifications/actions';
import {
  deserializeCertificateRequest,
  deserializeCodeSigningKeyRequest,
  deserializeCodeSigningRequest,
  serializeCertificateRequest,
  serializeCodeSigningKeyRequest,
  serializeCodeSigningRequest,
} from './helpers';
import {
  CertificateRequest,
  CodeSigningCertificateRequest,
  CodeSigningRequest,
  Request,
  RequestActionTypes,
  RequestsState,
  RequestType,
} from './types';
import { Filter } from '../../components/FilterBar/types';

import { api, getUrlWithFilters, mapKeysWithValues } from '../../libs/helpers';

interface RequestParams {
  page: number | undefined;
  sizePerPage: number | undefined;
  uuid?: string | undefined;
  query: Filter[];
  requestType?: RequestType;
}

const error = require('debug')('pki-app:error:request');

export const getRequest = (
  requestParams: RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    const { requestType } = requestParams;
    if (requestType === RequestType.Certificate) {
      return dispatch(getCertificateRequests(requestParams, tokenSource));
    }
    if (requestType === RequestType.CodeSigning) {
      return dispatch(getCodeSigningRequests(requestParams, tokenSource));
    }
    if (requestType === RequestType.CodeSigningCertificate) {
      return dispatch(getCodeSigningKeyRequests(requestParams, tokenSource));
    }
    return false;
  };
};

export const getCertificateRequests = (
  requestParams: RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    const { query, page, sizePerPage, uuid } = requestParams;

    dispatch({
      type: RequestActionTypes.GET_CERTIFICATE_REQUEST_LIST_REQUEST,
    });

    let url = `certificate/request?sortBy=date_created&sortDir=desc`;
    url = getUrlWithFilters(url, { sizePerPage, page, query, uuid });

    try {
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificateRequest(item)
      );
      dispatch({
        type: RequestActionTypes.GET_CERTIFICATE_REQUEST_LIST_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      if (!axios.isCancel(err)) {
        dispatch({
          type: RequestActionTypes.GET_CERTIFICATE_REQUEST_LIST_ERROR,
          payload: err,
        });
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Certificate Requests list!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getCertificateRequests: ${err}`);
      return false;
    }
  };
};

export const getCodeSigningRequests = (
  requestParams: RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    const { query, page, sizePerPage, uuid } = requestParams;

    dispatch({
      type: RequestActionTypes.GET_CODE_SIGNING_REQUEST_LIST_REQUEST,
    });

    let url = `code-signing/signature?sortBy=date_created&sortDir=desc`;

    url = getUrlWithFilters(url, { sizePerPage, page, query, uuid });

    try {
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });

      const payload = _.map(response.data, (item) =>
        deserializeCodeSigningRequest(item)
      );
      dispatch({
        type: RequestActionTypes.GET_CODE_SIGNING_REQUEST_LIST_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      if (!axios.isCancel(err)) {
        dispatch({
          type: RequestActionTypes.GET_CODE_SIGNING_REQUEST_LIST_ERROR,
          payload: err,
        });
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Code Signing Requests list!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getCodeSigningRequests: ${err}`);
      return false;
    }
  };
};

export const getCodeSigningKeyRequests = (
  requestParams: RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.GET_CODE_SIGNING_KEY_REQUEST_LIST_REQUEST,
    });

    const { query, page, sizePerPage, uuid } = requestParams;

    let url = `code-signing/key?sortBy=date_created&sortDir=desc`;
    url = getUrlWithFilters(url, { sizePerPage, page, query, uuid });
    try {
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCodeSigningKeyRequest(item)
      );
      dispatch({
        type: RequestActionTypes.GET_CODE_SIGNING_KEY_REQUEST_LIST_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      if (!axios.isCancel(err)) {
        dispatch({
          type: RequestActionTypes.GET_CODE_SIGNING_KEY_REQUEST_LIST_ERROR,
          payload: err,
        });
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Code Signing Certificate Requests list!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getCodeSigningKeyRequests: ${err}`);
      return false;
    }
  };
};

export const getAwaitingRequests = (
  tokenSources: CancelTokenSource[]
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.GET_AWAITING_REQUEST_LIST_REQUEST,
    });
    try {
      const [
        awaitingCertificateResponse,
        awaitingCodeSigningKeyResponse,
        awaitingCodeSigningResponse,
      ] = await Promise.all([
        api().get('certificate/request?filter=status=awaiting_approval', {
          cancelToken: tokenSources[0]?.token,
        }),
        api().get('code-signing/key?filter=status=awaiting_approval', {
          cancelToken: tokenSources[1]?.token,
        }),
        api().get('code-signing/signature?filter=status=awaiting_approval', {
          cancelToken: tokenSources[2]?.token,
        }),
      ]);

      const certificatePayload = _.map(
        awaitingCertificateResponse.data,
        (item) => deserializeCertificateRequest(item)
      );
      const codeSigningKeyPayload = _.map(
        awaitingCodeSigningKeyResponse.data,
        (item) => deserializeCodeSigningKeyRequest(item)
      );

      const codeSigningPayload = _.map(
        awaitingCodeSigningResponse.data,
        (item) => deserializeCodeSigningRequest(item)
      );

      dispatch({
        type: RequestActionTypes.GET_AWAITING_REQUEST_LIST_SUCCESS,
        payload: [
          ...certificatePayload,
          ...codeSigningKeyPayload,
          ...codeSigningPayload,
        ],
      });
      return true;
    } catch (err) {
      dispatch({
        type: RequestActionTypes.GET_AWAITING_REQUEST_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Awaiting Requests list!';

      if (!axios.isCancel(err)) {
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getAwaitingRequests: ${err}`);
      return false;
    }
  };
};

export const getRequestsCounter = (
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.GET_REQUEST_COUNTER_REQUEST,
    });
    try {
      const NOW = moment();
      const startDate = NOW.subtract(1, 'month').format('YYYY-MM-DD');
      const endDate = moment().add(1, 'day').format('YYYY-MM-DD');

      const [
        certificateResponse,
        codeSigningKeyResponse,
        codeSigningResponse,
      ] = await Promise.all([
        api().get(
          `certificate/request?filter=date_created>${startDate},date_created<${endDate}`,
          {
            cancelToken: tokenSource?.token,
          }
        ),
        api().get(
          `code-signing/key?filter=date_created>${startDate},date_created<${endDate}`,
          {
            cancelToken: tokenSource?.token,
          }
        ),
        api().get(
          `code-signing/signature?filter=date_created>${startDate},date_created<${endDate}`,
          {
            cancelToken: tokenSource?.token,
          }
        ),
      ]);

      const certificatePayload = _.map(certificateResponse.data, (item) =>
        deserializeCertificateRequest(item)
      );
      const codeSigningKeyPayload = _.map(codeSigningKeyResponse.data, (item) =>
        deserializeCodeSigningKeyRequest(item)
      );

      const codeSigningPayload = _.map(codeSigningResponse.data, (item) =>
        deserializeCodeSigningRequest(item)
      );

      const requests = [
        ...certificatePayload,
        ...codeSigningKeyPayload,
        ...codeSigningPayload,
      ];

      const constRequestStatus = _.countBy(requests, 'status');

      dispatch({
        type: RequestActionTypes.GET_REQUEST_COUNTER_SUCCESS,
        payload: constRequestStatus,
      });
      return true;
    } catch (err) {
      dispatch({
        type: RequestActionTypes.GET_REQUEST_COUNTER_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Requests Status!';

      if (!axios.isCancel(err)) {
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getRequestsCounter: ${err}`);
      return false;
    }
  };
};

export const createRequest = (
  request: Request
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  const { type } = request;
  let url: string;
  let requestSerialized: Request;
  const formData = new FormData();
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  if (type === RequestType.Certificate) {
    requestSerialized = serializeCertificateRequest(
      request as CertificateRequest
    );
    url = 'certificate/request';
  }
  if (type === RequestType.CodeSigningCertificate) {
    const codeSigningCertificateRequest = request as CodeSigningCertificateRequest;
    if (!isNil(codeSigningCertificateRequest.wrappedKey)) {
      formData.append(
        'ext_key_tar_file',
        codeSigningCertificateRequest.wrappedKey
      );
      formData.append(
        'data',
        JSON.stringify({
          certificate_profile_uuid:
            codeSigningCertificateRequest.certificateProfileUuid,
          certificate_subject_vars: mapKeysWithValues(
            codeSigningCertificateRequest.certificateSubjectVars
          ),
          notes: codeSigningCertificateRequest.notes,
          upload_cert: false,
        })
      );

      url = 'code-signing/key/upload';
    } else {
      requestSerialized = serializeCodeSigningKeyRequest(
        codeSigningCertificateRequest
      );
      url = 'code-signing/key';
    }
  }
  if (type === RequestType.CodeSigning) {
    requestSerialized = serializeCodeSigningRequest(
      request as CodeSigningRequest
    );
    url = 'code-signing/signature';
  }
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.POST_REQUEST_REQUEST,
    });
    try {
      let payload;
      if (
        RequestType.CodeSigningCertificate === type &&
        !isNil((request as CodeSigningCertificateRequest).wrappedKey)
      ) {
        payload = await api().post(url, formData, config);
      } else {
        payload = await api().post(url, {
          ...requestSerialized,
        });
      }

      dispatch({
        type: RequestActionTypes.POST_REQUEST_SUCCESS,
        payload,
      });

      if (get(payload, 'data.status') === 'completed') {
        if (type === RequestType.CodeSigningCertificate) {
          sendNotification({
            text: `The Code Signing Key and its Certificate have been successfully created.`,
          })(dispatch);
        }
        if (type === RequestType.Certificate) {
          sendNotification({
            text: `The Certificate has been successfully created.`,
          })(dispatch);
        }
        if (type === RequestType.CodeSigning) {
          sendNotification({
            text: `The Code has been successfully signed.`,
          })(dispatch);
        }
      } else {
        sendNotification({
          text: `The ${startCase(type)} Request has been successfully opened!`,
        })(dispatch);
      }

      return true;
    } catch (err) {
      dispatch({
        type: RequestActionTypes.POST_REQUEST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        `Oops! Something went wrong creating the ${startCase(type)} Request!`;
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createRequest: ${err}`);
      return false;
    }
  };
};

export const editRequest = (
  request: Request
): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  const { type } = request;
  let url: string;
  let requestSerialized: Request;
  if (type === RequestType.Certificate) {
    requestSerialized = serializeCertificateRequest(
      request as CertificateRequest
    );
    url = `certificate/request/${request.uuid}`;
  }
  if (type === RequestType.CodeSigningCertificate) {
    requestSerialized = serializeCodeSigningKeyRequest(
      request as CodeSigningCertificateRequest
    );
    url = `code-signing/key/${request.uuid}`;
  }
  if (type === RequestType.CodeSigning) {
    requestSerialized = serializeCodeSigningRequest(
      request as CodeSigningRequest
    );
    url = `code-signing/signature/${request.uuid}`;
  }
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.PUT_REQUEST_REQUEST,
    });
    try {
      const payload = await api().put(url, {
        ...requestSerialized,
      });
      dispatch({
        type: RequestActionTypes.PUT_REQUEST_SUCCESS,
        payload,
      });
      sendNotification({
        text: `The ${type} Request has been successfully updated!`,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: RequestActionTypes.PUT_REQUEST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        `Oops! Something went wrong editing the ${type} Request!`;
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`editRequest: ${err}`);
      return false;
    }
  };
};

export const editRequestStatus = ({
  uuid,
  action,
  notes,
  type,
}: {
  uuid: string;
  action: 'Approve' | 'Reject';
  notes: string;
  type: RequestType;
}): ThunkAction<Promise<boolean>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.PUT_REQUEST_STATUS_REQUEST,
    });
    const requestPayload: {
      action?: string;
      notes?: string;
      // eslint-disable-next-line camelcase
      approver_notes?: string;
    } = {
      action: action.toLowerCase(),
    };
    if (
      [RequestType.CodeSigning, RequestType.CodeSigningCertificate].includes(
        type
      )
    ) {
      requestPayload.approver_notes = notes;
    } else {
      requestPayload.notes = notes;
    }
    let url = '';
    if (type === RequestType.Certificate) {
      url = `certificate/request/${uuid}/approval_status`;
    }
    if (type === RequestType.CodeSigningCertificate) {
      url = `code-signing/key/${uuid}`;
    }
    if (type === RequestType.CodeSigning) {
      url = `code-signing/signature/${uuid}`;
    }
    try {
      const payload = await api().put(url, requestPayload);
      dispatch({
        type: RequestActionTypes.PUT_REQUEST_STATUS_SUCCESS,
        payload,
      });
      sendNotification({
        text: `The ${startCase(type)} Request has been successfully updated!`,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: RequestActionTypes.PUT_REQUEST_STATUS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        `Oops! Something went wrong editing the ${startCase(
          type
        )} Request status!`;
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`editRequestStatus: ${err}`);
      return false;
    }
  };
};

export const downloadBatchCertificates = (
  certificateRequestId: string
): ThunkAction<Promise<boolean | string>, RequestsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RequestsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: RequestActionTypes.DOWLOAD_BATCH_CERTIFICATES_REQUEST,
      payload: certificateRequestId,
    });
    try {
      // KEYSCRED-3581 - Workaround to retrieve the S3 URL from API first,
      // then call S3 URL directly. Original download API redirect not handled
      // properly by the browser/library due to not dropping Authentication
      // header.
      let response = await api().get(
        `certificate/request/${certificateRequestId}/downloadurl`
      );
      const downloadUrl = response.data.download_url;
      response = await axios.get(downloadUrl);
      dispatch({
        type: RequestActionTypes.DOWLOAD_BATCH_CERTIFICATES_SUCCESS,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: RequestActionTypes.DOWLOAD_BATCH_CERTIFICATES_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong downloading the Batch of Certificates!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`downloadBatchCertificates: ${err}`);
      return false;
    }
  };
};
