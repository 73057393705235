import { Reducer } from 'redux';
import { LayoutState, LayoutTypes } from './types';

export const initialState: LayoutState = {
  isSidebarOpen: true,
};

const reducer: Reducer<LayoutState> = (
  state = initialState,
  action
): LayoutState => {
  switch (action.type) {
    case LayoutTypes.SET_SIDEBAR: {
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as layoutReducer };
