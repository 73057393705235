import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const Clock: FC = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup(): void {
      clearInterval(timerID);
    };
  });

  const tick = (): void => {
    setDate(new Date());
  };

  return (
    <div className="Clock text-white">
      <span className="mr-1">
        <FontAwesomeIcon icon={faClock} />
      </span>{' '}
      {date.toLocaleTimeString()}
    </div>
  );
};

export default Clock;
