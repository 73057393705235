import _, { includes, isEmpty } from 'lodash';
import React, { useState, FC, useEffect, ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card, Collapse, Button, Modal, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faEdit,
  faTrash,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons';
import {
  ConfirmationForm,
  Table,
  Spinner,
  TableActions,
  ErrorHandler,
  FilterBar,
} from '../../components';
import { ApplicationState } from '../../store';
import { getCertificateAuthorities } from '../../store/certificates/actions';
import { getCertificateProfiles } from '../../store/certificateProfiles/actions';
import {
  getClients,
  createClient,
  editClient,
  deleteClient,
  getClientEntitlements,
} from '../../store/clients/actions';
import { ClientsState, Client } from '../../store/clients/types';
import { CertificatesState } from '../../store/certificates/types';
import { UsersState, EntityType, User } from '../../store/users/types';
import ClientForm from './ClientForm';
import ClientRolePolicy from './ClientRolePolicy';
import {
  setUsersEntityRole,
  removeUserEntityRole,
  getUsers,
} from '../../store/users/actions';

import { sendNotification } from '../../store/notifications/actions';
import { defaultValuesClient as defaultValues } from './constants';
import { CertificateProfilesState } from '../../store/certificateProfiles/types';
import { Resource, Role } from '../../libs/constants';
import { Filter } from '../../components/FilterBar/types';
import { amIAdmin, dateFormatter } from '../../libs/helpers';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'clientID':
      return 'client_id';
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

interface ClientsToolbarProps {
  onCreateClientClick?: Function;
  actions?: string[];
}

const ClientsToolbar: FC<ClientsToolbarProps> = ({
  onCreateClientClick = (): null => null,
  actions = [],
}) => {
  return (
    <div className="ClientsToolbar d-flex">
      <span className="mx-2">
        <Button
          outline
          id="create-client-button"
          disabled={!_.includes(actions, 'create')}
          size="sm"
          onClick={(): void => {
            onCreateClientClick();
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> API Client{' '}
        </Button>
      </span>
    </div>
  );
};

const Clients: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    certificateProfileList,
    isGettingClientsList,
    isGettingUserList,
    isCreatingClient,
    isEditingClient,
    isDeletingClient,
    isLoadedClientsList,
    clientsList,
    isEditiningEntityRole,
    currentClientEntitlements,
    clientsErrors,
    OnlineCAList,
    userProfile: { resources, entityRoles },
    usersList,
  } = useSelector<
    ApplicationState,
    UsersState & ClientsState & CertificatesState & CertificateProfilesState
  >((pki) => ({
    ...pki.users,
    ...pki.certificates,
    ...pki.certificateProfiles,
    ...pki.clients,
  }));

  const params = new URLSearchParams(location.search);
  const clientUuidParam = params.get('uuid');

  const initialQuery: Filter[] = useMemo(
    () =>
      clientUuidParam
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: clientUuidParam,
            },
          ]
        : [],
    [clientUuidParam]
  );

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>(initialQuery);
  const [isShown, setIsShown] = useState(initialQuery.length <= 0);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [formModal, setFormModal] = useState<boolean>(false);
  const [issuingPolicyModal, setIssuingPolicyModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<Client>(defaultValues);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  // TODO uncomment the section after fixing the RBAC on ca_admin in backend
  // const [readOnlyRolePolicy, setReadOnlyRolePolicy] = useState(true);

  const isLoadingData = isGettingClientsList;

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setCurrentClient(defaultValues);
      }
      return !previousValue;
    });
  };

  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentClient(defaultValues);
      }
      return !previousValue;
    });
  };

  const toggleIssuingPolicyModal = (): void => {
    setIssuingPolicyModal((previousValue) => {
      if (previousValue) {
        setCurrentClient(defaultValues);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }
    if (isLoadedClientsList) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [isLoadedClientsList, isLoadingData]);

  useEffect(() => {
    const isPerformingAction =
      isCreatingClient || isEditingClient || isDeletingClient;
    if (!isPerformingAction) {
      dispatch(
        getClients({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
          query,
        })
      );
    }
  }, [
    dispatch,
    isCreatingClient,
    isEditingClient,
    isDeletingClient,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    query,
  ]);

  useEffect(() => {
    if (issuingPolicyModal && currentClient.serviceAccount?.uuid) {
      dispatch(
        getCertificateAuthorities({
          isKeyOnline: true,
          // action: 'assign_roles',
          // resource: Resource.CertificateAuthority,
        })
      );
      dispatch(
        getCertificateProfiles({
          action: 'assign_roles',
          resource: Resource.CertificateProfile,
        })
      );
      dispatch(getClientEntitlements(currentClient.serviceAccount?.uuid));
    }
  }, [currentClient, dispatch, issuingPolicyModal]);

  useEffect(() => {
    if (
      issuingPolicyModal &&
      !isEditiningEntityRole &&
      currentClient.serviceAccount?.uuid
    ) {
      dispatch(getClientEntitlements(currentClient.serviceAccount?.uuid));
    }
  }, [
    currentClient.serviceAccount,
    dispatch,
    isEditiningEntityRole,
    issuingPolicyModal,
  ]);

  const availablesCertificateProfiles = _.filter(
    certificateProfileList,
    (item) => includes(item.actions, 'assign_roles')
  );

  useEffect(() => {
    if (
      isLoadedClientsList &&
      !isEmpty(clientUuidParam) &&
      clientsList.findIndex((client) => client.uuid === clientUuidParam) > -1 &&
      initialQuery.findIndex(
        (filter) => filter.key === 'uuid' && filter.value === clientUuidParam
      ) > -1 &&
      !isShown
    ) {
      const clientToShow = clientsList.find(
        (certificateProfile) => certificateProfile.uuid === clientUuidParam
      );
      if (clientToShow) {
        setCurrentClient(clientToShow);
        setFormReadOnly(true);
        setFormModal(true);
        setIsShown(true);
      }
    }
  }, [clientUuidParam, isLoadedClientsList]);

  const clientResources = _.find(resources, (item) => item.name === 'client');

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    { dataField: 'clientID', text: 'Client ID' },
    {
      dataField: 'description',
      text: 'Description',
      formatter: (description: string): string =>
        _.isEmpty(description) ? 'N/A' : description,
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Created by',
      formatter: (createdBy: string, current: Client): ReactNode => {
        const isCreatedByUserDeleted = _.get(current, 'isCreatedByUserDeleted');
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
    {
      dataField: 'enabled',
      text: 'Status',
      csvFormatter: (enabled: boolean): string =>
        enabled ? 'Enabled' : 'Disabled',
      formatter: (enabled: boolean): ReactNode => (
        <h6>
          <Badge color={enabled ? 'success' : 'danger'}>
            {enabled ? 'Active' : 'Blocked'}
          </Badge>
        </h6>
      ),
    },
    {
      dataField: 'uuid',
      text: 'Actions',
      sort: false,
      formatter: (uuid: string, current: Client): ReactNode => {
        const canEditClient = _.includes(
          _.get(current, 'actions', []),
          'update'
        );
        const canDeleteClient = _.includes(
          _.get(current, 'actions', []),
          'delete'
        );
        return (
          <TableActions
            rowId={uuid}
            options={[
              {
                label: 'Edit',
                disabled: !canEditClient,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canEditClient) {
                    setCurrentClient(current);
                    setFormReadOnly(false);
                    setFormModal(true);
                  }
                },
              },
              {
                label: 'Delete',
                disabled: !canDeleteClient,
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canDeleteClient) {
                    setCurrentClient(current);
                    toggleDeleteModal();
                  }
                },
              },
              {
                label: 'Role Policy',
                ico: <FontAwesomeIcon className="pki-ico" icon={faUserTag} />,
                onClick: async (e: MouseEvent): Promise<void> => {
                  e.stopPropagation();
                  // TODO uncomment the section after fixing the RBAC on ca_admin in backend
                  // setReadOnlyRolePolicy(
                  //   !current?.actions.includes('assign_roles')
                  // );
                  setCurrentClient(current);
                  toggleIssuingPolicyModal();
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div className="Clients">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">API Clients</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'client_id',
                  label: 'Client ID',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'description',
                  label: 'Description',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'enabled',
                  label: 'Status',
                  type: 'filter',
                  options: [
                    { value: 'Active', label: 'Active' },
                    { value: 'Blocked', label: 'Blocked' },
                  ],
                },
                {
                  key: 'created_by_user_uuid',
                  label: 'Created By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'created_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'updated_by_user_uuid',
                  label: 'Updated By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'updated_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Created Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Modified Date',
                  type: 'filter',
                },
              ]}
              initialFilters={initialQuery}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  let mapValues = JSON.stringify(newFilters);
                  mapValues = mapValues.replace('Active', 'true');
                  mapValues = mapValues.replace('Blocked', 'false');
                  setQuery(() => {
                    setTablePage(1);
                    return JSON.parse(mapValues);
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {clientsErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                keyField="uuid"
                rowEvents={{
                  onClick: (notUsedValue: null, current: Client): void => {
                    setCurrentClient(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                remote={true}
                search={false}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                sort={{
                  dataField: tableSortField,
                  order: tableSortOrder as SortOrder,
                }}
                noDataIndication={
                  tablePage > 1
                    ? 'No more API Clients available'
                    : 'No API Clients available'
                }
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  // sortField set to date_updated as default if not defined
                  setTableSortField(mapSortField(sortField || 'date_updated'));
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                  setTableSortOrder(sortOrder);
                }}
                toolbar={
                  <ClientsToolbar
                    actions={_.get(clientResources, 'actions', [])}
                    onCreateClientClick={(): void => {
                      setFormReadOnly(false);
                      toggleFormModal();
                    }}
                  />
                }
                data={clientsList}
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        <ClientForm
          readOnly={formReadOnly}
          onCancel={toggleFormModal}
          onChangeMode={toggleMode}
          defaultValues={currentClient}
          actions={currentClient?.actions}
          isAdmin={amIAdmin(entityRoles)}
          onSubmit={({
            values,
            isValid,
          }: {
            values: Client;
            isValid: boolean;
          }): void => {
            if (isValid) {
              if (!_.isEmpty(values.uuid)) {
                dispatch(editClient(values));
              } else {
                dispatch(createClient(values));
              }

              toggleFormModal();
            } else {
              dispatch(
                sendNotification({
                  text: 'Some fields are mandatory, please fill them all.',
                  success: false,
                })
              );
            }
          }}
        />
      </Modal>

      <Modal
        className="PKIApp"
        size="lg"
        isOpen={issuingPolicyModal}
        toggle={toggleIssuingPolicyModal}
      >
        <ClientRolePolicy
          issuingCAs={OnlineCAList}
          certificateProfiles={availablesCertificateProfiles}
          users={usersList}
          client={currentClient}
          // TODO uncomment the section after fixing the RBAC on ca_admin in backend
          // readOnly={readOnlyRolePolicy}
          clientEntitlements={currentClientEntitlements}
          onCancel={toggleIssuingPolicyModal}
          isLoading={isGettingUserList || isEditiningEntityRole}
          onSetEntityRole={async ({
            userUuids,
            entityType,
            entityRole,
            entityUuid,
            usersAlreadyEntitled,
          }: {
            userUuids: string[];
            entityRole: string;
            entityType: EntityType;
            entityUuid: string;
            usersAlreadyEntitled: string[];
          }): Promise<void> => {
            await dispatch(
              setUsersEntityRole({
                userUuids,
                entityRole,
                entityUuid,
                entityType,
                usersAlreadyEntitled,
                type: 'Client',
                inherit:
                  entityType === 'IssuingCA' && entityRole === Role.CAAdmin,
              })
            );
            dispatch(getUsers());
          }}
          onRemoveEntityRole={async ({
            userUuid,
            entityRole,
            entityType,
            entityUuid,
          }: {
            userUuid: string;
            entityType: EntityType;
            entityRole: string;
            entityUuid: string;
          }): Promise<void> => {
            await dispatch(
              removeUserEntityRole({
                userUuid,
                entityType,
                entityRole,
                entityUuid,
              })
            );
            dispatch(getUsers());
          }}
        />
      </Modal>

      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title={`Delete API Client Confirmation`}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>delete</strong> an API Client{' '}
                <strong>{currentClient.clientID}</strong>
              </p>
              <p>
                <strong>{currentClient.description}</strong>.
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={(): void => {
            if (currentClient) {
              dispatch(deleteClient(currentClient.uuid));
            }
            toggleDeleteModal();
          }}
        />
      </Modal>
    </div>
  );
};

export default Clients;
