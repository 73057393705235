import React, { FC } from 'react';
import { Label } from 'reactstrap';
import { Moment } from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import './DatePicker.scss';

const { DatetimePickerTrigger } = require('rc-datetime-picker');

interface Props {
  id?: string;
  value: Moment | undefined;
  label?: string;
  format?: string;
  readOnly?: boolean;
  onChange: Function;
  className?: string;
  showTimePicker?: boolean;
  closeOnSelectDay?: boolean;
}

const DatePicker: FC<Props> = ({
  value,
  id,
  label,
  format = 'L',
  onChange = (): null => null,
  readOnly = false,
  className,
  showTimePicker = false,
  closeOnSelectDay = false,
}) => {
  return (
    <div id={id} className={`DatePicker ${className}`}>
      <DatetimePickerTrigger
        moment={value}
        showTimePicker={showTimePicker}
        onChange={(newValue: Moment): void => {
          onChange(newValue);
        }}
        closeOnSelectDay={closeOnSelectDay}
      >
        {label && <Label className="pki-label">{label}</Label>}
        <div className="date-value cursor-pointer px-2 h-100">
          {value?.format(format)}
        </div>
      </DatetimePickerTrigger>
    </div>
  );
};

export default DatePicker;
