import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Notification.scss';

const Notification: FC = () => {
  return (
    <div className="Notification">
      <ToastContainer
        className="w-100 px-3 rounded"
        position="bottom-center"
        toastClassName="rounded"
        autoClose={7000}
        hideProgressBar={false}
        draggable={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default Notification;
