/* eslint-disable camelcase */
import Cookies from 'js-cookie';
import _, { get, round } from 'lodash';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import { getVars } from './provisioning';

const debug = require('debug')('pki-app:libs:auth');

let keycloak: Keycloak.KeycloakInstance;

export const initKeycloak = async (): Promise<boolean> => {
  const { KEYCLOAK_REALM, KEYCLOAK_URL, KEYCLOAK_CLIENT_ID } = getVars();
  keycloak = Keycloak({
    realm: String(KEYCLOAK_REALM),
    url: String(KEYCLOAK_URL),
    clientId: String(KEYCLOAK_CLIENT_ID),
  });

  await keycloak.init({
    onLoad: 'login-required',
  });

  setInterval(async () => {
    const appToken = Cookies.get('id_token');
    try {
      if (_.isNil(appToken)) {
        throw new Error('Token not available');
      }
      const tokenExpired = keycloak.isTokenExpired(30);
      if (tokenExpired) {
        keycloak.logout();
      }
    } catch (err) {
      keycloak.logout();
    }
  }, 10000);

  if (keycloak.authenticated) {
    debug('Successfully authenticated');
    Cookies.set('id_token', String(keycloak.token));
    Cookies.set('kc-access', String(keycloak.token), {
      domain: `.${window.location.hostname}`,
    });
    return true;
  }
  keycloak.login();
  return false;
};

export const updateToken = (): void => {
  try {
    if (keycloak && keycloak.tokenParsed && keycloak.tokenParsed.exp) {
      const tokenExpPeriod =
        get(keycloak, 'tokenParsed.exp') - get(keycloak, 'tokenParsed.iat');
      const minValidity = round(tokenExpPeriod / 2);
      keycloak.updateToken(minValidity).then(() => {
        debug('Token successfully updated');
      });
    }
  } catch {
    keycloak.logout();
  }
};

export const getOfflineToken = async (): Promise<string> => {
  try {
    const { PKI_API_URL } = getVars();
    const ID_TOKEN = Cookies.get('id_token');
    const response = await axios.get(`${PKI_API_URL}/auth/offline_token`, {
      headers: {
        Authorization: `Bearer ${ID_TOKEN}`,
      },
    });
    const {
      data: { offline_token },
    } = response;
    return offline_token;
  } catch (err) {
    debug(err);
    return err;
  }
};

export const logout = async (): Promise<void> => {
  keycloak.logout();
};
