import {
  General,
  Policies,
  Extensions,
  CustomExtensions,
  CertificatePolicies,
} from '../../libs/types';

interface Actions {
  actions?: string[];
}

export type CertificateProfile = General &
  Extensions &
  Policies &
  CustomExtensions &
  CertificatePolicies &
  Actions;

export interface CertificateProfilesState {
  readonly isGettingCertificateProfileList: boolean;
  readonly isLoadedCertificateProfileList: boolean;
  readonly isGettingAllCertificateProfileList: boolean;
  readonly isGettingProvisioningCertificateProfileList: boolean;
  readonly isLoadedAllCertificateProfileList: boolean;
  readonly isLoadedProvisioningCertificateProfileList: boolean;
  readonly isCreatingProfile: boolean;
  readonly isDeletingProfile: boolean;
  readonly isArchivingProfile: boolean;
  readonly isEditingProfile: boolean;
  readonly allCertificateProfileList: CertificateProfile[];
  readonly certificateProfileList: CertificateProfile[];
  readonly provisioningCertificateProfileList: CertificateProfile[];
  readonly allCertificateProfilesListErrors: unknown;
  readonly certificateProfilesListErrors: unknown;
  readonly provisioningCertificateProfilesListErrors: unknown;
  readonly profileKeySpecs: {
    keySpec: { key: string; value: string }[];
    signatureAlgorithm: { key: string; value: string }[];
    signatureHashAlgorithm: { key: string; value: string }[];
  };
  readonly isGettingProfileKeySpecs: boolean;
  readonly isLoadedProfileKeySpecs: boolean;
}

export enum CertificateProfileActionTypes {
  GET_PROFILE_LIST_REQUEST = '@@CertificateProfile/GET_PROFILE_LIST_REQUEST',
  GET_PROFILE_LIST_SUCCESS = '@@CertificateProfile/GET_PROFILE_LIST_SUCCESS',
  GET_PROFILE_LIST_ERROR = '@@CertificateProfile/GET_PROFILE_LIST_ERROR',

  GET_ALL_PROFILE_LIST_REQUEST = '@@CertificateProfile/GET_ALL_PROFILE_LIST_REQUEST',
  GET_ALL_PROFILE_LIST_SUCCESS = '@@CertificateProfile/GET_ALL_PROFILE_LIST_SUCCESS',
  GET_ALL_PROFILE_LIST_ERROR = '@@CertificateProfile/GET_ALL_PROFILE_LIST_ERROR',

  GET_PROVISIONING_PROFILE_LIST_REQUEST = '@@CertificateProfile/GET_PROVISIONING_PROFILE_LIST_REQUEST',
  GET_PROVISIONING_PROFILE_LIST_SUCCESS = '@@CertificateProfile/GET_PROVISIONING_PROFILE_LIST_SUCCESS',
  GET_PROVISIONING_PROFILE_LIST_ERROR = '@@CertificateProfile/GET_PROVISIONING_PROFILE_LIST_ERROR',

  GET_PROFILE_KEY_SPECS_REQUEST = '@@CertificateProfile/GET_PROFILE_KEY_SPECS_REQUEST',
  GET_PROFILE_KEY_SPECS_SUCCESS = '@@CertificateProfile/GET_PROFILE_KEY_SPECS_SUCCESS',
  GET_PROFILE_KEY_SPECS_ERROR = '@@CertificateProfile/GET_PROFILE_KEY_SPECS_ERROR',

  POST_PROFILE_REQUEST = '@@CertificateProfile/POST_PROFILE_REQUEST',
  POST_PROFILE_SUCCESS = '@@CertificateProfile/POST_PROFILE_SUCCESS',
  POST_PROFILE_ERROR = '@@CertificateProfile/POST_PROFILE_ERROR',

  DELETE_PROFILE_REQUEST = '@@CertificateProfile/DELETE_PROFILE_REQUEST',
  DELETE_PROFILE_SUCCESS = '@@CertificateProfile/DELETE_PROFILE_SUCCESS',
  DELETE_PROFILE_ERROR = '@@CertificateProfile/DELETE_PROFILE_ERROR',

  ARCHIVE_PROFILE_REQUEST = '@@CertificateProfile/ARCHIVE_PROFILE_REQUEST',
  ARCHIVE_PROFILE_SUCCESS = '@@CertificateProfile/ARCHIVE_PROFILE_SUCCESS',
  ARCHIVE_PROFILE_ERROR = '@@CertificateProfile/ARCHIVE_PROFILE_ERROR',

  PUT_PROFILE_REQUEST = '@@CertificateProfile/PUT_PROFILE_REQUEST',
  PUT_PROFILE_SUCCESS = '@@CertificateProfile/PUT_PROFILE_SUCCESS',
  PUT_PROFILE_ERROR = '@@CertificateProfile/PUT_PROFILE_ERROR',
}
