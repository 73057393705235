import { Reducer } from 'redux';
import {
  CodeSigningProfilesState,
  CodeSigningProfilesActionTypes,
} from './types';

export const initialState: CodeSigningProfilesState = {
  codeSigningProfilesList: [],
  codeSigningProfilesErrors: undefined,
  isGettingCodeSigningProfileList: false,
  isLoadedCodeSigningProfileList: false,
  isCreatingCodeSigningProfile: false,
  isDeletingCodeSigningProfile: false,
  isEditingCodeSigningProfile: false,
  isGettingCodeSignProfileKeySpecs: false,
  isLoadedCodeSignProfileKeySpecs: false,
  codeSigningProfileKeySpecs: {
    signatureAlgorithm: [],
    signatureHashAlgorithm: [],
    outputFormat: [],
  },
};

const reducer: Reducer<CodeSigningProfilesState> = (
  state = initialState,
  action
): CodeSigningProfilesState => {
  switch (action.type) {
    case CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_LIST_REQUEST: {
      return {
        ...state,
        isGettingCodeSigningProfileList: true,
        isLoadedCodeSigningProfileList: false,
        codeSigningProfilesErrors: undefined,
      };
    }
    case CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCodeSigningProfileList: false,
        isLoadedCodeSigningProfileList: true,
        codeSigningProfilesList: action.payload,
      };
    }
    case CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_LIST_ERROR: {
      return {
        ...state,
        isGettingCodeSigningProfileList: false,
        isLoadedCodeSigningProfileList: false,
        codeSigningProfilesErrors: action.payload,
      };
    }

    case CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_KEY_SPECS_REQUEST: {
      return {
        ...state,
        isGettingCodeSignProfileKeySpecs: true,
        isLoadedCodeSignProfileKeySpecs: false,
      };
    }
    case CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_KEY_SPECS_SUCCESS: {
      return {
        ...state,
        isGettingCodeSignProfileKeySpecs: false,
        isLoadedCodeSignProfileKeySpecs: true,
        codeSigningProfileKeySpecs: action.payload,
      };
    }
    case CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_KEY_SPECS_ERROR: {
      return {
        ...state,
        isGettingCodeSignProfileKeySpecs: false,
        isLoadedCodeSignProfileKeySpecs: false,
      };
    }

    case CodeSigningProfilesActionTypes.POST_CODE_SIGNING_PROFILE_REQUEST: {
      return { ...state, isCreatingCodeSigningProfile: true };
    }
    case CodeSigningProfilesActionTypes.POST_CODE_SIGNING_PROFILE_SUCCESS: {
      return { ...state, isCreatingCodeSigningProfile: false };
    }
    case CodeSigningProfilesActionTypes.POST_CODE_SIGNING_PROFILE_ERROR: {
      return {
        ...state,
        isCreatingCodeSigningProfile: false,
      };
    }

    case CodeSigningProfilesActionTypes.PUT_CODE_SIGNING_PROFILE_REQUEST: {
      return { ...state, isEditingCodeSigningProfile: true };
    }
    case CodeSigningProfilesActionTypes.PUT_CODE_SIGNING_PROFILE_SUCCESS: {
      return { ...state, isEditingCodeSigningProfile: false };
    }
    case CodeSigningProfilesActionTypes.PUT_CODE_SIGNING_PROFILE_ERROR: {
      return {
        ...state,
        isEditingCodeSigningProfile: false,
      };
    }

    case CodeSigningProfilesActionTypes.DELETE_CODE_SIGNING_PROFILE_REQUEST: {
      return { ...state, isDeletingCodeSigningProfile: true };
    }
    case CodeSigningProfilesActionTypes.DELETE_CODE_SIGNING_PROFILE_SUCCESS: {
      return { ...state, isDeletingCodeSigningProfile: false };
    }
    case CodeSigningProfilesActionTypes.DELETE_CODE_SIGNING_PROFILE_ERROR: {
      return {
        ...state,
        isDeletingCodeSigningProfile: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as codeSigningProfilesReducer };
