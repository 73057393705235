import _ from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { AnyAction } from 'redux';
import { sendNotification } from '../notifications/actions';
import {
  deserializeCodeSigningProfile,
  serializeCodeSigningProfile,
  deserializeCodeSigningProfileKeySpecs,
} from './helpers';

import {
  CodeSigningProfile,
  CodeSigningProfilesState,
  CodeSigningProfilesActionTypes,
} from './types';
import { Filter } from '../../components/FilterBar/types';
import { api, getUrlWithFilters } from '../../libs/helpers';

const error = require('debug')('pki-app:error:codeSigningProfiles');

interface RequestParams {
  page?: number | undefined;
  sizePerPage?: number | undefined;
  uuid?: string | undefined;
  query?: Filter[];
  sortBy?: string;
  sortDir?: string;
}

export const getCodeSigningProfiles = (
  { sizePerPage, page, query, uuid, sortBy, sortDir } = {} as RequestParams
): ThunkAction<Promise<boolean>, CodeSigningProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CodeSigningProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type:
        CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_LIST_REQUEST,
    });
    try {
      let url = 'code-signing/profile';

      url = getUrlWithFilters(url, {
        sizePerPage,
        page,
        sortBy,
        sortDir,
        query,
        uuid,
      });

      const response = await api().get(url);
      const payload = _.map(response.data, (item) =>
        deserializeCodeSigningProfile(item)
      );
      dispatch({
        type:
          CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type:
          CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Code Signing Profiles list!';
      sendNotification({
        text,

        success: false,
      })(dispatch);
      error(`getCodeSigningProfiles: ${err}`);
      return false;
    }
  };
};

export const getCodeSigningProfileKeySpecs = (
  CAuuid: string
): ThunkAction<Promise<boolean>, CodeSigningProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CodeSigningProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type:
        CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_KEY_SPECS_REQUEST,
    });
    try {
      const response = await api().get(
        `code-signing/profile/signature_specs/${CAuuid}`
      );
      const payload = deserializeCodeSigningProfileKeySpecs(response.data);
      dispatch({
        type:
          CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_KEY_SPECS_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type:
          CodeSigningProfilesActionTypes.GET_CODE_SIGNING_PROFILE_KEY_SPECS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Code Signing Profile Key Specs!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getCodeSigningProfileKeySpecs: ${err}`);
      return false;
    }
  };
};

export const createCodeSigningProfile = (
  codeSigningProfile: CodeSigningProfile
): ThunkAction<Promise<boolean>, CodeSigningProfilesState, {}, AnyAction> => {
  const CodeSigningProfileSerialized = serializeCodeSigningProfile(
    codeSigningProfile
  );
  return async (
    dispatch: ThunkDispatch<CodeSigningProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CodeSigningProfilesActionTypes.POST_CODE_SIGNING_PROFILE_REQUEST,
    });
    try {
      const payload = await api().post('code-signing/profile', {
        ...CodeSigningProfileSerialized,
      });
      dispatch({
        type: CodeSigningProfilesActionTypes.POST_CODE_SIGNING_PROFILE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Code Signing Profile has been successfully created!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CodeSigningProfilesActionTypes.POST_CODE_SIGNING_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the Code Signing Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createCodeSigningProfile: ${err}`);
      return false;
    }
  };
};

export const editCodeSigningProfile = (
  codeSigningProfile: CodeSigningProfile
): ThunkAction<Promise<boolean>, CodeSigningProfilesState, {}, AnyAction> => {
  const { uuid } = codeSigningProfile;
  const codeSigningProfileSerialized = serializeCodeSigningProfile(
    codeSigningProfile
  );
  return async (
    dispatch: ThunkDispatch<CodeSigningProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CodeSigningProfilesActionTypes.PUT_CODE_SIGNING_PROFILE_REQUEST,
    });
    try {
      const payload = await api().put(`code-signing/profile/${uuid}`, {
        ...codeSigningProfileSerialized,
      });
      dispatch({
        type: CodeSigningProfilesActionTypes.PUT_CODE_SIGNING_PROFILE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Code Signing Profile has been successfully edited!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CodeSigningProfilesActionTypes.PUT_CODE_SIGNING_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong editing the Code Signing Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`editCodeSigningProfile: ${err}`);
      return false;
    }
  };
};
