import _, { isNil } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CancelTokenSource } from 'axios';
import {
  ManufacturingStationsActionTypes,
  ManufacturingStationsState,
  ManufacturingStation,
  SecureToken,
} from './types';
import { sendNotification } from '../notifications/actions';
import {
  deserializeManufacturingStation,
  serializeManufacturingStation,
  deserializeProvisioningProfile,
  deserializeSecureToken,
  serializeSecureToken,
} from './helpers';
import { api, getUrlWithFilters } from '../../libs/helpers';
import { Params } from '../../libs/types';

const error = require('debug')('pki-app:error:manufacturing_stations');

export const getManufacturingStation = (
  uuid: string
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.GET_MANUFACTURING_STATION_REQUEST,
    });
    try {
      const response = await api().get(`manufacturing/station/${uuid}`);
      const payload = deserializeManufacturingStation(response.data);

      dispatch({
        type:
          ManufacturingStationsActionTypes.GET_MANUFACTURING_STATION_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.GET_MANUFACTURING_STATION_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Manufacturing Station!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getManufacturingStation: ${err}`);
      return false;
    }
  };
};

export const getManufacturingStations = (
  { page, sizePerPage, sortBy, sortDir }: Params,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type:
        ManufacturingStationsActionTypes.GET_MANUFACTURING_STATIONS_LIST_REQUEST,
    });

    let url = 'manufacturing/station';
    url = getUrlWithFilters(url, { sizePerPage, page, sortBy, sortDir });

    try {
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (rawManufacturingStation) =>
        deserializeManufacturingStation(rawManufacturingStation)
      );
      dispatch({
        type:
          ManufacturingStationsActionTypes.GET_MANUFACTURING_STATIONS_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type:
          ManufacturingStationsActionTypes.GET_MANUFACTURING_STATIONS_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Manufacturing Stations list!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getManufacturingStations: ${err}`);
      return false;
    }
  };
};

export const createManufacturingStation = (
  manufacturingStation: ManufacturingStation
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.POST_MANUFACTURING_STATION_REQUEST,
    });
    try {
      await api().post('manufacturing/station', {
        ...serializeManufacturingStation(manufacturingStation),
      });
      dispatch({
        type:
          ManufacturingStationsActionTypes.POST_MANUFACTURING_STATION_SUCCESS,
      });
      sendNotification({
        text: `The Manufacturing Station has been successfully created.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.POST_MANUFACTURING_STATION_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the new Manufacturing Station!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createManufacturingStation: ${err}`);
      return false;
    }
  };
};

export const editManufacturingStation = (
  manufacturingStation: ManufacturingStation
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.PUT_MANUFACTURING_STATION_REQUEST,
    });
    try {
      const response = await api().put(
        `manufacturing/station/${manufacturingStation.uuid}`,
        {
          ...serializeManufacturingStation(manufacturingStation),
        }
      );
      const payload = deserializeManufacturingStation(response.data);

      dispatch({
        type:
          ManufacturingStationsActionTypes.PUT_MANUFACTURING_STATION_SUCCESS,
        payload,
      });
      sendNotification({
        text: `The Manufacturing Station has been successfully edited.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.PUT_MANUFACTURING_STATION_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong updating the Manufacturing Station!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createManufacturingStation: ${err}`);
      return false;
    }
  };
};

export const getProvisioningProfiles = (
  manufacturingStationUuid: string
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.GET_PROVISIONING_PROFILES_REQUEST,
    });
    try {
      const response = await api().get(
        `manufacturing/station/${manufacturingStationUuid}/certificate/profile`
      );
      const payload = _.map(response.data, (item) =>
        deserializeProvisioningProfile(item)
      );
      dispatch({
        type:
          ManufacturingStationsActionTypes.GET_PROVISIONING_PROFILES_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.GET_PROVISIONING_PROFILES_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Manufacturing Station Provisioning Profiles!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getProvisioningProfiles: ${err}`);
      return false;
    }
  };
};

export const createProvisioningProfile = ({
  manufacturingStationUuid,
  certificateProfileUuid,
}: {
  manufacturingStationUuid: string;
  certificateProfileUuid: string;
}): ThunkAction<
  Promise<boolean>,
  ManufacturingStationsState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    if (isNil(manufacturingStationUuid)) {
      const text = `Manufacturing Station UUID is mandatory`;
      sendNotification({
        text,
      })(dispatch);
    }

    if (isNil(certificateProfileUuid)) {
      const text = `Certificate Profile UUID is mandatory`;
      sendNotification({
        text,
      })(dispatch);
    }

    dispatch({
      type: ManufacturingStationsActionTypes.POST_PROVISIONING_PROFILE_REQUEST,
    });

    try {
      const response = await api().post(
        `manufacturing/station/${manufacturingStationUuid}/certificate/profile/${certificateProfileUuid}`
      );
      const payload = _.map(response.data, (item) =>
        deserializeProvisioningProfile(item)
      );
      dispatch({
        type:
          ManufacturingStationsActionTypes.POST_PROVISIONING_PROFILE_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.POST_PROVISIONING_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong with assigning Provisioning Profile to the Manufacturing Station!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createProvisioningProfile: ${err}`);
      return false;
    }
  };
};

export const deleteProvisioningProfile = ({
  manufacturingStationUuid,
  certificateProfileUuid,
}: {
  manufacturingStationUuid: string;
  certificateProfileUuid: string;
}): ThunkAction<
  Promise<boolean>,
  ManufacturingStationsState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type:
        ManufacturingStationsActionTypes.DELETE_PROVISIONING_PROFILE_REQUEST,
    });

    try {
      const response = await api().delete(
        `manufacturing/station/${manufacturingStationUuid}/certificate/profile/${certificateProfileUuid}`
      );
      const payload = _.map(response.data, (item) =>
        deserializeProvisioningProfile(item)
      );
      dispatch({
        type:
          ManufacturingStationsActionTypes.DELETE_PROVISIONING_PROFILE_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      dispatch({
        type:
          ManufacturingStationsActionTypes.DELETE_PROVISIONING_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong with removing Provisioning Profile from the Manufacturing Station!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`deleteProvisioningProfile: ${err}`);
      return false;
    }
  };
};

export const getSecureTokens = (
  manufacturingStationUuid: string
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.GET_SECURE_TOKENS_REQUEST,
    });
    try {
      const response = await api().get(
        `manufacturing/station/${manufacturingStationUuid}/secure/token`
      );
      const payload = _.map(response.data, (item) =>
        deserializeSecureToken(item)
      );
      dispatch({
        type: ManufacturingStationsActionTypes.GET_SECURE_TOKENS_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.GET_SECURE_TOKENS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Manufacturing Station Secure Tokens!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getSecureTokens: ${err}`);
      return false;
    }
  };
};

export const createSecureToken = (
  secureToken: SecureToken
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.POST_SECURE_TOKEN_REQUEST,
    });

    try {
      await api().post('secure/token', {
        ...serializeSecureToken(secureToken),
      });
      dispatch({
        type: ManufacturingStationsActionTypes.POST_SECURE_TOKEN_SUCCESS,
      });

      sendNotification({
        text: `The Secure Token has been successfully created.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.POST_SECURE_TOKEN_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the new Secure Token!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createSecureToken: ${err}`);
      return false;
    }
  };
};

export const updateSecureToken = (
  secureToken: SecureToken
): ThunkAction<Promise<boolean>, ManufacturingStationsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ManufacturingStationsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ManufacturingStationsActionTypes.PUT_SECURE_TOKEN_REQUEST,
    });

    try {
      await api().put(`secure/token/${secureToken.uuid}`, {
        ...serializeSecureToken(secureToken),
      });
      dispatch({
        type: ManufacturingStationsActionTypes.PUT_SECURE_TOKEN_SUCCESS,
      });

      sendNotification({
        text: `The Secure Token has been successfully updated.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ManufacturingStationsActionTypes.PUT_SECURE_TOKEN_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong with updating of the Secure Token!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`updateSecureToken: ${err}`);
      return false;
    }
  };
};
