/* eslint-disable no-plusplus */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Moment } from 'moment';
import _ from 'lodash';
import { AnyAction } from 'redux';
import { sendNotification } from '../notifications/actions';
import { deserializeDeviceProvisioning } from './helpers';
import { DeviceProvisioningState, DeviceProvisioningTypes } from './types';
import { api } from '../../libs/helpers';

const error = require('debug')('pki-app:error:deviceProvisioning');

export const getDeviceProvisioning = ({
  startDate,
  endDate,
}: {
  startDate: Moment;
  endDate: Moment;
}): ThunkAction<Promise<boolean>, DeviceProvisioningState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<DeviceProvisioningState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: DeviceProvisioningTypes.GET_DEVICE_PROVISIONING_REQUEST });
    try {
      const response = await api().get(
        `certificate/stats?start_date=${startDate.format(
          'YYYY-MM-DD'
        )}&end_date=${endDate.format('YYYY-MM-DD')}`
      );
      const payload = _.map(response.data, (item) =>
        deserializeDeviceProvisioning(item)
      );
      dispatch({
        type: DeviceProvisioningTypes.GET_DEVICE_PROVISIONING_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: DeviceProvisioningTypes.GET_DEVICE_PROVISIONING_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching Device Provisioning!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getDeviceProvisioning: ${err}`);
      return false;
    }
  };
};
