import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, startCase } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { Badge, Collapse } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { User, UsersState } from '../../../store/users/types';
import { ApplicationState } from '../../../store';
import { Certificate } from '../../../store/certificates/types';
import { Filter } from '../../../components/FilterBar/types';
import { FilterBar, Spinner, Table } from '../../../components';
import { api } from '../../../libs/helpers';
import { sendNotification } from '../../../store/notifications/actions';
import { deserializeCertificate } from '../../../store/certificates/helpers';
import { mapRoleToValue } from '../../../store/users/helpers';

interface CertificateAuthoritiesProps {
  user: User | null;
}

interface EntitlementCA {
  entity: Certificate;
  entityRoles: string[];
}

export const CertificateAuthorities: FC<CertificateAuthoritiesProps> = ({
  user,
}) => {
  const {
    userProfile: { resources, uuid: currentUserUuid },
  } = useSelector<ApplicationState, UsersState>((pki) => ({
    ...pki.users,
  }));

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [query, setQuery] = useState<Filter[]>([]);
  const [certificateAuthorities, setCertificateAuthorities] = useState<
    EntitlementCA[]
  >();
  const fetchSourceRef = useRef<CancelTokenSource>();

  const viewLocation = location.pathname;

  useEffect(() => {
    return function cleanup(): void {
      if (location.pathname !== viewLocation) {
        setShowContent(false);
      }
    };
  }, [location.pathname, viewLocation]);

  const fetchData = async () => {
    fetchSourceRef.current = axios.CancelToken.source();
    if (user) {
      setShowSpinner(true);
      setShowContent(false);
      try {
        const {
          data: cas,
        } = await api().get(
          `/user/entitlements/summary?filter=entity_type=issuing_ca,user_uuid=${user?.uuid}&page=${tablePage}&count=${tableSizePerPage}`,
          { cancelToken: fetchSourceRef.current.token }
        );
        const casToSet = cas.map(
          // eslint-disable-next-line camelcase
          (ca: { entity: Certificate; entity_roles: string[] }) => {
            return {
              entity: deserializeCertificate(ca.entity),
              entityRoles: ca.entity_roles,
            };
          }
        );
        setCertificateAuthorities(() => {
          setShowContent(true);
          setShowSpinner(false);
          return casToSet;
        });
      } catch (e) {
        if (!axios.isCancel(e)) {
          sendNotification({
            text: 'Failed to get certificate authorities!',
            success: false,
          })(dispatch);
          setShowSpinner(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [tablePage, tableSizePerPage, query, user]);
  useEffect(() => {
    return () => {
      fetchSourceRef.current?.cancel();
    };
  }, []);
  const columns = [
    { dataField: 'uuid', text: 'uuid', hidden: true },
    { dataField: 'entity.commonName', text: 'Common Name' },
    {
      dataField: 'noData',
      text: 'Entitlements',
      formatter: (
        unused: string,
        { entityRoles }: { entityRoles: string[] }
      ): ReactNode => {
        return (
          <>
            {entityRoles.map((role) => (
              <span key={role} className={'m-1 d-inline-block'}>
                <Badge color={'primary'} className={'p-2'}>
                  {mapRoleToValue?.[role] ?? startCase(role)}
                </Badge>
              </span>
            ))}
          </>
        );
      },
    },
  ];

  const CAListDataTable = map(certificateAuthorities, (item) => ({
    ...item,
    isKeyOnline: item.entity.isKeyOnline ? 'Online' : 'Offline',
    issuer: item.entity.issuer?.commonName || '',
  }));
  return (
    <div className="user-profile-cas">
      {showSpinner && <Spinner className="mb-2" size="sm" type="border" />}
      <Collapse isOpen={showContent}>
        <div className="filters-container d-none">
          <FilterBar
            initialFilters={[]}
            filters={[
              {
                key: 'cn',
                label: 'Common Name',
                type: 'filter',
                operators: ['=', '~'],
              },
              // {
              //   key: 'state',
              //   label: 'State',
              //   type: 'filter',
              //   options: [
              //     { value: 'issued', label: 'Issued' },
              //     { value: 'revoked', label: 'Revoked' },
              //     {
              //       value: 'revocation_requested',
              //       label: 'Revocation Requested',
              //     },
              //   ],
              // },
              // {
              //   key: 'is_key_online',
              //   label: 'Key',
              //   type: 'filter',
              //   options: [
              //     { value: 'true', label: 'Online' },
              //     { value: 'false', label: 'Offline' },
              //   ],
              // },
              // {
              //   key: 'inserted_time',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Issued Date',
              //   type: 'filter',
              // },
              // {
              //   key: 'not_before',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Starting Date',
              //   type: 'filter',
              // },
              // {
              //   key: 'not_after',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Expiring Date',
              //   type: 'filter',
              // },
            ]}
            onFiltersChange={(newFilters: Filter[]): void => {
              if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                setQuery(() => {
                  setTablePage(1);
                  return newFilters;
                });
              }
            }}
          />
        </div>
        <div className="table-container">
          <Table
            data={CAListDataTable}
            remote={true}
            search={false}
            keyField="entity.uuid"
            pagination={{
              page: tablePage,
              sizePerPage: tableSizePerPage,
            }}
            onTableChange={(
              valueNotUsed: null,
              { page, sizePerPage }: { page: number; sizePerPage: number }
            ): void => {
              setTablePage(page);
              setTableSizePerPage(sizePerPage);
            }}
            rowEvents={{
              onClick: (notUsedValue: null, current: EntitlementCA): void => {
                history.push({
                  pathname: '/pki/cas',
                  search: `?uuid=${current.entity.uuid}`,
                });
              },
            }}
            noDataIndication={
              tablePage > 1
                ? 'No more Authorities available'
                : 'No Authorities available'
            }
            sort={{ dataField: 'issuedAt', order: 'desc' }}
            columns={columns}
          />
        </div>
      </Collapse>
    </div>
  );
};
