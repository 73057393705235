import React, {
  useState,
  FC,
  ReactNode,
  useEffect,
  MouseEvent,
  useMemo,
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Card, UncontrolledPopover, Collapse } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faEdit,
  faClone,
  faUserTag,
  faExternalLink,
} from '@fortawesome/free-solid-svg-icons';
import CodeSigningProfileForm from './CodeSigningProfileForm';
import {
  TableActions,
  Table,
  Spinner,
  ErrorHandler,
  FilterBar,
} from '../../components';
import {
  CodeSigningProfilesState,
  CodeSigningProfile,
} from '../../store/codeSigningProfiles/types';
import { sendNotification } from '../../store/notifications/actions';
import { ApplicationState } from '../../store';
import { defaultValuesCodeSigningProfiles as defaultValues } from './constants';
import {
  getCodeSigningProfiles,
  createCodeSigningProfile,
  editCodeSigningProfile,
} from '../../store/codeSigningProfiles/actions';
import { EntityType, UsersState } from '../../store/users/types';
import {
  api,
  dateFormatter,
  onRemoveEntityRole,
  onSetEntityRole,
} from '../../libs/helpers';
import { Filter } from '../../components/FilterBar/types';
import RolePolicy from '../ViewsComponents/RolePolicy';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'name':
      return 'name';
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

interface CodeSigningProfilesToolbarI {
  onCreateProfileClick?: Function;
  onBulkDeleteClick?: Function;
  bulkDeleteDisabled?: boolean;
  actions: string[];
}

const CodeSigningProfilesToolbar: FC<CodeSigningProfilesToolbarI> = ({
  onCreateProfileClick = (): null => null,
  onBulkDeleteClick = (): null => null,
  bulkDeleteDisabled = true,
  actions = [],
}) => {
  return (
    <div className="CodeSigningProfilesToolbar d-flex">
      <span className="mx-2">
        <Button
          outline
          id="create-code-sign-profile-button"
          disabled={!_.includes(actions, 'create')}
          size="sm"
          onClick={(): void => {
            onCreateProfileClick();
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Code Signing Profile{' '}
        </Button>
      </span>
    </div>
  );
};

const CodeSigningProfiles: FC = () => {
  const {
    isGettingCodeSigningProfileList,
    isLoadedCodeSigningProfileList,
    codeSigningProfilesErrors,
    isCreatingCodeSigningProfile,
    isEditingCodeSigningProfile,
    isDeletingCodeSigningProfile,
    codeSigningProfilesList,
    userProfile: { resources, uuid: currentUserUuid },
  } = useSelector<ApplicationState, CodeSigningProfilesState & UsersState>(
    (pki) => ({
      ...pki.codeSigningProfiles,
      ...pki.users,
    })
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const codeSigningProfileUuidParam = params.get('uuid');

  const initialQuery: Filter[] = useMemo(
    () =>
      codeSigningProfileUuidParam
        ? [
            {
              key: 'uuid',
              label: 'REST API ID',
              operator: '=',
              type: 'filter',
              value: codeSigningProfileUuidParam,
            },
          ]
        : [],
    [codeSigningProfileUuidParam]
  );

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>(initialQuery);
  const [hasOneKey, setHasOneKey] = useState<boolean | undefined>(undefined);

  const [formModal, setFormModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [isCloning, setIsCloning] = useState<boolean>(false);
  const [
    currentCodeSigningProfile,
    setCurrentCodeSigningProfile,
  ] = useState<CodeSigningProfile>(defaultValues);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [refetchRolePolicy, setRefetchRolePolicy] = useState(false);
  const [canEditRoles, setCanEditRoles] = useState(false);

  const isLoadingData =
    isCreatingCodeSigningProfile ||
    isEditingCodeSigningProfile ||
    isDeletingCodeSigningProfile ||
    isGettingCodeSigningProfileList;

  const isDataLoaded = isLoadedCodeSigningProfileList;

  const isPerformingAction =
    isCreatingCodeSigningProfile ||
    isEditingCodeSigningProfile ||
    isDeletingCodeSigningProfile;

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }

    if (isLoadedCodeSigningProfileList) {
      setShowContent(true);
    }

    if (isDataLoaded) {
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [
    isDataLoaded,
    isGettingCodeSigningProfileList,
    isLoadedCodeSigningProfileList,
    isLoadingData,
  ]);

  useEffect(() => {
    if (!isPerformingAction) {
      dispatch(
        getCodeSigningProfiles({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
          query,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPerformingAction,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    query,
  ]);

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setCurrentCodeSigningProfile(defaultValues);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const codeSignProfileResource = _.find(
    resources,
    (item) => item.name === 'code_signing_profile'
  );

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (
        name: string,
        { notes, uuid }: { uuid: string; notes: string }
      ): ReactNode => {
        return (
          <>
            <div>{name}</div>
            {!_.isEmpty(notes) && (
              <div
                id={`code-profile-${uuid}`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small> {notes}</small>
                <UncontrolledPopover
                  trigger="hover"
                  target={`code-profile-${uuid}`}
                >
                  <div className="p-2">
                    <small>{notes}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Created by',
      formatter: (
        createdBy: string,
        current: CodeSigningProfile
      ): ReactNode => {
        const isCreatedByUserDeleted = _.get(current, 'isCreatedByUserDeleted');
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
    {
      dataField: 'uuid',
      text: 'Actions',
      sort: false,
      formatter: (uuid: string, current: CodeSigningProfile): ReactNode => {
        const canUpdate = _.includes(current.actions, 'update');
        const canRead = _.includes(current.actions, 'read');
        const canClone = _.includes(current.actions, 'create');
        const canEditPolicy = _.includes(current.actions, 'assign_roles');

        return (
          <TableActions
            rowId={String(uuid)}
            options={[
              {
                label: 'Edit',
                disabled: !canUpdate,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canUpdate) {
                    const selected = _.find(
                      codeSigningProfilesList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCodeSigningProfile(selected);
                      setFormReadOnly(false);
                      toggleFormModal();
                    }
                  }
                },
              },
              {
                label: 'Clone',
                disabled: !canClone,
                ico: <FontAwesomeIcon className="pki-ico" icon={faClone} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canClone) {
                    if (current) {
                      setCurrentCodeSigningProfile({
                        ...current,
                        codeSigningProfileId: '',
                        name: '',
                        uuid: '',
                      });
                      setIsCloning(true);
                      setFormReadOnly(false);
                      toggleFormModal();
                    }
                  }
                },
              },
              {
                label: 'Related Certificate',
                ico: (
                  <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />
                ),
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  history.push({
                    pathname: '/inventory/certificates',
                    search: `?uuid=${current.certificateUuid}`,
                  });
                },
              },
              {
                label: 'Role Policy',
                disabled: !canRead,
                ico: <FontAwesomeIcon className="pki-ico" icon={faUserTag} />,
                onClick: async (e: MouseEvent): Promise<void> => {
                  e.stopPropagation();
                  setCanEditRoles(canEditPolicy);
                  setCurrentCodeSigningProfile(current);
                  setRolesModalOpen(true);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const checkHasOneKey = async () => {
    try {
      const { data: keys } = await api().get(
        'code-signing/key?filter=status=completed&count=1'
      );
      if (keys.length > 0) setHasOneKey(true);
      else {
        setHasOneKey(false);
      }
    } catch (e) {
      sendNotification({
        text: 'Failed to receive first code signing key',
        success: false,
      })(dispatch);
      setHasOneKey(undefined);
    }
  };

  useEffect(() => {
    checkHasOneKey();
  }, []);

  const toggleRolesModal = (): void => {
    setRolesModalOpen((prev) => {
      return !prev;
    });
  };

  const closeAndRefetch = () => {
    setRolesModalOpen(false);
    dispatch(
      getCodeSigningProfiles({
        page: tablePage,
        sizePerPage: tableSizePerPage,
        sortBy: tableSortField,
        sortDir: tableSortOrder,
        query,
      })
    );
  };

  return (
    <div className="CodeSigningProfiles">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Code Signing Profiles</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'name',
                  label: 'Name',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'notes',
                  label: 'Description',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'code_signing_key.certificate.cn',
                  label: 'Common Name',
                  type: 'filter',
                  operators: ['=', '~'],
                },
                {
                  key: 'code_signing_key.uuid',
                  label: 'Code Signing Certificate Request ID',
                  type: 'filter',
                },
                {
                  key: 'code_signing_profile_id',
                  label: 'REST API ID',
                  type: 'filter',
                },
                {
                  key: 'created_by_user_uuid',
                  label: 'Created By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'created_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'updated_by_user_uuid',
                  label: 'Updated By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'updated_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Created Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Modified Date',
                  type: 'filter',
                },
              ]}
              initialFilters={initialQuery}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {codeSigningProfilesErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                keyField="uuid"
                remote={true}
                search={false}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                sort={{
                  dataField: tableSortField,
                  order: tableSortOrder as SortOrder,
                }}
                noDataIndication={
                  tablePage > 1
                    ? 'No more Code Signing Profiles available'
                    : 'No Code Signing Profiles available'
                }
                data={codeSigningProfilesList}
                columns={columns}
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  setTablePage(() => {
                    setTableSortField(mapSortField(sortField));
                    setTableSizePerPage(sizePerPage);
                    setTableSortOrder(sortOrder);
                    return page;
                  });
                }}
                rowEvents={{
                  onClick: (
                    notUsedValue: null,
                    current: CodeSigningProfile
                  ): void => {
                    setCurrentCodeSigningProfile(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                toolbar={
                  <CodeSigningProfilesToolbar
                    actions={_.get(codeSignProfileResource, 'actions', [])}
                    onCreateProfileClick={(): void => {
                      if (hasOneKey === undefined) {
                        dispatch(
                          sendNotification({
                            text:
                              'Please wait while the list of Code Signing Keys is retrieved.',
                            success: false,
                          })
                        );
                      } else if (!hasOneKey) {
                        dispatch(
                          sendNotification({
                            text:
                              'There are not enough data for opening Code Signing Profiles. Be sure to have at least one Code Signing Key.',
                            success: false,
                          })
                        );
                      } else {
                        setFormReadOnly(false);
                        toggleFormModal();
                      }
                    }}
                  />
                }
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        <CodeSigningProfileForm
          readOnly={formReadOnly}
          onCancel={toggleFormModal}
          onChangeMode={toggleMode}
          defaultValues={currentCodeSigningProfile}
          onSubmit={({
            values,
            isValid,
          }: {
            values: CodeSigningProfile;
            isValid: boolean;
          }): void => {
            if (isValid) {
              if (!_.isEmpty(values.uuid) && !isCloning) {
                dispatch(editCodeSigningProfile(values));
              } else {
                dispatch(createCodeSigningProfile(values));
                setIsCloning(false);
              }
              toggleFormModal();
            } else {
              dispatch(
                sendNotification({
                  text: 'Some fields are mandatory, please fill them all.',
                  success: false,
                })
              );
            }
          }}
        />
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={rolesModalOpen}
        toggle={toggleRolesModal}
      >
        {currentCodeSigningProfile && (
          <RolePolicy
            closeAndRefetch={closeAndRefetch}
            readOnly={!canEditRoles}
            currentUserUuid={currentUserUuid}
            entity={currentCodeSigningProfile}
            entityType="CodeSigningProfile"
            onCancel={toggleRolesModal}
            shouldFetch={refetchRolePolicy}
            setShouldFetch={setRefetchRolePolicy}
            onSetEntityRole={async (role: {
              entityType: EntityType.CodeSigningProfile;
              userUuids: string[];
              entityRole: string;
              entityUuid: string;
              usersAlreadyEntitled: string[];
            }) => {
              await onSetEntityRole(
                { ...role, type: 'User', inherit: false },
                dispatch,
                setRefetchRolePolicy
              );
            }}
            onRemoveEntityRole={async (role: {
              entityType: EntityType.CertificateProfile;
              userUuid: string;
              entityRole: string;
              entityUuid: string;
            }) => {
              await onRemoveEntityRole(role, dispatch, setRefetchRolePolicy);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default CodeSigningProfiles;
