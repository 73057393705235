import _, { isEmpty } from 'lodash';
import React, {
  useState,
  FC,
  ReactNode,
  useEffect,
  MouseEvent,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, Card, UncontrolledPopover, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faArchive,
  faEdit,
  faClone,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons';
import CertificateProfileForm from './CertificateProfileForm';
import {
  TableActions,
  Table,
  Spinner,
  ConfirmationForm,
  ButtonDropdown,
  ErrorHandler,
  FilterBar,
} from '../../components';
import {
  CertificateProfilesState,
  CertificateProfile,
} from '../../store/certificateProfiles/types';
import { ApplicationState } from '../../store';
import { certificateProfileDefaultValues } from './constants';
import {
  getCertificateProfiles,
  archiveCertificateProfiles,
  createCertificateProfile,
  editCertificateProfile,
} from '../../store/certificateProfiles/actions';
import { sendNotification } from '../../store/notifications/actions';
import TemplatesForm from './TemplatesForm';
import { EntityType, User, UsersState } from '../../store/users/types';
import {
  dateFormatter,
  onRemoveEntityRole,
  onSetEntityRole,
} from '../../libs/helpers';
import RolePolicy from '../ViewsComponents/RolePolicy';
import { Filter } from '../../components/FilterBar/types';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'name':
      return 'certificate_profile.name';
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

interface CertificateProfilesToolbarI {
  onCreateProfileClick?: Function;
  onBulkDeleteClick?: Function;
  bulkDeleteDisabled?: boolean;
  actions: string[];
}

const CertificateProfilesToolbar: FC<CertificateProfilesToolbarI> = ({
  actions = [],
  onCreateProfileClick = (): null => null,
  onBulkDeleteClick = (): null => null,
  bulkDeleteDisabled = true,
}) => {
  return (
    <div className="CertificateProfilesToolbar d-flex">
      <span className="mx-2">
        <ButtonDropdown
          id="add-profile-button"
          options={[
            { key: 'scratch', value: 'Create' },
            { key: 'template', value: 'Create from Template' },
          ]}
          value={
            <>
              <FontAwesomeIcon icon={faPlusCircle} /> Certificate Profile{' '}
            </>
          }
          disabled={!_.includes(actions, 'create')}
          onClick={({ key }: { key: string }): void => {
            onCreateProfileClick(key);
          }}
        />
      </span>
    </div>
  );
};

const CertificateProfiles: FC = () => {
  const {
    isGettingCertificateProfileList,
    isLoadedCertificateProfileList,
    isCreatingProfile,
    isEditingProfile,
    isArchivingProfile,
    userProfile: { resources, uuid: currentUserUuid },
    certificateProfileList,
    certificateProfilesListErrors,
  } = useSelector<ApplicationState, CertificateProfilesState & UsersState>(
    (pki) => ({
      ...pki.certificateProfiles,
      ...pki.users,
    })
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const certificateProfileUuidParam = params.get('uuid');
  const initialQuery: Filter[] = useMemo(
    () =>
      certificateProfileUuidParam
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: certificateProfileUuidParam,
            },
          ]
        : [],
    [certificateProfileUuidParam]
  );

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>(initialQuery);
  const [isShown, setIsShown] = useState(initialQuery.length <= 0);
  const [refetchRolePolicy, setRefetchRolePolicy] = useState(false);

  const [formModal, setFormModal] = useState<boolean>(false);
  const [templateModal, setTemplateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [
    currentCertificateProfile,
    setCurrentCertificateProfile,
  ] = useState<CertificateProfile>(certificateProfileDefaultValues);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [canEditRoles, setCanEditRoles] = useState(false);

  const isLoadingData =
    isCreatingProfile || isEditingProfile || isGettingCertificateProfileList;

  const isDataLoaded = isLoadedCertificateProfileList;

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }
    if (isLoadedCertificateProfileList || certificateProfilesListErrors) {
      setShowContent(true);
    }
    if (isDataLoaded || certificateProfilesListErrors) {
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [
    certificateProfilesListErrors,
    isDataLoaded,
    isLoadedCertificateProfileList,
    isLoadingData,
  ]);

  useEffect(() => {
    if (
      isDataLoaded &&
      !isEmpty(certificateProfileUuidParam) &&
      certificateProfileList.findIndex(
        (certificateProfile) =>
          certificateProfile.uuid === certificateProfileUuidParam
      ) > -1 &&
      initialQuery.findIndex(
        (filter) =>
          filter.key === 'uuid' && filter.value === certificateProfileUuidParam
      ) > -1 &&
      !isShown
    ) {
      const certificateToShow = certificateProfileList.find(
        (certificateProfile) =>
          certificateProfile.uuid === certificateProfileUuidParam
      );
      if (certificateToShow) {
        setCurrentCertificateProfile(certificateToShow);
        setFormReadOnly(true);
        setFormModal(true);
        setIsShown(true);
      }
    }
  }, [certificateProfileUuidParam, isDataLoaded]);

  useEffect(() => {
    const isPerformingAction =
      isCreatingProfile || isEditingProfile || isArchivingProfile;

    if (!isPerformingAction) {
      dispatch(
        getCertificateProfiles({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
          query,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isArchivingProfile,
    isCreatingProfile,
    isEditingProfile,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    query,
  ]);

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setCurrentCertificateProfile(certificateProfileDefaultValues);
      }
      return !previousValue;
    });
  };

  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentCertificateProfile(certificateProfileDefaultValues);
      }
      return !previousValue;
    });
  };

  const toggleTemplateModal = (): void => {
    setTemplateModal((previousValue) => !previousValue);
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const toggleRolesModal = (): void => {
    setRolesModalOpen((prev) => {
      if (prev) {
        setCurrentCertificateProfile(certificateProfileDefaultValues);
      }
      return !prev;
    });
  };

  const certificateProfileResource = _.find(
    resources,
    (item) => item.name === 'certificate_profile'
  );

  const columnsProfiles = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (
        name: string,
        { description, uuid }: { uuid: string; description: string }
      ): ReactNode => {
        return (
          <>
            <div>{name}</div>
            {!_.isEmpty(description) && (
              <div
                id={`profile-${uuid}`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small> {description}</small>
                <UncontrolledPopover trigger="hover" target={`profile-${uuid}`}>
                  <div className="p-2">
                    <small>{description}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Created by',
      formatter: (
        createdBy: string,
        current: CertificateProfile
      ): ReactNode => {
        const isCreatedByUserDeleted = _.get(current, 'isCreatedByUserDeleted');
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
    {
      dataField: 'uuid',
      sort: false,
      text: 'Actions',
      formatter: (uuid: string, current: CertificateProfile): ReactNode => {
        const { actions } = current;
        const canUpdate = _.includes(actions, 'update');
        const canClone = _.includes(actions, 'clone');
        const canArchive = _.includes(actions, 'archive');
        const canEditPolicy = _.includes(current.actions, 'assign_roles');
        return (
          <TableActions
            rowId={String(uuid)}
            options={[
              {
                label: 'Edit',
                disabled: !canUpdate,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canUpdate) {
                    const selected = _.find(
                      certificateProfileList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCertificateProfile(selected);
                      setFormReadOnly(false);
                      toggleFormModal();
                    }
                  }
                },
              },
              {
                label: 'Clone',
                disabled: !canClone,
                ico: <FontAwesomeIcon className="pki-ico" icon={faClone} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canClone) {
                    const selected = _.find(
                      certificateProfileList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCertificateProfile({
                        ...selected,
                        uuid: '',
                        name: '',
                        profileId: '',
                      });
                      setFormReadOnly(false);
                      toggleFormModal();
                    }
                  }
                },
              },
              {
                label: 'Archive',
                disabled: !canArchive,
                ico: <FontAwesomeIcon className="pki-ico" icon={faArchive} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canArchive) {
                    const selected = _.find(
                      certificateProfileList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCertificateProfile(selected);
                      toggleDeleteModal();
                    }
                  }
                },
              },
              {
                label: 'Role Policy',
                ico: <FontAwesomeIcon className="pki-ico" icon={faUserTag} />,
                onClick: async (e: MouseEvent): Promise<void> => {
                  e.stopPropagation();
                  setCanEditRoles(canEditPolicy);
                  setCurrentCertificateProfile(current);
                  setRolesModalOpen(true);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div className="CertificateProfiles">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Certificate Profiles</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'certificate_profile.name',
                  label: 'Name',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'notes',
                  label: 'Description',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'certificate_profile_id',
                  label: 'REST API ID',
                  type: 'filter',
                },
                {
                  key: 'issuing_ca_uuid',
                  label: 'Issuing CA',
                  type: 'filter',
                  serverSideConfig: {
                    defaultFilters: 'is_key_online=true',
                    searchParam: 'cn',
                    urlParams: [
                      ['resource', 'certificate_profile'],
                      ['action', 'create'],
                    ],
                    fetchUrl: '/certificate/authority',
                    formatter: (entity: any) => entity?.cn || 'N/A',
                    id: 'issuing-ca-filter',
                  },
                  serverSide: true,
                  placeholder: 'Select an Issuing CA',
                },
                {
                  key: 'created_by_user_uuid',
                  label: 'Created By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'created_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'updated_by_user_uuid',
                  label: 'Updated By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    searchPlaceholder: 'Type username...',
                    fetchUrl: '/user',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'updated_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Created Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Modified Date',
                  type: 'filter',
                },
              ]}
              initialFilters={initialQuery}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {certificateProfilesListErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                remote={true}
                search={false}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                keyField="uuid"
                sort={{
                  dataField: tableSortField,
                  order: tableSortOrder as SortOrder,
                }}
                noDataIndication={
                  tablePage > 1
                    ? 'No more Certificate Profiles available'
                    : 'No Certificate Profiles available'
                }
                data={certificateProfileList}
                columns={columnsProfiles}
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                  setTableSortOrder(sortOrder);
                  setTableSortField(mapSortField(sortField));
                }}
                rowEvents={{
                  onClick: (
                    notUsedValue: null,
                    current: CertificateProfile
                  ): void => {
                    setCurrentCertificateProfile(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                toolbar={
                  <CertificateProfilesToolbar
                    actions={_.get(certificateProfileResource, 'actions', [])}
                    onCreateProfileClick={(key: string): void => {
                      if (key === 'template') {
                        toggleTemplateModal();
                      }
                      if (key === 'scratch') {
                        toggleFormModal();
                      }
                      setFormReadOnly(false);
                    }}
                    onBulkDeleteClick={toggleDeleteModal}
                  />
                }
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        <CertificateProfileForm
          readOnly={formReadOnly}
          onCancel={toggleFormModal}
          onSubmit={({
            values,
            isValid,
          }: {
            values: CertificateProfile;
            isValid: boolean;
          }): void => {
            if (isValid) {
              if (!_.isEmpty(values?.uuid)) {
                dispatch(editCertificateProfile(values));
              } else {
                dispatch(createCertificateProfile(values));
              }
              toggleFormModal();
            } else {
              dispatch(
                sendNotification({
                  text: 'Some fields are mandatory, please fill them all.',
                  success: false,
                })
              );
            }
          }}
          onChangeMode={toggleMode}
          defaultValues={currentCertificateProfile}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title="Archive Confirmation"
          content={
            <div className="text-center">
              <p>
                You are about to <strong>archive</strong> the selected Profile.
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={(): void => {
            if (currentCertificateProfile.uuid) {
              dispatch(
                archiveCertificateProfiles(currentCertificateProfile.uuid)
              );
              toggleDeleteModal();
            }
          }}
        />
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={templateModal}
        toggle={toggleTemplateModal}
      >
        <TemplatesForm
          onSelectTemplate={(selectedTemplate: CertificateProfile) => {
            setCurrentCertificateProfile({
              ...selectedTemplate,
              name: '',
              description: '',
              // keyPolicy: 'kms',
              approvalPolicy: {
                approverUuids: [],
              },
            });
            toggleTemplateModal();
            setFormReadOnly(false);
            setFormModal(true);
          }}
          onCancel={() => {
            toggleTemplateModal();
            toggleFormModal();
          }}
        />
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={rolesModalOpen}
        toggle={toggleRolesModal}
      >
        {currentCertificateProfile && (
          <RolePolicy
            readOnly={!canEditRoles}
            currentUserUuid={currentUserUuid}
            entity={currentCertificateProfile}
            entityType="CertificateProfile"
            onCancel={toggleRolesModal}
            shouldFetch={refetchRolePolicy}
            setShouldFetch={setRefetchRolePolicy}
            onSetEntityRole={async (role: {
              entityType: EntityType.CertificateProfile;
              userUuids: string[];
              entityRole: string;
              entityUuid: string;
              usersAlreadyEntitled: string[];
            }) => {
              await onSetEntityRole(
                { ...role, type: 'User', inherit: false },
                dispatch,
                setRefetchRolePolicy
              );
            }}
            onRemoveEntityRole={async (role: {
              entityType: EntityType.CertificateProfile;
              userUuid: string;
              entityRole: string;
              entityUuid: string;
            }) => {
              await onRemoveEntityRole(role, dispatch, setRefetchRolePolicy);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default CertificateProfiles;
