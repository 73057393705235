import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import { AnyAction } from 'redux';
import { sendNotification } from '../notifications/actions';
import { ClientsState, ClientsActionTypes, Client } from './types';
import {
  deserializeClient,
  serializeClient,
  deserializeClientEntitlement,
} from './helpers';
import { api, getUrlWithFilters } from '../../libs/helpers';
import { Filter } from '../../components/FilterBar/types';
import { NewErrorObject } from '../../libs/types';

const error = require('debug')('pki-app:error:auditLogs');

interface RequestParams {
  action?: string;
  resource?: string;
  removeActions?: boolean;
  page?: number | undefined;
  sizePerPage?: number | undefined;
  uuid?: string | undefined;
  query?: Filter[];
  sortBy?: string;
  sortDir?: string;
}

export const getClients = (
  { sizePerPage, page, query, uuid, sortBy, sortDir } = {} as RequestParams
): ThunkAction<Promise<boolean>, ClientsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: ClientsActionTypes.GET_CLIENTS_LIST_REQUEST });
    try {
      let url = 'client';

      url = getUrlWithFilters(url, {
        sizePerPage,
        page,
        sortBy,
        sortDir,
        query,
        uuid,
      });

      const { data } = await api().get(url);
      const clientsDesirialized = _.map(data, (item) =>
        deserializeClient(item)
      );
      dispatch({
        type: ClientsActionTypes.GET_CLIENTS_LIST_SUCCESS,
        payload: clientsDesirialized,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ClientsActionTypes.GET_CLIENTS_LIST_ERROR,
        payload: err,
      });
      sendNotification({
        text: 'Oops! Something went wrong fetching the Clients list!',
        success: false,
      })(dispatch);
      error(`getClients: ${err}`);
      return false;
    }
  };
};

export interface ForbiddenClientSecretError {
  text?: string;
  missingMessage?: string;
  missingEntitlements?: string[];
}

const generateClientSecretForbiddenMessage = (errorObject: NewErrorObject) => {
  const response: ForbiddenClientSecretError = {};
  response.text =
    'Unable to show or generate the API Client secret; the client has higher privileges than you. Please contact your CA Administrator.';
  const reasons = errorObject?.missing_entitlements?.map(
    ({
      entity_name: entityName,
      entity_type: entityType,
      entity_role: entityRole,
    }) => `"${entityRole}" on ${entityType} "${entityName}"`
  );
  if (reasons && reasons.length > 0) {
    response.missingMessage = `Your needed entitlement(s):`;
    response.missingEntitlements = reasons;
  }
  return response;
};

export const getClientSecret = async (
  uuid: string,
  dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
): Promise<string> => {
  try {
    const {
      data: { client_secret: clientSecret },
    } = await api().get(`client/${uuid}/secret`);
    return clientSecret;
  } catch (err) {
    const dataObject = err?.response?.data;
    let text = '';
    if (dataObject?.type === '/insufficient-entitlements') {
      throw generateClientSecretForbiddenMessage(dataObject);
    } else {
      text = `Unable to get the Client Secret: ${JSON.stringify(
        err?.response?.data?.message
      )}`;
    }
    sendNotification({
      text,
      success: false,
    })(dispatch);
    error(`getClientSecret: ${err}`);
    return '';
  }
};

export const getClientEntitlements = (
  userUuid: string
): ThunkAction<Promise<boolean>, ClientsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ClientsActionTypes.GET_CLIENT_ENTITLEMENTS_REQUEST,
    });
    try {
      const { data } = await api().get(`user/${userUuid}`);
      const { entity_roles: clientEtitlements } = data;
      const payload = _.map(clientEtitlements, (item) =>
        deserializeClientEntitlement(item)
      );
      dispatch({
        type: ClientsActionTypes.GET_CLIENT_ENTITLEMENTS_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ClientsActionTypes.GET_CLIENT_ENTITLEMENTS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        `Oops! Something went wrong fetching the Client's Entitlements!`;
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getClientEntitlements: ${err}`);
      return false;
    }
  };
};

export const generateClientSecret = async (
  uuid: string,
  dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
): Promise<void> => {
  try {
    await api().post(`client/${uuid}/secret`);
    sendNotification({
      text: 'A New Client Secret has been successfully generated!',
      success: true,
    })(dispatch);
  } catch (err) {
    const text =
      JSON.stringify(err?.response?.data?.detail) ||
      'Oops! Something went wrong generating the Client Secret!';
    sendNotification({
      text,
      success: false,
    })(dispatch);
    error(`getClientSecret: ${err}`);
  }
};

export const createClient = (
  client: Client
): ThunkAction<Promise<boolean>, ClientsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: ClientsActionTypes.POST_CLIENT_REQUEST });
    try {
      const clientSerialized = serializeClient(client);
      await api().post('client', { ...clientSerialized });
      dispatch({
        type: ClientsActionTypes.POST_CLIENT_SUCCESS,
        payload: clientSerialized,
      });
      sendNotification({
        text: 'The new client has been successfully created!',
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ClientsActionTypes.POST_CLIENT_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the new Client!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createClient: ${err}`);
      return false;
    }
  };
};

export const editClient = (
  client: Client
): ThunkAction<Promise<boolean>, ClientsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: ClientsActionTypes.PUT_CLIENT_REQUEST });
    try {
      const clientSerialized = serializeClient(client);
      await api().put(`client/${client.uuid}`, { ...clientSerialized });
      dispatch({
        type: ClientsActionTypes.PUT_CLIENT_SUCCESS,
        payload: clientSerialized,
      });
      sendNotification({
        text: 'The client has been successfully updated!',
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ClientsActionTypes.PUT_CLIENT_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong editing the Client!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`editClient: ${err}`);
      return false;
    }
  };
};

export const deleteClient = (
  clientUuid: string
): ThunkAction<Promise<boolean>, ClientsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<ClientsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: ClientsActionTypes.DELETE_CLIENT_REQUEST,
    });

    try {
      await api().delete(`client/${clientUuid}`);
      dispatch({
        type: ClientsActionTypes.DELETE_CLIENT_SUCCESS,
      });
      sendNotification({
        text: 'The client has been successfully deleted!',
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: ClientsActionTypes.DELETE_CLIENT_ERROR,
        payload: err,
      });

      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong with deleting client!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`deleteClient: ${err}`);
      return false;
    }
  };
};
