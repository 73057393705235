import React, { FC, ReactNode, useState, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import Sidebar from 'react-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { setSidebar } from '../../store/layout/actions';
import { User } from '../../store/users/types';
import Navbar from '../../components/Navbar';
import './Layout.scss';

type leftMenuItem = {
  label: string;
  path: string;
  externalLink?: string;
  icon?: ReactNode;
  options?: leftMenuItem[];
};

type navMenuItem = {
  label: string;
  onClick: Function;
  icon?: ReactNode;
  hidden?: boolean;
};

interface Props {
  leftMenuConfig: leftMenuItem[];
  navMenuConfig: navMenuItem[];
  user: User;
  customers: {
    code: string;
    name: string;
  }[];
}

const renderSubMenu = (
  items: leftMenuItem[],
  path: string[],
  currentPath: string[]
): ReactNode => (
  <ListGroup flush>
    {_.map(items, (item: leftMenuItem, index) => {
      const newPath = [...path, item.path];
      const isActive = _.join(currentPath, '/').includes(_.join(newPath, '/'));
      const isIncludedIn = _.includes(currentPath, item.path);
      const classes = classNames({
        'py-1 bg-white ml-2 pl-0 d-flex': true,
        active: isActive,
      });

      return (
        <div key={index}>
          <ListGroupItem action className={classes}>
            {item.externalLink && (
              <a
                className={classes}
                href={item.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <span className="mr-2 text-left" style={{ width: '13px' }}>
                  {item.icon}
                </span>
                {item.label}
              </a>
            )}
            {!item.externalLink && (
              <Link
                id={_.join(newPath, '/')}
                className={classes}
                to={`/${_.join(newPath, '/')}`}
              >
                <span className="mr-2 text-left" style={{ width: '13px' }}>
                  {item.icon}
                </span>
                {item.label}
              </Link>
            )}
          </ListGroupItem>
          <Collapse isOpen={isIncludedIn}>
            <div className="ml-3">
              {item.options !== undefined &&
                renderSubMenu(item.options, newPath, currentPath)}
            </div>
          </Collapse>
        </div>
      );
    })}
  </ListGroup>
);

const getNavbarPath = (
  menuConfig: leftMenuItem[],
  path: string[],
  output: string[] = []
): string[] => {
  const menuItem = _.find(menuConfig, (item) => item.path === path[0]);
  if (menuItem) {
    output.push(menuItem.label);
    if (menuItem.options) {
      getNavbarPath(menuItem.options, _.slice(path, 1), output);
    }
  } else {
    path.forEach((slug) => output.push(_.startCase(slug)));
  }
  return output;
};

const Layout: FC<Props> = ({
  children,
  leftMenuConfig,
  navMenuConfig,
  user,
  customers,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean[]>(
    _.map(leftMenuConfig, (item) => true)
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const toggleSideBar = (): void => {
    setSidebarOpen((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    dispatch(setSidebar({ isOpen: sidebarOpen }));
  }, [dispatch, sidebarOpen]);

  useEffect(() => {
    setIsOpen(_.map(leftMenuConfig, (item) => true));
  }, [leftMenuConfig]);

  const toggleIsOpen = (index: number): void => {
    const currentValues = [...isOpen];
    currentValues[index] = !currentValues[index];
    setIsOpen(currentValues);
  };

  const currentPath = _(location.pathname)
    .split('/')
    .filter((item) => !_.isEmpty(item))
    .value();
  const navbarPath = getNavbarPath(leftMenuConfig, currentPath);

  const sidebarContent = _.map(leftMenuConfig, (item: leftMenuItem, index) => {
    return (
      <div className="fisrt-level-container" key={index}>
        <div
          id={String(item.path)}
          className="label cursor-pointer d-flex py-3 pl-3"
          onClick={(): void => {
            toggleIsOpen(index);
          }}
        >
          {item.label}{' '}
          <span className="menu-ico ml-auto mr-3">
            <FontAwesomeIcon
              icon={isOpen[index] ? faChevronDown : faChevronRight}
            />
          </span>
        </div>
        <Collapse isOpen={isOpen[index]}>
          {!_.isNil(item.options) &&
            renderSubMenu(item.options, [item.path], currentPath)}
        </Collapse>
      </div>
    );
  });

  return (
    <div className="Layout">
      <div className="main-layout-container">
        <div className="fixed-container">
          <Navbar
            user={user}
            path={navbarPath}
            customers={customers}
            menuConfig={navMenuConfig}
            sidebarOpen={sidebarOpen}
            onToggle={(): void => {
              toggleSideBar();
            }}
          />
        </div>
        <div className="flex-container bg-light">
          <Sidebar
            sidebarClassName="sidebar"
            sidebar={sidebarContent}
            open={sidebarOpen}
            docked={sidebarOpen}
            styles={{
              sidebar: {
                background: 'white',
                width: '230px',
                overflowX: 'hidden',
              },
            }}
          >
            <div id="content" className="p-3">
              {children}
            </div>
          </Sidebar>
        </div>
      </div>
    </div>
  );
};

export default Layout;
