export interface DeviceProvisioning {
  certificateProfileUuid: string;
  certificateProfileName: string;
  count: number;
  date: string;
}

export interface DeviceProvisioningState {
  readonly deviceProvisioningList: DeviceProvisioning[];
  readonly isGettingDeviceProvisioningList: boolean;
  readonly isLoadedDeviceProvisioningList: boolean;
  readonly deviceProvisioningErrors: unknown;
}

export enum DeviceProvisioningTypes {
  GET_DEVICE_PROVISIONING_REQUEST = '@@DeviceProvisionings/GET_DEVICE_PROVISIONING_REQUEST',
  GET_DEVICE_PROVISIONING_SUCCESS = '@@DeviceProvisionings/GET_DEVICE_PROVISIONING_SUCCESS',
  GET_DEVICE_PROVISIONING_ERROR = '@@DeviceProvisionings/GET_DEVICE_PROVISIONING_ERROR',
}
