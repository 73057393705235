import {
  Button,
  FormGroup,
  Label,
  Form,
  Modal,
  UncontrolledTooltip,
  ButtonGroup,
} from 'reactstrap';
import _, { find, snakeCase, startCase } from 'lodash';
import React, {
  FC,
  useState,
  ReactNode,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  ButtonDropdown,
  Table,
  Spinner,
  ConfirmationForm,
} from '../../components';
import { Certificate } from '../../store/certificates/types';
import { User, EntityRole } from '../../store/users/types';
import { deserializeUser, mapRoleToValue } from '../../store/users/helpers';
import { CertificateProfile } from '../../store/certificateProfiles/types';

import './Components.scss';
import { api } from '../../libs/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { CodeSigningProfile } from '../../store/codeSigningProfiles/types';
import { sendNotification } from '../../store/notifications/actions';
import { ProductKey } from '../keys/types';
import { ProductProfile } from '../ProductProfiles/types';

const subscriberRoles = [
  { key: 'ca_admin', value: 'CA Administrator' },
  { key: 'approver', value: 'Approver' },
  { key: 'pki_subscriber', value: 'PKI Subscriber' },
];

const codeSigningSubscriberRoles = [
  { key: 'product_admin', value: 'Product Administrator' },
];

const productKeyRoles = [{ key: 'crypto_user', value: 'Crypto User' }];
const productProfileRoles = [
  { key: 'product_admin', value: 'Product Administrator' },
  { key: 'approver', value: 'Approver' },
  { key: 'product_user', value: 'Product User' },
  { key: 'crypto_user', value: 'Crypto User' },
];

interface Props {
  entityType:
    | 'IssuingCA'
    | 'CertificateProfile'
    | 'CodeSigningProfile'
    | 'ProductKey'
    | 'ProductProfile';
  entity:
    | Certificate
    | CertificateProfile
    | CodeSigningProfile
    | ProductKey
    | ProductProfile;
  onCancel: Function;
  currentUserUuid: string;
  onSetEntityRole: Function;
  onRemoveEntityRole: Function;
  readOnly: boolean;
  shouldFetch?: boolean;
  setShouldFetch?: Dispatch<SetStateAction<boolean>>;
  closeAndRefetch?: Function;
}

const entityTypeUrlMap = {
  IssuingCA: 'issuing_ca',
  CertificateProfile: 'certificate_profile',
  CodeSigningProfile: 'code_signing_profile',
  ProductKey: 'product_key',
  ProductProfile: 'product_profile',
};

const RolePolicy: FC<Props> = ({
  onCancel = (): null => null,
  onSetEntityRole = (): null => null,
  onRemoveEntityRole = (): null => null,
  entity,
  entityType,
  readOnly = false,
  currentUserUuid,
  shouldFetch = false,
  setShouldFetch = (): null => null,
  closeAndRefetch = (): null => null,
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [rolePolicyUsers, setRolePolicyUsers] = useState<Array<User>>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [waitToGetUsers, setWaitToGetUsers] = useState(false);
  const [onClickDeleteConfirm, setOnClickDeleteConfirm] = useState<Function>(
    () => {
      return 1;
    }
  );
  const [deleteFromCurrent, setDeleteFromCurrent] = useState(false);

  const dispatch = useDispatch();
  const fetchData = async () => {
    setWaitToGetUsers(true);
    setLoadingUsers(true);
    try {
      const entityTypeUrl = entityTypeUrlMap[entityType];

      const response = await api().get(
        `entity/${entityTypeUrl}/${entity.uuid}/user`
      );
      const filteredResponse =
        entityType !== 'CodeSigningProfile'
          ? response.data
          : response.data.filter(
              (user: any) =>
                user.entity_roles.findIndex(
                  (entityRole: EntityRole) =>
                    entityRole.role === 'product_admin'
                ) > -1
            );
      setRolePolicyUsers(
        _.map(filteredResponse, (rawUser) => deserializeUser(rawUser))
      );
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching role policies!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setLoadingUsers(false);
      setShouldFetch(false);
      setWaitToGetUsers(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldFetch) fetchData();
  }, [shouldFetch]);

  const toggleDeleteModal = (): void => {
    setDeleteModal((prev) => !prev);
  };

  const onConfirmDelete = (uuid: string, entityRole: EntityRole): void => {
    if (!readOnly) {
      onRemoveEntityRole({
        entityType,
        userUuid: uuid,
        entityRole: entityRole.role,
        entityUuid: entityRole.entityUuid,
      });
      if (uuid === currentUserUuid && entityType === 'CodeSigningProfile') {
        closeAndRefetch();
      }
      setDeleteModal(false);
    }
  };

  const userOptionFormatter = (user: User) =>
    `${user.username.replaceAll(/^service-account(-token)?-/g, '')} (${
      user.email || 'No Email Address'
    })`;

  const columns = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'username', text: 'Username' },
    { dataField: 'email', text: 'Email' },
    {
      dataField: 'entityRoles',
      text: 'Roles',
      headerClasses: 'text-center',
      formatter: (
        entityRoles: EntityRole[],
        { uuid }: { uuid: string }
      ): ReactNode => (
        <div className="entity-roles text-center">
          {_.map(entityRoles, (entityRole, index) => {
            if (
              entityType === 'CodeSigningProfile' &&
              entityRole.role !== 'product_admin'
            )
              return null;
            const id = `role-${_.uniqueId(index.toString())}`;
            const divClass = classnames({
              'cursor-pointer': !readOnly,
            });
            const onClick = (): void => {
              if (entityType === 'CodeSigningProfile') {
                const currentProductAdmins = rolePolicyUsers.filter(
                  (user) =>
                    user.entityRoles.findIndex(
                      (role) =>
                        role.entityUuid === entity.uuid &&
                        role.entityType ===
                          entityTypeUrlMap.CodeSigningProfile &&
                        role.role === 'product_admin'
                    ) > -1
                );
                if (currentProductAdmins.length <= 1) {
                  sendNotification({
                    text: 'You cannot remove the last Product Administrator!',
                    success: false,
                  })(dispatch);
                  return;
                }
              }
              setOnClickDeleteConfirm(() => {
                return () => {
                  onConfirmDelete(uuid, entityRole);
                };
              });
              setDeleteFromCurrent(uuid === currentUserUuid);
              setDeleteModal(true);
            };
            return (
              <div className={divClass} key={index}>
                <ButtonGroup className="mt-1 mb-1" size="sm">
                  <Button disabled>{mapRoleToValue[entityRole.role]}</Button>
                  {!readOnly ? (
                    <>
                      <Button color="danger" id={id} onClick={onClick}>
                        {entityRole.role && !readOnly && (
                          <>
                            <FontAwesomeIcon icon={faTrash} />
                            <UncontrolledTooltip
                              hideArrow={true}
                              innerClassName="bg-light text-muted"
                              placement="auto"
                              target={id}
                            >
                              Remove
                            </UncontrolledTooltip>
                          </>
                        )}
                      </Button>
                    </>
                  ) : null}
                </ButtonGroup>
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  let roleOptions = subscriberRoles;
  if (entityType === 'CodeSigningProfile')
    roleOptions = codeSigningSubscriberRoles;
  else if (entityType === 'ProductKey') {
    roleOptions = productKeyRoles;
  } else if (entityType === 'ProductProfile') {
    roleOptions = productProfileRoles;
  }

  const titleKeys = ['name', 'label', 'commonName', 'title'];
  let entityTitle = '';
  titleKeys.every((key) => {
    if (key in entity) {
      entityTitle = _.get(entity, key);
      return false;
    }
    return true;
  });

  return (
    <div id="pki-roles-form" className="RolePolicy">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <span className="text-muted">
            <span className="d-flex">
              <h4 className="text-muted">
                Role Policy for {startCase(entityType)}
                {entityTitle ? ` "${entityTitle}"` : ''}
              </h4>
              {loadingUsers && (
                <Spinner className="mt-2 ml-3" size="sm" type="border" />
              )}
            </span>
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {
          <div>
            {!readOnly && (
              <>
                {' '}
                <Label className="pki-label">Users</Label>
                <Form
                  onSubmit={(e: React.FormEvent): void => {
                    e.preventDefault();
                  }}
                  inline
                >
                  <FormGroup className="w-75">
                    <ServerSideSelect
                      wait={waitToGetUsers}
                      value={
                        selectedUser
                          ? userOptionFormatter(selectedUser)
                          : 'Select A User'
                      }
                      searchPlaceholder={'Search username'}
                      fetchUrl={
                        entityType === 'CodeSigningProfile'
                          ? `entity/certificate_profile/${
                              (entity as CodeSigningProfile)
                                .certificateProfileUuid
                            }/user`
                          : 'user'
                      }
                      filterFrontend={
                        entityType === 'CodeSigningProfile'
                          ? (user: any) =>
                              user.entity_roles.findIndex(
                                (entityRole: EntityRole) =>
                                  ['pki_subscriber', 'approver'].includes(
                                    entityRole.role
                                  )
                              ) > -1
                          : undefined
                      }
                      onSelectEntity={(user: User) =>
                        setSelectedUser(deserializeUser(user))
                      }
                      pageUrlParam={'page'}
                      searchParam={'username'}
                      pageSize={entityType === 'CodeSigningProfile' ? 500 : 50}
                      searchOperator={'~'}
                      formatter={userOptionFormatter}
                      isParentLoading={false}
                    />
                  </FormGroup>
                  <div className="w-25">
                    <ButtonDropdown
                      className="float-right select-height"
                      id="entitle-button"
                      options={roleOptions}
                      value="Entitle as"
                      disabled={_.isEmpty(selectedUser)}
                      onClick={({ key }: { key: string }): void => {
                        if (!selectedUser) return;
                        const userUuids: string[] = [];
                        const usersAlreadyEntitled: string[] = [];
                        const userIsAlreadyEntitled = find(
                          selectedUser.entityRoles,
                          (entityRole) =>
                            snakeCase(entityRole.entityType) ===
                              snakeCase(entityType) &&
                            entityRole.entityUuid === entity.uuid &&
                            snakeCase(entityRole.role) === snakeCase(key)
                        );

                        if (userIsAlreadyEntitled) {
                          usersAlreadyEntitled.push(selectedUser.username);
                        } else {
                          userUuids.push(selectedUser.uuid);
                        }

                        onSetEntityRole({
                          entityRole: key,
                          entityType,
                          userUuids,
                          entityUuid: entity.uuid,
                          usersAlreadyEntitled,
                        });
                        setSelectedUser(null);
                      }}
                    />
                  </div>
                </Form>
              </>
            )}
            <div className="mt-5">
              <Table
                classes="role-policy-table"
                loading={loadingUsers}
                noDataIndication="No users entitled yet"
                keyField="uuid"
                columns={columns}
                pagination={{ sizePerPage: 5 }}
                data={rolePolicyUsers}
              />
            </div>
          </div>
        }
      </div>
      <Modal
        className="PKIApp"
        isOpen={deleteModal}
        style={{ marginTop: '10%' }}
        toggle={toggleDeleteModal}
      >
        <ConfirmationForm
          title="Remove Role"
          content={
            <div className="text-center">
              You're about to delete a role
              {deleteFromCurrent ? ' from your own user' : ''}.
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={onClickDeleteConfirm}
        />
      </Modal>
    </div>
  );
};

export default RolePolicy;
