import { Button, Modal } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import React, { FC, useState, useEffect } from 'react';
import SmartSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';
import { setCustomer } from '../../libs/helpers';

interface Props {
  customers: { code: string; name: string }[];
}

const Customer: FC<Props> = ({ customers }) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const customersParam = params.get('customers');

  const customerCode = localStorage.getItem('pki_customer');

  const [selectedCustomer, setSelectedCustomer] = useState<{
    value: string;
    label: string;
  }>();

  const selectOptions = _.map(customers, (customer) => ({
    value: customer?.code,
    label: customer?.name,
  }));

  useEffect(() => {
    const customer = _.find(customers, (item) => item.code === customerCode);
    if (customer) {
      setSelectedCustomer({
        value: customer.code,
        label: customer.name,
      });
    }
  }, [customerCode, customers]);

  const [modalOpen, setModalOpen] = useState<boolean>(
    customersParam === 'true'
  );

  useEffect(() => {
    setModalOpen(customersParam === 'true');
  }, [customersParam]);

  const toggleModal = (): void => {
    setModalOpen((prev) => !prev);
    history.push(location.pathname);
  };

  return (
    <Modal className="PKIApp" size="lg" isOpen={modalOpen} toggle={toggleModal}>
      <div className="Customer">
        <div className="form-header d-flex">
          <div className="mt-5 ml-5 d-flex text-muted">
            <h3 className="text-muted">Customers</h3>
          </div>
          <div className="ml-auto m-3">
            <Button
              id="close-form-button"
              outline
              size="sm"
              onClick={(): void => {
                setModalOpen(false);
                history.push(location.pathname);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>

        <div className="form-content mt-4 px-5 pb-5">
          <SmartSelect
            isSearchable={true}
            onChange={(selectedValue): void => {
              setSelectedCustomer({
                value: _.get(selectedValue, 'value') as string,
                label: _.get(selectedValue, 'label') as string,
              });
            }}
            value={selectedCustomer}
            options={selectOptions}
          />
          <div className="d-flex justify-content-between mt-5">
            <Button
              id="cancel-form-button"
              outline
              onClick={(): void => {
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button
              id="confirm-form-button"
              outline
              disabled={customerCode === selectedCustomer?.value}
              onClick={(): void => {
                if (selectedCustomer?.value) {
                  setCustomer(selectedCustomer.value);
                  toggleModal();
                  window.location.href = location.pathname;
                  Sentry.setTag('customer', selectedCustomer.value);
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Customer;
