/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import Cookies from 'js-cookie';
import moment from 'moment';
import axios, { AxiosInstance } from 'axios';
import _, {
  Dictionary,
  find,
  groupBy,
  includes,
  isEmpty,
  keys,
  replace,
  size,
} from 'lodash';
import { useLayoutEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { Params } from './types';
import { getVars, Customer } from './provisioning';
import { subjectKeysSorted, subjectPreviewKeys } from './constants';
import { updateToken } from './auth';
import { EntityRole, EntityType } from '../store/users/types';
import { sendNotification } from '../store/notifications/actions';
import { serializeUserEntityRole } from '../store/users/helpers';

type KeyValue = { [key: string]: string };

const URL_PATTERN = /(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*/gim;
const RFC822_PATTERN = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
const URI_PATTERN = /\w+:(\/?\/?)[^\s]+/gm;
const OID_PATTERN = /^([0-2])((\.0)|(\.[1-9][0-9]*))*$/gm;
const DNS_PATTERN = /^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))|\*?\.)*[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/gm;
const IP_PATTERN = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;
const HEX_PATTERN = /^[0-9a-fA-F]+$/;
export const BRACKETS_VARS_PATTERN = /\{.*?\}/g;
export const DEFAULT_VARS_PATTERN = /\{(?:[^}{]+|\{(?:[^}{]+|\{[^}{]*\})*\})*\}/g;
export const AUTOGEN_VARS_PATTERN = /[^{\}]+(?=\})/g;
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VAR_DEFAULT_VALUE = /(?<variableName>[^{]*?):(((?<optional>optional)(?<valueSection>\|(?<defaultValue>.*?))?|(?<required>required))(?<delimiter>(:length\d*|:country_code\d*|:hex|:ip|:ipv4|:ipv6|:uri|:fqdn|:upper|:lower|:truncate\d*|:z_fill\d*|:mac|:oct|:bin|:strip_encoding|:as_hex|:as_mac|:as_oct|:as_bin|:as_int)*)*)\}$/;
export const VAR_OPTIONAL = '[^{]*?:optional';
export const VAR_REQUIRED = '[^{]*?:required';

export const isValidRFC822 = (str: string): boolean =>
  new RegExp(RFC822_PATTERN).test(str);
export const isValidURI = (str: string): boolean =>
  new RegExp(URI_PATTERN).test(str);
export const isValidURL = (str: string): boolean =>
  new RegExp(URL_PATTERN).test(str);
export const isValidOID = (str: string): boolean =>
  new RegExp(OID_PATTERN).test(str);
export const isValidDNS = (str: string): boolean =>
  new RegExp(DNS_PATTERN).test(str);
export const isValidIP = (str: string): boolean =>
  new RegExp(IP_PATTERN).test(str);
export const isValidHex = (str: string | number): boolean =>
  new RegExp(HEX_PATTERN).test(str as string);
export const areThereNestedBrackets = (str: string): boolean => {
  // toDo...
  return false;
};
export const isValidEmail = (str: string): boolean =>
  new RegExp(EMAIL_PATTERN).test(str as string);

export const extractVariable = (variable: string): undefined | string => {
  return variable.match(VAR_OPTIONAL)?.[0] || variable.match(VAR_REQUIRED)?.[0];
};

export const extractVariableName = (key: string): string | undefined => {
  if (key) {
    const matches = key.match(/\{[^}]*(?:\:[^}]*)*\}/g);
    let splitKey;
    if (matches) {
      const matchedSubjectVar = matches[0].toString();
      splitKey = matchedSubjectVar.toString().split(':');
    } else {
      splitKey = key.toString().split(':');
    }
    if (splitKey?.[0].includes('autogen')) {
      return undefined;
    }
    if (splitKey?.[1]) {
      if (splitKey[1].includes('optional')) {
        return key.match(VAR_OPTIONAL)?.[0].replace(':optional', '');
      }
      if (splitKey[1].includes('required')) {
        return key.match(VAR_REQUIRED)?.[0].replace(':required', '');
      }
      if (
        matches &&
        splitKey.findIndex((str) => str.includes('country_code')) > 0
      ) {
        return splitKey?.[0].replaceAll('{', '');
      }
    }
  }
  return undefined;
};

export const mapKeysWithValues = (
  vars: KeyValue | undefined
): Dictionary<string | undefined> => {
  const varKeys = keys(vars);
  const mappedVars: Dictionary<string | undefined> = {};

  if (vars) {
    varKeys.forEach((key) => {
      const value = vars[key];
      const mapKey = extractVariableName(key);

      if (mapKey && value?.length > 0) {
        mappedVars[mapKey] = value;
      }
    });
  }

  return mappedVars;
};

export const oppositeRequirement = (variable: string): string => {
  return variable.includes('required')
    ? variable.replace('required', 'optional')
    : variable.replace('optional', 'required');
};

export const extractOptionalDefault = (
  variable: string
): undefined | string => {
  const match = variable.match(VAR_DEFAULT_VALUE);
  return match?.groups?.defaultValue;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const balancedBrackets = ([...str]): boolean => {
  return (
    str.reduce((uptoPrevChar, thisChar) => {
      (thisChar === '{' && uptoPrevChar++) ||
        (thisChar === '}' && uptoPrevChar--);
      return uptoPrevChar;
    }, 0) === 0
  );
};

export const setCustomer = (cutomerCode: string): void => {
  localStorage.setItem('pki_customer', cutomerCode);
};

export const getVariablesFromSubject = (
  subject: { key: string; value: string }[],
  values?: KeyValue
): KeyValue => {
  const uniqueVarNames: Dictionary<string> = {};
  const trans = _.transform(
    subject,
    (output: KeyValue, item) => {
      const arrayOfKeys = [item.value];
      _.each(arrayOfKeys, (i) => {
        const varName = extractVariableName(i);
        if (varName && !uniqueVarNames[varName]) {
          uniqueVarNames[varName] = varName;
          output[i] = values && values[varName] ? values[varName] : '';
        }
      });
    },
    {}
  );

  return trans;
};

export const areAutogenVarsCorrect = (input: string): boolean => {
  const arrayOfVars = input.match(BRACKETS_VARS_PATTERN);
  const arrayOfAutogenVars = _.filter(arrayOfVars, (i) =>
    i.includes('autogen:')
  );
  return _.every(
    arrayOfAutogenVars,
    (i) =>
      /^({autogen:random:hex)(([1-9]|1[0-6])})$/.test(i) ||
      /^({autogen:serial:hex)(([1-9]|1[0-6])})$/.test(i)
  );
};

export const formatSubject = (
  subject: { key: string; value: string }[]
): { [key: string]: string[] } =>
  _.transform(
    subjectPreviewKeys,
    (output: { [key: string]: string[] }, value, key) => {
      const subjectValues = _(subject)
        .filter((item) => item.key === key)
        .map((item) => item.value)
        .value();
      if (!_.isEmpty(subjectValues)) {
        _.set(output, key, subjectValues);
      }
    },
    {}
  );

export const api = (baseUrl?: string): AxiosInstance => {
  updateToken();
  const { PKI_API_URL, PKI_SELECTED_CUSTOMER_CODE } = getVars();
  const ID_TOKEN = Cookies.get('id_token');
  return axios.create({
    baseURL: `${baseUrl || `${PKI_API_URL}/${PKI_SELECTED_CUSTOMER_CODE}`}`,
    headers: {
      Authorization: `Bearer ${ID_TOKEN}`,
    },
  });
};

export const downloadTransportKey = async (): Promise<void> => {
  const response = await api().get('transport-key');
  const { public_key_pem: publicKeyPem } = response.data;
  return publicKeyPem;
};

export const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (): void => {
      const content = reader.result;
      resolve(String(content));
    };
    reader.readAsText(file);
  });
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const dateFormatter = (date: number, format = 'LLL'): string =>
  moment(new Date(Number(date))).format(format);

export const unhexlify = (str: string): Uint8Array => {
  return new Uint8Array(Buffer.from(str, 'hex'));
};

export const CRLderToPem = (derCRL: ArrayBuffer): string => {
  const derBuffer = Buffer.from(derCRL);
  const prefix = '-----BEGIN X509 CRL-----\n';
  const suffix = '-----END X509 CRL-----';
  const pemText =
    prefix +
    derBuffer
      .toString('base64')
      .match(/.{0,64}/g)
      ?.join('\n') +
    suffix;
  return pemText;
};

export const toBase64 = (file: File, removePrefix = true): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      const output: string = removePrefix
        ? (reader.result as string).split(',')[1]
        : (reader.result as string);
      resolve(output);
    };
    reader.onerror = (error): void => reject(error);
  });

export const getCurrentCustomerInfo = (): Customer | undefined => {
  const {
    PKI_SELECTED_CUSTOMER_CODE: currentCustomerCode,
    PKI_CUSTOMERS: customers,
  } = getVars();
  const currentCustomer = find(
    customers,
    (customer) => customer?.code === currentCustomerCode
  );
  return currentCustomer;
};

export const shouldCRLandAIABeShown = (): boolean => {
  const currentCustomer = getCurrentCustomerInfo();
  const showCRLandAIASetting = find(
    currentCustomer?.settings,
    (setting) => setting.attribute === 'show_crl_aia'
  );
  const showCRLandAIAValue = showCRLandAIASetting?.value === 'true';
  return showCRLandAIAValue;
};

export const constructBasicUrl = (baseURL: string, params: Params): string => {
  let url = baseURL;

  const { page, sizePerPage, sortBy, sortDir } = params;

  if (!includes(url, '?')) {
    url += '?';
  }

  if (sizePerPage) {
    url += `&count=${sizePerPage}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (sortBy) {
    url += `&sortBy=${sortBy}`;
  }
  if (sortDir) {
    url += `&sortDir=${sortDir}`;
  }
  return url;
};
export const getUrlWithFilters = (baseURL: string, params: Params): string => {
  let url = constructBasicUrl(baseURL, params);

  const { uuid, query, certificateRequestId } = params;

  if (!isEmpty(uuid)) {
    url += `&filter=uuid=${uuid}`;
  }

  if (!isEmpty(certificateRequestId)) {
    url += `&filter=certificate_request_id=${certificateRequestId}`;
  }

  if (!isEmpty(query)) {
    const { filter, like } = groupBy(query, 'type');
    _.each(filter, (item) => {
      if (!includes(url, 'filter')) {
        url += `&filter=${item.key}${item.operator || '='}${item.value}`;
      } else {
        url += `,${item.key}${item.operator || '='}${item.value}`;
      }
    });
    if (!isEmpty(like)) {
      _.each(like, (item) => {
        if (!includes(url, 'filter')) {
          url += `&filter=${item.key}~${item.value}`;
        } else {
          url += `,${item.key}~${item.value}`;
        }
      });
    }
  }

  if (includes(url, '?&')) {
    url = replace(url, '?&', '?');
  }

  return url;
};
export const getUrlExpiringCertificates = (
  baseURL: string,
  params: Params
): string => {
  let url = constructBasicUrl(baseURL, params);

  const { startDate } = params;

  if (!isEmpty(startDate)) {
    url += `&filter=not_after>${startDate}`;
  }

  if (includes(url, '?&')) {
    url = replace(url, '?&', '?');
  }

  return url;
};
export const getUrlIssuedCertificates = (
  baseURL: string,
  params: Params
): string => {
  let url = constructBasicUrl(baseURL, params);

  const { startDate, endDate } = params;

  if (!isEmpty(endDate) && !isEmpty(startDate)) {
    url += `&filter=inserted_time>${startDate}&inserted_time<${endDate}`;
  }

  if (includes(url, '?&')) {
    url = replace(url, '?&', '?');
  }

  return url;
};

export const uuidv4 = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const useWindowSize = (): number[] => {
  const [windowSize, setWindowSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize(): void {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);
  return windowSize;
};

export const getSubjectSorted = (subject: { key: string; value: string }[]) => {
  const storeSubjectSorted: { key: string; value: string }[] = [];
  _.each(subjectKeysSorted, (item) => {
    const valuesSorted = _.filter(subject, (subItem) => subItem.key === item);
    if (!_.isEmpty(valuesSorted)) {
      _.each(valuesSorted, (value) => {
        storeSubjectSorted.push(value);
      });
    }
  });
  return storeSubjectSorted;
};

export const amIAdmin = (entityRoles: EntityRole[]) => {
  const myRoleOnCustomer = find(entityRoles, (entityRole) => {
    return entityRole.entityType === 'customer';
  });
  return myRoleOnCustomer?.role === 'admin' || false;
};

export const shouldShowDemoContent = () => {
  const { PKI_API_URL, PKI_SELECTED_CUSTOMER_CODE } = getVars();
  return (
    ['demo', 'dt-rdk', 'canary'].includes(PKI_SELECTED_CUSTOMER_CODE) ||
    PKI_API_URL.includes('pki.local.key-central.irdeto.com')
  );
};

export const canSeeAsymmetricViews = () => {
  const { PKI_API_URL, PKI_SELECTED_CUSTOMER_CODE } = getVars();
  return (
    ['demo', 'canary', 'irdeto', 'fordotosan', 'charter', 'dt-rdk'].includes(
      PKI_SELECTED_CUSTOMER_CODE
    ) || PKI_API_URL.includes('pki.local.key-central.irdeto.com')
  );
};

export const disableBodyScrollOnKeyboard = (ev: KeyboardEvent) => {
  switch (ev.key) {
    case 'Down':
    case 'ArrowDown':
    case 'Up':
    case 'ArrowUp':
    case 'PageDown':
    case 'PageUp':
      ev.preventDefault();
      ev.stopPropagation();
  }
};

export const onSetEntityRole = async (
  {
    userUuids,
    entityRole,
    entityType,
    entityUuid,
    usersAlreadyEntitled,
    type = 'User',
    inherit = false,
  }: {
    userUuids: string[];
    entityType:
      | EntityType.CertificateProfile
      | EntityType.IssuingCA
      | EntityType.CodeSigningProfile
      | EntityType.ProductKey;
    entityRole: string;
    entityUuid: string;
    usersAlreadyEntitled: string[];
    type: string;
    inherit?: boolean;
  },
  dispatch: Dispatch,
  setRefetchRolePolicy: Function
): Promise<void> => {
  if (!isEmpty(usersAlreadyEntitled)) {
    const moreThanOne = size(usersAlreadyEntitled) > 1;
    const text = `${
      moreThanOne ? `${type}s` : type
    } ${usersAlreadyEntitled?.join(', ')} ${
      moreThanOne ? 'are' : 'is'
    } already entitled.`;
    sendNotification({
      text,
      success: false,
    })(dispatch);
  }

  if (isEmpty(userUuids)) {
    return;
  }
  try {
    await api().post('user/entity_role', {
      ...serializeUserEntityRole({
        userUuids,
        entityRole,
        entityType,
        entityUuid,
        inherit,
      }),
    });
    sendNotification({
      text: 'Entitlements have been successfully set!',
    })(dispatch);
    setRefetchRolePolicy(true);
  } catch (err) {
    const text =
      JSON.stringify(err?.response?.data?.detail) ||
      'Oops! Something went wrong, failed to set entitlements!';
    sendNotification({
      text,
      success: false,
    })(dispatch);
  }
};

export const onRemoveEntityRole = async (
  {
    userUuid,
    entityRole,
    entityType,
    entityUuid,
  }: {
    userUuid: string;
    entityType:
      | EntityType.CertificateProfile
      | EntityType.IssuingCA
      | EntityType.ProductKey;
    entityRole: string;
    entityUuid: string;
  },
  dispatch: Dispatch,
  setRefetchRolePolicy: Function
): Promise<void> => {
  try {
    await api().delete('user/entity_role', {
      data: {
        ...serializeUserEntityRole({
          userUuid,
          entityRole,
          entityType,
          entityUuid,
        }),
      },
    });
    sendNotification({
      text: 'Entitlement has been successfully deleted!',
    })(dispatch);
    setRefetchRolePolicy(true);
  } catch (err) {
    const text =
      JSON.stringify(err?.response?.data?.detail) ||
      'Oops! Something went wrong, failed to delete the entitlement!';
    sendNotification({
      text,
      success: false,
    })(dispatch);
  }
};

export const deserializePGPKey = (raw: any) => {
  return {
    publicKey: raw.public_key || '',
    pgpKeyId: raw.pgp_key_id || '',
    pgpKeyIdV4: raw.pgp_key_id_v4 || '',
    fingerprint: raw.fingerprint || '',
    createdAt: moment(_.get(raw, 'date_created')).format('x'),
    updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format(
      'LLL'
    ),
    uuid: raw.uuid,
    name: raw.name || '',
    enabled: !!raw.enabled,
  };
};
