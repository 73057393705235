/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import { AnyAction } from 'redux';
import axios, { CancelTokenSource } from 'axios';
import { sendNotification } from '../notifications/actions';
import {
  deserializeCertificate,
  deserializeTree,
  serializeRevocation,
} from './helpers';
import { CertificatesState, CertificatesActionTypes } from './types';
import { getVars } from '../../libs/provisioning';
import {
  api,
  CRLderToPem,
  toBase64,
  getUrlWithFilters,
  getUrlExpiringCertificates,
  getUrlIssuedCertificates,
} from '../../libs/helpers';
import { Params } from '../../libs/types';

const error = require('debug')('pki-app:error:certificates');

const certUrl = 'certificate?';

export const getCertificates = (
  {
    page,
    sizePerPage,
    sortBy,
    sortDir,
    uuid,
    query,
    certificateRequestId,
  }: Params,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificatesActionTypes.GET_CERTIFICATE_LIST_REQUEST });

    let url = certUrl;

    url = getUrlWithFilters(url, {
      page,
      sizePerPage,
      sortBy,
      sortDir,
      uuid,
      query,
      certificateRequestId,
    });

    try {
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificate(item)
      );
      dispatch({
        type: CertificatesActionTypes.GET_CERTIFICATE_LIST_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.GET_CERTIFICATE_LIST_ERROR,
        payload: err,
      });

      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Certificates list!';

      if (!axios.isCancel(err)) {
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getCertificates: ${err}`);
      return false;
    }
  };
};

export const getExpiringCertificates = (
  { startDate, sizePerPage, page, sortBy, sortDir } = {} as RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.GET_EXPIRING_CERTIFICATES_LIST_REQUEST,
    });
    try {
      let url = certUrl;
      url = getUrlExpiringCertificates(url, {
        startDate,
        sizePerPage,
        page,
        sortBy,
        sortDir,
      });
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificate(item)
      );
      dispatch({
        type: CertificatesActionTypes.GET_EXPIRING_CERTIFICATES_LIST_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.GET_EXPIRING_CERTIFICATES_LIST_ERROR,
        payload: err,
      });

      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Expiring Certificates list!';

      if (!axios.isCancel(err)) {
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getExpiringCertificates: ${err}`);
      return false;
    }
  };
};

export const getIssuedCertificates = (
  {
    startDate,
    endDate,
    sizePerPage,
    page,
    sortBy,
    sortDir,
  } = {} as RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.GET_ISSUED_CERTIFICATES_LIST_REQUEST,
    });
    try {
      let url = certUrl;
      url = getUrlIssuedCertificates(url, {
        startDate,
        endDate,
        sizePerPage,
        page,
        sortBy,
        sortDir,
      });
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificate(item)
      );
      dispatch({
        type: CertificatesActionTypes.GET_ISSUED_CERTIFICATES_LIST_SUCCESS,
        payload,
      });

      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.GET_ISSUED_CERTIFICATES_LIST_ERROR,
        payload: err,
      });

      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Issued Certificates list!';

      if (!axios.isCancel(err)) {
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getIssuedCertificates: ${err}`);
      return false;
    }
  };
};

export const getPrintedCertificate = (
  uuid: string
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificatesActionTypes.GET_PRINTED_CERTIFICATE_REQUEST });
    try {
      const response = await api().get(`certificate/print/${uuid}`);
      const payload = response.data.pretty_print;
      dispatch({
        type: CertificatesActionTypes.GET_PRINTED_CERTIFICATE_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.GET_PRINTED_CERTIFICATE_ERROR,
        payload: err,
      });

      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Printed version of the Certificate!';

      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getPrintedCertificate: ${err}`);
      return false;
    }
  };
};

export const uploadCRL = (
  crl: File
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    try {
      const formData = new FormData();
      formData.append('file', crl);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await api().post(`crl`, formData, config);
      sendNotification({
        text: 'The CRL has been successfully uploaded!',
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong uploading the CRL!';

      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`uploadCRL: ${err}`);
      return false;
    }
  };
};

export const uploadCA = (
  bundleMode: boolean,
  {
    ca,
    key,
    bundle,
  }: {
    ca?: File;
    key?: File;
    bundle?: File;
  }
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: bundleMode
        ? CertificatesActionTypes.POST_UPLOAD_CA_BUNDLE_REQUEST
        : CertificatesActionTypes.POST_UPLOAD_CA_REQUEST,
    });

    if (!bundleMode) {
      if (!ca) {
        sendNotification({
          text: "CA can't be empty!",
          success: false,
        })(dispatch);
        return false;
      }
      const certBody = await toBase64(ca);
      const keyValue = key ? await toBase64(key) : undefined;
      try {
        await api().post(`certificate/authority`, {
          cert_body: certBody,
          key_value: keyValue,
          authorization_metadata: '',
        });
        dispatch({
          type: CertificatesActionTypes.POST_UPLOAD_CA_SUCCESS,
        });
        sendNotification({
          text: 'The CA has been successfully uploaded!',
          success: true,
        })(dispatch);
        return true;
      } catch (err) {
        dispatch({
          type: CertificatesActionTypes.POST_UPLOAD_CA_ERROR,
        });

        const text =
          JSON.stringify(
            err?.response?.data?.message || err?.response?.data?.error
          ) || 'Oops! Something went wrong uploading the CA!';

        sendNotification({
          text,
          success: false,
        })(dispatch);
        error(`uploadCA: ${err}`);
        return false;
      }
    } else {
      if (!bundle) {
        sendNotification({
          text: "Bundle can't be empty!",
          success: false,
        })(dispatch);
        return false;
      }
      try {
        const formData = new FormData();
        formData.append('ext_key_tar_file', bundle);
        await api().post(`certificate/authority/upload`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        dispatch({
          type: CertificatesActionTypes.POST_UPLOAD_CA_BUNDLE_SUCCESS,
        });
        sendNotification({
          text: 'The CA has been successfully uploaded!',
          success: true,
        })(dispatch);
        return true;
      } catch (err) {
        dispatch({
          type: CertificatesActionTypes.POST_UPLOAD_CA_BUNDLE_ERROR,
        });

        const text =
          JSON.stringify(
            err?.response?.data?.message || err?.response?.data?.error
          ) || 'Oops! Something went wrong uploading the CA!';

        sendNotification({
          text,
          success: false,
        })(dispatch);
        error(`uploadCABundle: ${err}`);
        return false;
      }
    }
  };
};

export const downloadCRL = (
  commonName: string,
  format: 'pem' | 'der'
): ThunkAction<Promise<string | boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<string | boolean> => {
    const { PKI_SELECTED_CUSTOMER_CODE } = getVars();
    const url = `https://s3.eu-central-1.amazonaws.com/crl.${
      window.location.hostname
    }/${PKI_SELECTED_CUSTOMER_CODE}/${commonName.split(' ').join('-')}.crl`;
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer',
      });
      if (format === 'pem') {
        const CRLPemFormat = CRLderToPem(response.data);
        return CRLPemFormat;
      }
      if (format === 'der') {
        window.location.href = url;
        return true;
      }
    } catch (err) {
      let text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong downloading the CRL!';

      if (err?.response?.status === 404) {
        text = 'The Certificate Revocation List has not been found!';
      }
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`downloadCRL: ${err}`);
    }
    return false;
  };
};

interface RequestParams {
  startDate?: string;
  endDate?: string;
  isKeyOnline?: boolean;
  lowestTier?: boolean;
  action?: string;
  resource?: string;
  page?: number | undefined;
  sizePerPage?: number | undefined;
  sortBy?: string;
  sortDir?: string;
}

export const getCertificateAuthorities = (
  requestParams = {} as RequestParams & Params,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    const {
      isKeyOnline,
      lowestTier,
      sizePerPage,
      page,
      query,
      uuid,
      action,
      resource,
    } = requestParams;

    dispatch({
      type: isKeyOnline
        ? CertificatesActionTypes.GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_REQUEST
        : CertificatesActionTypes.GET_CERTIFICATE_AUTHORITY_LIST_REQUEST,
    });
    try {
      let url = `certificate/authority`;

      if (isKeyOnline) {
        url += '?filter=is_key_online=true';
      }
      if (lowestTier) {
        url += '?filter=lowest_tier=true';
      }
      if (action && resource) {
        url += `&resource=${resource}&action=${action}`;
      }

      url = getUrlWithFilters(url, { sizePerPage, page, query, uuid });

      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificate(item)
      );

      dispatch({
        type: isKeyOnline
          ? CertificatesActionTypes.GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_SUCCESS
          : CertificatesActionTypes.GET_CERTIFICATE_AUTHORITY_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: isKeyOnline
          ? CertificatesActionTypes.GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_ERROR
          : CertificatesActionTypes.GET_CERTIFICATE_AUTHORITY_LIST_ERROR,
        payload: err,
      });
      if (!axios.isCancel(err)) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Certificate Authorities list!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getCertificateAuthorities: ${err}`);
      return false;
    }
  };
};

export const revokeCertificates = ({
  certificateUuids,
  reasonComment,
  reasonCode,
}: {
  certificateUuids: string[];
  reasonComment: string;
  reasonCode: string;
}): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.POST_REVOKE_CERTIFICATES_REQUEST,
    });
    try {
      const body = serializeRevocation({
        certificateUuids,
        reasonComment,
        reasonCode,
      });
      await api().post(`certificate/revocation`, {
        ...body,
      });
      dispatch({
        type: CertificatesActionTypes.POST_REVOKE_CERTIFICATES_SUCCESS,
      });
      sendNotification({
        text: 'The Certificates has been successfully revoked!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.POST_REVOKE_CERTIFICATES_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong revoking the Certificates!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`revokeCertificates: ${err}`);
      return false;
    }
  };
};

export const uploadWrappedKeyAndCertificate = ({
  wrappedKey,
  certificateProfileUuid,
}: {
  wrappedKey: File;
  certificateProfileUuid: string;
}): ThunkAction<
  Promise<boolean | string>,
  CertificatesState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.POST_UPLOAD_CERTIFICATE_AND_KEY_REQUEST,
    });
    try {
      const formData = new FormData();
      formData.append('ext_key_tar_file', wrappedKey);
      formData.append(
        'data',
        JSON.stringify({
          certificate_profile_uuid: certificateProfileUuid,
          upload_cert: true,
        })
      );
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await api().post(`code-signing/key/upload`, formData, config);
      dispatch({
        type: CertificatesActionTypes.POST_UPLOAD_CERTIFICATE_AND_KEY_SUCCESS,
      });
      sendNotification({
        text: 'Certificate and Wrapped Key have been successfully uploaded!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.POST_UPLOAD_CERTIFICATE_AND_KEY_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong uploading Certificate and Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`uploadWrappedKeyAndCertificate: ${err}`);
      return false;
    }
  };
};

export const downloadCertificate = (
  serial: string
): ThunkAction<Promise<boolean | string>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.DOWLOAD_CERTIFICATE_REQUEST,
      payload: serial,
    });
    try {
      const response = await api().get(`certificate/chain/${serial}/download`);
      dispatch({
        type: CertificatesActionTypes.DOWLOAD_CERTIFICATE_SUCCESS,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.DOWLOAD_CERTIFICATE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong downloading the Certificate!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`dowloadCertificate: ${err}`);
      return false;
    }
  };
};

export const getCertificateTreeList = (): ThunkAction<
  Promise<boolean>,
  CertificatesState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.GET_CERTIFICATE_TREE_LIST_REQUEST,
    });
    try {
      const response = await api().get(`certificate/authority/trees`);
      const payload = _.map(response.data, (tree) => deserializeTree(tree));
      dispatch({
        type: CertificatesActionTypes.GET_CERTIFICATE_TREE_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.GET_CERTIFICATE_TREE_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong getting the Tree List!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`dowloadCertificate: ${err}`);
      return false;
    }
  };
};

export const downloadCertificateAuthority = (
  uuid: string
): ThunkAction<Promise<boolean | string>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificatesActionTypes.DOWLOAD_CERTIFICATE_REQUEST,
      payload: uuid,
    });
    try {
      const response = await api().get(
        `certificate/authority/${uuid}/chain/download`
      );
      dispatch({
        type: CertificatesActionTypes.DOWLOAD_CERTIFICATE_SUCCESS,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.DOWLOAD_CERTIFICATE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong downloading the Certificate!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`dowloadCertificateAuthority: ${err}`);
      return false;
    }
  };
};

export const getPrintedCertificateAuthority = (
  uuid: string
): ThunkAction<Promise<boolean>, CertificatesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificatesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificatesActionTypes.GET_PRINTED_CERTIFICATE_REQUEST });
    try {
      const response = await api().get(`certificate/authority/${uuid}/print`);
      const payload = response.data.pretty_print;
      dispatch({
        type: CertificatesActionTypes.GET_PRINTED_CERTIFICATE_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificatesActionTypes.GET_PRINTED_CERTIFICATE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Printed version of the Certificate!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getPrintedCertificate: ${err}`);
      return false;
    }
  };
};
