import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import { AnyAction } from 'redux';
import { Moment } from 'moment';
import { CancelTokenSource } from 'axios';
import { sendNotification } from '../notifications/actions';
import { deserializeAuditLog } from './helpers';
import { AuditLogsState, AuditLogsActionTypes } from './types';

import { api } from '../../libs/helpers';

const error = require('debug')('pki-app:error:auditLogs');

export const getAuditLogs = (
  {
    startDate,
    endDate,
    limit,
  }: {
    startDate: Moment;
    endDate: Moment;
    limit: string;
  },
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, AuditLogsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<AuditLogsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: AuditLogsActionTypes.GET_AUDIT_LOGS_LIST_REQUEST });
    try {
      const url = `audit-log?limit=${limit}&start_time=${startDate.valueOf()}&end_time=${endDate.valueOf()}`;
      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const {
        data: { events },
      } = response;
      const eventsDesirialized = _.map(events, (item) =>
        deserializeAuditLog(item)
      );
      dispatch({
        type: AuditLogsActionTypes.GET_AUDIT_LOGS_LIST_SUCCESS,
        payload: {
          events: eventsDesirialized,
        },
      });
      return true;
    } catch (err) {
      dispatch({
        type: AuditLogsActionTypes.GET_AUDIT_LOGS_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Audit Logs list!';
      sendNotification({
        text,
        success: false,
      })(dispatch);

      error(`getAuditLogs: ${err}`);
      return false;
    }
  };
};
