import { AnyAction, Dispatch } from 'redux';
import { LayoutTypes } from './types';

export const setSidebar = ({ isOpen }: { isOpen: boolean }) => (
  dispatch: Dispatch<AnyAction>
): void => {
  dispatch({
    type: LayoutTypes.SET_SIDEBAR,
    payload: isOpen,
  });
};
