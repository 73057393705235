import React, { FC, ReactNode } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledPopover,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  extractOptionalDefault,
  extractVariableName,
} from '../../libs/helpers';

type KeyValue = { [key: string]: string };

interface Props {
  id?: string;
  className?: string;
  readOnly?: boolean;
  label?: string;
  keys: KeyValue;
  highlightMandatoryFields?: boolean;
  labelPopover?: string | ReactNode | Function;
  onChange?: Function;
  simpleObject?: Boolean;
}

const KeyNInput: FC<Props> = ({
  id = '',
  label = '',
  keys = {},
  highlightMandatoryFields = false,
  labelPopover = '',
  readOnly = false,
  simpleObject = false,
  className,
  onChange = (): null => null,
}) => {
  return (
    <div id={id} className={`KeyNInput ${className}`}>
      <Label className="pki-label">{label}</Label>
      {(!_.isEmpty(labelPopover) || !_.isNil(labelPopover)) && (
        <FontAwesomeIcon
          id="label-hint"
          className="pki-ico ml-1"
          icon={faInfoCircle}
        />
      )}
      {(!_.isEmpty(labelPopover) || !_.isNil(labelPopover)) && (
        <UncontrolledPopover trigger="hover" target="label-hint">
          <div className="p-2">
            <small>{labelPopover}</small>
          </div>
        </UncontrolledPopover>
      )}
      <Row>
        {_.map(keys, (value, key: string) => {
          const variableName = simpleObject ? key : extractVariableName(key);
          return (
            variableName && (
              <Col md={6} key={key}>
                <FormGroup>
                  <Label className="pki-label">
                    {variableName}
                    {key.includes('required') ? '*' : ''}
                  </Label>
                  <Input
                    id={`input_${variableName}`}
                    required={key.includes('required')}
                    readOnly={readOnly}
                    placeholder={extractOptionalDefault(key)}
                    invalid={
                      !readOnly &&
                      key.includes('required') &&
                      (value !== '' || highlightMandatoryFields) &&
                      _.chain(value).trim().isEmpty().value()
                    }
                    plaintext={readOnly}
                    value={value}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChange({ key, value: event.target.value });
                    }}
                    type="text"
                  />
                  <FormFeedback>Cannot be empty</FormFeedback>
                </FormGroup>
              </Col>
            )
          );
        })}
      </Row>
    </div>
  );
};

export default KeyNInput;
