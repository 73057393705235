import { Reducer } from 'redux';
import { CertificatesState, CertificatesActionTypes } from './types';

export const initialState: CertificatesState = {
  expiringCertificateList: [],
  issuedCertificateList: [],
  certificateList: [],
  CAList: [],
  OnlineCAList: [],
  certificateTreeList: [],
  certificatesListErrors: undefined,
  certificatesAuthorityListErrors: undefined,
  treeErrors: undefined,
  printedCertificate: '',
  isGettingExpiringCertificateList: false,
  isLoadedExpiringCertificateList: false,
  isGettingIssuedCertificateList: false,
  isLoadedIssuiedCertificateList: false,
  isGettingCertificateTreeList: false,
  isGettingPrintedCertificate: false,
  isGettingCertificateList: false,
  isLoadedCertificateTreeList: false,
  isUploadingCA: false,
  isUploadingCertificateKey: false,
  isCertificateKeyUploaded: false,
  isLoadedCertificateList: false,
  isLoadedCAList: false,
  isGettingCAList: false,
  isLoadedOnlineCAList: false,
  isGettingOnlineCAList: false,
  isRevokingCertificates: false,
  certificateSerialInDownload: String(0),
  isGettingCodeSigningProfileOptions: false,
  isLoadedCodeSigningProfileOptions: false,
};

const reducer: Reducer<CertificatesState> = (
  state = initialState,
  action
): CertificatesState => {
  switch (action.type) {
    case CertificatesActionTypes.GET_CERTIFICATE_LIST_REQUEST: {
      return {
        ...state,
        isGettingCertificateList: true,
        isLoadedCertificateList: false,
        certificatesListErrors: undefined,
      };
    }
    case CertificatesActionTypes.GET_CERTIFICATE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCertificateList: false,
        isLoadedCertificateList: true,
        certificateList: action.payload,
      };
    }
    case CertificatesActionTypes.GET_CERTIFICATE_LIST_ERROR: {
      return {
        ...state,
        isGettingCertificateList: false,
        isLoadedCertificateList: false,
        certificatesListErrors: action.payload,
      };
    }

    case CertificatesActionTypes.GET_PRINTED_CERTIFICATE_REQUEST: {
      return { ...state, isGettingPrintedCertificate: true };
    }
    case CertificatesActionTypes.GET_PRINTED_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        isGettingPrintedCertificate: false,
        printedCertificate: action.payload,
      };
    }
    case CertificatesActionTypes.GET_PRINTED_CERTIFICATE_ERROR: {
      return {
        ...state,
        isGettingPrintedCertificate: false,
      };
    }

    case CertificatesActionTypes.POST_UPLOAD_CERTIFICATE_AND_KEY_REQUEST: {
      return {
        ...state,
        isUploadingCertificateKey: true,
        isCertificateKeyUploaded: false,
      };
    }
    case CertificatesActionTypes.POST_UPLOAD_CERTIFICATE_AND_KEY_SUCCESS: {
      return {
        ...state,
        isUploadingCertificateKey: false,
        isCertificateKeyUploaded: false,
      };
    }
    case CertificatesActionTypes.POST_UPLOAD_CERTIFICATE_AND_KEY_ERROR: {
      return {
        ...state,
        isUploadingCertificateKey: false,
        isCertificateKeyUploaded: false,
      };
    }

    case CertificatesActionTypes.POST_UPLOAD_CA_REQUEST: {
      return { ...state, isUploadingCA: true };
    }
    case CertificatesActionTypes.POST_UPLOAD_CA_SUCCESS: {
      return {
        ...state,
        isUploadingCA: false,
      };
    }
    case CertificatesActionTypes.POST_UPLOAD_CA_ERROR: {
      return {
        ...state,
        isUploadingCA: false,
      };
    }

    case CertificatesActionTypes.GET_CERTIFICATE_AUTHORITY_LIST_REQUEST: {
      return {
        ...state,
        isGettingCAList: true,
        isLoadedCAList: false,
        certificatesAuthorityListErrors: undefined,
      };
    }
    case CertificatesActionTypes.GET_CERTIFICATE_AUTHORITY_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCAList: false,
        isLoadedCAList: true,
        CAList: action.payload,
      };
    }
    case CertificatesActionTypes.GET_CERTIFICATE_AUTHORITY_LIST_ERROR: {
      return {
        ...state,
        isGettingCAList: false,
        isLoadedCAList: false,
        certificatesAuthorityListErrors: action.payload,
      };
    }

    case CertificatesActionTypes.GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_REQUEST: {
      return {
        ...state,
        isGettingOnlineCAList: true,
        isLoadedOnlineCAList: false,
        certificatesAuthorityListErrors: undefined,
      };
    }
    case CertificatesActionTypes.GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_SUCCESS: {
      return {
        ...state,
        isGettingOnlineCAList: false,
        isLoadedOnlineCAList: true,
        OnlineCAList: action.payload,
      };
    }
    case CertificatesActionTypes.GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_ERROR: {
      return {
        ...state,
        isGettingOnlineCAList: false,
        isLoadedOnlineCAList: false,
        certificatesAuthorityListErrors: action.payload,
      };
    }

    case CertificatesActionTypes.GET_EXPIRING_CERTIFICATES_LIST_REQUEST: {
      return {
        ...state,
        isGettingExpiringCertificateList: true,
        isLoadedExpiringCertificateList: false,
      };
    }
    case CertificatesActionTypes.GET_EXPIRING_CERTIFICATES_LIST_SUCCESS: {
      return {
        ...state,
        isGettingExpiringCertificateList: false,
        isLoadedExpiringCertificateList: true,
        expiringCertificateList: action.payload,
      };
    }
    case CertificatesActionTypes.GET_EXPIRING_CERTIFICATES_LIST_ERROR: {
      return {
        ...state,
        isGettingExpiringCertificateList: false,
        isLoadedExpiringCertificateList: false,
      };
    }

    case CertificatesActionTypes.GET_ISSUED_CERTIFICATES_LIST_REQUEST: {
      return {
        ...state,
        isGettingIssuedCertificateList: true,
        isLoadedIssuiedCertificateList: false,
      };
    }
    case CertificatesActionTypes.GET_ISSUED_CERTIFICATES_LIST_SUCCESS: {
      return {
        ...state,
        isGettingIssuedCertificateList: false,
        isLoadedIssuiedCertificateList: true,
        issuedCertificateList: action.payload,
      };
    }
    case CertificatesActionTypes.GET_ISSUED_CERTIFICATES_LIST_ERROR: {
      return {
        ...state,
        isGettingIssuedCertificateList: false,
        isLoadedIssuiedCertificateList: false,
      };
    }

    case CertificatesActionTypes.DOWLOAD_CERTIFICATE_REQUEST: {
      return { ...state, certificateSerialInDownload: action.payload };
    }
    case CertificatesActionTypes.DOWLOAD_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        certificateSerialInDownload: String(0),
      };
    }
    case CertificatesActionTypes.DOWLOAD_CERTIFICATE_ERROR: {
      return {
        ...state,
        certificateSerialInDownload: String(0),
      };
    }

    case CertificatesActionTypes.GET_CERTIFICATE_TREE_LIST_REQUEST: {
      return {
        ...state,
        isGettingCertificateTreeList: true,
        isLoadedCertificateTreeList: false,
        treeErrors: undefined,
      };
    }
    case CertificatesActionTypes.GET_CERTIFICATE_TREE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCertificateTreeList: false,
        isLoadedCertificateTreeList: true,
        certificateTreeList: action.payload,
      };
    }
    case CertificatesActionTypes.GET_CERTIFICATE_TREE_LIST_ERROR: {
      return {
        ...state,
        isGettingCertificateTreeList: false,
        isLoadedCertificateTreeList: false,
        treeErrors: action.payload,
      };
    }

    case CertificatesActionTypes.POST_REVOKE_CERTIFICATES_REQUEST: {
      return {
        ...state,
        isRevokingCertificates: true,
      };
    }
    case CertificatesActionTypes.POST_REVOKE_CERTIFICATES_SUCCESS: {
      return {
        ...state,
        isRevokingCertificates: false,
      };
    }
    case CertificatesActionTypes.POST_REVOKE_CERTIFICATES_ERROR: {
      return {
        ...state,
        isRevokingCertificates: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as certificatesReducer };
