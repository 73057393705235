import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import _ from 'lodash';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import moment from 'moment';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { initApp } from './bootstrap/init';
import PKIRootReducers from './store';
import PKIApp from './PKIApp';
import { logout } from './libs/auth';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import { getVars } from './libs/provisioning';

const locale =
  _.get(window, 'navigator.userLanguage') || window.navigator.language;
moment.locale(locale);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store: any;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers(PKIRootReducers),
  composeEnhancers(applyMiddleware(thunk))
);

const SENTRY_DSN = {
  DEV:
    'https://d83badde5e7348228ccdd765701c752c@sentry.key-central.irdeto.com/4',
  QA:
    'https://1fb3624e35aa4327be4e3a11cb9528f7@sentry.key-central.irdeto.com/5',
  STAGE:
    'https://5fe102cc80ce482e93df937fd353fcd0@sentry.key-central.irdeto.com/6',
  LIVE:
    'https://1bbee5fd709a431dacf7493b6be7996b@sentry.key-central.irdeto.com/7',
};

const renderApp = async (
  customers: {
    code: string;
    name: string;
  }[]
): Promise<void> => {
  const { ENV } = getVars();
  const envUpper = ENV.toUpperCase() as
    | 'DEV'
    | 'QA'
    | 'STAGE'
    | 'LIVE'
    | undefined;
  const dsn =
    envUpper && envUpper in SENTRY_DSN ? SENTRY_DSN[envUpper] : undefined;
  if (dsn) {
    Sentry.init({
      dsn,
      integrations: [new BrowserTracing()],
      environment: ENV,
      tracesSampleRate: 1.0,
    });
    const customerCode = localStorage.getItem('pki_customer');
    if (customerCode) {
      Sentry.setTag('customer', customerCode);
    }
  }

  ReactDOM.render(
    <ReduxProvider store={store}>
      <DragDropContextProvider backend={HTML5Backend}>
        <Router>
          <PKIApp customers={customers} />
        </Router>
      </DragDropContextProvider>
    </ReduxProvider>,
    document.getElementById('root')
  );

  if (window.Cypress) {
    window.store = store;
  }
};

const renderErrorPage = async (error: string): Promise<void> => {
  ReactDOM.render(
    <div>
      <p>{error}</p>
      <button
        onClick={(): void => {
          logout();
        }}
      >
        Go to Login
      </button>
    </div>,
    document.getElementById('root')
  );
};

initApp(renderApp, renderErrorPage);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
