import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Badge,
  Button,
  Col,
  Collapse,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { IPatchProductProfile, ProductProfile } from '../../../types';
import { sendNotification } from '../../../../../store/notifications/actions';
import {
  api,
  dateFormatter,
  readFileAsText,
} from '../../../../../libs/helpers';
import { PGPKey } from '../../../../../libs/types';
import { Table, TableActions } from '../../../../../components';

interface PGPKEysProps {
  productProfile: ProductProfile;
  onSubmitData: IPatchProductProfile;
  pgpKeys: ProductProfile['pgpKeys'];
}

export const PGPKeys: FC<PGPKEysProps> = ({
  productProfile,
  onSubmitData,
  pgpKeys,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const uuidList = pgpKeys?.map((pgpKey) => pgpKey.uuid) || [];

  const inputEl = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const copyToClipboard = (content: string, confirmation: string) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    sendNotification({
      text: confirmation,
    })(dispatch);
  };

  const uploadAndLinkPgpKey = async (pgpKeyContent: string) => {
    setIsLoading(true);
    try {
      const {
        data: { uuid },
      } = await api().post('pgp-key?allow_re_upload=True', {
        public_key: pgpKeyContent,
      });
      if (uuidList?.includes(uuid)) {
        sendNotification({
          text: 'The PGP Key has been already added to the Product Profile!',
          success: true,
        })(dispatch);
      } else {
        onSubmitData({
          pgp_key_uuids: [...uuidList, uuid],
        });
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong linking the PGP Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { dataField: 'id', text: 'Id', hidden: true },
    {
      dataField: 'pgpKeyId',
      style: { width: '20%', textAlign: 'left' },
      text: 'PGP Key ID',
      formatter: (pgpKeyId: string): ReactNode => (
        <strong>
          <div
            className="textCopyPaste"
            onClick={() => {
              copyToClipboard(pgpKeyId, 'PGP Key ID copied to clipboard.');
            }}
          >
            <span className="value">{pgpKeyId}</span>
          </div>
        </strong>
      ),
    },
    {
      dataField: 'name',
      style: { width: '10%', textAlign: 'left' },
      text: 'Name',
      formatter: (name: string): ReactNode => (
        <strong>
          <span className="value">{name}</span>
        </strong>
      ),
    },
    {
      dataField: 'pgpKeyIdV4',
      style: {
        width: '150',
        textAlign: 'left',
      },
      text: 'PGP Key ID v4',
      formatter: (pgpKeyIdV4: string): ReactNode => (
        <strong>
          <div
            className="textCopyPaste"
            onClick={() => {
              copyToClipboard(pgpKeyIdV4, 'PGP Key ID v4 copied to clipboard.');
            }}
          >
            <span className="value">{pgpKeyIdV4}</span>
          </div>
        </strong>
      ),
    },
    {
      dataField: 'fingerprint',
      style: {
        width: '150',
        textAlign: 'left',
      },
      text: 'Fingerprint',
      formatter: (fingerprint: string): ReactNode => (
        <strong>
          <div
            className="textCopyPaste"
            onClick={() => {
              copyToClipboard(fingerprint, 'Fingerprint copied to clipboard.');
            }}
          >
            <span className="value">{fingerprint}</span>
          </div>
        </strong>
      ),
    },
    {
      dataField: 'createdAt',
      style: { width: '20%', textAlign: 'left' },
      text: 'Date Added',
      formatter: (date: number): ReactNode => (
        <strong>
          <span className="value">{dateFormatter(date)}</span>
        </strong>
      ),
    },
    {
      dataField: 'enabled',
      text: 'Status',
      style: { width: '10%', textAlign: 'left' },
      formatter: (value: boolean): ReactNode => (
        <h6>
          <Badge color={value ? 'success' : 'danger'}>
            {value ? 'Enabled' : 'Disabled'}
          </Badge>
        </h6>
      ),
    },
    {
      dataField: 'noData',
      text: 'Actions',
      csvExport: false,
      formatter: (_notUsedValue: null, item: PGPKey): ReactNode => {
        return (
          <TableActions
            rowId={item.uuid}
            options={[
              {
                label: 'Remove From Profile',
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: () => {
                  onSubmitData({
                    pgp_key_uuids: uuidList.filter(
                      (pgpKeyUuid) => pgpKeyUuid !== item.uuid
                    ),
                  });
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div id={'product-profile-pgp-keys'}>
      <div className={'upload-container'}>
        <div className="upload-code-image">
          <Row>
            <Col className="d-flex justify-content-end mb-2" md={12}>
              <Button
                outline
                size={'sm'}
                id="upload-pgp-key-button"
                disabled={isLoading}
                onClick={(): void => {
                  inputEl?.current?.click();
                }}
              >
                <FontAwesomeIcon className="mr-1" icon={faUpload} />
                <UncontrolledTooltip
                  hideArrow={true}
                  innerClassName="bg-light text-muted"
                  placement="auto"
                  target={'upload-pgp-key-button'}
                >
                  <small>ASCII Armored Format</small>
                </UncontrolledTooltip>
                PGP Key <small></small>
              </Button>
            </Col>
            <input
              type="file"
              id="file-input"
              className="d-none"
              multiple
              ref={inputEl}
              onChange={async ({ target: { files } }): Promise<void> => {
                if (files && files.length > 0) {
                  const textFiles = await Promise.all(
                    Array.from(files).map(async (file) => {
                      return readFileAsText(file);
                    })
                  );
                  await uploadAndLinkPgpKey(textFiles[0]);
                  if (inputEl.current) inputEl.current.value = '';
                }
              }}
            />
          </Row>
        </div>
      </div>
      <Collapse isOpen={!isLoading}>
        <div className="pgp-keys-table mt-3">
          <Table
            keyField="uuid"
            id="pgp-keys"
            loading={isLoading}
            search={false}
            remote={false}
            noDataIndication={'No PGP Keys'}
            data={pgpKeys || []}
            columns={columns}
          />
        </div>
      </Collapse>
    </div>
  );
};
