import React, { useState, useEffect, FC } from 'react';
import SmartSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import _, { isNil, isEmpty, find, filter, map, get } from 'lodash';
import { ManufacturingStation } from '../../store/manufacturingStations/types';
import { Certificate, CertificatesState } from '../../store/certificates/types';
import {
  CertificateProfile,
  CertificateProfilesState,
} from '../../store/certificateProfiles/types';
import { getCertificateAuthorities } from '../../store/certificates/actions';
import { getCertificateProfiles } from '../../store/certificateProfiles/actions';
import { Resource } from '../../libs/constants';
import { ApplicationState } from '../../store';
import { api } from '../../libs/helpers';
import { deserializeCertificateProfile } from '../../store/certificateProfiles/helpers';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  readOnly: boolean;
  actions?: string[];
}

const ManufacturingStationModal: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [currentIssuingCA, setCurrentIssuingCA] = useState<Certificate>();
  const [
    currentCertificateProfile,
    setCurrentCertificateProfile,
  ] = useState<CertificateProfile>();
  const [formValues, setFormValues] = useState<ManufacturingStation>({
    enabled: true,
  } as ManufacturingStation);

  const { name, manufacturer, location, externalId, notes } = formValues;

  const isValid = !isEmpty(name);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setFormValues((current: ManufacturingStation) => ({
      ...current,
      [property]: value,
    }));
  };

  useEffect(() => {
    setCurrentCertificateProfile(undefined);
  }, [currentIssuingCA?.uuid]);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  return (
    <div id="manufacturing-station-form" className="ManufacturingStationForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <div>
            <h3 className="text-muted">Manufacturing Station</h3>
          </div>
        </div>

        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label">Name</Label>
              <Input
                id="name"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields && isEmpty(name)}
                value={name || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'name',
                    value: event.target.value,
                  });
                }}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">External ID</Label>
              <Input
                id="external-id"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields}
                value={externalId || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'externalId',
                    value: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label">Manufacturer</Label>
              <Input
                id="manufacturer"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields}
                value={manufacturer || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'manufacturer',
                    value: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">Location</Label>
              <Input
                id="location"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields}
                value={location || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'location',
                    value: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label className="pki-label">Notes</Label>
            <FormGroup>
              <Input
                value={isEmpty(notes) && readOnly ? 'N/A' : notes}
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'notes',
                    value: event.target.value,
                  });
                }}
                name="notes"
                id="notes"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <Label className="pki-header">
              TLS Certificate Profile used for new Secure Token Certificate
              Requests
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label className="pki-label">Issuing CA</Label>
            {readOnly && (
              <Input
                id="issuer-ca-uuid"
                readOnly
                value={currentIssuingCA?.commonName}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(ca) => {
                    setProfileFormProperty({
                      property: 'issuingCAUuid',
                      value: ca?.uuid,
                    });
                    setCurrentIssuingCA(() => {
                      setCurrentCertificateProfile(undefined);
                      return deserializeCertificate(ca);
                    });
                  }}
                  formatter={(ca) => ca.cn}
                  fetchUrl={`certificate/authority`}
                  defaultFilters={'is_key_online=true'}
                  searchParam={`cn`}
                  value={currentIssuingCA?.commonName || ''}
                />
              </>
            )}
          </Col>
          <Col>
            <Label className="pki-label">Certificate Profile</Label>
            {readOnly && (
              <Input
                id="certificate-profile"
                readOnly
                value={currentCertificateProfile?.name ?? ''}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(profile) => {
                    setProfileFormProperty({
                      property: 'certificateProfileUuid',
                      value: profile.uuid,
                    });
                    setCurrentCertificateProfile(
                      deserializeCertificateProfile(profile)
                    );
                  }}
                  formatter={(profile) => profile?.certificate_profile?.name}
                  fetchUrl={`certificate/profile`}
                  defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
                  searchParam={`certificate_profile.name`}
                  value={
                    currentCertificateProfile?.name ||
                    'Select a certificate profile'
                  }
                  urlParams={[
                    ['resource', 'certificate_request'],
                    ['action', 'create'],
                    ['with_actions', 'false'],
                  ]}
                  error={
                    !currentCertificateProfile && highlightMandatoryFields
                      ? 'Cannot be empty, please select one!'
                      : undefined
                  }
                  wait={currentIssuingCA === undefined}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
      {!readOnly && (
        <div className="float-right pb-5 pr-5">
          <span className="mr-2">
            <Button
              id="cancel-button"
              outline
              onClick={(): void => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </span>
          <span>
            <Button
              id="confirm-button"
              outline
              disabled={!currentIssuingCA || !currentCertificateProfile}
              onClick={(): void => {
                if (!isValid) {
                  setHighlightMandatoryFields(true);
                }
                onSubmit({ values: formValues, isValid });
              }}
            >
              Confirm
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};

export default ManufacturingStationModal;
