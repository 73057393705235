import React, { useEffect, useState, FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Card,
  Collapse,
  Modal,
  UncontrolledPopover,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlusCircle,
  faUserShield,
  faUserTimes,
  faRetweet,
} from '@fortawesome/free-solid-svg-icons';
import { find, isEmpty } from 'lodash';
import { ApplicationState } from '../../store';
import {
  Table,
  Spinner,
  TableActions,
  ConfirmationForm,
  ErrorHandler,
  FilterBar,
} from '../../components';
import {
  EntityRole,
  EntityType,
  User,
  UsersState,
} from '../../store/users/types';
import {
  getUsers,
  removeUserEntityRole,
  setUsersEntityRole,
  createUser,
  editUser,
  resetUserCredentialsOTP,
} from '../../store/users/actions';
import { Filter } from '../../components/FilterBar/types';
import { sendNotification } from '../../store/notifications/actions';
import UserForm from './UserForm';
import { userDefaultValues } from './constants';
import { getCurrentCustomerInfo } from '../../libs/helpers';

interface UsersToolbarPros {
  onCreateUserClick?: Function;
  actions?: string[];
}

const UsersToolbarToolbar: FC<UsersToolbarPros> = ({
  actions = [],
  onCreateUserClick = (): null => null,
}) => {
  return (
    <div className="UsersToolbarToolbar d-flex">
      <span className="mx-2">
        <Button
          onClick={(): void => {
            onCreateUserClick();
          }}
          id="create-user-button"
          className="mr-2"
          size="sm"
          outline
        >
          <FontAwesomeIcon className="mr-1" icon={faPlusCircle} />
          User
        </Button>
      </span>
    </div>
  );
};

const Users: FC = () => {
  const {
    usersList,
    isGettingUserList,
    isLoadedUserList,
    userErrors,
    isEditiningEntityRole,
    isCreatingUser,
    isEditingUser,
    isResettingOTPCredentials,
  } = useSelector<ApplicationState, UsersState>((pki) => pki.users);

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('asc');
  const [tableSortField, setTableSortField] = useState<string>('username');
  const [query, setQuery] = useState<Filter[]>([]);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [formModal, setFormModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>(userDefaultValues);
  const [userAdminAction, setUserAdminAction] = useState<'Assign' | 'Remove'>();
  const [adminModal, setAdminModal] = useState<boolean>(false);
  const [resetOTPModal, setResetOTPModal] = useState<boolean>(false);

  const currentCustomer = getCurrentCustomerInfo();

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setCurrentUser(userDefaultValues);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const toggleAdminModal = (): void => {
    setAdminModal((previousValue) => !previousValue);
  };

  const toggleResetOTPFormModal = (): void => {
    setResetOTPModal((previousValue) => !previousValue);
  };

  const dispatch = useDispatch();

  const isPerformingAction =
    isEditiningEntityRole ||
    isCreatingUser ||
    isEditingUser ||
    isResettingOTPCredentials;

  useEffect(() => {
    if (isGettingUserList) {
      setShowSpinner(true);
    }
    if (isLoadedUserList || userErrors) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [isGettingUserList, isLoadedUserList, userErrors, isPerformingAction]);

  useEffect(() => {
    if (!isPerformingAction) {
      dispatch(
        getUsers({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
          query,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    isPerformingAction,
  ]);

  const columns = [
    { dataField: 'uuid', text: 'uuid', hidden: true, csvExport: false },
    {
      dataField: 'username',
      text: 'Username',
    },
    {
      dataField: 'entityRoles',
      csvExport: false,
      text: '',
      formatter: (
        entityRoles: EntityRole[],
        { uuid }: { uuid: string }
      ): ReactNode => {
        const id = `user-admin-${uuid}-ico`;
        const isUserAdmin = !!find(
          entityRoles,
          (item) =>
            item.entityType === 'customer' &&
            item.role === 'user_admin' &&
            item.entityUuid === currentCustomer?.uuid
        );

        return (
          <span>
            {isUserAdmin && (
              <>
                <FontAwesomeIcon
                  id={id}
                  className="pki-ico mr-5"
                  icon={faUserShield}
                />
                <UncontrolledPopover
                  placement="bottom"
                  trigger="hover"
                  target={id}
                >
                  <div className="p-1">
                    <small>User Administrator Role</small>
                  </div>
                </UncontrolledPopover>
              </>
            )}
          </span>
        );
      },
    },
    {
      dataField: 'email',
      text: 'Email',
      formatter: (email: string): string => email || 'N/A',
    },
    {
      dataField: 'enabled',
      text: 'Status',
      csvFormatter: (enabled: boolean): string =>
        enabled ? 'Enabled' : 'Disabled',
      formatter: (enabled: boolean): ReactNode => (
        <h6>
          <Badge color={enabled ? 'success' : 'danger'}>
            {enabled ? 'Active' : 'Blocked'}
          </Badge>
        </h6>
      ),
    },
    {
      dataField: 'uuid',
      text: 'Actions',
      sort: false,
      formatter: (uuid: string, current: User): ReactNode => {
        const isUserAdmin = !!find(
          current.entityRoles,
          (item) =>
            item.entityType === 'customer' &&
            item.role === 'user_admin' &&
            item.entityUuid === currentCustomer?.uuid
        );

        const adminAction = !isUserAdmin
          ? 'Assign User Administrator Role'
          : 'Remove User Administrator Role';
        return (
          <TableActions
            rowId={String(uuid)}
            options={[
              {
                label: adminAction,
                ico: (
                  <FontAwesomeIcon
                    className="pki-ico"
                    icon={!isUserAdmin ? faUserShield : faUserTimes}
                  />
                ),
                onClick: (e: MouseEvent): void => {
                  setUserAdminAction(!isUserAdmin ? 'Assign' : 'Remove');
                  setCurrentUser(current);
                  toggleAdminModal();
                },
              },
              {
                label: 'Reset OTP Credentials',
                ico: <FontAwesomeIcon className="pki-ico" icon={faRetweet} />,
                onClick: (e: MouseEvent): void => {
                  setCurrentUser(current);
                  toggleResetOTPFormModal();
                  setFormReadOnly(false);
                },
              },
              {
                label: 'Edit',
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  setCurrentUser(current);
                  toggleFormModal();
                  setFormReadOnly(false);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div className="Users">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Users</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'username',
                  label: 'Username',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'email',
                  label: 'Email',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'first_name',
                  label: 'First Name',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'last_name',
                  label: 'Last Name',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'enabled',
                  label: 'Enabled',
                  type: 'filter',
                  options: [
                    { value: 'Active', label: 'Active' },
                    { value: 'Blocked', label: 'Blocked' },
                  ],
                },
              ]}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  let mapValues = JSON.stringify(newFilters);
                  mapValues = mapValues.replace('Active', 'true');
                  mapValues = mapValues.replace('Blocked', 'false');
                  setQuery(() => {
                    setTablePage(1);
                    return JSON.parse(mapValues);
                  });
                }
              }}
            />
          </div>
          <div className="view mt-5">
            {userErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                data={usersList}
                keyField="uuid"
                remote={true}
                search={false}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                sort={{
                  dataField: tableSortField,
                  order: tableSortOrder as SortOrder,
                }}
                rowEvents={{
                  onClick: (notUsedValue: null, current: User): void => {
                    setCurrentUser(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                toolbar={
                  <UsersToolbarToolbar
                    onCreateUserClick={(): void => {
                      toggleFormModal();
                      setFormReadOnly(false);
                    }}
                  />
                }
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                  setTableSortOrder(sortOrder);
                  setTableSortField(sortField);
                }}
                noDataIndication={
                  tablePage > 1
                    ? 'No more Users available'
                    : 'No Users available'
                }
                exportCSV={{
                  fileName: 'users.csv',
                  onlyExportSelection: false,
                  exportAll: true,
                }}
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        {!isGettingUserList && (
          <UserForm
            readOnly={formReadOnly}
            onCancel={toggleFormModal}
            defaultValues={currentUser}
            onSubmit={({
              values,
              isValid,
            }: {
              values: User;
              isValid: boolean;
            }): void => {
              if (isValid) {
                if (isEmpty(values?.uuid)) {
                  dispatch(createUser(values));
                } else {
                  dispatch(editUser(values));
                }
                toggleFormModal();
              } else {
                dispatch(
                  sendNotification({
                    text: 'Some fields are mandatory, please fill them all.',
                    success: false,
                  })
                );
              }
            }}
            onChangeMode={toggleMode}
          />
        )}
      </Modal>

      <Modal className="PKIApp" isOpen={adminModal} toggle={toggleAdminModal}>
        <ConfirmationForm
          title={`${userAdminAction} User Administrator Role`}
          content={
            <div className="text-center">
              <p>
                You are about to{' '}
                <strong>{userAdminAction} User Administrator Role</strong> to{' '}
                <strong>{currentUser.username}</strong>.
              </p>
            </div>
          }
          onCancel={toggleAdminModal}
          onConfirm={(): void => {
            if (userAdminAction === 'Assign' && currentCustomer) {
              dispatch(
                setUsersEntityRole({
                  userUuids: [currentUser.uuid],
                  entityRole: 'user_admin',
                  entityUuid: currentCustomer?.uuid,
                  entityType: EntityType.Customer,
                  type: 'User',
                  inherit: true,
                })
              );
            }
            if (userAdminAction === 'Remove' && currentCustomer) {
              dispatch(
                removeUserEntityRole({
                  userUuid: currentUser.uuid,
                  entityType: EntityType.Customer,
                  entityRole: 'user_admin',
                  entityUuid: currentCustomer?.uuid,
                })
              );
            }
            toggleAdminModal();
          }}
        />
      </Modal>

      <Modal
        className="PKIApp"
        isOpen={resetOTPModal}
        toggle={toggleResetOTPFormModal}
      >
        <ConfirmationForm
          title={`Reset User OTP Credentials`}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>Reset User OTP Credentials</strong> for{' '}
                <strong>{currentUser.username}</strong>.
              </p>
            </div>
          }
          onCancel={toggleResetOTPFormModal}
          onConfirm={(): void => {
            if (currentUser) {
              dispatch(resetUserCredentialsOTP(currentUser));
            }
            toggleResetOTPFormModal();
          }}
        />
      </Modal>
    </div>
  );
};

export default Users;
