/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from 'lodash';
import moment from 'moment';
import { Client, ClientEntitlement } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeClient = (raw: any): Client => ({
  uuid: raw.uuid,
  name: raw.name,
  description: raw.description,
  code: raw.code,
  clientID: raw.client_id,
  enabled: raw.enabled,
  serviceAccount: {
    username: raw.service_account.username,
    uuid: raw.service_account.uuid,
    enabled: raw.service_account.enabled,
  },
  createdAt: moment(raw.date_created).format('x'),
  updatedAt: moment(raw.date_updated || get(raw, 'date_created')).format('x'),
  createdBy: raw.created_by_user?.email || raw.created_by_user?.username,
  isCreatedByUserDeleted: raw.created_by_user?.deleted,
  actions: raw.actions,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeClientEntitlement = (raw: any): ClientEntitlement => ({
  entityType: raw.entity_type,
  entityUuid: raw.entity_uuid,
  role: raw.role,
});

export const serializeClient = (client: Client): any => ({
  code: client.code,
  name: client.name,
  description: client.description,
});
