import React, { FC } from 'react';
import ReactToggle from 'react-toggle';
import './Toggle.scss';

interface Props {
  onChange?: Function;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}

const Toggle: FC<Props> = ({
  onChange = (): null => null,
  checked = false,
  disabled = false,
  id = '',
  className = '',
}) => (
  <div id={id} className="Toggle">
    <ReactToggle
      icons={false}
      disabled={disabled}
      className={className}
      checked={checked}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e.target.checked);
      }}
    />
  </div>
);

export default Toggle;
