import React, { FC } from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ButtonDropdown } from '../../components';
import { RequestType } from '../../store/requests/types';

interface Props {
  actions: string[];
  onCreateRequestClick?: Function;
  onShowAwaitingApprovalClick?: Function;
  type: RequestType | 'All';
}

const RequestsToolbar: FC<Props> = ({
  actions = [],
  type,
  onCreateRequestClick = (): null => null,
}) => {
  return (
    <div className="RequestsToolbar w-100">
      <span className="float-right">
        {type === 'All' ? (
          <ButtonDropdown
            disabled={!_.includes(actions, 'create')}
            onClick={({ key }: { key: RequestType }): void => {
              onCreateRequestClick(key);
            }}
            className="mr-2"
            options={[
              { key: RequestType.Certificate, value: 'Certificate' },
              { key: RequestType.CodeSigning, value: 'Code Signing' },
              {
                key: RequestType.CodeSigningCertificate,
                value: 'Code Signing Certificate',
              },
              {
                key: RequestType.DeviceBatch,
                value: 'Device Batch',
              },
            ]}
            value={
              <span>
                <FontAwesomeIcon icon={faPlusCircle} /> Create{' '}
              </span>
            }
          />
        ) : (
          <Button
            onClick={(): void => {
              onCreateRequestClick(type);
            }}
            id="create-request-button"
            disabled={!_.includes(actions, 'create')}
            className="mr-2"
            size="sm"
            outline
          >
            <FontAwesomeIcon className="mr-1" icon={faPlusCircle} />
            {`${_.startCase(type)} Request`}
          </Button>
        )}
      </span>
    </div>
  );
};

export default RequestsToolbar;
