import React, { FC, useState, ReactNode, useEffect } from 'react';
import {
  Button,
  Badge,
  UncontrolledTooltip,
  Nav,
  Col,
  Row,
  NavItem,
  NavLink,
  ButtonGroup,
  Modal,
} from 'reactstrap';
import _, {
  startCase,
  snakeCase,
  find,
  isEmpty,
  includes,
  isEqual,
} from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonDropdown,
  Table,
  Spinner,
  ConfirmationForm,
} from '../../components';
import { Certificate } from '../../store/certificates/types';
import { EntityRole, EntityType, User } from '../../store/users/types';
import { deserializeUser, mapRoleToValue } from '../../store/users/helpers';
import { Client, ClientEntitlement } from '../../store/clients/types';
import { CertificateProfile } from '../../store/certificateProfiles/types';
import {
  getOwnersByClient,
  getCertificateProfilesByClient,
  ownerRoles,
  subscriberRoles,
  getIssuingCAsByClient,
} from './helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { deserializeCertificateProfile } from '../../store/certificateProfiles/helpers';
import { Resource } from '../../libs/constants';

interface TableData {
  name?: string;
  commonName?: string;
  username?: string;
  uuid: string;
  roles: string[];
  actions?: string[];
}

interface Props {
  onCancel: Function;
  client: Client;
  onSetEntityRole: Function;
  clientEntitlements: ClientEntitlement[];
  onRemoveEntityRole: Function;
  readOnly?: boolean;
  issuingCAs: Certificate[];
  certificateProfiles: CertificateProfile[];
  users: User[];
  isLoading: boolean;
}

const ClientRolePolicy: FC<Props> = ({
  onCancel = (): null => null,
  onSetEntityRole = (): null => null,
  onRemoveEntityRole = (): null => null,
  readOnly = false,
  client,
  clientEntitlements,
  issuingCAs,
  users,
  certificateProfiles,
  isLoading,
}) => {
  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);
  const [currentCertificateProfile, setCurrentCertificateProfile] = useState<
    CertificateProfile | undefined
  >(undefined);
  const [selectedOwner, setSelectedOwner] = useState<User | undefined>(
    undefined
  );
  const [activeEntity, setActiveEntity] = useState<EntityType>(
    EntityType.IssuingCA
  );

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [entityUuid, setEntityUuid] = useState<string | undefined>(undefined);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = (): void => {
    setDeleteModal((prev) => !prev);
  };
  const [onClickDeleteConfirm, setOnClickDeleteConfirm] = useState<Function>(
    () => {
      return 1;
    }
  );

  const toggleTab = (tab: EntityType): void => {
    activeEntity !== tab && setActiveEntity(tab);
  };

  const availableIssuingCAs = _.filter(issuingCAs, (item) =>
    includes(item.actions, 'assign_roles')
  );

  useEffect(() => {
    setCurrentIssuingCA(undefined);
  }, [activeEntity]);

  const navMenu = [
    EntityType.IssuingCA,
    EntityType.CertificateProfile,
    EntityType.Owner,
  ];

  const clientOwner = includes(client.actions, 'assign_roles');

  const renderNav = (): ReactNode => (
    <Nav tabs>
      {_.map(navMenu, (label: EntityType, index: number) => (
        <NavItem key={index} className="cursor-pointer pki-label">
          <NavLink
            id={label}
            className={classnames({ active: activeEntity === label })}
            onClick={(): void => {
              toggleTab(label);
            }}
          >
            {`${startCase(label)}s`}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const onConfirmDelete = (
    uuid: string | undefined,
    entityUUID: string | undefined,
    entityRole: string,
    entityType: string,
    disableRemoveRole: boolean
  ): void => {
    if (!readOnly) {
      if (!disableRemoveRole) {
        onRemoveEntityRole({
          userUuid: uuid,
          entityType,
          entityRole,
          entityUuid: entityUUID,
        });
      }
    }
    setDeleteModal(false);
  };

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'commonName',
      text: 'Common Name',
      hidden: activeEntity !== EntityType.IssuingCA,
    },
    {
      dataField: 'name',
      text: 'Name',
      hidden: activeEntity !== EntityType.CertificateProfile,
    },
    {
      dataField: 'name',
      text: 'Username',
      hidden: activeEntity !== EntityType.Owner,
    },
    {
      dataField: 'roles',
      text: 'Roles',
      style: { width: '50%' },
      headerClasses: 'text-center',

      formatter: (
        roles: string[],
        { uuid, actions }: { uuid: string; actions: string[] }
      ): ReactNode => {
        const disableRemoveRole =
          (!includes(actions, 'assign_roles') &&
            activeEntity === EntityType.IssuingCA) ||
          (!clientOwner && activeEntity === EntityType.Owner);
        const isTypeOwner = activeEntity === EntityType.Owner;
        const useruuid = isTypeOwner ? uuid : client.serviceAccount?.uuid;
        const entityType = isTypeOwner ? 'user' : activeEntity;
        const entityuuid = isTypeOwner ? client.serviceAccount?.uuid : uuid;
        return (
          <div className="entity-roles text-center">
            {_.map(roles, (role, index) => {
              const id = `role-${_.uniqueId(index.toString())}`;
              const divClass = classnames({
                'cursor-pointer': !readOnly,
              });
              const onClick = (): void => {
                setOnClickDeleteConfirm(() => {
                  return () => {
                    onConfirmDelete(
                      useruuid,
                      entityuuid,
                      role,
                      entityType,
                      disableRemoveRole
                    );
                  };
                });
                setDeleteModal(true);
              };
              return (
                <div className={divClass} key={index}>
                  <ButtonGroup className="mt-1 mb-1" size="sm">
                    <Button disabled>{mapRoleToValue[role]}</Button>
                    {!readOnly && !disableRemoveRole ? (
                      <>
                        <Button color="danger" id={id} onClick={onClick}>
                          {role && !readOnly && (
                            <>
                              <UncontrolledTooltip
                                hideArrow={true}
                                innerClassName="bg-light text-muted"
                                placement="auto"
                                target={id}
                              >
                                Remove
                              </UncontrolledTooltip>
                              <FontAwesomeIcon icon={faTrash} />
                            </>
                          )}
                        </Button>
                      </>
                    ) : null}
                  </ButtonGroup>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];
  let newTableData: TableData[] = [];
  let newEntityUuid: string | undefined;
  if (activeEntity === EntityType.IssuingCA) {
    newTableData = getIssuingCAsByClient(issuingCAs, clientEntitlements);
    newEntityUuid = currentIssuingCA?.uuid;
  }

  if (activeEntity === EntityType.CertificateProfile) {
    newTableData = getCertificateProfilesByClient(
      certificateProfiles,
      clientEntitlements
    );
    newEntityUuid = currentCertificateProfile?.uuid;
  }

  if (activeEntity === EntityType.Owner) {
    newTableData = getOwnersByClient(
      users,
      String(client.serviceAccount?.uuid)
    );
    newEntityUuid = client.serviceAccount?.uuid;
  }

  if (
    !isEqual(tableData, newTableData) ||
    !isEqual(newEntityUuid, entityUuid)
  ) {
    setTableData(() => {
      setEntityUuid(newEntityUuid);
      return newTableData;
    });
  }

  let disabledEntitleAs: boolean | undefined;
  if (activeEntity === EntityType.IssuingCA) {
    disabledEntitleAs = isEmpty(currentIssuingCA);
  } else if (activeEntity === EntityType.CertificateProfile) {
    disabledEntitleAs = isEmpty(currentCertificateProfile);
  } else {
    disabledEntitleAs = isEmpty(selectedOwner);
  }

  const userOptionFormatter = (user: User) =>
    `${user.username.replaceAll(/^service-account(-token)?-/g, '')} (${
      user.email || 'No Email Address'
    })`;

  return (
    <div id="pki-roles-form" className="ClientRolePolicy">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <span>
            <h4 className="text-muted">
              Role Policy for API Client "{client.clientID}"
            </h4>
          </span>
          {isLoading && (
            <Spinner className="mt-2 ml-3" size="sm" type="border" />
          )}
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {renderNav()}
        {
          <div className="mt-5">
            {!readOnly && (
              <>
                <Row>
                  <Col
                    md={activeEntity !== EntityType.CertificateProfile ? 10 : 5}
                  >
                    {activeEntity !== EntityType.Owner && (
                      <>
                        <ServerSideSelect
                          id="ca-select"
                          placeholder={'Select issuing CA'}
                          onSelectEntity={(ca) => {
                            setCurrentIssuingCA(() => {
                              setCurrentCertificateProfile(undefined);
                              return deserializeCertificate(ca);
                            });
                          }}
                          formatter={(ca) => ca.cn}
                          fetchUrl={`certificate/authority`}
                          defaultFilters={'is_key_online=true'}
                          urlParams={[['action', 'assign_roles']]}
                          searchParam={`cn`}
                          value={currentIssuingCA?.commonName || ''}
                        />
                      </>
                    )}
                    {activeEntity === EntityType.Owner && clientOwner && (
                      <>
                        <ServerSideSelect
                          id="owner-select"
                          value={
                            selectedOwner
                              ? userOptionFormatter(selectedOwner)
                              : ''
                          }
                          searchPlaceholder={'Search username'}
                          placeholder={'Select a user'}
                          fetchUrl={'user'}
                          onSelectEntity={(user: User) =>
                            setSelectedOwner(deserializeUser(user))
                          }
                          pageUrlParam={'page'}
                          searchParam={'username'}
                          pageSize={50}
                          searchOperator={'~'}
                          formatter={userOptionFormatter}
                          isParentLoading={false}
                        />
                      </>
                    )}
                  </Col>
                  {activeEntity === EntityType.CertificateProfile && (
                    <Col md={5}>
                      <>
                        <ServerSideSelect
                          id="certificate-profile-select"
                          onSelectEntity={(profile) => {
                            setCurrentCertificateProfile(
                              deserializeCertificateProfile(profile)
                            );
                          }}
                          formatter={(profile) =>
                            profile?.certificate_profile?.name
                          }
                          fetchUrl={`certificate/profile`}
                          defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
                          searchParam={`certificate_profile.name`}
                          wait={!currentIssuingCA}
                          placeholder={'Select a certificate profile'}
                          value={currentCertificateProfile?.name || ''}
                          urlParams={[
                            ['resource', Resource.CertificateProfile],
                            ['action', 'assign_roles'],
                          ]}
                        />
                      </>
                    </Col>
                  )}
                  {(activeEntity !== EntityType.Owner || clientOwner) && (
                    <Col md={2}>
                      <ButtonDropdown
                        id="entitle-button"
                        className="float-right select-height"
                        options={
                          activeEntity === EntityType.Owner
                            ? ownerRoles
                            : subscriberRoles
                        }
                        value="Entitle as"
                        disabled={disabledEntitleAs}
                        onClick={({ key }: { key: string }): void => {
                          const isAlreadyEntitled = !!find(
                            clientEntitlements,
                            (entitlement) =>
                              snakeCase(entitlement.entityType) ===
                                snakeCase(activeEntity) &&
                              entitlement.entityUuid === entityUuid &&
                              snakeCase(entitlement.role) === snakeCase(key)
                          );
                          const isTypeOwner = activeEntity === EntityType.Owner;
                          const entity = isTypeOwner ? 'user' : activeEntity;
                          const uuidToEntitle = isTypeOwner
                            ? selectedOwner?.uuid
                            : client.serviceAccount?.uuid;
                          onSetEntityRole({
                            entityRole: key,
                            entityType: entity,
                            userUuids: isAlreadyEntitled ? [] : [uuidToEntitle],
                            entityUuid,
                            usersAlreadyEntitled: isAlreadyEntitled
                              ? [client.serviceAccount?.username]
                              : [],
                          });
                          setCurrentIssuingCA(() => {
                            setCurrentCertificateProfile(undefined);
                            setSelectedOwner(undefined);
                            return undefined;
                          });
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
            <div className="mt-5">
              <Table
                classes="role-policy-table"
                noDataIndication="No Roles Yet"
                keyField="uuid"
                columns={columns}
                data={tableData}
              />
            </div>
          </div>
        }
      </div>
      <Modal
        className="PKIApp"
        isOpen={deleteModal}
        style={{ marginTop: '10%' }}
        toggle={toggleDeleteModal}
      >
        <ConfirmationForm
          title="Remove Role"
          content={
            <div className="text-center">You're about to delete a role.</div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={onClickDeleteConfirm}
        />
      </Modal>
    </div>
  );
};

export default ClientRolePolicy;
