import moment from 'moment/moment';
import { startCase } from 'lodash';
import { deserializeUserOutput } from '../../../store/users/helpers';
import { DeviceBatchParameters, DeviceBatchRequest } from '../types';
import { deserializeProductProfile } from '../../ProductProfiles/helpers';

export const serializeDeviceBatchParameters = (
  batchParams: DeviceBatchParameters
): any => {
  const newObj: DeviceBatchParameters = {};
  Object.keys(batchParams).forEach((variableName) => {
    const key = variableName.substring(
      variableName.indexOf('{') + 1,
      variableName.indexOf(':')
    );
    newObj[key] = batchParams[variableName];
  });
  return newObj;
};

export const deserializeDeviceBatchParameters = (
  batchParams: DeviceBatchParameters
): DeviceBatchParameters => {
  return batchParams;
};
export const serializeDeviceBatchRequest = (
  batchRequest: Partial<DeviceBatchRequest>
): any => {
  return {
    ...(batchRequest.uuid ? { uuid: batchRequest.uuid } : {}),
    product_profile_uuid: batchRequest.productProfileUuid,
    notes: batchRequest.notes,
    ...(batchRequest.status ? { status: batchRequest.status } : {}),
    quantity: batchRequest.quantity,
    batch_parameters: serializeDeviceBatchParameters(
      batchRequest.batchParameters || {}
    ),
  };
};

export const deserializeDeviceBatchRequest = (raw: any): DeviceBatchRequest => {
  return {
    uuid: raw.uuid,
    batchId: raw.batch_id,
    productProfileUuid: raw.product_profile_uuid,
    productProfileName: raw?.product_profile_name || raw.product_profile_uuid,
    productProfile: raw.product_profile
      ? deserializeProductProfile(raw.product_profile)
      : undefined,
    status: startCase(raw.status) as DeviceBatchRequest['status'],
    quantity: raw.quantity,
    notes: raw.notes || '',
    actionNotes: raw.action_notes || '',
    actions: raw.actions || [],
    batchParameters: deserializeDeviceBatchParameters(raw.batch_parameters),
    createdAt: moment(raw.date_created).format('LLL'),
    createdBy: deserializeUserOutput(raw.created_by_user),
  };
};
