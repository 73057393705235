import { Collapse } from 'reactstrap';
import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { dateFormatter } from '../../../libs/helpers';
import { Table, Spinner } from '../../../components';
import { ApplicationState } from '../../../store';
import { getIssuedCertificates } from '../../../store/certificates/actions';
import { CertificatesState } from '../../../store/certificates/types';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'commonName':
      return 'cn';
    case 'issuedAt':
      return 'inserted_time';
    default:
      return sortField;
  }
};

const endOfToday = moment(new Date());

const beginOfOneWeekAgo = moment().subtract(1, 'weeks').startOf('day');

const IssuedCertificates: FC = () => {
  const {
    isGettingIssuedCertificateList,
    isLoadedIssuiedCertificateList,
    issuedCertificateList,
  } = useSelector<ApplicationState, CertificatesState>((pki) => ({
    ...pki.certificates,
  }));
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('asc');
  const [tableSortField, setTableSortField] = useState<string>('inserted_time');

  const [showCertificateSpinner, setShowCertificateSpinner] = useState<boolean>(
    false
  );
  const [showContent, setShowContent] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingIssuedCertificateList) {
      setShowCertificateSpinner(true);
    }
    if (isLoadedIssuiedCertificateList) {
      setShowContent(true);
      setShowCertificateSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [isGettingIssuedCertificateList, isLoadedIssuiedCertificateList]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    dispatch(
      getIssuedCertificates(
        {
          startDate: beginOfOneWeekAgo.subtract(1, 'day').format('YYYY-MM-DD'),
          endDate: endOfToday.add(1, 'day').format('YYYY-MM-DD'),
          sizePerPage: tableSizePerPage,
          page: tablePage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
        },
        tokenSource
      )
    );
    return function cleanup(): void {
      tokenSource.cancel('IssuedCertificates::getIssuedCertificates');
    };
  }, [tablePage, tableSizePerPage, tableSortOrder, tableSortField]);

  const issuedCertificatesColumns = [
    { dataField: 'uuid', text: 'uuid', hidden: true },
    { dataField: 'commonName', text: 'Common Name' },
    {
      dataField: 'issuedAt',
      text: 'Issued Date',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
  ];

  let endDate = endOfToday;
  const lastIndex = issuedCertificateList.length;
  if (lastIndex !== 0) {
    endDate = moment(
      dateFormatter(Number(issuedCertificateList[lastIndex - 1].issuedAt))
    );
  }

  return (
    <div className="IssuedCertificates overflow-auto">
      <div className="header-contanier d-flex">
        <h5 className="text-muted">Issued Certificates</h5>
        {showCertificateSpinner && (
          <Spinner className="mt-1 ml-2" size="sm" type="border" />
        )}
      </div>
      <small>
        Issued certificates from {beginOfOneWeekAgo.format('LL')} to{' '}
        {endDate.format('LL')}
      </small>
      <Collapse isOpen={showContent}>
        <Table
          keyField="uuid"
          remote={true}
          search={false}
          pagination={{
            page: tablePage,
            sizePerPage: tableSizePerPage,
          }}
          sort={{
            dataField: tableSortField,
            order: tableSortOrder as SortOrder,
          }}
          noDataIndication="No Issued Certificates"
          columns={issuedCertificatesColumns}
          data={issuedCertificateList}
          onTableChange={(
            valueNotUsed: null,
            {
              page,
              sizePerPage,
              sortOrder,
              sortField,
            }: {
              page: number;
              sizePerPage: number;
              sortOrder: string;
              sortField: string;
            }
          ): void => {
            setTablePage(page);
            setTableSizePerPage(sizePerPage);
            setTableSortOrder(sortOrder);
            setTableSortField(mapSortField(sortField));
          }}
        />
      </Collapse>
    </div>
  );
};

export default IssuedCertificates;
