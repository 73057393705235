import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import axios from 'axios';
import { first, last, map, size } from 'lodash';
import { Spinner } from '../../../components';
import { ApplicationState } from '../../../store';
import { getLoginStats } from '../../../store/statistics/actions';
import { StatisticsState } from '../../../store/statistics/types';

const LoginStatistics: FC = () => {
  const { isGettingLoginStats, isLoadedLoginStats, loginStats } = useSelector<
    ApplicationState,
    StatisticsState
  >((pki) => ({
    ...pki.statistics,
  }));

  const dateArray = map(loginStats, (item) => moment(item.date).format('L'));
  const chartColors = Array(7).fill('#d87548');

  const data = {
    labels: dateArray,
    datasets: [
      {
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        backgroundColor: chartColors,
        data: map(loginStats, (item) => item.count),
      },
    ],
  };

  const [showChartSpinner, setShowChartSpinner] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingLoginStats) {
      setShowChartSpinner(true);
    }
    if (isLoadedLoginStats) {
      setShowChartSpinner(false);
    }
  }, [isGettingLoginStats, isLoadedLoginStats]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    dispatch(getLoginStats(tokenSource));
    return function cleanup(): void {
      tokenSource.cancel('LoginStatistics::getLoginStats');
    };
  }, [dispatch]);

  return (
    <div className="RequestsStatus overflow-auto">
      <div className="header-contanier d-flex">
        <h5 className="text-muted">Number of Logins Last 7 Days</h5>
        {showChartSpinner && (
          <Spinner className="mt-1 ml-2" size="sm" type="border" />
        )}
      </div>
      <small>
        Number of Logins from {first(dateArray)} to {last(dateArray)}
      </small>
      <div className="content mt-5">
        {!size(loginStats) && isLoadedLoginStats && (
          <div className="text-center mt-5">No Statistics Available</div>
        )}
        <div className="text-center align-middle">
          <Bar
            data={data}
            legend={{ display: false }}
            options={{ responsive: true }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginStatistics;
