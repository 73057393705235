import _, { includes, isEmpty } from 'lodash';
import React, { useEffect, useState, FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Card, Collapse, Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ApplicationState } from '../../store';
import { Table, Spinner, ErrorHandler } from '../../components';
import {
  ManufacturingStation,
  ManufacturingStationsActionTypes,
  ManufacturingStationsState,
} from '../../store/manufacturingStations/types';
import {
  getManufacturingStations,
  createManufacturingStation,
  editManufacturingStation,
} from '../../store/manufacturingStations/actions';
import { CertificateProfilesState } from '../../store/certificateProfiles/types';
import { CertificatesState } from '../../store/certificates/types';
import { UsersState } from '../../store/users/types';
import { sendNotification } from '../../store/notifications/actions';
import ManufacturingStationModal from './ManufacturingStationModal';

interface ManufacturingStationsToolbarPros {
  onCreateManufacturingStationClick?: Function;
  actions?: string[];
}
const ManufacturingStationsToolbar: FC<ManufacturingStationsToolbarPros> = ({
  actions = [],
  onCreateManufacturingStationClick = (): null => null,
}) => {
  const canCreate = includes(actions, 'create');

  return (
    <div className="ManufacturingStationsToolbar d-flex">
      <span className="mx-2">
        <Button
          onClick={(): void => {
            onCreateManufacturingStationClick();
          }}
          id="create-manufacturing-station-button"
          disabled={!canCreate}
          className="mr-2"
          size="sm"
          outline
        >
          <FontAwesomeIcon className="mr-1" icon={faPlusCircle} />
          Manufacturing Station
        </Button>
      </span>
    </div>
  );
};

export const ManufacturingStationsList: FC = () => {
  const {
    manufacturingStationsList,
    isGettingManufacturingStationList,
    isLoadedManufacturingStationList,
    manufacturingStationErrors,
    isCreatingManufacturingStation,
    isEditingManufacturingStation,
    isGettingOnlineCAList,
    userProfile: { resources },
    isGettingCertificateProfileList,
  } = useSelector<
    ApplicationState,
    ManufacturingStationsState &
      CertificateProfilesState &
      CertificatesState &
      UsersState
  >((pki) => ({
    ...pki.manufacturingStations,
    ...pki.certificateProfiles,
    ...pki.certificates,
    ...pki.users,
  }));
  const history = useHistory();
  const dispatch = useDispatch();

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('asc');
  const [tableSortField, setTableSortField] = useState<string>('name');
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [formModal, setFormModal] = useState<boolean>(false);

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      return !previousValue;
    });
  };

  const isGettingDependencyData =
    isGettingOnlineCAList || isGettingCertificateProfileList;

  const isPerformingAction =
    isCreatingManufacturingStation || isEditingManufacturingStation;

  useEffect(() => {
    if (isGettingManufacturingStationList || isGettingDependencyData) {
      setShowSpinner(true);
    }
    if (isLoadedManufacturingStationList || manufacturingStationErrors) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [
    isGettingManufacturingStationList,
    isGettingDependencyData,
    isLoadedManufacturingStationList,
    manufacturingStationErrors,
    isPerformingAction,
  ]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    if (!isCreatingManufacturingStation) {
      dispatch(
        getManufacturingStations(
          {
            page: tablePage,
            sizePerPage: tableSizePerPage,
            sortBy: tableSortField,
            sortDir: tableSortOrder,
          },
          tokenSource
        )
      );
    }

    return function cleanup(): void {
      tokenSource.cancel('ManufacturingStations::getManufacturingStations');
    };
  }, [
    dispatch,
    isCreatingManufacturingStation,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
  ]);

  const manufacturingStationResources = _.find(
    resources,
    (item) => item.name === 'manufacturing_station'
  );

  const actions = _.get(manufacturingStationResources, 'actions', []);

  const columns = [
    { dataField: 'uuid', text: 'uuid', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (name: string, data: ManufacturingStation): ReactNode => (
        <div id={data.uuid}>{name}</div>
      ),
    },
    {
      dataField: 'manufacturer',
      text: 'Manufacturer',
      formatter: (manufacturer: string): string => manufacturer || 'N/A',
    },
    {
      dataField: 'location',
      text: 'Location',
      formatter: (location: string): string => location || 'N/A',
    },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (enabled: boolean): ReactNode => (
        <h6>
          <Badge color={enabled ? 'success' : 'danger'}>
            {enabled ? 'Enabled' : 'Disabled'}
          </Badge>
        </h6>
      ),
    },
  ];

  return (
    <div className="ManufacturingStations">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Manufacturing Stations</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="view mt-5">
            {manufacturingStationErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                data={manufacturingStationsList}
                keyField="uuid"
                sort={{ dataField: 'name', order: 'asc' }}
                remote={true}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                  setTableSortOrder(sortOrder);
                  setTableSortField(sortField);
                }}
                rowEvents={{
                  onClick: (
                    notUsedValue: null,
                    current: ManufacturingStation
                  ): void => {
                    dispatch({
                      type:
                        ManufacturingStationsActionTypes.CLEAR_MANUFACTURING_STATION,
                      manufacturingStation: current,
                    });

                    history.push(
                      `/management/manufacturing-stations/${current.uuid}`,
                      { manufacturingStation: current }
                    );
                  },
                }}
                toolbar={
                  <ManufacturingStationsToolbar
                    actions={actions}
                    onCreateManufacturingStationClick={(): void => {
                      toggleFormModal();
                    }}
                  />
                }
                noDataIndication={
                  tablePage > 1
                    ? 'No more Manufacturing Stations available'
                    : 'No Manufacturing Stations available'
                }
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        <ManufacturingStationModal
          readOnly={false}
          onCancel={toggleFormModal}
          onSubmit={({
            values,
            isValid,
          }: {
            values: ManufacturingStation;
            isValid: boolean;
          }): void => {
            if (isValid) {
              if (isEmpty(values?.uuid)) {
                dispatch(createManufacturingStation(values));
              } else {
                dispatch(editManufacturingStation(values));
              }
              toggleFormModal();
            } else {
              dispatch(
                sendNotification({
                  text: 'Some fields are mandatory, please fill them all.',
                  success: false,
                })
              );
            }
          }}
        />
      </Modal>
    </div>
  );
};
