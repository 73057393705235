/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import _, { isNil } from 'lodash';
import { TreeItem } from 'react-sortable-tree';
import { Certificate } from './types';

export const serializeRevocation = ({
  certificateUuids,
  reasonComment,
  reasonCode,
}: {
  certificateUuids: string[];
  reasonComment: string;
  reasonCode: string;
}): {
  certificate_uuids: string[];
  reason_code: number;
  notes: string;
} => ({
  certificate_uuids: certificateUuids,
  reason_code: Number(reasonCode),
  notes: reasonComment,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeCertificate = (raw: any): Certificate => {
  let depth: 0 | 1 | 2 = 2; // defaul is end leaf
  if (!raw.is_issuer && !raw.issuing_ca_uuid) {
    depth = 0; // is root CA
  }
  if (raw.is_issuer && !_.isEmpty(raw.issuing_ca_uuid)) {
    depth = 1; // is issuing CA
  }

  return {
    serial: raw.serial,
    uuid: raw.uuid,
    commonName: raw.cn,
    keyIdentifier: raw.key_identifier,
    issuingCaUuid: raw.issuing_ca_uuid,
    actions: raw.actions,
    caUrls: raw.ca_urls,
    isKeyOnline: raw.is_key_online,
    crlUrls: raw.crl_urls,
    resources: raw.resources,
    state: raw.status,
    deviceUuid: raw.device_uuid || '',
    deviceRequestUuid: raw.device_request_uuid || '',
    issuedAt: moment(raw.inserted_time).format('x'),
    startsAt: moment(raw.not_before).format('x'),
    expiresAt: moment(raw.not_after).format('x'),
    issuer: {
      uuid: raw?.issuer?.uuid,
      commonName: raw?.issuer?.cn,
    },
    depth,
    references: {
      profileUuid:
        raw?.certificate_request?.certificate_profile_id ||
        raw?.code_signing_key_request?.certificate_profile_uuid,
      requestUuid:
        raw?.certificate_request?.uuid || raw?.code_signing_key_request?.uuid,
      requestType: !isNil(raw?.certificate_request?.uuid)
        ? 'Certificate'
        : 'CodeSigningCertificate',
    },
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeTree = (raw: any): TreeItem => ({
  title: raw.certificate.cn,
  certificate: deserializeCertificate(raw.certificate),
  expanded: true,
  children: _.map(raw.issued, (subItem) => deserializeTree(subItem)),
});
