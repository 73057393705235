import React, { useState, useRef, useEffect, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faLock,
  faUpload,
  faUnlock,
  faTrash,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Button,
  Collapse,
  UncontrolledPopover,
  Alert,
} from 'reactstrap';
import _, { isNil, set, split, isEmpty, size } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';
import { KeyNInput, FileViolations } from '../../components';
import { CodeSigningCertificateRequest } from '../../store/requests/types';
import { CertificateProfile } from '../../store/certificateProfiles/types';
import { Certificate } from '../../store/certificates/types';
import { getVariablesFromSubject, formatBytes, api } from '../../libs/helpers';
import { getVars } from '../../libs/provisioning';
import { defaultValuesCodeSigningKeyRequest } from './constants';
import './CodeSigningCertificateRequestForm.scss';
import { combineAndDestructSubjectVars } from '../ViewsComponents/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { deserializeCertificateProfile } from '../../store/certificateProfiles/helpers';
import { sendNotification } from '../../store/notifications/actions';

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onChangeMode?: Function;
  defaultValues: CodeSigningCertificateRequest;
  wrappedKey: boolean;
  readOnly: boolean;
}

const CodeSigningCertificateRequestForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onChangeMode = (): null => null,
  defaultValues = defaultValuesCodeSigningKeyRequest,
  readOnly = false,
  wrappedKey,
}) => {
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);

  const [currentCertificateProfile, setCurrentCertificateProfile] = useState<
    CertificateProfile | undefined
  >(undefined);

  const editMode = !_.isEmpty(defaultValues.uuid);

  const [requestForm, setRequestForm] = useState<CodeSigningCertificateRequest>(
    defaultValues
  );
  const [selectProfileFlag, setSelectProfileFlag] = useState<boolean>(
    editMode // true when to component loads the first time, then set to false
  );
  const [currentFile, setCurrentFile] = useState<File>();
  const [showEntitlementMessage, setShowEntitlementMessage] = useState(false);

  const inputEl = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const fetchCancelTokenSource = useRef<CancelTokenSource>();

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setRequestForm((current: CodeSigningCertificateRequest) => ({
      ...current,
      [property]: value,
    }));
  };

  const {
    notes,
    approverNotes,
    issuingCAUuid,
    certificateProfileUuid,
    certificateSubjectVars,
  } = requestForm;

  useEffect(() => {
    if (currentCertificateProfile) {
      const {
        subject = [],
        subjectAlternativeNames = [],
      } = currentCertificateProfile;
      let combinedVars = [];
      combinedVars = combineAndDestructSubjectVars(
        subject,
        subjectAlternativeNames
      );
      const value = currentCertificateProfile
        ? getVariablesFromSubject(combinedVars, certificateSubjectVars)
        : {};
      setProfileFormProperty({
        property: 'certificateSubjectVars',
        value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCertificateProfile]);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  if (readOnly && _.isEmpty(issuingCAUuid) && currentCertificateProfile) {
    setProfileFormProperty({
      property: 'issuingCAUuid',
      value: currentCertificateProfile.issuingCaUuid,
    });
  }

  const isEditable =
    _.includes(
      (defaultValues as CodeSigningCertificateRequest).actions,
      'update'
    ) &&
    (defaultValues as CodeSigningCertificateRequest).status ===
      'Awaiting Approval';

  const invalidCertificateSubjectVars = !_.isNil(
    _.find(_.keys(certificateSubjectVars), (key) => {
      if (key.includes('required') && certificateSubjectVars) {
        return _.chain(certificateSubjectVars[key]).trim().isEmpty().value();
      }

      return false;
    })
  );

  const fileViolations = ((
    filename: string | undefined
  ): { [key: string]: string } => {
    if (isNil(filename)) {
      return {};
    }
    const [groupId, customer, env, timestamp] = split(filename, '_');

    const {
      PKI_SELECTED_CUSTOMER_CODE: currentCustomer,
      ENV: currentEnv,
    } = getVars();

    const output: { [key: string]: string } = {};

    const groupIdViolation =
      isEmpty(groupId) || !/^\d+$/.test(groupId) || size(groupId) !== 3;
    const timestampViolation =
      isEmpty(timestamp) || new Date(timestamp).getTime() > 0;
    const customerViolation = isEmpty(customer) || customer !== currentCustomer;
    const envViolation = isEmpty(env) || env !== currentEnv;
    const fileExtension = _((filename as unknown) as string[])
      .takeRight(6)
      .join('');

    const extensionViolation = fileExtension !== 'tar.gz';

    groupIdViolation && (output.groupId = 'Wrong Group ID format!');
    customerViolation && (output.customer = 'Wrong Customer name!');
    envViolation && (output.env = 'Wrong Environment variable!');
    timestampViolation && (output.timestamp = 'Timestamp not valid!');
    extensionViolation &&
      (output.extension = 'File Extension not allowed! Must be .tar.gz!');
    return output;
  })(currentFile?.name);

  let isValid = !_.isEmpty(issuingCAUuid) && !invalidCertificateSubjectVars;

  if (wrappedKey && !isNil(currentFile)) {
    isValid = isValid && isEmpty(fileViolations);
  }

  const fetchData = async () => {
    setShowEntitlementMessage(false);
    fetchCancelTokenSource.current = axios.CancelToken.source();
    try {
      const { data: profile } = await api().get(
        `certificate/profile/${certificateProfileUuid}`,
        {
          cancelToken: fetchCancelTokenSource.current?.token,
        }
      );
      const { data: ca } = await api().get(
        `certificate/authority/${issuingCAUuid}`,
        {
          cancelToken: fetchCancelTokenSource.current?.token,
        }
      );
      setCurrentCertificateProfile(() => {
        setCurrentIssuingCA(deserializeCertificate(ca));
        return deserializeCertificateProfile(profile);
      });
    } catch (err) {
      if (err?.response?.status === 403) {
        setShowEntitlementMessage(true);
      } else if (!axios.isCancel(err)) {
        sendNotification({
          text: err?.response?.data?.detail || 'Failed to load the data!',
          success: false,
        })(dispatch);
      }
    }
  };

  useEffect(() => {
    if (certificateProfileUuid && issuingCAUuid) {
      fetchData();
    }
    return () => {
      fetchCancelTokenSource.current?.cancel('Form got unmounted');
    };
  }, [readOnly]);

  return (
    <div
      id="code-signing-key-request-form"
      className="CodeSigningCertificateRequestForm"
    >
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3 className="text-muted">Code Signing Certificate Request</h3>
          <span
            onClick={(): void => {
              if (requestForm.status === 'Awaiting Approval') {
                onChangeMode();
              }
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-5 px-5 pb-5">
        <Row>
          <Col>
            <Label className="pki-label">Notes</Label>
            <FormGroup>
              <Input
                value={_.isEmpty(notes) && readOnly ? 'N/A' : notes}
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'notes',
                    value: event.target.value,
                  });
                }}
                name="notes"
                id="notes"
              />
            </FormGroup>
          </Col>
          {readOnly && approverNotes && (
            <Col>
              <Label className="pki-label">Approver Notes</Label>
              <FormGroup>
                <Input
                  value={approverNotes}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  type="textarea"
                  name="approver_notes"
                  id="approver_notes"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Label className="pki-label">Issuing CA</Label>
            {readOnly && !showEntitlementMessage && (
              <Input
                id="issuer-ca-uuid"
                readOnly
                value={currentIssuingCA?.commonName || ''}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(ca) => {
                    setProfileFormProperty({
                      property: 'issuingCAUuid',
                      value: ca?.uuid,
                    });
                    setCurrentIssuingCA(() => {
                      setCurrentCertificateProfile(undefined);
                      setProfileFormProperty({
                        property: 'certificateProfile',
                        value: null,
                      });
                      return deserializeCertificate(ca);
                    });
                  }}
                  id="issuer-ca-uuid"
                  formatter={(ca) => ca.cn}
                  disabled={readOnly}
                  fetchUrl={`certificate/authority`}
                  defaultFilters={'is_key_online=true'}
                  searchParam={`cn`}
                  value={currentIssuingCA?.commonName || ''}
                />
              </>
            )}
          </Col>
          <Col>
            <Label className="pki-label">Certificate Profile</Label>
            {readOnly && !showEntitlementMessage && (
              <Input
                id="certificate-profile"
                readOnly
                value={currentCertificateProfile?.name || ''}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  id="certificate-profile"
                  onSelectEntity={(profile) => {
                    setProfileFormProperty({
                      property: 'certificateProfileUuid',
                      value: profile?.uuid,
                    });
                    setCurrentCertificateProfile(
                      deserializeCertificateProfile(profile)
                    );
                  }}
                  pageSize={1000}
                  disabled={readOnly}
                  formatter={(profile) => profile?.certificate_profile?.name}
                  fetchUrl={`certificate/profile`}
                  defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
                  searchParam={`certificate_profile.name`}
                  wait={!currentIssuingCA}
                  value={
                    currentCertificateProfile?.name ||
                    'Select a certificate profile'
                  }
                  urlParams={[
                    ['resource', 'code_signing_key'],
                    ['action', 'create'],
                  ]}
                  filterFrontend={(profile) => {
                    const deserializedProfile = deserializeCertificateProfile(
                      profile
                    );
                    return (
                      _.includes(
                        deserializedProfile.extendedKeyUsage,
                        'code_signing'
                      ) &&
                      _.includes(
                        deserializedProfile.keyUsage,
                        'digital_signature'
                      )
                    );
                  }}
                  error={
                    !currentCertificateProfile && highlightMandatoryFields
                      ? 'Cannot be empty, please select one!'
                      : undefined
                  }
                />
              </>
            )}
          </Col>
        </Row>
        {!showEntitlementMessage && (
          <Collapse isOpen={!!_.size(certificateSubjectVars)}>
            <KeyNInput
              readOnly={readOnly}
              className="mt-4"
              label="Variables"
              highlightMandatoryFields={highlightMandatoryFields}
              keys={certificateSubjectVars || {}}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string;
              }): void => {
                setProfileFormProperty({
                  property: 'certificateSubjectVars',
                  value: { ...certificateSubjectVars, [key]: value },
                });
              }}
              labelPopover="These variables are defined in either the Subject and/or Subject Alternative Names for the selected profile."
            />
          </Collapse>
        )}

        {wrappedKey && !readOnly && (
          <div className="mt-3">
            <Label className="pki-label">
              Wrapped Key (Optional){' '}
              <FontAwesomeIcon
                id="code-signing-request-wrapped-key-hint"
                className="pki-ico mt-4"
                icon={faInfoCircle}
              />
              <UncontrolledPopover
                target="code-signing-request-wrapped-key-hint"
                placement="top"
                popperClassName="code-signing-request-wrapped-key-popover"
                trigger="hover"
                title="Optional"
              >
                <div className="p-2">
                  <p>Wrapped Key filename must match the following pattern:</p>
                  <p>
                    {
                      '<group_id>_<customer>_<environment>_<timestamp>_<original_filename>_wrapped_key.tar.gz'
                    }
                  </p>
                  <p className="mb-0">
                    If you don't provide a wrapped key, a new code signing key
                    will be generated.
                  </p>
                </div>
              </UncontrolledPopover>
            </Label>
            <Row>
              <Col className="d-flex" md={4}>
                <Button
                  outline
                  id="upload-key-button"
                  onClick={(): void => {
                    if (inputEl && inputEl.current) {
                      inputEl.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon className="mr-1" icon={faUpload} />
                  Select
                </Button>
                <div className="mt-auto ml-1">
                  <code className="black-code">.tar.gz</code>
                </div>
              </Col>
              {currentFile && (
                <Col className="d-flex" md={7}>
                  <div id="file-description" className="text-truncate mt-auto">
                    {currentFile.name} ({formatBytes(currentFile.size)})
                  </div>
                </Col>
              )}
              {currentFile && (
                <Col>
                  <FontAwesomeIcon
                    className="pki-ico file-delete mt-4"
                    onClick={(): void => {
                      setCurrentFile(undefined);
                      set(inputEl, 'current.value', null);
                    }}
                    icon={faTrash}
                  />
                </Col>
              )}
            </Row>
            {isNil(currentFile) && highlightMandatoryFields && (
              <div className="invalid-text">
                Key cannot be empty, please select a file!
              </div>
            )}
            <input
              type="file"
              id="file-input"
              className="d-none"
              ref={inputEl}
              onChange={({ target: { files } }): void => {
                if (files && files.length > 0) {
                  setCurrentFile(files[0]);
                  set(inputEl, 'current.value', null);
                }
              }}
            />
            {!isNil(currentFile) && (
              <div className="file-violations mt-3">
                <FileViolations violations={fileViolations} />
              </div>
            )}
          </div>
        )}
        {readOnly && showEntitlementMessage && (
          <Alert color={'info'} className={'mt-2'}>
            <span>
              You are not entitled to view the CA and the Certificate Profile.
              Please ask your CA admin to entitle you to the relevant CA and
              Certificate Profile.
            </span>
          </Alert>
        )}
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-form-button"
                outline
                disabled={false}
                onClick={(): void => {
                  onSubmit({
                    values: { ...requestForm, wrappedKey: currentFile },
                    isValid,
                  });
                  if (!isValid) {
                    setHighlightMandatoryFields(true);
                  }
                }}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeSigningCertificateRequestForm;
