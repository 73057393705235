import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Badge, Collapse } from 'reactstrap';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { User } from '../../../store/users/types';
import { Filter } from '../../../components/FilterBar/types';
import { Spinner, Table } from '../../../components';
import { api } from '../../../libs/helpers';
import { sendNotification } from '../../../store/notifications/actions';
import { mapRoleToValue } from '../../../store/users/helpers';
import { Key } from '../../keys/types';
import { deserializeKey } from '../../keys/AsymmetricKeys/helpers';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

interface KeysProps {
  user: User | null;
}

interface EntitlementKey {
  entity: Key;
  entityRoles: string[];
}

export const Keys: FC<KeysProps> = ({ user }) => {
  const dispatch = useDispatch();

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>([]);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [keys, setKeys] = useState<EntitlementKey[]>();
  const fetchSourceRef = useRef<CancelTokenSource>();

  const fetchData = async () => {
    fetchSourceRef.current = axios.CancelToken.source();
    if (user) {
      setShowSpinner(true);
      setShowContent(false);
      try {
        const {
          data: keysData,
        } = await api().get(
          `/user/entitlements/summary?filter=entity_type=key,user_uuid=${user?.uuid}&page=${tablePage}&count=${tableSizePerPage}`,
          { cancelToken: fetchSourceRef.current.token }
        );
        const keysToSet = keysData.map(
          // eslint-disable-next-line camelcase
          (key: { entity: Key; entity_roles: string[] }) => {
            return {
              entity: deserializeKey(key.entity),
              entityRoles: key.entity_roles,
            };
          }
        );
        setKeys(() => {
          setShowSpinner(false);
          setShowContent(true);
          return keysToSet;
        });
      } catch (e) {
        if (!axios.isCancel(e)) {
          sendNotification({
            text: 'Failed to fetch Keys!',
            success: false,
          })(dispatch);
          setShowSpinner(false);
          setShowContent(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    query,
    user,
  ]);
  useEffect(() => {
    return () => {
      fetchSourceRef.current?.cancel();
    };
  }, []);
  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    { dataField: 'entity.label', text: 'Label' },
    {
      dataField: 'entity.keyType',
      text: 'Key Type',
      formatter: (type: string) => startCase(type),
    },
    {
      dataField: 'noData',
      text: 'Entitlements',
      formatter: (
        unused: string,
        { entityRoles }: { entityRoles: string[] }
      ): ReactNode => {
        return (
          <>
            {entityRoles.map((role) => (
              <span key={role} className={'m-1 d-inline-block'}>
                <Badge color={'primary'} className={'p-2'}>
                  {mapRoleToValue?.[role] ?? startCase(role)}
                </Badge>
              </span>
            ))}
          </>
        );
      },
    },
  ];
  return (
    <div className="user-profile-keys">
      {showSpinner && <Spinner className="mb-2" size="sm" type="border" />}
      <Collapse isOpen={showContent}>
        <div className="table-container">
          <Table
            keyField="entity.uuid"
            remote={true}
            search={false}
            pagination={{
              page: tablePage,
              sizePerPage: tableSizePerPage,
            }}
            sort={{
              dataField: tableSortField,
              order: tableSortOrder as SortOrder,
            }}
            noDataIndication={
              tablePage > 1 ? 'No more Keys available' : 'No Key available'
            }
            onTableChange={(
              valueNotUsed: null,
              {
                page,
                sizePerPage,
                sortOrder,
                sortField,
              }: {
                page: number;
                sizePerPage: number;
                sortOrder: string;
                sortField: string;
              }
            ): void => {
              // sortField set to date_updated as default if not defined
              setTableSortField(mapSortField(sortField || 'date_updated'));
              setTablePage(page);
              setTableSizePerPage(sizePerPage);
              setTableSortOrder(sortOrder);
            }}
            data={keys ?? []}
            columns={columns}
          />
        </div>
      </Collapse>
    </div>
  );
};
