import { Reducer } from 'redux';
import { NotificationsState, NotificationsActionTypes } from './types';

export const initialState: NotificationsState = {
  text: '',
  success: false,
};

const reducer: Reducer<NotificationsState> = (
  state = initialState,
  action
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.SEND_NOTIFICATION: {
      return {
        ...state,
        text: action.payload.text,
        success: action.payload.success,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as notificationsReducer };
