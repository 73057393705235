import React, { FC } from 'react';
import { Button } from 'reactstrap';

interface Props {
  onDownloadSelect: Function;
}

export enum FormatType {
  PEM = 'PEM',
  DER = 'DER',
}

const DownloadCRL: FC<Props> = ({ onDownloadSelect = (): null => null }) => {
  return (
    <div className="DownloadCRL">
      <div className="p-3">
        <span className="text-center text-muted">
          <h3>Select CRL format</h3>
        </span>
        <div className="d-flex mt-5 justify-content-between">
          <Button
            outline
            size="sm"
            onClick={(): void => {
              onDownloadSelect({ formatType: FormatType.PEM });
            }}
          >
            Download PEM
          </Button>
          <Button
            outline
            size="sm"
            onClick={(): void => {
              onDownloadSelect({ formatType: FormatType.DER });
            }}
          >
            Download DER
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadCRL;
