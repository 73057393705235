import React, {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  UIEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input } from 'reactstrap';
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ServerSideSelect.scss';
import _, { debounce, trimEnd } from 'lodash';
import { useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { api, disableBodyScrollOnKeyboard } from '../../libs/helpers';
import { useClickAway } from '../../libs/useClickAway';
import { OptionItems } from './OptionItems/OptionItems';
import Spinner from '../Spinner/Spinner';
import { sendNotification } from '../../store/notifications/actions';
import { useMounted } from '../../libs/hooks';

export interface ServerSideSelectProps {
  onSelectEntity: (entity: any) => void;
  formatter: (entity: any) => string;
  fetchUrl: string;
  id?: string;
  modalMode?: boolean;
  pageUrlParam?: string;
  searchParam: string;
  searchOperator?: string;
  defaultFilters?: string;
  value?: any;
  searchPlaceholder?: string;
  pageSize?: number;
  infinite?: boolean;
  isParentLoading?: boolean;
  error?: string;
  urlParams?: [string, string][];
  filterFrontend?: (entity: any) => boolean;
  wait?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export const ServerSideSelect: FC<ServerSideSelectProps> = ({
  onSelectEntity,
  formatter,
  fetchUrl,
  pageUrlParam = 'page',
  searchParam,
  infinite = true,
  placeholder,
  id,
  searchOperator = '~',
  pageSize = 50,
  value = undefined,
  wait = false,
  urlParams = [],
  searchPlaceholder,
  error,
  defaultFilters,
  isParentLoading = false,
  filterFrontend,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const groupRef = useRef<HTMLDivElement>(null);
  const searchTextTimeoutRef = useRef<number | undefined>(undefined);
  const infiniteTimeoutRef = useRef<number | undefined>(undefined);
  const scrollRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [hasNext, setHasNext] = useState(true);
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const [isDisabledNoRecords, setIsDisabledNoRecords] = useState(false);
  const firstRun = useRef<boolean>(true);
  const fetchCancelTokenSource = useRef<CancelTokenSource>();
  const [focusIndex, setFocusIndex] = useState(0);
  const isMounted = useMounted();
  if (pageSize > 1000) {
    alert("don't set the page size more than 1000!");
  }
  const fetchData = async (isSearch = false) => {
    setFirstLoad(false);
    if (wait) {
      return;
    }
    fetchCancelTokenSource.current = axios.CancelToken.source();
    setIsFetching(true);
    setIsDisabledNoRecords(false);
    setHasNext(true);
    const filters = [];
    const params = urlParams.map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`;
    });
    if (defaultFilters) filters.push(defaultFilters);
    if (searchText)
      filters.push(`${searchParam}${searchOperator}${searchText}`);
    const url = `${trimEnd(fetchUrl, '/')}?${
      params.length > 0 ? `${params.join('&')}&` : ''
    }${pageUrlParam}=${page}&count=${pageSize}${
      searchText || defaultFilters ? `&filter=${filters.join(',')}` : ''
    }`;
    try {
      const { data: results } = await api().get(url, {
        cancelToken: fetchCancelTokenSource.current?.token,
      });
      if (!isMounted()) return;
      if (page === 1 && results.length === 0 && !searchText) {
        setIsDisabledNoRecords(true);
      }
      setData(() => {
        if (results.length < pageSize) setHasNext(false);
        let dataToSet = results;
        if (filterFrontend && results.length > 0) {
          dataToSet = _.filter(results, (entity: any) =>
            filterFrontend(entity)
          );
        }
        if (isSearch) setFocusIndex(0);
        if (infinite && !isSearch && !searchTriggered && page !== 1) {
          return [...data, ...dataToSet];
        }
        return dataToSet;
      });
      setSearchTriggered(false);
    } catch (e) {
      if (!axios.isCancel(e)) {
        sendNotification({
          text: 'Failed to load the data!',
          success: false,
        })(dispatch);
        if (isMounted()) {
          setData([]);
        }
      }
    } finally {
      if (isMounted()) setIsFetching(false);
    }
  };
  const fetchOnSearch = () => {
    window.clearTimeout(searchTextTimeoutRef.current);
    if (isMounted()) {
      searchTextTimeoutRef.current = window.setTimeout(() => {
        setSearchTriggered(() => {
          setData([]);
          if (page === 1) {
            fetchData(true);
          } else {
            setPage(1);
          }
          return true;
        });
      }, 400);
    }
  };
  useEffect(() => {
    if (isMounted()) fetchData();
  }, [page, isMounted]);
  useEffect(() => {
    if (isMounted() && !isFetching) {
      setFocusIndex(0);
      if (page === 1 && !firstLoad) {
        setData([]);
        if (searchText) {
          setSearchText('');
        } else {
          fetchData();
        }
      } else {
        setPage(1);
      }
    }
  }, [fetchUrl, defaultFilters, wait, isMounted]);
  useEffect(() => {
    if (isMounted() && !firstRun.current) {
      fetchOnSearch();
    }
  }, [searchText]);
  useEffect(() => {
    setData([]);
    firstRun.current = false;
    return () => {
      window.clearTimeout(searchTextTimeoutRef.current);
      window.clearTimeout(infiniteTimeoutRef.current);
      fetchCancelTokenSource.current?.cancel('Selector got unmounted');
      setData([]);
    };
  }, []);

  const toggleOpen: MouseEventHandler = (ev) => {
    if (disabled) return;
    ev.preventDefault();
    ev.stopPropagation();
    if (!isDisabledNoRecords && !wait) setIsOpen(!isOpen);
  };

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setSearchText(ev.target.value);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const changePage = (dir: 'prev' | 'next') => {
    const newPage =
      dir === 'prev' ? Math.max(1, page - 1) : Math.max(1, page + 1);
    setPage(newPage);
  };

  const fetchOnInfiniteScroll = () => {
    if (hasNext && !isFetching) {
      window.clearTimeout(infiniteTimeoutRef.current);
      infiniteTimeoutRef.current = window.setTimeout(() => {
        if (isMounted()) setPage(page + 1);
      }, 500);
    }
  };

  const debouncedFetchOnScroll = useCallback(
    debounce(fetchOnInfiniteScroll, 300),
    [page, hasNext, isMounted, isFetching]
  );
  const handleScroll: UIEventHandler = (ev) => {
    if (scrollRef.current) {
      const distance =
        scrollRef.current.scrollHeight -
        (scrollRef.current.scrollTop + scrollRef.current.clientHeight);
      if (distance <= 100) {
        debouncedFetchOnScroll();
      }
    }
  };

  const handleInvisibleFocused = () => {
    if (scrollRef.current) {
      const focusedItem = scrollRef.current.querySelector(
        '.focused'
      ) as HTMLDivElement;
      if (focusedItem) {
        const { offsetTop: focusTop, clientHeight: focusHeight } = focusedItem;
        const {
          scrollTop: parentScroll,
          clientHeight: parentHeight,
        } = scrollRef.current;
        const { top: parentTop } = scrollRef.current.getBoundingClientRect();
        const parentBottom = parentTop + parentHeight;
        const focusTopLine = focusTop + parentTop - focusHeight - parentScroll;
        const focusBottomLine = focusTop + parentTop - parentScroll;
        if (focusTopLine < parentTop)
          scrollRef.current.scrollTo({ top: focusTop - focusHeight });
        if (focusBottomLine > parentBottom)
          scrollRef.current.scrollTo({
            top: parentScroll + (focusBottomLine - parentBottom),
          });
      }
    }
  };

  const onSelectItem = (entity: any) => {
    onSelectEntity(entity);
    setIsOpen(() => {
      if (value === undefined) {
        setInnerValue(formatter(entity));
      }
      return false;
    });
  };
  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (
    event
  ) => {
    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
        if (focusIndex < data.length - 1) {
          setFocusIndex(focusIndex + 1);
        }
        break;
      case 'Up':
      case 'ArrowUp':
        if (focusIndex > 0) {
          setFocusIndex(focusIndex - 1);
        }
        break;
      case 'PageDown':
        if (scrollRef.current) {
          setFocusIndex(data.length - 1);
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        break;
      case 'PageUp':
        if (scrollRef.current) {
          scrollRef.current.scrollTop = Math.max(
            0,
            scrollRef.current.scrollTop - pageSize * 50
          );
          setFocusIndex(Math.max(0, focusIndex - pageSize));
        }
        break;
      case ' ':
        if (document.activeElement !== searchInputRef.current) {
          if (!isOpen) {
            setIsOpen(true);
          } else if (data[focusIndex]) {
            onSelectItem(data[focusIndex]);
          }
        }
        break;
      case 'Enter':
        if (data[focusIndex]) {
          onSelectItem(data[focusIndex]);
        }
        break;
    }
  };

  useEffect(() => {
    if (focusIndex === 0) {
      setFocusIndex(
        Math.max(
          data.findIndex(
            (entity) =>
              (typeof value === 'object' &&
                (entity?.uuid === value?.uuid || entity?.id === value?.id)) ||
              (typeof value === 'string' && formatter(entity) === value)
          ),
          0
        )
      );
    }
  }, [data]);
  useEffect(() => {
    handleInvisibleFocused();
  }, [focusIndex]);

  const menuRef = useClickAway(handleClickAway);
  let inputValue = '';
  if (isParentLoading) {
    inputValue = 'Loading...';
  } else if (isDisabledNoRecords) {
    inputValue = 'No Options To Select';
  } else if (value) {
    inputValue =
      (typeof value === 'string' ? value : formatter(value)) ?? 'N/A';
  } else if (innerValue) {
    inputValue = innerValue;
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', disableBodyScrollOnKeyboard, false);
    } else {
      window.removeEventListener('keydown', disableBodyScrollOnKeyboard, false);
    }
    return () =>
      window.removeEventListener('keydown', disableBodyScrollOnKeyboard, false);
  }, [isOpen]);
  return (
    <div
      {...(id && { id })}
      className={`serverSideSelectContainer ${
        isOpen && !isParentLoading ? 'open' : ''
      } ${isDisabledNoRecords || wait ? 'disabled' : ''}`}
      ref={menuRef}
      role={'menu'}
      onClick={(ev) => ev.stopPropagation()}
      tabIndex={0}
      onKeyUp={handleKeyPress}
      onBlur={() => {
        setTimeout(() => {
          if (
            menuRef.current &&
            !menuRef.current.contains(document.activeElement)
          ) {
            setIsOpen(false);
          }
        }, 40);
      }}
    >
      <div
        className={'serverSideSelectGroupContainer'}
        ref={groupRef}
        onClick={(ev) => ev.stopPropagation()}
      >
        <div
          className={`serverSideSelectInputGroup ${error ? 'hasError' : ''}`}
          onClick={toggleOpen}
        >
          <Input
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
            onFocus={() => {
              if (menuRef.current)
                menuRef.current.className = `${menuRef.current.className} focused`;
            }}
            disabled={true}
            placeholder={placeholder || 'Select an option'}
            className={'input'}
            value={inputValue}
            invalid={!!error}
          />
          <span className={'inputIcon'}>
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
          </span>
        </div>
        {error && <span className="text-danger">{error}</span>}
      </div>
      <div
        className={'menuContainer'}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div style={{ padding: '5px' }}>
          <input
            onChange={onChangeSearch}
            value={searchText}
            ref={searchInputRef}
            className={'form-control w-100 serverSideSelectSearchInput'}
            placeholder={`${searchPlaceholder ?? 'Search...'}`}
          />
        </div>
        <div
          className={'itemsContainer'}
          ref={scrollRef}
          {...(infinite && { onScroll: handleScroll })}
        >
          {isFetching && !infinite && (
            <div className={'menuItem disabled'}>Fetching...</div>
          )}
          {((!isFetching && data.length > 0) || infinite) && (
            <OptionItems
              onSelectEntity={onSelectItem}
              data={data}
              formatter={formatter}
              hasNext={hasNext}
              isFetching={isFetching}
              infinite={infinite}
              focusIndex={focusIndex}
              setFocusIndex={setFocusIndex}
            />
          )}
          {!isFetching && data.length === 0 && (
            <div className={'menuItem disabled text-center'}>
              No Data To Show
            </div>
          )}
        </div>
        {isFetching && infinite && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '3px',
            }}
          >
            <Spinner size={'sm'} />
          </div>
        )}
        {!infinite && (
          <div
            className={'paginationContainer'}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div
              className={`navigation prev ${page <= 1 && 'disabled'}`}
              onClick={() => (page > 1 ? changePage('prev') : 1)}
            >
              <FontAwesomeIcon className="pki-ico" icon={faChevronLeft} />
            </div>
            <div className="pageInfo">
              <span>Page: {page}</span>
            </div>
            <div
              className={`navigation next ${!hasNext && 'disabled'}`}
              onClick={() => (hasNext ? changePage('next') : 1)}
            >
              <FontAwesomeIcon className="pki-ico" icon={faChevronRight} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
