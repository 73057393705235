import React, { FC, useEffect, useState } from 'react';
import { get, isEmpty, omit } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Spinner } from '../../../../../components';
import { ProductProfile } from '../../../types';

const defaultValues = {
  name: '',
  notes: '',
  productProfileId: '',
  uuid: undefined,
};

const formKeys = ['uuid', 'name', 'productProfileId', 'notes'];

interface ProductProfileFormProps {
  productProfile?: ProductProfile;
  onSubmit?: (formValues: Partial<ProductProfile>) => void;
  isLoading?: boolean;
  onCancel: Function;
}

export const ProductProfileForm: FC<ProductProfileFormProps> = ({
  productProfile,
  onSubmit,
  isLoading = false,
  onCancel,
}) => {
  const [formValues, setFormValues] = useState<Partial<ProductProfile>>(
    defaultValues
  );
  useEffect(() => {
    if (productProfile?.uuid) {
      setFormValues({
        ...productProfile,
        productProfileId:
          productProfile.uuid.replaceAll('-', '') ===
          productProfile.productProfileId
            ? ''
            : productProfile.productProfileId,
      });
    }
  }, [productProfile]);
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  useEffect(() => {
    setFieldsWithError([]);
  }, [productProfile, onSubmit]);

  const { name = '', uuid, productProfileId = '', notes = '' } = formValues;
  const cloneMode = !!productProfile?.uuid;

  const validateAndSubmit = () => {
    const errors: string[] = [];
    ['name'].forEach((property) => {
      if (isEmpty(get(formValues, property))) {
        errors.push(property);
      }
    });
    setFieldsWithError(errors);
    if (errors.length === 0 && onSubmit !== undefined) {
      let dataToSend = formValues;
      if (cloneMode) {
        dataToSend = omit(dataToSend, 'destinationUuid');
      }
      onSubmit(dataToSend);
    }
  };
  const onChange = (property: keyof typeof formValues, value: any) => {
    if (formKeys.includes(property)) {
      setFormValues({
        ...formValues,
        [property]: value,
      });
    }
  };

  return (
    <div id={'ProductProfileForm'}>
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>{productProfile ? 'Clone ' : ''}Product Profile</h3>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="name">
                Name *
              </Label>
              <Input
                id="name"
                invalid={fieldsWithError?.includes('name')}
                value={name}
                onChange={(ev) => onChange('name', ev.target.value)}
                type="text"
                name="name"
                placeholder="Name"
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="productProfileId">
                REST API ID
              </Label>
              <Input
                id="productProfileId"
                invalid={fieldsWithError?.includes('productProfileId')}
                value={productProfileId || ''}
                onChange={(ev) => onChange('productProfileId', ev.target.value)}
                type="text"
                name="productProfileId"
                placeholder="REST API ID"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="notes">
                Notes
              </Label>
              <Input
                id="notes"
                value={notes || ''}
                onChange={(ev) => onChange('notes', ev.target.value)}
                type="textarea"
                name="notes"
                placeholder="Notes"
              />
            </FormGroup>
          </Col>
        </Row>
        {onSubmit !== undefined && (
          <div className="modal-buttons">
            <div className="float-right mt-5 pb-5 d-flex">
              <>
                <span className="mr-2">
                  <Button
                    id={`close-product-profile-form`}
                    outline
                    disabled={isLoading}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    id={`submit-product-profile-form`}
                    outline
                    disabled={isLoading}
                    onClick={validateAndSubmit}
                  >
                    Confirm
                  </Button>
                </span>
              </>
              {isLoading && (
                <Spinner
                  size={'sm'}
                  className={'ml-2 btn-group-vertical'}
                  type={'border'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
