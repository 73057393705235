import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

interface NotFoundProps {
  toPrevious?: boolean;
}

export const NotFound: FC<NotFoundProps> = ({ toPrevious = false }) => {
  const history = useHistory();
  const onNavigate = () => {
    toPrevious ? history.goBack() : history.push('/');
  };
  return (
    <div
      className="NotFound text-center position-absolute align-items-cente align-content-center"
      style={{
        display: 'grid',
        justifyItems: 'center',
        width: 'calc(100% - 1rem)',
        height: 'calc(100% - 1rem)',
      }}
    >
      <h1 className={'mb-2 font-weight-bolder'} style={{ fontSize: '5rem' }}>
        404!
      </h1>
      <h4 className={'mb-5'}>Resource Not Found</h4>
      <Button size={'lg'} color={'info'} onClick={onNavigate}>
        <FontAwesomeIcon icon={faArrowLeft} />{' '}
        {toPrevious ? 'Back To Previous Page' : 'Return To Dashboard'}
      </Button>
    </div>
  );
};
