import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';
import _, { keysIn, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { Certificate } from '../../store/certificates/types';
import { Filter } from '../../components/FilterBar/types';
import ButtonDropdown from '../../components/ButtonDropdown';
import { api } from '../../libs/helpers';
import { sendNotification } from '../../store/notifications/actions';
import { ConfirmationForm } from '../../components';

interface Props {
  ca: Certificate | undefined;
  onClose: Function;
  onSubmit: Function;
}

const KEY_BACKEND_LABELS = {
  utimaco_hsm: 'Utimaco HSM',
  aws_cloud_hsm: 'AWS Cloud HSM',
  aws_kms: 'AWS KMS',
};

type hsmType = keyof typeof KEY_BACKEND_LABELS;
const SwitchKeyBackend: FC<Props> = ({
  ca,
  onClose = (): null => null,
  onSubmit = (): null => null,
}) => {
  const [initialKeyBackend, setInitialKeyBackend] = useState<hsmType>();
  const [currentKeyBackend, setCurrentKeyBackend] = useState<hsmType>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [availableKeyBackends, setAvailableKeyBackends] = useState<string[]>(
    []
  );
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const dispatch = useDispatch();

  const fetchAvailableKeyBackends = async (caId: string) => {
    try {
      const {
        data: {
          key: {
            key_backend: caKeyBackend,
            allowed_key_backends: allowedKeyBackends,
          },
        },
      } = await api().get(`certificate/authority/${caId}`);
      setCurrentKeyBackend(() => {
        setAvailableKeyBackends(allowedKeyBackends);
        setInitialKeyBackend(caKeyBackend);
        return caKeyBackend;
      });
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the CA!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };
  useEffect(() => {
    if (ca?.uuid) {
      fetchAvailableKeyBackends(ca.uuid);
    }
    return () => setCurrentKeyBackend(undefined);
  }, []);
  const onConfirm: MouseEventHandler<HTMLButtonElement> = () => {
    setConfirmModal(true);
  };

  const onConfirmSwitch = () => {
    if (currentKeyBackend && currentKeyBackend !== initialKeyBackend) {
      onSubmit(currentKeyBackend);
    }
  };

  const toggleConfirmModal = () => {
    setConfirmModal((prev) => !prev);
  };

  const justShowClose =
    availableKeyBackends.length <= 1 || !_.includes(ca?.actions, 'create');

  return (
    <div className="SwitchKeyBackend">
      <div className="modal-content p-4">
        <span className="text-center text-muted">
          <h3>Key Backend - {ca?.commonName || ''}</h3>
        </span>
        <span className="description mt-4">
          <p>
            The HSM cluster where the CA key used for signing certificates will
            be sent to. Change only on indication by Service Management. The
            change can be reverted at any time.
          </p>
        </span>
        <div className="mb-3 mt-2" id="SwitchKeyBackendForm">
          <Row>
            <Col md={6} s={12}>
              <p className={'pki-label mb-2'}>Key Backend</p>
              {justShowClose ? (
                <h6 className={'font-weight-bold'}>
                  {KEY_BACKEND_LABELS[currentKeyBackend as hsmType] || ''}
                </h6>
              ) : (
                <ButtonDropdown
                  id={'key-backend-selector'}
                  value={
                    KEY_BACKEND_LABELS[currentKeyBackend as hsmType] ||
                    'Select A Key Backend'
                  }
                  options={map(availableKeyBackends, (keyBackend) => ({
                    key: keyBackend,
                    value: KEY_BACKEND_LABELS[keyBackend as hsmType],
                  }))}
                  idPrefix={'switch-key-backend-options'}
                  disabled={availableKeyBackends.length <= 1}
                  onClick={(selectedOption: Filter): void => {
                    setCurrentKeyBackend(() => {
                      setConfirmDisabled(
                        selectedOption.key === initialKeyBackend
                      );
                      return selectedOption.key as hsmType;
                    });
                  }}
                />
              )}
            </Col>
          </Row>
        </div>

        <div className="mt-3 d-flex">
          <Button
            outline
            className="ml-auto"
            size="sm"
            onClick={(): void => {
              onClose();
            }}
          >
            {justShowClose ? 'Close' : 'Cancel'}
          </Button>
          {!justShowClose && (
            <Button
              outline
              className="ml-3"
              size="sm"
              disabled={confirmDisabled}
              id="confirm-switch-key-backend"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
      <Modal
        className="PKIApp"
        isOpen={confirmModal}
        toggle={toggleConfirmModal}
      >
        <ConfirmationForm
          title="Switch Key Backend"
          message={''}
          content={
            <div className="text-center">
              <p>
                You are about to switch the Key Backend for "
                <strong>{ca?.commonName}</strong>" from{' '}
                <strong>
                  {KEY_BACKEND_LABELS[initialKeyBackend as hsmType]}
                </strong>{' '}
                to{' '}
                <strong>
                  {KEY_BACKEND_LABELS[currentKeyBackend as hsmType]}
                </strong>
                .
              </p>
            </div>
          }
          onCancel={toggleConfirmModal}
          onConfirm={onConfirmSwitch}
        />
      </Modal>
    </div>
  );
};

export default SwitchKeyBackend;
