import {
  Card,
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import _, { kebabCase } from 'lodash';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import { KeycloakTokenParsed } from 'keycloak-js';
import jwtDecode from 'jwt-decode';
import axios, { CancelToken } from 'axios';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../components';
import { GeneralInfo } from './components/GeneralInfo';
import { User } from '../../store/users/types';
import { api, canSeeAsymmetricViews } from '../../libs/helpers';
import { deserializeUser } from '../../store/users/helpers';
import { CertificateAuthorities } from './components/CertificateAuthorities';
import { CertificateProfiles } from './components/CertificateProfiles';
import { ApiClients } from './components/ApiClients';
import { sendNotification } from '../../store/notifications/actions';
import { CodeSigningProfiles } from './components/CodeSigningProfiles';
import { ProductProfiles } from './components/ProductProfiles';
import { Keys } from './components/Keys';

let navMenu: any = {
  CAS: 'CAs',
  CERTIFICATE_PROFILES: 'Certificate Profiles',
  CODE_SIGNING_PROFILES: 'Code Signing Profiles',
  API_CLIENTS: 'API Clients',
};

const UserProfile: FC = () => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState(navMenu.CAS);
  const [userData, setUserData] = useState<User | null>(null);
  const dispatch = useDispatch();
  const tokenData = jwtDecode(
    Cookies.get('id_token') || ''
  ) as KeycloakTokenParsed;
  if (canSeeAsymmetricViews()) {
    navMenu = {
      ...navMenu,
      PRODUCT_PROFILES: 'Product Profiles',
      KEYS: 'Keys',
    };
  }
  const getUserData = async (cancelToken: CancelToken) => {
    try {
      const { data } = await api().get(`user/profile`, { cancelToken });
      const user = deserializeUser(data);
      setUserData(user);
    } catch (e: any) {
      sendNotification({
        text: e.message || 'Something went wrong!',
        success: false,
      })(dispatch);
    }
  };
  useEffect(() => {
    const tokenSource = axios.CancelToken.source().token;
    getUserData(tokenSource);
  }, []);

  const renderNav = (): ReactNode => (
    <Nav tabs className="user-profile-tabs">
      {_.map(Object.values(navMenu), (label: string, index: number) => (
        <NavItem
          id={`nav-${kebabCase(label)}`}
          key={index}
          className="cursor-pointer pki-label"
        >
          <NavLink
            className={classnames({ active: activeTab === label })}
            onClick={(): void => {
              setActiveTab(label);
            }}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  return (
    <div className="UserProfile">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">User Profile</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-3">
            <Row>
              <Col xs={12} md={3} className="border-right">
                <GeneralInfo tokenData={tokenData} user={userData} />
              </Col>
              <Col xs={12} md={9}>
                {renderNav()}
                <TabContent
                  className="pt-3 user-profile-tab-content"
                  activeTab={activeTab}
                >
                  {activeTab === navMenu.CAS && (
                    <TabPane tabId={navMenu.CAS}>
                      <CertificateAuthorities user={userData} />
                    </TabPane>
                  )}
                  {activeTab === navMenu.CERTIFICATE_PROFILES && (
                    <TabPane tabId={navMenu.CERTIFICATE_PROFILES}>
                      <CertificateProfiles user={userData} />
                    </TabPane>
                  )}
                  {activeTab === navMenu.API_CLIENTS && (
                    <TabPane tabId={navMenu.API_CLIENTS}>
                      <ApiClients user={userData} />
                    </TabPane>
                  )}
                  {activeTab === navMenu.CODE_SIGNING_PROFILES && (
                    <TabPane tabId={navMenu.CODE_SIGNING_PROFILES}>
                      <CodeSigningProfiles user={userData} />
                    </TabPane>
                  )}
                  {activeTab === navMenu.PRODUCT_PROFILES && (
                    <TabPane tabId={navMenu.PRODUCT_PROFILES}>
                      <ProductProfiles user={userData} />
                    </TabPane>
                  )}
                  {activeTab === navMenu.KEYS && (
                    <TabPane tabId={navMenu.KEYS}>
                      <Keys user={userData} />
                    </TabPane>
                  )}
                </TabContent>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

export default UserProfile;
