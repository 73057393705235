import _ from 'lodash';
import React, {
  useState,
  FC,
  ReactNode,
  useEffect,
  MouseEvent,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, Card, UncontrolledPopover, Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  TableActions,
  Table,
  Spinner,
  ConfirmationForm,
  FilterBar,
} from '../../../components';
import { ApplicationState } from '../../../store';
import { UserOutput, UsersState } from '../../../store/users/types';
import { api, getUrlWithFilters } from '../../../libs/helpers';
import { Filter } from '../../../components/FilterBar/types';
import { AsymmetricKey, Key } from '../types';
import { deserializeAsymmetricKey } from './helpers';
import { AsymmetricDetailsTable } from './components/AsymmetricDetailsTable/AsymmetricDetailsTable';
import { UploadAsymmetricKeys } from './components/UploadAsymmetricKeys/UploadAsymmetricKeys';
import { AsymmetricKeyForm } from './components/AsymmetricKeyForm/AsymmetricKeyForm';
import { keySpecOptions } from '../../../libs/constants';
import { KeyForm } from '../components/KeyForm/KeyForm';
import { sendNotification } from '../../../store/notifications/actions';

interface AsymmetricKeysToolbarProps {
  onUploadAsymmetricKeyClick?: Function;
}

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'keySpec':
      return 'key_spec';
    case 'updatedAt':
      return 'date_updated';
    case 'updatedBy':
      return 'updated_by_user_uuid';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

const AsymmetricKeysToolbar: FC<AsymmetricKeysToolbarProps> = ({
  onUploadAsymmetricKeyClick = (): null => null,
}) => {
  const {
    userProfile: { resources },
  } = useSelector<ApplicationState, UsersState>((pki) => ({
    ...pki.users,
  }));
  const asymmetricKeysResource = _.find(
    resources,
    (item) => item.name === 'asymmetric_key'
  );
  return (
    <div className="AsymmetricKeysToolbar d-flex">
      <span className="mx-2">
        <Button
          id="upload-asymmetric-key-button"
          onClick={() => onUploadAsymmetricKeyClick()}
          color={'secondary'}
          outline
          disabled={!_.includes(asymmetricKeysResource?.actions, 'create')}
          size={'sm'}
        >
          <>
            <FontAwesomeIcon icon={faPlusCircle} /> Upload Asymmetric Keys
          </>
        </Button>
      </span>
    </div>
  );
};

const AsymmetricKeys: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const asymmetricKeyParamUuid = params.get('uuid');
  const initialQuery: Filter[] = useMemo(
    () =>
      asymmetricKeyParamUuid
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: asymmetricKeyParamUuid,
            },
          ]
        : [],
    [asymmetricKeyParamUuid]
  );

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>(initialQuery);

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [
    showAsymmetricFormModal,
    setShowAsymmetricFormModal,
  ] = useState<boolean>(false);
  const [showKeyFormModal, setShowKeyFormModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [
    currentAsymmetricKey,
    setCurrentAsymmetricKey,
  ] = useState<AsymmetricKey>();
  const [currentKey, setCurrentKey] = useState<Key>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [asymmetricKeysList, setAsymmetricKeysList] = useState<AsymmetricKey[]>(
    []
  );

  const fetchData = async () => {
    try {
      setShowContent(false);
      setShowSpinner(true);
      const urlWithParams = getUrlWithFilters('key/asymmetric', {
        page: tablePage,
        sizePerPage: tableSizePerPage,
        sortBy: tableSortField,
        sortDir: tableSortOrder,
        query,
      });
      const { data: asymmetricKeys } = await api().get(urlWithParams);
      setAsymmetricKeysList(
        asymmetricKeys.map((asymmetricKey: any) =>
          deserializeAsymmetricKey(asymmetricKey)
        )
      );
      setShowContent(true);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong uploading the key files!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setShowSpinner(false);
    }
  };
  useEffect(() => {
    // TODO: Open the current one from uuid in uuid query param
  }, [asymmetricKeyParamUuid]);

  useEffect(() => {
    fetchData();
  }, [tablePage, tableSizePerPage, tableSortOrder, tableSortField, query]);

  const toggleUploadModal = (): void => {
    setShowUploadModal((previousValue) => {
      return !previousValue;
    });
  };

  const toggleAsymmetricKeyFormModal = (): void => {
    setShowAsymmetricFormModal((previousValue) => {
      if (previousValue) {
        setCurrentAsymmetricKey(undefined);
      }
      return !previousValue;
    });
  };
  const toggleKeyFormModal = (): void => {
    setShowKeyFormModal((previousValue) => {
      if (previousValue) {
        setCurrentKey(undefined);
      }
      return !previousValue;
    });
  };
  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentAsymmetricKey(undefined);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const onsubmitAsymmetricKey = async (keyToSet: Partial<AsymmetricKey>) => {
    try {
      if (keyToSet?.uuid) {
        await api().put(`key/asymmetric/${keyToSet.uuid}`, {
          label: keyToSet.label,
          asymmetric_key_id: keyToSet.asymmetricKeyId,
          notes: keyToSet.notes,
        });
        sendNotification({
          text: 'The Asymmetric Key has been updated successfully!',
          success: true,
        })(dispatch);
        setShowAsymmetricFormModal(false);
        await fetchData();
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong editing the Asymmetric Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const onsubmitKey = async (keyToSet: Partial<Key>) => {
    try {
      if (keyToSet?.uuid) {
        await api().put(`key/${keyToSet.uuid}`, {
          label: keyToSet.label,
          key_id: keyToSet.keyId,
          notes: keyToSet.notes,
        });
        sendNotification({
          text: 'The Key has been updated successfully!',
          success: true,
        })(dispatch);
        setShowKeyFormModal(false);
        await fetchData();
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong editing the Asymmetric Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const onKeyDetailsRowClick = (key: Key) => {
    setShowKeyFormModal(() => {
      setFormReadOnly(true);
      setCurrentKey(key);
      return true;
    });
  };

  const onEditActionClick = (key: Key) => {
    setShowKeyFormModal(() => {
      setFormReadOnly(false);
      setCurrentKey(key);
      return true;
    });
  };

  const handleDeleteAsymmetricKey = async (uuid: string) => {
    try {
      if (uuid) {
        await api().delete(`key/asymmetric/${uuid}`);
        sendNotification({
          text: 'The Asymmetric Key has been deleted successfully!',
          success: true,
        })(dispatch);
        toggleDeleteModal();
        await fetchData();
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong deleting the Asymmetric Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const columnsAsymmetricKeys = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'label',
      text: 'Label',
      formatter: (
        label: string,
        { notes, uuid }: { uuid: string; notes: string }
      ): ReactNode => {
        return (
          <>
            <div>{label}</div>
            {!_.isEmpty(notes) && (
              <div
                id={`asymmetric-key-${uuid}`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small>{notes}</small>
                <UncontrolledPopover
                  trigger="hover"
                  target={`asymmetric-key-${uuid}`}
                >
                  <div className="p-2">
                    <small>{notes}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'keySpec',
      text: 'Specification',
      formatter: (keySpec: string) => {
        const option = keySpecOptions.find(
          (keySpecOption) => keySpecOption.key === keySpec
        );
        return option?.value || 'N/A';
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
    },
    {
      dataField: 'createdBy',
      text: 'Created by',
      formatter: (createdBy: UserOutput, current: AsymmetricKey): ReactNode => {
        return createdBy.email;
      },
    },
    {
      dataField: 'noData',
      sort: false,
      text: 'Actions',
      formatter: (noData: string, current: AsymmetricKey): ReactNode => {
        const { actions, uuid } = current;
        const canUpdate = _.includes(actions, 'update');
        const canDelete = _.includes(actions, 'delete');
        return (
          <TableActions
            rowId={String(uuid)}
            options={[
              {
                label: 'Edit',
                disabled: !canUpdate,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canUpdate) {
                    const selected = _.find(
                      asymmetricKeysList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentAsymmetricKey(selected);
                      setFormReadOnly(false);
                      toggleAsymmetricKeyFormModal();
                    }
                  }
                },
              },
              {
                label: 'Delete',
                disabled: !canDelete,
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canDelete) {
                    const selected = _.find(
                      asymmetricKeysList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentAsymmetricKey(selected);
                      toggleDeleteModal();
                    }
                  }
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div className="AsymmetricKeys">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Asymmetric Keys</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'label',
                  label: 'Label',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'asymmetric_key_id',
                  label: 'Key ID',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'notes',
                  label: 'Notes',
                  type: 'like',
                  operators: ['~'],
                },
                // {
                //   key: 'key_spec',
                //   label: 'Specification',
                //   type: 'filter',
                //   options: keySpecOptions
                //     .filter(({ key }) => key !== undefined)
                //     .map(({ value: label, key: value }) => ({
                //       value: value || '',
                //       label: label || '',
                //     })),
                // },
                {
                  key: 'created_by_user_uuid',
                  label: 'Created By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'created_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'updated_by_user_uuid',
                  label: 'Updated By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    searchPlaceholder: 'Type username...',
                    fetchUrl: '/user',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'updated_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Created Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Modified Date',
                  type: 'filter',
                },
              ]}
              initialFilters={initialQuery}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            <Table
              striped={false}
              expandable={true}
              id={'asymmetric-keys-table'}
              ExpandedComponent={({ row }) => {
                return (
                  <AsymmetricDetailsTable
                    asymmetricKey={row}
                    onRowClick={onKeyDetailsRowClick}
                    onEditActionClick={onEditActionClick}
                  />
                );
              }}
              remote={true}
              search={false}
              pagination={{
                page: tablePage,
                sizePerPage: tableSizePerPage,
              }}
              keyField="uuid"
              sort={{
                dataField: tableSortField,
                order: tableSortOrder as SortOrder,
              }}
              noDataIndication={
                tablePage > 1
                  ? 'No more Asymmetric Keys available'
                  : 'No Asymmetric Key available'
              }
              data={asymmetricKeysList}
              columns={columnsAsymmetricKeys}
              onTableChange={(
                valueNotUsed: null,
                {
                  page,
                  sizePerPage,
                  sortOrder,
                  sortField,
                }: {
                  page: number;
                  sizePerPage: number;
                  sortOrder: string;
                  sortField: string;
                }
              ): void => {
                setTablePage(page);
                setTableSizePerPage(sizePerPage);
                setTableSortOrder(sortOrder);
                setTableSortField(mapSortField(sortField));
              }}
              rowEvents={{
                onClick: (notUsedValue: null, current: AsymmetricKey): void => {
                  setCurrentAsymmetricKey(current);
                  setFormReadOnly(true);
                  setShowAsymmetricFormModal(true);
                },
              }}
              toolbar={
                <AsymmetricKeysToolbar
                  onUploadAsymmetricKeyClick={(key: string): void => {
                    toggleUploadModal();
                  }}
                />
              }
            />
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={showAsymmetricFormModal}
        toggle={toggleAsymmetricKeyFormModal}
      >
        <AsymmetricKeyForm
          readOnly={formReadOnly}
          asymmetricKey={currentAsymmetricKey}
          onModeChange={() => setFormReadOnly((prevState) => !prevState)}
          onSubmit={onsubmitAsymmetricKey}
          onCancel={() => setShowAsymmetricFormModal(false)}
        />
      </Modal>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={showKeyFormModal}
        toggle={toggleKeyFormModal}
      >
        <KeyForm
          readOnly={formReadOnly}
          keyObject={currentKey}
          onModeChange={() => setFormReadOnly((prevState) => !prevState)}
          onSubmit={onsubmitKey}
          onCancel={() => setShowKeyFormModal(false)}
        />
      </Modal>
      <Modal
        className="PKIApp"
        centered
        style={{ width: '1200px', maxWidth: '100%' }}
        isOpen={showUploadModal}
        toggle={toggleUploadModal}
      >
        <UploadAsymmetricKeys
          onCancel={() => setShowUploadModal(false)}
          fetchDataTable={fetchData}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title="Delete Asymmetric Key Confirmation"
          message={''}
          challenge={{
            value: currentAsymmetricKey?.label || '',
            label: 'Please type in the Asymmetric Key label',
          }}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>delete</strong> Asymmetric Key "
                <strong>{currentAsymmetricKey?.label}</strong>"
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={async (): Promise<void> => {
            if (currentAsymmetricKey?.uuid) {
              await handleDeleteAsymmetricKey(currentAsymmetricKey.uuid);
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default AsymmetricKeys;
