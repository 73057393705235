import React, { FC, useState, ReactNode } from 'react';
import {
  Button,
  ButtonDropdown as BootstrapButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import _ from 'lodash';

interface ButtonDropdownI {
  id?: string;
  className?: string;
  value?: string | number | ReactNode | Function;
  options: {
    key: string | number;
    value: string | number | ReactNode;
    disabled?: boolean;
  }[];
  onChange?: Function;
  onClick?: Function;
  disabled?: boolean;
  idPrefix?: string;
  size?: string;
}

const ButtonDropdown: FC<ButtonDropdownI> = ({
  onChange = (): null => null,
  onClick = (): null => null,
  id,
  options = [],
  value = '',
  idPrefix,
  className,
  disabled = false,
  size = 'sm',
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = (): void => {
    setIsOpen((current) => !current);
  };

  if (disabled) {
    return (
      <div id={id} className="ButtonDropdown">
        <Button className={className} disabled={true} size={size} outline>
          {value || children}
        </Button>
      </div>
    );
  }

  return (
    <div className="ButtonDropdown">
      <BootstrapButtonDropdown
        id={id}
        onClick={(e: React.MouseEvent<HTMLElement>): void => {
          e.stopPropagation();
        }}
        className={className}
        onChange={(selectedValue): void => {
          onChange(selectedValue);
        }}
        isOpen={isOpen}
        toggle={toggle}
      >
        <DropdownToggle outline caret size={size}>
          {value || children}
        </DropdownToggle>
        <DropdownMenu>
          {_.map(options, (option, index) => (
            <DropdownItem
              disabled={option?.disabled}
              id={`${idPrefix ? `${idPrefix}-` : ''}${String(option.key)}`}
              onClick={(): void => {
                if (!option?.disabled) {
                  onClick(option);
                }
              }}
              key={option.key}
              value={option.key}
            >
              {option.value}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </BootstrapButtonDropdown>
    </div>
  );
};

export default ButtonDropdown;
