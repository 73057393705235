import { Card, UncontrolledPopover } from 'reactstrap';
import React, { FC, useEffect, useState, useLayoutEffect } from 'react';
import _, { each, includes } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import GridLayout, { Layout } from 'react-grid-layout';
import { LayoutState } from '../../store/layout/types';
import { ApplicationState } from '../../store/index';
import { UsersState } from '../../store/users/types';
import { setUserSettings, getUserProfile } from '../../store/users/actions';
import {
  AwaitingRequest,
  IssuedCertificates,
  ExpiringCertificates,
  RequestsStatus,
  LoginStatistics,
  HSMStatus,
} from './Widget';
import { useWindowSize } from '../../libs/helpers';

const defaultDashboardConfig = [
  { i: 'HSMStatus', x: 0, y: 0, w: 6, h: 3 },
  { i: 'AwaitingRequest', x: 0, y: 0, w: 6, h: 9 },
  { i: 'LoginStatistics', x: 6, y: 0, w: 6, h: 9 },
  { i: 'RequestsStatus', x: 6, y: 0, w: 6, h: 9 },
  { i: 'ExpiringCertificates', x: 0, y: 9, w: 6, h: 9 },
  { i: 'IssuedCertificates', x: 6, y: 9, w: 6, h: 9 },
];

const Overview: FC = () => {
  const [dashboardLocked, setDashboardLocked] = useState<boolean>(true);
  const [width] = useWindowSize();
  const [dashboardConfig, setDashboardConfig] = useState<Layout[]>(
    defaultDashboardConfig
  );

  const {
    isSidebarOpen,
    isSettingUserSettings,
    userProfile: { settings },
  } = useSelector<ApplicationState, LayoutState & UsersState>((pki) => ({
    ...pki.layout,
    ...pki.users,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const dashboardConfigSetting = _.find(
      settings,
      (item) => item.attribute === 'Dasboard Config'
    );
    if (dashboardConfigSetting?.value) {
      const currentDashboardConfigSavedParsed = JSON.parse(
        dashboardConfigSetting?.value
      );
      each(defaultDashboardConfig, (item) => {
        if (!includes(currentDashboardConfigSavedParsed, item.i)) {
          currentDashboardConfigSavedParsed.push(item);
        }
      });
      setDashboardConfig(currentDashboardConfigSavedParsed);
    }
  }, [settings]);

  useEffect(() => {
    if (!isSettingUserSettings) {
      dispatch(getUserProfile());
    }
  }, [dispatch, isSettingUserSettings]);

  const layoutWidth = isSidebarOpen ? width - 260 : width - 30;

  const currentDashboardConfig = _.map(
    dashboardConfig,
    ({ i, x, y, w, h }) => ({
      i,
      x,
      y,
      w,
      h,
      static: dashboardLocked,
    })
  );

  return (
    <div className="Overview">
      <div className="mr-3 text-right">
        <span
          id="dashboard-locked-unlocked"
          onClick={(): void => {
            if (!dashboardLocked) {
              const newSettings = _.filter(
                settings,
                (item) => item.attribute === 'Dasboard Config'
              );
              dispatch(
                setUserSettings([
                  ...newSettings,
                  {
                    attribute: 'Dasboard Config',
                    value: JSON.stringify(dashboardConfig),
                  },
                ])
              );
            }
            setDashboardLocked((prev) => !prev);
          }}
        >
          <FontAwesomeIcon
            className="pki-ico"
            icon={dashboardLocked ? faLock : faUnlock}
          />
          <UncontrolledPopover
            trigger="hover"
            target="dashboard-locked-unlocked"
          >
            <div className="p-1">
              <small>
                {dashboardLocked
                  ? 'Unlock Dashboard Configuration'
                  : 'Lock Dashboard Configuration'}
              </small>
            </div>
          </UncontrolledPopover>
        </span>
      </div>
      <GridLayout
        className="layout"
        layout={currentDashboardConfig}
        onLayoutChange={(newDashboardConfig: Layout[]): void => {
          setDashboardConfig(newDashboardConfig);
        }}
        cols={12}
        rowHeight={30}
        width={layoutWidth}
      >
        <Card key="HSMStatus" className="widget rounded p-3">
          <HSMStatus />
        </Card>
        <Card key="AwaitingRequest" className="widget rounded p-3">
          <AwaitingRequest />
        </Card>
        <Card key="LoginStatistics" className="widget rounded p-3">
          <LoginStatistics />
        </Card>
        <Card key="RequestsStatus" className="widget rounded p-3">
          <RequestsStatus />
        </Card>
        <Card key="ExpiringCertificates" className="widget rounded p-3">
          <ExpiringCertificates />
        </Card>
        <Card key="IssuedCertificates" className="widget rounded p-3">
          <IssuedCertificates />
        </Card>
      </GridLayout>
    </div>
  );
};

export default Overview;
