import React, { FC, useEffect } from 'react';
import { Badge, Col, Label, Row } from 'reactstrap';
import { startCase } from 'lodash';
import { KeycloakTokenParsed } from 'keycloak-js';
import { useDispatch } from 'react-redux';
import { api } from '../../../libs/helpers';
import { LabelValue } from '../../../components/LabelValue/LabelValue';
import { User } from '../../../store/users/types';
import { sendNotification } from '../../../store/notifications/actions';
import { mapRoleToValue } from '../../../store/users/helpers';

interface GeneralInfoProps {
  user: User | null | undefined;
  tokenData: KeycloakTokenParsed;
}

export const GeneralInfo: FC<GeneralInfoProps> = ({ user, tokenData }) => {
  const dispatch = useDispatch();
  const fetchGeneralInfo = async () => {
    try {
      const { data: cas } = await api().get(`certificate/authority`);
    } catch (e: any) {
      sendNotification({
        text: e.message || 'Something went wrong!',
        success: false,
      })(dispatch);
    }
  };
  useEffect(() => {
    fetchGeneralInfo();
  }, []);

  const renderAccountTypeBadge = () => {
    return (
      (user?.email.endsWith('@irdeto.com') ||
        user?.email.endsWith('@local.com')) && (
        <h5>
          <Badge color={'primary'} className="p-2">
            Irdeto Employee
          </Badge>
        </h5>
      )
    );
  };
  return (
    <>
      {user && (
        <div className="user-profile-general-info">
          <Row>
            <Col xs={12}>{renderAccountTypeBadge()}</Col>
            <Col xs={12}>
              <LabelValue value={user.firstName} label={'First Name'} />
            </Col>
            <Col xs={12}>
              <LabelValue value={user.lastName} label={'Last Name'} />
            </Col>
            <Col xs={12}>
              <LabelValue value={user.username} label={'Username'} />
            </Col>
            <Col xs={12}>
              <LabelValue value={user.email} label={'Email'} />
            </Col>
            <Col xs={12}>
              <LabelValue value={user.uuid} label={'User ID'} copyable />
            </Col>
            {tokenData.resource_access?.kcps?.roles && (
              <Col xs={12}>
                <div className="label-value mb-3">
                  <Label className="pki-label d-block mb-0">Role(s)</Label>
                  {tokenData.resource_access.kcps.roles.map((role) => (
                    <span key={role} className="label-value-value">
                      <Badge color={'primary'} className={'p-2 mt-1'}>
                        {mapRoleToValue?.[role] ?? startCase(role)}
                      </Badge>
                    </span>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
