import React, { FC, useRef, useState } from 'react';
import { Button, Collapse, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { set } from 'lodash';
import { Toggle } from '../../components';

interface Props {
  onClose: Function;
  onUpload: Function;
}

enum UploadType {
  CA = 'CA',
  KEY = 'KEY',
  BUNDLE = 'BUNDLE',
}

const UploadCA: FC<Props> = ({
  onClose = (): null => null,
  onUpload = (): null => null,
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [inputCAFile, setInputCAFile] = useState<File>();
  const [inputBundleFile, setInputBundleFile] = useState<File>();
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [inputKeyFile, setInputKeyFile] = useState<File>();
  const [uploadType, setUploadType] = useState<UploadType>();
  const [uploadCaBundleFile, setUploadCaBundleFile] = useState<boolean>(false);

  return (
    <div className="UploadCA">
      <div className="modal-content p-4">
        <span className="text-center text-muted">
          <h3>Upload CA</h3>
        </span>
        <span className="description mt-4">
          <p>
            Uploading CAs in the top-down order of the PKI tree is not required.
            However, to ensure a complete PKI tree, all available CAs of the
            tree need to be uploaded.
          </p>
        </span>
        <div>
          <FormGroup>
            <Label style={{ display: 'flex' }}>
              <Toggle
                id="select-ca-bundle-file-toggle"
                onChange={(checked: boolean): void => {
                  setUploadCaBundleFile(() => {
                    set(inputEl, 'current.value', null);
                    setShowValidation(false);
                    return checked;
                  });
                }}
                checked={uploadCaBundleFile}
              />
              <span className="ml-2">Bundle (.tar.gz)</span>
            </Label>
          </FormGroup>
        </div>
        <div className="mb-3 mt-2">
          <Collapse isOpen={!uploadCaBundleFile}>
            <div id="upload-certificate-and-key">
              <p>
                Select CA certificate file and (optionally) the corresponding
                key file
              </p>
              <div className="upload-ca">
                <Button
                  outline
                  size="sm"
                  onClick={(): void => {
                    setUploadType(UploadType.CA);
                    if (inputEl?.current) {
                      set(inputEl, 'current.accept', '.pem, .der');
                      inputEl.current.click();
                    }
                  }}
                >
                  Select Certificate <small>( .pem or .der )</small>
                </Button>
                {!inputCAFile?.name && (
                  <p className="text-muted mt-1">No Certificate selected</p>
                )}
                {inputCAFile && (
                  <div className="mt-1 ml-auto">
                    {inputCAFile.name}{' '}
                    {
                      <FontAwesomeIcon
                        onClick={(): void => {
                          setInputCAFile(undefined);
                        }}
                        className="pki-ico ml-2"
                        icon={faTrash}
                      />
                    }
                  </div>
                )}
              </div>
              <div className="upload-key mt-4">
                <Button
                  outline
                  size="sm"
                  onClick={(): void => {
                    setUploadType(UploadType.KEY);
                    if (inputEl?.current) {
                      set(inputEl, 'current.accept', '.cpk, .wrp');
                      inputEl.current.click();
                    }
                  }}
                >
                  Select Key <small>( .cpk or .wrp )</small>
                </Button>
                {!inputKeyFile?.name && (
                  <p className="text-muted mt-1">No Key selected</p>
                )}
                {inputKeyFile && (
                  <div className="mt-1 ml-auto">
                    {inputKeyFile.name}
                    <FontAwesomeIcon
                      onClick={(): void => {
                        setInputKeyFile(undefined);
                      }}
                      className="pki-ico ml-2"
                      icon={faTrash}
                    />
                  </div>
                )}
                <p className="mt-2">
                  If you do not specify a Key to upload, the CA will be marked
                  as offline.
                </p>
              </div>
              {!inputCAFile && !uploadCaBundleFile && showValidation && (
                <div className="mt-2" id="upload-certificate-and-key-invalid">
                  <span className="invalid-text">
                    Certificate cannot be empty, please select one!
                  </span>
                </div>
              )}
            </div>
          </Collapse>
          <Collapse isOpen={uploadCaBundleFile}>
            <div id="upload-bundle-file">
              <p>
                Select TAR file containing the CA certificate, key and metadata
              </p>
              <Button
                outline
                size="sm"
                onClick={(): void => {
                  setUploadType(UploadType.BUNDLE);
                  if (inputEl?.current) {
                    set(
                      inputEl,
                      'current.accept',
                      'application/gzip, .tar.gz, .gz'
                    );
                    inputEl.current.click();
                  }
                }}
              >
                Select TAR File <small>( .tar.gz )</small>
              </Button>
              {!inputBundleFile?.name && (
                <p className="text-muted mt-1">No TAR file selected</p>
              )}
              {inputBundleFile && (
                <div className="mt-1 ml-auto">
                  {inputBundleFile.name}
                  <FontAwesomeIcon
                    onClick={(): void => {
                      setInputBundleFile(undefined);
                    }}
                    className="pki-ico ml-2"
                    icon={faTrash}
                  />
                </div>
              )}
            </div>
            {!inputBundleFile && uploadCaBundleFile && showValidation && (
              <div className="mt-2" id="upload-bundle-invalid">
                <span className="invalid-text">
                  Bundle cannot be empty, please select one!
                </span>
              </div>
            )}
          </Collapse>
        </div>

        <div className="mt-3 d-flex">
          <Button
            outline
            className="ml-auto"
            size="sm"
            onClick={(): void => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            className="ml-3"
            size="sm"
            onClick={(): void => {
              setShowValidation(true);
              if (uploadCaBundleFile) {
                if (inputBundleFile) {
                  onUpload(uploadCaBundleFile, {
                    bundle: inputBundleFile,
                  });
                }
              } else if (inputCAFile) {
                onUpload(uploadCaBundleFile, {
                  ca: inputCAFile,
                  key: inputKeyFile,
                });
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
      <input
        type="file"
        id="file-input"
        className="d-none"
        ref={inputEl}
        onChange={({ target: { files } }): void => {
          if (files && files.length > 0) {
            if (uploadType === UploadType.CA) {
              setInputCAFile(files[0]);
            }
            if (uploadType === UploadType.KEY) {
              setInputKeyFile(files[0]);
            }
            if (uploadType === UploadType.BUNDLE) {
              setInputBundleFile(files[0]);
            }
            set(inputEl, 'current.value', null);
          }
        }}
      />
    </div>
  );
};

export default UploadCA;
