import { Reducer } from 'redux';
import {
  CertificateProfilesState,
  CertificateProfileActionTypes,
} from './types';

export const initialState: CertificateProfilesState = {
  allCertificateProfileList: [],
  certificateProfileList: [],
  provisioningCertificateProfileList: [],
  provisioningCertificateProfilesListErrors: undefined,
  certificateProfilesListErrors: undefined,
  allCertificateProfilesListErrors: undefined,
  isGettingAllCertificateProfileList: false,
  isGettingProvisioningCertificateProfileList: false,
  isGettingCertificateProfileList: false,
  isLoadedAllCertificateProfileList: false,
  isLoadedProvisioningCertificateProfileList: false,
  isLoadedCertificateProfileList: false,
  isCreatingProfile: false,
  isArchivingProfile: false,
  isEditingProfile: false,
  isDeletingProfile: false,
  profileKeySpecs: {
    keySpec: [],
    signatureAlgorithm: [],
    signatureHashAlgorithm: [],
  },
  isGettingProfileKeySpecs: false,
  isLoadedProfileKeySpecs: false,
};

const reducer: Reducer<CertificateProfilesState> = (
  state = initialState,
  action
): CertificateProfilesState => {
  switch (action.type) {
    case CertificateProfileActionTypes.GET_PROFILE_LIST_REQUEST: {
      return {
        ...state,
        isGettingCertificateProfileList: true,
        isLoadedCertificateProfileList: false,
        certificateProfilesListErrors: undefined,
      };
    }
    case CertificateProfileActionTypes.GET_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCertificateProfileList: false,
        isLoadedCertificateProfileList: true,
        certificateProfileList: action.payload,
      };
    }
    case CertificateProfileActionTypes.GET_PROFILE_LIST_ERROR: {
      return {
        ...state,
        isGettingCertificateProfileList: false,
        isLoadedCertificateProfileList: false,
        certificateProfilesListErrors: action.payload,
      };
    }

    case CertificateProfileActionTypes.GET_ALL_PROFILE_LIST_REQUEST: {
      return {
        ...state,
        isGettingAllCertificateProfileList: true,
        isLoadedAllCertificateProfileList: false,
        allCertificateProfilesListErrors: undefined,
      };
    }
    case CertificateProfileActionTypes.GET_ALL_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingAllCertificateProfileList: false,
        isLoadedAllCertificateProfileList: true,
        allCertificateProfileList: action.payload,
      };
    }
    case CertificateProfileActionTypes.GET_ALL_PROFILE_LIST_ERROR: {
      return {
        ...state,
        isGettingAllCertificateProfileList: false,
        isLoadedAllCertificateProfileList: false,
        allCertificateProfilesListErrors: action.payload,
      };
    }

    case CertificateProfileActionTypes.GET_PROVISIONING_PROFILE_LIST_REQUEST: {
      return {
        ...state,
        isGettingProvisioningCertificateProfileList: true,
        isLoadedProvisioningCertificateProfileList: false,
        provisioningCertificateProfilesListErrors: undefined,
      };
    }
    case CertificateProfileActionTypes.GET_PROVISIONING_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingProvisioningCertificateProfileList: false,
        isLoadedProvisioningCertificateProfileList: true,
        provisioningCertificateProfileList: action.payload,
      };
    }
    case CertificateProfileActionTypes.GET_PROVISIONING_PROFILE_LIST_ERROR: {
      return {
        ...state,
        isGettingProvisioningCertificateProfileList: false,
        isLoadedProvisioningCertificateProfileList: false,
        provisioningCertificateProfilesListErrors: action.payload,
      };
    }

    case CertificateProfileActionTypes.GET_PROFILE_KEY_SPECS_REQUEST: {
      return {
        ...state,
        isGettingProfileKeySpecs: true,
        isLoadedProfileKeySpecs: false,
      };
    }
    case CertificateProfileActionTypes.GET_PROFILE_KEY_SPECS_SUCCESS: {
      return {
        ...state,
        isGettingProfileKeySpecs: false,
        isLoadedProfileKeySpecs: true,
        profileKeySpecs: action.payload,
      };
    }
    case CertificateProfileActionTypes.GET_PROFILE_KEY_SPECS_ERROR: {
      return {
        ...state,
        isGettingProfileKeySpecs: false,
        isLoadedProfileKeySpecs: false,
      };
    }

    case CertificateProfileActionTypes.POST_PROFILE_REQUEST: {
      return { ...state, isCreatingProfile: true };
    }
    case CertificateProfileActionTypes.POST_PROFILE_SUCCESS: {
      return { ...state, isCreatingProfile: false };
    }
    case CertificateProfileActionTypes.POST_PROFILE_ERROR: {
      return {
        ...state,
        isCreatingProfile: false,
      };
    }

    case CertificateProfileActionTypes.PUT_PROFILE_REQUEST: {
      return { ...state, isEditingProfile: true };
    }
    case CertificateProfileActionTypes.PUT_PROFILE_SUCCESS: {
      return { ...state, isEditingProfile: false };
    }
    case CertificateProfileActionTypes.PUT_PROFILE_ERROR: {
      return {
        ...state,
        isEditingProfile: false,
      };
    }

    case CertificateProfileActionTypes.DELETE_PROFILE_REQUEST: {
      return { ...state, isDeletingProfile: true };
    }
    case CertificateProfileActionTypes.DELETE_PROFILE_SUCCESS: {
      return { ...state, isDeletingProfile: false };
    }
    case CertificateProfileActionTypes.DELETE_PROFILE_ERROR: {
      return {
        ...state,
        isDeletingProfile: false,
      };
    }

    case CertificateProfileActionTypes.ARCHIVE_PROFILE_REQUEST: {
      return { ...state, isArchivingProfile: true };
    }
    case CertificateProfileActionTypes.ARCHIVE_PROFILE_SUCCESS: {
      return { ...state, isArchivingProfile: false };
    }
    case CertificateProfileActionTypes.ARCHIVE_PROFILE_ERROR: {
      return {
        ...state,
        isArchivingProfile: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as certificateProfilesReducer };
