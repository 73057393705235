import _, { filter, find, forEach, map } from 'lodash';
import { CertificateProfile } from '../../store/certificateProfiles/types';
import { Certificate } from '../../store/certificates/types';
import { ClientEntitlement } from '../../store/clients/types';
import { User } from '../../store/users/types';

export const subscriberRoles = [
  { key: 'ca_admin', value: 'CA Administrator' },
  { key: 'approver', value: 'Approver' },
  { key: 'pki_subscriber', value: 'PKI Subscriber' },
];

export const ownerRoles = [{ key: 'client_owner', value: 'API Client Owner' }];

type TableData = {
  uuid: string;
  name?: string;
  commonName?: string;
  roles: string[];
  actions?: string[];
};

export const getOwnersByClient = (
  users: User[],
  serviceAccountUuid: string
): TableData[] =>
  _(users)
    .filter((user) => {
      const { entityRoles } = user;
      const isOwner = !!find(
        entityRoles,
        (entityRole) =>
          entityRole.role === 'client_owner' &&
          entityRole.entityUuid === serviceAccountUuid
      );
      return isOwner;
    })
    .map((user) => ({
      uuid: user.uuid,
      name: user.username,
      roles: ['client_owner'],
    }))
    .value();

export const getCertificateProfilesByClient = (
  certificateProfiles: CertificateProfile[],
  clientEntitlements: ClientEntitlement[]
): TableData[] => {
  const output: TableData[] = [];
  const avalableProfilesUuid = _(clientEntitlements)
    .filter((item) => item.entityType === 'certificate_profile')
    .map((item) => item.entityUuid)
    .uniq()
    .value();
  forEach(avalableProfilesUuid, (profileUuid) => {
    const entitlemes = filter(
      clientEntitlements,
      (entitlement) => entitlement.entityUuid === profileUuid
    );
    const profileName = find(
      certificateProfiles,
      (profile) => profile.uuid === profileUuid
    )?.name;
    // if undefined I'm not entitled to work with this certificate profile
    if (profileName) {
      output.push({
        uuid: profileUuid,
        name: profileName,
        roles: map(entitlemes, (entitlemet) => entitlemet.role),
      });
    }
  });
  return output;
};

export const getIssuingCAsByClient = (
  issuingCAs: Certificate[],
  clientEntitlements: ClientEntitlement[]
): TableData[] => {
  const output: TableData[] = [];
  const availableIssuingCAUuids = _(clientEntitlements)
    .filter((item) => item.entityType === 'issuing_ca')
    .map((item) => item.entityUuid)
    .uniq()
    .value();

  forEach(availableIssuingCAUuids, (caUuid) => {
    const entitlemes = filter(
      clientEntitlements,
      (entitlement) => entitlement.entityUuid === caUuid
    );
    const issungCA = find(issuingCAs, (ca) => ca.uuid === caUuid);
    // if undefined I'm not entitled to work with this certificate profile
    if (issungCA) {
      output.push({
        uuid: caUuid,
        actions: issungCA.actions,
        commonName: issungCA.commonName,
        roles: map(entitlemes, (entitlemet) => entitlemet.role),
      });
    }
  });
  return output;
};
