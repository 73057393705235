import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Collapse, Input, Modal, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheckCircle,
  faDownload,
  faEdit,
  faExclamation,
  faExternalLink,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import DeviceBatchRequestForm from './DeviceBatchRequestForm';

import {
  ConfirmationForm,
  ErrorHandler,
  FilterBar,
  Spinner,
  Table,
  TableActions,
} from '../../components';
import { Request, RequestType } from '../../store/requests/types';
import { sendNotification } from '../../store/notifications/actions';
import { ApplicationState } from '../../store';
import { UserOutput, UsersState } from '../../store/users/types';
import { api, getUrlWithFilters } from '../../libs/helpers';
import RequestsToolbar from '../ViewsComponents/RequestsToolbar';
import { Filter } from '../../components/FilterBar/types';
import './DeviceBatchRequests.scss';
import { deserializeDeviceBatchRequest } from './helpers';
import { DeviceBatchRequest } from './types';
import { getVars } from '../../libs/provisioning';

const { saveAs } = require('file-saver');

enum confirmationType {
  Approve = 'Approve',
  Reject = 'Reject',
  Cancel = 'Cancel',
}

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'createdAt':
      return 'date_created';
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    case 'updatedBy':
      return 'updated_by_user_uuid';
    case 'productProfileName':
      // we don't have something like product_profile_name or
      // product_profile.name in DeviceRequest model in the backend.
      // So use product_profile_json as a workaround
      // since it has the same effect as sorting by name.
      return 'product_profile_json';
    default:
      return sortField;
  }
};

const DeviceBatchRequests: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    userProfile: { resources },
  } = useSelector<ApplicationState, UsersState>((pki) => pki.users);

  const [deviceBatchRequests, setDeviceBatchRequests] = useState<any[]>([]);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [formModal, setFormModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [editRequestReason, setEditRequestReason] = useState<string>('');
  const [requestForm, setRequestForm] = useState<DeviceBatchRequest>();
  const [
    confirmationFormType,
    setConfirmationFormType,
  ] = useState<confirmationType>();
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const requestUuidParam = params.get('uuid');
  const { PKI_SELECTED_CUSTOMER_CODE: currentCustomerCode } = getVars();

  const initialQuery: Filter[] = useMemo(
    () =>
      requestUuidParam
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: requestUuidParam,
            },
          ]
        : [],
    [requestUuidParam]
  );
  const [query, setQuery] = useState<Filter[]>(initialQuery);

  const viewLocation = location.pathname;

  useEffect(() => {
    if (!isLoading) {
      setShowContent(true);
    }
    return function cleanup(): void {
      if (location.pathname !== viewLocation) {
        setShowContent(false);
      }
    };
  }, [location.pathname, viewLocation]);

  // Add fetch device batch requests method
  const fetchDeviceRequests = async () => {
    try {
      setShowContent(false);
      setIsLoading(true);
      const urlWithParams = getUrlWithFilters('device/request', {
        page: tablePage,
        sizePerPage: tableSizePerPage,
        sortBy: tableSortField,
        sortDir: tableSortOrder,
        query,
      });
      const { data: deviceRequests } = await api().get(urlWithParams);
      setDeviceBatchRequests(
        deviceRequests.map((deviceRequest: any) =>
          deserializeDeviceBatchRequest(deviceRequest)
        )
      );
      setShowContent(true);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Device Batch Requests!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    fetchDeviceRequests();
    return function cleanup(): void {
      tokenSource.cancel(`DeviceBatchRequest::getDeviceBatchRequests`);
    };
  }, [
    dispatch,
    query,
    requestUuidParam,
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
  ]);

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setRequestForm(undefined);
        setConfirmationFormType(undefined);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const columns = [
    {
      dataField: 'noData1',
      text: '',
      formatter: (
        valueNotImportant: null,
        currentRequest: Request
        // eslint-disable-next-line consistent-return
      ): ReactNode | void => {
        const { actions, status } = currentRequest;
        if (status === 'Awaiting Approval' && _.includes(actions, 'approve')) {
          return (
            <div id={`alert-${currentRequest.uuid}`}>
              <FontAwesomeIcon className="pki-ico" icon={faExclamation} />
              <UncontrolledPopover
                trigger="hover"
                target={`alert-${currentRequest.uuid}`}
              >
                <div className="p-1">
                  <small>This request requires your action</small>
                </div>
              </UncontrolledPopover>
            </div>
          );
        }
      },
    },
    {
      dataField: 'uuid',
      text: 'Request ID',
      formatter: (uuid: string, { notes }: DeviceBatchRequest): ReactNode => {
        return (
          <>
            <div>{uuid.toUpperCase()}</div>
            {!_.isEmpty(notes) && (
              <div
                id={`request-${uuid}`}
                style={{ maxWidth: '600px' }}
                className="d-inline-block text-truncate"
              >
                <small> {notes}</small>
                <UncontrolledPopover trigger="hover" target={`request-${uuid}`}>
                  <div className="p-2">
                    <small>{notes}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'productProfileName',
      text: 'Product Profile',
    },
    {
      dataField: 'quantity',
      style: { textAlign: 'right' },
      text: 'Quantity',
      formatter: (quantity: number) => {
        return quantity.toLocaleString('en-US');
      },
    },
    { dataField: 'status', text: 'Status' },
    {
      dataField: 'createdAt',
      text: 'Request Date',
    },
    {
      dataField: 'createdBy',
      text: 'Requested by',
      formatter: (
        createdBy: UserOutput,
        currentRequest: DeviceBatchRequest
      ): ReactNode => {
        return createdBy.email;
      },
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      style: { width: '100px' },
      formatter: (
        valueNotImportant: null,
        currentRequest: DeviceBatchRequest
      ): ReactNode => {
        const { actions } = currentRequest;
        const canApprove = _.includes(actions, 'approve');
        const canReject = _.includes(actions, 'reject');
        const canDownloadBatch = _.includes(actions, 'download_batch');
        const canCancel = _.includes(actions, 'cancel');

        const options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        if (currentRequest.status === 'Awaiting Approval') {
          options.push(
            {
              label: 'Approve',
              disabled: !canApprove,
              ico: <FontAwesomeIcon className="pki-ico" icon={faCheckCircle} />,
              onClick: (e: MouseEvent): void => {
                e.stopPropagation();
                if (canApprove) {
                  setConfirmationFormType(confirmationType.Approve);
                  setRequestForm(currentRequest);
                  setFormModal(true);
                }
              },
            },
            {
              label: 'Reject',
              disabled: !canReject,
              ico: <FontAwesomeIcon className="pki-ico" icon={faTimesCircle} />,
              onClick: (e: MouseEvent): void => {
                e.stopPropagation();
                if (canReject) {
                  setConfirmationFormType(confirmationType.Reject);
                  setRequestForm(currentRequest);
                  setFormModal(true);
                }
              },
            }
          );
        }

        if (currentRequest.status === 'In Progress') {
          options.push({
            label: 'Cancel',
            disabled: !canCancel,
            ico: <FontAwesomeIcon className="pki-ico" icon={faBan} />,
            onClick: (e: MouseEvent): void => {
              e.stopPropagation();
              if (canCancel) {
                setConfirmationFormType(confirmationType.Cancel);
                setRequestForm(currentRequest);
                setFormModal(true);
              }
            },
          });
        }

        options.push({
          label: 'Related Product Profile',
          ico: <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />,
          hidden: false,
          onClick: () => {
            history.push(
              `/management/product-profiles/${currentRequest.productProfileUuid}`
            );
          },
        });

        if (_.includes(['Completed'], currentRequest.status)) {
          options.push({
            label: 'Download Batch',
            ico: <FontAwesomeIcon className="pki-ico" icon={faDownload} />,
            hidden: false,
            disabled: !canDownloadBatch,
            onClick: () => onDownloadDeviceBatch(currentRequest),
          });
          if (currentRequest.uuid) {
            options.push({
              label: 'Related Device(s)',
              ico: (
                <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />
              ),
              hidden: false,
              onClick: () => {
                history.push(
                  `/inventory/devices?device_request_uuid=${currentRequest.uuid}`
                );
              },
            });
          }
        }

        return (
          <TableActions rowId={String(currentRequest.uuid)} options={options} />
        );
      },
    },
  ];

  const deviceBatchRequestResource = _.find(
    resources,
    (item) => item.name === 'device_request'
  );

  const onSubmitForm = async (requestData: any) => {
    setIsLoading(true);
    if (requestData.uuid) {
      try {
        await api().put(`device/request/${requestData.uuid}`, requestData);
        toggleFormModal();
        await fetchDeviceRequests();
        sendNotification({
          text: 'The request has been updated successfully.',
          success: true,
        })(dispatch);
      } catch (err) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong updating the Request!';

        sendNotification({
          text,
          success: false,
        })(dispatch);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        await api().post(`device/request`, requestData);
        toggleFormModal();
        await fetchDeviceRequests();
        sendNotification({
          text: 'The request has been created successfully.',
          success: true,
        })(dispatch);
      } catch (err) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong creating the Request!';

        sendNotification({
          text,
          success: false,
        })(dispatch);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onAction = async () => {
    if (requestForm && requestForm.uuid && confirmationFormType) {
      try {
        setIsLoading(true);
        await api().put(`device/request/${requestForm.uuid}/action`, {
          action_notes: editRequestReason,
          action: confirmationFormType.toLowerCase(),
        });
        await fetchDeviceRequests();
        setFormModal(false);
        setEditRequestReason('');
        setConfirmationFormType(undefined);
        setRequestForm(undefined);
        sendNotification({
          text: 'The request has been updated successfully.',
          success: true,
        })(dispatch);
      } catch (err) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong updating the Device Batch Request!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onDownloadDeviceBatch = async (request: DeviceBatchRequest) => {
    setIsLoading(true);
    try {
      const {
        data: { download_url: downloadUrl, file_name: fileName },
      } = await api().get(`/device/request/${request.uuid}/downloadurl`);
      const { data } = await axios.get(downloadUrl);
      const deviceBatchBlob = new Blob([String(data)]);
      saveAs(
        deviceBatchBlob,
        fileName ||
          `${currentCustomerCode}_${request.uuid}_${request.quantity}.tar.gz.pgp`
      );
    } catch (err: any) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Device Batch package!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="DeviceBatchRequests">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Device Batch Requests</h3>
          {isLoading && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              initialFilters={initialQuery}
              filters={[
                { key: 'uuid', label: 'Request ID', type: 'filter' },
                {
                  key: 'status',
                  label: 'Status',
                  type: 'filter',
                  options: [
                    { value: 'created', label: 'Created' },
                    { value: 'awaiting_approval', label: 'Awaiting Approval' },
                    { value: 'in_progress', label: 'In Progress' },
                    { value: 'rejected', label: 'Rejected' },
                    { value: 'completed', label: 'Completed' },
                    { value: 'failed', label: 'Failed' },
                    { value: 'canceled', label: 'Canceled' },
                  ],
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Request Date',
                  type: 'filter',
                },
              ]}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {hasErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                search={false}
                keyField="uuid"
                noDataIndication={
                  tablePage > 1
                    ? 'No more Requests available'
                    : 'No Requests available'
                }
                remote={true}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                  }: {
                    page: number;
                    sizePerPage: number;
                    sortOrder: string;
                    sortField: string;
                  }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                  setTableSortOrder(sortOrder);
                  setTableSortField(mapSortField(sortField));
                }}
                data={deviceBatchRequests}
                columns={columns}
                rowEvents={{
                  onClick: (
                    notUsedValue: null,
                    current: DeviceBatchRequest
                  ): void => {
                    setRequestForm(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                toolbar={
                  <RequestsToolbar
                    type={RequestType.DeviceBatch}
                    actions={_.get(deviceBatchRequestResource, 'actions', [])}
                    onCreateRequestClick={(): void => {
                      toggleFormModal();
                      setFormReadOnly(false);
                    }}
                  />
                }
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        {!confirmationFormType && (
          <DeviceBatchRequestForm
            readOnly={formReadOnly}
            onCancel={toggleFormModal}
            onChangeMode={toggleMode}
            defaultValues={requestForm}
            onSubmit={onSubmitForm}
            onDownloadDeviceBatch={onDownloadDeviceBatch}
          />
        )}
        {confirmationFormType && requestForm && (
          <ConfirmationForm
            title={`${_.capitalize(confirmationFormType)} Confirmation`}
            content={
              <div className="px-5 text-center">
                <p>
                  You are about to {confirmationFormType}{' '}
                  <strong>
                    {requestForm.uuid &&
                      requestForm.uuid.slice(0, 8).toLocaleUpperCase()}
                  </strong>
                  , requested by <strong>{requestForm.createdBy.email}</strong>
                </p>
                <p>
                  Add notes to include in the notification mail to{' '}
                  {requestForm.createdBy.email}
                </p>
                <Input
                  className="my-2"
                  value={editRequestReason}
                  rows={3}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    setEditRequestReason(event.target.value);
                  }}
                  type="textarea"
                />
              </div>
            }
            onCancel={toggleFormModal}
            onConfirm={onAction}
          />
        )}
      </Modal>
    </div>
  );
};
export default DeviceBatchRequests;
