import React, { FC, ReactNode, useEffect, useState } from 'react';
import _, { includes } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, UncontrolledPopover } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ConfirmationForm,
  Table,
  TableActions,
} from '../../../../../components';
import { AddCertificateProfileForm } from './components/AddCertificateProfileForm/AddCertificateProfileForm';
import { sendNotification } from '../../../../../store/notifications/actions';
import {
  IPatchProductProfile,
  ProductProfile,
  ProductProfileCertificateProfile,
} from '../../../types';
import { serializeProductProfileCertificateProfilesUuids } from '../../../helpers';

interface CertificateProfilesProps {
  setShowSpinner: (state: boolean) => void;
  profileActions?: string[];
  profileId: string;
  certificateProfiles: ProductProfile['certificateProfiles'];
  certificateProfileUuids: ProductProfile['certificateProfileUuids'];
  patchProductProfile: IPatchProductProfile;
}

export const CertificateProfiles: FC<CertificateProfilesProps> = ({
  setShowSpinner,
  profileActions = [],
  profileId,
  certificateProfiles,
  certificateProfileUuids,
  patchProductProfile,
}) => {
  const [tablePage, setTablePage] = useState(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [showAddFormModal, setShowAddFormModal] = useState<boolean>(false);
  const history = useHistory();

  const [
    currentCertificateProfile,
    setCurrentCertificateProfile,
  ] = useState<ProductProfileCertificateProfile>();

  const dispatch = useDispatch();

  const toggleAddFormModal = (): void => {
    setShowAddFormModal((previousValue) => {
      if (previousValue) {
        setCurrentCertificateProfile(undefined);
      }
      return !previousValue;
    });
  };

  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentCertificateProfile(undefined);
      }
      return !previousValue;
    });
  };

  const onRowClick = (
    selectedCertificateProfile: ProductProfileCertificateProfile
  ) => {
    return undefined;
  };

  const onRemoveFromProductProfile = async (profileUuid: string) => {
    setIsLoading(true);
    const newCertificateProfiles = certificateProfileUuids?.filter(
      ({ uuid }) => uuid !== profileUuid
    );
    patchProductProfile(
      {
        certificate_profile_uuids: serializeProductProfileCertificateProfilesUuids(
          newCertificateProfiles
        ),
      },
      () => {
        setIsLoading(false);
        toggleDeleteModal();
      },
      () => {
        setIsLoading(false);
      }
    );
  };
  const onCancelAddForm = () => {
    setShowAddFormModal(() => {
      setCurrentCertificateProfile(undefined);
      return false;
    });
  };

  const onSubmitAddForm = async (
    certificateProfileId: string,
    label: string
  ) => {
    setIsLoading(true);
    const newCertificateProfiles = certificateProfileUuids || [];
    if (
      certificateProfileUuids?.findIndex(
        ({ uuid, label: certLabel }) =>
          certificateProfileId === uuid || label === certLabel
      ) === -1
    ) {
      newCertificateProfiles.push({
        label,
        uuid: certificateProfileId,
      });
      patchProductProfile(
        {
          certificate_profile_uuids: serializeProductProfileCertificateProfilesUuids(
            newCertificateProfiles
          ),
        },
        () => {
          setIsLoading(false);
          toggleAddFormModal();
        },
        () => {
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
      sendNotification({
        text:
          'Selected Certificate Profile or label was already added to the Product Profile.',
        success: false,
      })(dispatch);
    }
  };

  const onAddCertificateProfile = () => {
    setCurrentCertificateProfile(() => {
      setShowAddFormModal(true);
      return undefined;
    });
  };

  const onConfirmRemove = async () => {
    if (currentCertificateProfile?.certificateProfile.uuid) {
      await onRemoveFromProductProfile(
        currentCertificateProfile.certificateProfile.uuid
      );
    }
  };

  const certificateProfilesColumns = [
    {
      dataField: 'certificateProfile.uuid',
      text: 'uuid',
      hidden: true,
    },
    {
      dataField: 'label',
      text: 'Label',
    },
    {
      dataField: 'certificateProfile.name',
      text: 'Name',
      formatter: (
        certName: string,
        {
          certificateProfile: { description, uuid },
        }: ProductProfileCertificateProfile
      ): ReactNode => {
        return (
          <>
            <div>{certName}</div>
            {!_.isEmpty(description) && (
              <div
                id={`certificateProfile-${uuid}-notes`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small>{description}</small>
                <UncontrolledPopover
                  trigger="hover"
                  target={`certificateProfile-${uuid}-notes`}
                >
                  <div className="p-2">
                    <small>{description}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'certificateProfile.issuerCN',
      text: 'Issuing CA',
    },
    {
      dataField: 'certificateProfile.createdBy',
      text: 'Created By',
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      formatter: (
        valueNotImportant: null,
        currentRow: ProductProfileCertificateProfile
      ): ReactNode => {
        const canDelete = includes(profileActions, 'update');
        const options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        options.push({
          label: 'Remove From Profile',
          ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
          onClick: async (e: MouseEvent): Promise<void> => {
            e.stopPropagation();
            setCurrentCertificateProfile(() => {
              setDeleteModal(true);
              return currentRow;
            });
          },
          disabled: !canDelete,
        });

        options.push({
          label: 'Related Certificate Profile',
          ico: <FontAwesomeIcon className="pki-ico" icon={faFileInvoice} />,
          onClick: (e: MouseEvent): void => {
            const pathname = '/management/certificate-profiles';
            history.push({
              pathname,
              search: `?uuid=${currentRow.certificateProfile.uuid}`,
            });
          },
        });

        return (
          <TableActions
            rowId={String(currentRow.certificateProfile.uuid)}
            options={options}
          />
        );
      },
    },
  ];

  return (
    <div id={'product-profile-certificate-profiles'}>
      <div className="d-flex justify-content-end mb-2">
        <Button
          id="link-certificate-profile"
          outline
          disabled={
            isLoading ||
            !profileActions?.includes('update') ||
            (certificateProfiles && certificateProfiles.length >= 1)
          }
          size="sm"
          onClick={onAddCertificateProfile}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Certificate Profile
        </Button>
      </div>
      <Table
        search={false}
        keyField="certificateProfile.uuid"
        loading={isLoading}
        remote={false}
        data={certificateProfiles || []}
        columns={certificateProfilesColumns}
        noDataIndication={
          tablePage > 1
            ? 'No more Certificate Profiles for current Product Profile'
            : 'No Certificate Profile has been linked to Product Profile'
        }
        pagination={{
          page: tablePage,
          sizePerPage: tableSizePerPage,
        }}
        onTableChange={(
          valueNotUsed: null,
          { page, sizePerPage }: { page: number; sizePerPage: number }
        ): void => {
          setTablePage(page);
          setTableSizePerPage(sizePerPage);
        }}
        rowEvents={{
          onClick: (
            notUsedValue: null,
            current: ProductProfileCertificateProfile
          ): void => {
            onRowClick(current);
          },
        }}
      />
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={showAddFormModal}
        toggle={toggleAddFormModal}
      >
        <AddCertificateProfileForm
          profileId={profileId}
          onCancel={onCancelAddForm}
          onSubmit={onSubmitAddForm}
          isLoading={isLoading}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title="Remove Certificate Profile Confirmation"
          message={''}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>remove</strong> Certificate Profile "
                <strong>
                  {currentCertificateProfile?.certificateProfile.name}
                </strong>
                " from current Product Profile.
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={onConfirmRemove}
        />
      </Modal>
    </div>
  );
};
