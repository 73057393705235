import React, { useState, useEffect, FC, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import {
  General,
  Extensions,
  CustomExtensions,
  CertificatePolicies,
} from '../ViewsComponents';
import { CustomExtension } from '../../libs/types';
import { CertificateTemplate } from '../../store/certificateTemplates/types';

import { defaultState, navMenu } from './constants';
import { getSubjectSorted } from '../../libs/helpers';

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onModeChange?: Function;
  defaultValues?: CertificateTemplate;
  readOnly: boolean;
}

const CertificateTemplateForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onModeChange = (): null => null,
  defaultValues = defaultState,
  readOnly = false,
}) => {
  const [activeTab, setActiveTab] = useState('0');
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [templateForm, setTemplateForm] = useState(defaultValues);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  const toggleTab = (tab: string): void => {
    activeTab !== tab && setActiveTab(tab);
  };

  const setTemplateFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setTemplateForm((current: CertificateTemplate) => ({
      ...current,
      [property]: value,
    }));
  };

  const renderNav = (): ReactNode => (
    <Nav tabs>
      {_.map(navMenu, (label: string, index: number) => (
        <NavItem key={index} className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === String(index) })}
            onClick={(): void => {
              toggleTab(String(index));
            }}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const {
    name,
    validity,
    keySpec,
    signatureHashAlgorithm,
    signatureAlgorithm,
    subject,
    keyUsage,
    description,
    extendedKeyUsage,
    subjectAlternativeNames,
    authorityInformationAccess,
    CRLDistributionPointURIs,
    certificatePolicies,
    customExtensions,
    critical,
  } = templateForm;

  const generalValues = {
    name,
    validity,
    keySpec,
    signatureHashAlgorithm,
    signatureAlgorithm,
    subject,
    description,
  };

  const extensionsValues = {
    keyUsage,
    extendedKeyUsage,
    subjectAlternativeNames,
    authorityInformationAccess,
    CRLDistributionPointURIs,
  };

  const isEditable = _.includes(
    (defaultValues as CertificateTemplate).actions,
    'update'
  );

  const isValid =
    !_.chain(name).trim().isEmpty().value() && _.size(subject) >= 1;
  const [currentReformattedSan, setCurrentReformattedSan] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const subjectSorted = getSubjectSorted(subject);

  return (
    <div className="CertificateTemplateForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>Certificate Template</h3>
          <span
            onClick={(): void => {
              onModeChange();
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {renderNav()}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <General
              type="TEMPLATE"
              id="general"
              readOnly={readOnly}
              highlightMandatoryFields={highlightMandatoryFields}
              subjectSorted={subjectSorted}
              reformattedSan={currentReformattedSan}
              disabledFields={[
                'authority',
                'profileId',
                'signatureAlgorithm',
                'signatureHashAlgorithm',
              ]}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setTemplateFormProperty({ value, property: key });
              }}
              values={generalValues}
            />
          </TabPane>
          <TabPane tabId="1">
            <Extensions
              id="extensions"
              readOnly={readOnly}
              reformattedSan={currentReformattedSan}
              onReformattedSanChange={setCurrentReformattedSan}
              subjectSorted={subjectSorted}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setTemplateFormProperty({ value, property: key });
              }}
              values={extensionsValues}
            />
          </TabPane>
          <TabPane tabId="2">
            <CustomExtensions
              id="custom-extensions"
              readOnly={readOnly}
              onChange={({ value }: { value: CustomExtension[] }): void => {
                setTemplateFormProperty({
                  value,
                  property: 'customExtensions',
                });
              }}
              values={customExtensions}
            />
          </TabPane>
          <TabPane tabId="3">
            <CertificatePolicies
              id="certificate-policies"
              readOnly={readOnly}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setTemplateFormProperty({ value, property: key });
              }}
              values={{ certificatePolicies, critical }}
            />
          </TabPane>
        </TabContent>
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-button"
                outline
                disabled={false}
                onClick={(): void => {
                  onSubmit({ values: templateForm, isValid });
                  if (!isValid) {
                    setHighlightMandatoryFields(true);
                  }
                }}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CertificateTemplateForm;
