import { Collapse } from 'reactstrap';
import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { dateFormatter } from '../../../libs/helpers';
import { Table, Spinner } from '../../../components';
import { ApplicationState } from '../../../store';
import { getExpiringCertificates } from '../../../store/certificates/actions';
import { CertificatesState } from '../../../store/certificates/types';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'commonName':
      return 'cn';
    case 'expiresAt':
      return 'not_after';
    default:
      return sortField;
  }
};

const beginOfToday = moment(new Date()).startOf('day');

const ExpiringCertificates: FC = () => {
  const {
    isGettingExpiringCertificateList,
    isLoadedExpiringCertificateList,
    expiringCertificateList,
  } = useSelector<ApplicationState, CertificatesState>((pki) => ({
    ...pki.certificates,
  }));

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('asc');
  const [tableSortField, setTableSortField] = useState<string>('not_after');

  const [showCertificateSpinner, setShowCertificateSpinner] = useState<boolean>(
    false
  );
  const [showContent, setShowContent] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingExpiringCertificateList) {
      setShowCertificateSpinner(true);
    }
    if (isLoadedExpiringCertificateList) {
      setShowContent(true);
      setShowCertificateSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [isGettingExpiringCertificateList, isLoadedExpiringCertificateList]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    dispatch(
      getExpiringCertificates(
        {
          startDate: beginOfToday.subtract(1, 'day').format('YYYY-MM-DD'),
          sizePerPage: tableSizePerPage,
          page: tablePage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
        },
        tokenSource
      )
    );
    return function cleanup(): void {
      tokenSource.cancel('ExpiringCertificates::getExpiringCertificates');
    };
  }, [tablePage, tableSizePerPage, tableSortOrder, tableSortField]);

  const expiringCertificatesColumns = [
    { dataField: 'uuid', text: 'uuid', hidden: true },
    { dataField: 'commonName', text: 'Common Name' },
    {
      dataField: 'expiresAt',
      text: 'Expiring Date',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
  ];

  let endDate = beginOfToday;
  const lastIndex = expiringCertificateList.length;
  if (lastIndex !== 0) {
    endDate = moment(
      dateFormatter(Number(expiringCertificateList[lastIndex - 1].expiresAt))
    );
  }

  return (
    <div className="Expiring Certificates overflow-auto">
      <div className="header-contanier d-flex">
        <h5 className="text-muted">Expiring Certificates</h5>
        {showCertificateSpinner && (
          <Spinner className="mt-1 ml-2" size="sm" type="border" />
        )}
      </div>
      <small>
        Expiring certificates from {beginOfToday.format('LL')} to{' '}
        {endDate.format('LL')}
      </small>
      <Collapse isOpen={showContent}>
        <Table
          keyField="uuid"
          remote={true}
          search={false}
          pagination={{
            page: tablePage,
            sizePerPage: tableSizePerPage,
          }}
          sort={{
            dataField: tableSortField,
            order: tableSortOrder as SortOrder,
          }}
          noDataIndication="No Expiring Certificates"
          columns={expiringCertificatesColumns}
          data={expiringCertificateList}
          onTableChange={(
            valueNotUsed: null,
            {
              page,
              sizePerPage,
              sortOrder,
              sortField,
            }: {
              page: number;
              sizePerPage: number;
              sortOrder: string;
              sortField: string;
            }
          ): void => {
            setTablePage(page);
            setTableSizePerPage(sizePerPage);
            setTableSortOrder(sortOrder);
            setTableSortField(mapSortField(sortField));
          }}
        />
      </Collapse>
    </div>
  );
};

export default ExpiringCertificates;
