export interface ServiceAccount {
  enabled: boolean;
  username: string;
  uuid: string;
}

export interface Client {
  uuid: string;
  code?: string;
  name: string;
  description: string;
  serviceAccount?: ServiceAccount;
  clientID?: string;
  enabled?: boolean;
  actions: string[];
  createdBy?: string;
  isCreatedByUserDeleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface ClientEntitlement {
  entityUuid: string;
  role: string;
  entityType: string;
}

export interface ClientsState {
  readonly isGettingClientsList: boolean;
  readonly isLoadedClientsList: boolean;
  readonly isCreatingClient: boolean;
  readonly isEditingClient: boolean;
  readonly isDeletingClient: boolean;
  readonly isGettingClientEntitlements: boolean;
  readonly currentClientEntitlements: ClientEntitlement[];
  readonly clientsList: Client[];
  readonly clientsErrors: unknown;
}

export enum ClientsActionTypes {
  GET_CLIENTS_LIST_REQUEST = '@@Clients/GET_CLIENTS_LIST_REQUEST',
  GET_CLIENTS_LIST_SUCCESS = '@@Clients/GET_CLIENTS_LIST_SUCCESS',
  GET_CLIENTS_LIST_ERROR = '@@Clients/GET_CLIENTS_LIST_ERROR',

  POST_CLIENT_REQUEST = '@@Clients/POST_CLIENT_REQUEST',
  POST_CLIENT_SUCCESS = '@@Clients/POST_CLIENT_SUCCESS',
  POST_CLIENT_ERROR = '@@Clients/POST_CLIENT_ERROR',

  PUT_CLIENT_REQUEST = '@@Clients/PUT_CLIENT_REQUEST',
  PUT_CLIENT_SUCCESS = '@@Clients/PUT_CLIENT_SUCCESS',
  PUT_CLIENT_ERROR = '@@Clients/PUT_CLIENT_ERROR',

  DELETE_CLIENT_REQUEST = '@@Clients/DELETE_CLIENT_REQUEST',
  DELETE_CLIENT_SUCCESS = '@@Clients/DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR = '@@Clients/DELETE_CLIENT_ERROR',

  GET_CLIENT_ENTITLEMENTS_REQUEST = '@@Clients/GET_CLIENT_ENTITLEMENTS_REQUEST',
  GET_CLIENT_ENTITLEMENTS_SUCCESS = '@@Clients/GET_CLIENT_ENTITLEMENTS_SUCCESS',
  GET_CLIENT_ENTITLEMENTS_ERROR = '@@Clients/GET_CLIENT_ENTITLEMENTS_ERROR',
}
