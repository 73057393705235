import React, { FC } from 'react';
import { map } from 'lodash';
import Select, { InputActionMeta } from 'react-select';
import { Button } from 'reactstrap';
import { useTemplatesSelect } from '../../libs/hooks';
import { CertificateTemplate } from '../../store/certificateTemplates/types';

interface Props {
  onCancel: Function;
  onSelectTemplate: Function;
}

const TemplatesForm: FC<Props> = ({ onSelectTemplate, onCancel }) => {
  const { loading, templates, onMore, onFilter } = useTemplatesSelect();

  return (
    <div className="p-5">
      <h3 className="text-muted">Select a Template</h3>
      <small>Title and Description won't be filled with Template data</small>
      <Select
        isSearchable={true}
        className="my-5"
        id="template-select"
        options={map(templates, (item) => ({
          value: item,
          label: item.name,
        }))}
        onMenuScrollToBottom={onMore}
        isLoading={loading}
        onInputChange={(value: string, action: InputActionMeta) => {
          if (action.action === 'input-change') {
            onFilter(value);
          }
        }}
        onChange={(selectedValue): void => {
          const selectedTemplate: CertificateTemplate = {
            ...selectedValue?.value,
          } as CertificateTemplate;
          delete selectedTemplate.uuid;

          onSelectTemplate({
            ...selectedTemplate,
            name: '',
            description: '',
            // keyPolicy: 'kms',
            approvalPolicy: {
              approverUuids: [],
            },
          });
        }}
      />
      <div className="float-right">
        <Button id="no-template-button" onClick={() => onCancel()} outline>
          Continue without Template
        </Button>
      </div>
    </div>
  );
};

export default TemplatesForm;
