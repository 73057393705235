export const defaultValuesCodeSigningProfiles = {
  uuid: '',
  name: '',
  notes: '',
  codeSigningKeyUuid: '',
  codeSigningProfileId: '',
  signingAlgorithm: '',
  signingHashAlgorithm: '',
  outputFormat: '',
  approvalPolicy: {
    approverUuids: [],
    notification: false,
  },
};

export const navMenu = ['General', 'Policy'];
