import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadCertificate } from '../../store/certificates/actions';
import { Spinner } from '../../components';

const { saveAs } = require('file-saver');

const CertificateDownload: FC = () => {
  const dispatch = useDispatch();
  const [isDownloding, setIsDownloadig] = useState(false);
  const { serial } = useParams<{ serial: string }>();
  useEffect(() => {
    const actionDownload = async (): Promise<void> => {
      setIsDownloadig(true);
      const certificate = await dispatch(downloadCertificate(serial));
      const certificateBlob = new Blob([String(certificate)]);
      saveAs(certificateBlob, `${serial}.pem`);
      setIsDownloadig(false);
    };
    actionDownload();
  }, [dispatch, serial]);

  return (
    <div className="CertificateDownload">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <span className="text-muted">
            <h3 className="text-muted">Certificate Download</h3>
            <>serial: {serial}</>
          </span>
          {isDownloding && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <span className="mt-4">
          {' '}
          Download again{' '}
          <FontAwesomeIcon
            className="pki-ico ml-1"
            onClick={async (): Promise<void> => {
              setIsDownloadig(true);
              const certificate = await dispatch(downloadCertificate(serial));
              const certificateBlob = new Blob([String(certificate)]);
              saveAs(certificateBlob, `${serial}.pem`);
              setIsDownloadig(false);
            }}
            icon={faDownload}
          />{' '}
        </span>
      </Card>
    </div>
  );
};

export default CertificateDownload;
