import React, { FC } from 'react';
import { Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Table } from '../../components';
import { dateFormatter } from '../../libs/helpers';
import { AuditLog } from '../../store/auditLogs/types';

interface Props {
  auditLog: AuditLog;
  onCancel: Function;
}

const entityCategory: { [entity: string]: string } = {
  'certificate-template': 'management',
  'certificate-profile': 'management',
  'code-signing-profile': 'management',
  'common-key': 'inventory',
  'api-client': 'management',
  'issuing-ca': 'pki',
  request: 'operations',
};

const AuditLogPreview: FC<Props> = ({ auditLog, onCancel }) => {
  const basicCols = [
    {
      dataField: 'id',
      hidden: true,
      text: '',
    },
    {
      dataField: 'timestamp',
      text: 'Timestamp',
    },
    {
      dataField: 'user',
      text: 'User',
    },
    {
      dataField: 'outcome',
      text: 'Outcome',
    },
  ];
  const datailCols = [
    {
      dataField: 'attribute',
      text: 'Attribute',
      formatter: (key: string): string => _.startCase(key),
    },
    {
      dataField: 'value',
      text: 'Value',
    },
  ];

  const history = useHistory();
  const { details } = auditLog;
  const entityType = _.get(details, 'entity_type');
  const entityUuid = _.get(details, 'uuid');

  return (
    <div className="AuditLogPreview">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5">
          <h3 className="text-muted">{auditLog?.eventName}</h3>
          {entityUuid && entityType && (
            <span
              className="mt-2"
              onClick={(): void => {
                const pathname = `/${entityCategory[entityType]}/${entityType}s`;
                history.push({
                  pathname,
                  search: `?uuid=${entityUuid}`,
                });
              }}
            >
              Related {_.startCase(entityType)}
              <FontAwesomeIcon
                className="ml-3 pki-ico"
                icon={faExternalLinkAlt}
              />
            </span>
          )}
        </div>
        <div className="ml-auto p-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="content p-5">
        {!_.isEmpty(auditLog.description) && (
          <div className="event-description">
            <Label className="pki-label">Event Description</Label>
            <div className="mx-2 mt-1">{auditLog.description}</div>
          </div>
        )}
        <div className="info-table mt-4">
          <Label className="pki-label">General Info</Label>
          <Table
            search={false}
            data={[
              {
                id: 0,
                timestamp: dateFormatter(Number(auditLog.timestamp)),
                user: auditLog.authorizedUser || '-- No User Related --',
                outcome: auditLog.eventOutcome,
              },
            ]}
            keyField="id"
            noDataIndication="No Info"
            columns={basicCols}
          />
        </div>
        <div className="details-table mt-2">
          <Label className="pki-label">Details</Label>
          <Table
            search={false}
            data={_.map(auditLog?.details, (value, attribute) => {
              return {
                value: _.isObject(value) ? JSON.stringify(value) : value,
                attribute,
              };
            })}
            keyField="attribute"
            noDataIndication="No Details"
            columns={datailCols}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditLogPreview;
