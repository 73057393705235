import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../../components';

interface Props {
  onClose: Function;
  isLoadign: boolean;
  commonName?: string;
  printedCertificate?: string;
}

const Preview: FC<Props> = ({
  onClose = (): null => null,
  isLoadign = false,
  commonName,
  printedCertificate,
}) => {
  return (
    <div className="Preview">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>{commonName}</h3>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="content p-5">
        {isLoadign && <Spinner />}
        {printedCertificate && (
          <code className="white-space-pre-wrap black-code">
            {printedCertificate}
          </code>
        )}
      </div>
    </div>
  );
};

export default Preview;
