import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Collapse, Button, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment, { Moment } from 'moment';
import { dateFormatter } from '../../libs/helpers';
import { ApplicationState } from '../../store';
import { getDeviceProvisioning } from '../../store/deviceProvisioning/actions';
import { DeviceProvisioningState } from '../../store/deviceProvisioning/types';
import { Table, Spinner, DatePicker, ErrorHandler } from '../../components';

import './DeviceProvisioning.scss';

const startOfMonth = moment().startOf('month');
const endOfDay = moment().endOf('day');

const DeviceProvisioning: FC = () => {
  const {
    deviceProvisioningList,
    isGettingDeviceProvisioningList,
    isLoadedDeviceProvisioningList,
    deviceProvisioningErrors,
  } = useSelector<ApplicationState, DeviceProvisioningState>(
    (pki) => pki.deviceProvisioning
  );

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(startOfMonth);
  const [endDate, setEndDate] = useState<Moment>(endOfDay);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingDeviceProvisioningList) {
      setShowSpinner(true);
    }
    if (isLoadedDeviceProvisioningList || deviceProvisioningErrors) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [
    deviceProvisioningErrors,
    isGettingDeviceProvisioningList,
    isLoadedDeviceProvisioningList,
  ]);

  useEffect(() => {
    dispatch(
      getDeviceProvisioning({
        startDate: startOfMonth,
        endDate: endOfDay,
      })
    );
  }, [dispatch]);

  const columns = [
    { dataField: 'id', text: 'id', hidden: true, csvExport: false },
    { dataField: 'certificateProfileName', text: 'Certificate Profile' },
    {
      dataField: 'date',
      text: 'Date',
      formatter: (date: number): string => dateFormatter(date, 'LL'),
      csvFormatter: (date: number): string => dateFormatter(date, 'LL'),
    },
    { dataField: 'count', text: 'Count' },
  ];

  return (
    <div className="DeviceProvisioning">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Device Provisioning</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <div className="date-filter my-3">
          <Row>
            <Col md={5}>
              <DatePicker
                label="Start Date"
                format="LL"
                showTimePicker={false}
                value={startDate}
                onChange={(value: Moment): void => {
                  setStartDate(value);
                }}
              />
            </Col>
            <Col md={5}>
              <DatePicker
                label="End Date"
                format="LL"
                className="ml-2"
                showTimePicker={false}
                value={endDate}
                onChange={(value: Moment): void => {
                  setEndDate(value);
                }}
              />
            </Col>
            <Col md={2}>
              <Button
                onClick={(): void => {
                  dispatch(getDeviceProvisioning({ startDate, endDate }));
                }}
                size="sm"
                className="filter-button float-right ml-2 p-1 w-100"
                outline
              >
                <FontAwesomeIcon className="mr-1" icon={faFilter} /> Filter
              </Button>
            </Col>
          </Row>
        </div>
        <Collapse isOpen={showContent}>
          <div className="view mt-5">
            {deviceProvisioningErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                data={_.map(deviceProvisioningList, (item, index) => ({
                  ...item,
                  id: index + 1,
                }))}
                keyField="id"
                noDataIndication="No Report Available"
                sort={{ dataField: 'timestamp', order: 'desc' }}
                exportCSV={{
                  fileName: 'device-provisioning.csv',
                  onlyExportSelection: false,
                  exportAll: true,
                }}
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

export default DeviceProvisioning;
