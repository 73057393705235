export const AGGREGATION = {
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const COLORS = {
  KEY_SETS_PRODUCED: '#4470d4',
  KEY_SETS_PERSONALIZED: '#f17c2b',
};

export const DATE_FORMAT = 'yyyy-MM-DD';
