import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  IPatchProductProfile,
  ProductProfile,
  ProductProfileOutputFile,
  ProductProfileRequest,
} from '../../../../../types';
import { FileNamePattern } from './components/FileNamePattern';
import { serializeProductProfileOutputFiles } from '../../../../../helpers';
import { ProductProfileDistributionConfigs } from './components/ProductProfileDistributionConfigs';
import { sendNotification } from '../../../../../../../store/notifications/actions';

interface DistributionConfigProps {
  productProfile: ProductProfile;
  onPatchProductProfile: IPatchProductProfile;
}

export const DistributionConfig: FC<DistributionConfigProps> = ({
  productProfile,
  onPatchProductProfile,
}) => {
  const generateConfig = productProfile.outputFiles?.generate || undefined;
  const dispatch = useDispatch();
  const submitFileNamePattern = async (
    outputConfig: ProductProfileOutputFile,
    onSuccess?: Function
  ) => {
    try {
      const outputFiles = {
        ...productProfile.outputFiles,
        generate: outputConfig,
      };
      const payload: Partial<ProductProfileRequest> = {
        output_files: serializeProductProfileOutputFiles(outputFiles),
      };
      const newProfile = await onPatchProductProfile(payload);
      if (onSuccess && newProfile) onSuccess(newProfile?.outputFiles?.generate);
    } catch (e) {
      sendNotification({
        text: 'Oops! Something went wrong updating the Product Profile!',
        success: false,
      })(dispatch);
    }
  };
  return (
    <div className="distribution-config-wrapper p-2">
      <div className="output-file-wrapper">
        <FileNamePattern
          fileNamePatternConfig={generateConfig}
          onSubmit={submitFileNamePattern}
        />
      </div>
      <hr />
      <div className="distribution-configs-wrapper">
        <ProductProfileDistributionConfigs
          productProfile={productProfile}
          onPatchProductProfile={onPatchProductProfile}
        />
      </div>
    </div>
  );
};
