import React, { FC } from 'react';
import { Spinner as BootstrapSpinner } from 'reactstrap';
import './Spinner.scss';

interface Props {
  className?: string;
  type?: 'border' | 'grow';
  size?: 'sm' | 'lg';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
}

const Spinner: FC<Props> = (props) => {
  const { className, ...spinnerProps } = props;

  return (
    <div className={`Spinner text-center ${className}`}>
      <BootstrapSpinner {...spinnerProps}>{''}</BootstrapSpinner>
    </div>
  );
};

export default Spinner;
