import { DeviceBatchRequest } from './types';

export const defaultValuesRequest: Partial<DeviceBatchRequest> = {
  uuid: '',
  createdAt: '',
};

export const defaultValuesDeviceBatchRequest: Partial<DeviceBatchRequest> = {
  quantity: 1,
};
