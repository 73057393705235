/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ManufacturingStation,
  ProvisioningProfile,
  SecureToken,
} from './types';

export const deserializeManufacturingStation = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): ManufacturingStation => ({
  uuid: raw.uuid,
  name: raw.name,
  manufacturer: raw.manufacturer,
  location: raw.location,
  externalId: raw.external_id,
  notes: raw.description,
  issuingCAUuid: '',
  ...(raw?.provisioning_profiles ? {} : {}),
  provisioningProfiles: [],
  enabled: raw.enabled,
  certificateProfileUuid: raw.certificate_profile_uuid,
  actions: raw.actions,
});

export const serializeManufacturingStation = (
  manufacturingStation: ManufacturingStation
): any => ({
  name: manufacturingStation.name,
  manufacturer: manufacturingStation.manufacturer,
  location: manufacturingStation.location,
  external_id: manufacturingStation.externalId,
  description: manufacturingStation.notes,
  certificate_profile_uuid: manufacturingStation.certificateProfileUuid,
  enabled: manufacturingStation.enabled,
});

export const deserializeProvisioningProfile = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): ProvisioningProfile => ({
  manufacturingStationUuid: raw.manufacturing_station_uuid,
  certificateProfileUuid: raw.certificate_profile_uuid,
  name: raw.certificate_profile_name,
  actions: raw.actions,
});

export const deserializeSecureToken = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): SecureToken => ({
  uuid: raw.uuid,
  serialNumber: raw.serial_number,
  notes: raw.description,
  manufacturingStationUuid: raw.manufacturing_station_uuid,
  enabled: raw.enabled,
  certificateUuid: raw.certificate_uuid,
  actions: raw.actions,
});

export const serializeSecureToken = (secureToken: SecureToken): any => {
  let body: any = {
    manufacturing_station_uuid: secureToken.manufacturingStationUuid,
    serial_number: secureToken.serialNumber,
    description: secureToken.notes,
    enabled: secureToken.enabled,
  };

  if (secureToken.csr) {
    body = {
      ...body,
      certificate_request: {
        csr: secureToken.csr,
        csr_filename: secureToken.csrFilename,
      },
    };
  }

  return body;
};
