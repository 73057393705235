import React, { FC, useEffect, useState } from 'react';
import { Input, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { kebabCase } from 'lodash';
import useCopy from '@react-hook/copy';

interface InputRevealProps {
  value: string;
  copyable?: boolean;
  prefix?: string;
  readonly?: boolean;
  secret?: boolean;
}

export const InputReveal: FC<InputRevealProps> = ({
  value,
  copyable = true,
  prefix,
  secret = true,
  readonly = false,
}) => {
  const prefixToUse = `${prefix || ''}-${value}`;
  const [showSecret, setShowSecret] = useState(false);
  const {
    copy: copyValue,
    copied: valueCopied,
    reset: resetValueCopy,
  } = useCopy(value || '');

  useEffect(() => {
    let timerId: any = null;
    if (valueCopied) {
      timerId = setTimeout(() => {
        resetValueCopy();
      }, 5000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [copyValue, valueCopied, resetValueCopy, value]);

  const targetSuffix = kebabCase(
    `${kebabCase(String(prefixToUse)?.toLowerCase())}`
  );
  const copyTarget = `copy-${targetSuffix}`;
  const showHideTarget = `show-hide-${targetSuffix}`;
  const inputId = `input-${targetSuffix}`;
  return (
    <div>
      <div className="d-flex align-content-between justify-content-between align-items-center">
        <Input
          plaintext={true}
          readOnly={true}
          id={inputId}
          type={showSecret || !secret ? 'text' : 'password'}
          value={value || ''}
          className="w-50"
        />
        <div>
          {secret && (
            <span
              id={showHideTarget}
              className="client-form-ico"
              onClick={(): void => {
                if (!value) return;
                setShowSecret((prev) => !prev);
              }}
            >
              <FontAwesomeIcon
                className="pki-ico"
                style={{
                  cursor: value ? 'pointer' : 'not-allowed',
                  color: value ? '#d87548' : '#CCC',
                }}
                icon={!showSecret ? faEye : faEyeSlash}
              />
              <UncontrolledPopover
                trigger="hover"
                disabled={!value}
                target={showHideTarget}
              >
                <div className="p-2">
                  <small>{showSecret ? 'Hide Secret' : 'Show Secret'}</small>
                </div>
              </UncontrolledPopover>
            </span>
          )}
          <span
            id={copyTarget}
            className="client-form-ico ml-4"
            onClick={(): void => {
              if (!value) return;
              copyValue();
            }}
          >
            <FontAwesomeIcon
              className="pki-ico"
              style={{
                cursor: value ? 'pointer' : 'not-allowed',
                color: value ? '#d87548' : '#CCC',
              }}
              icon={faCopy}
            />
            <UncontrolledPopover
              trigger="hover"
              disabled={!value}
              target={copyTarget}
            >
              <div className="p-2">
                <small>
                  {valueCopied ? 'Copied!' : `Copy${secret ? ' Secret' : ''}`}
                </small>
              </div>
            </UncontrolledPopover>
          </span>
        </div>
      </div>
    </div>
  );
};
