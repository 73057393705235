import { Button, Modal } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import React, { FC, ReactNode, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { setUserSettings, getUserProfile } from '../../store/users/actions';
import { ApplicationState } from '../../store';
import { UsersState, UserSettings } from '../../store/users/types';
import { Table, Spinner, Toggle } from '../../components';
import { downloadTransportKey } from '../../libs/helpers';
import { getVars } from '../../libs/provisioning';

const { saveAs } = require('file-saver');

const eventList = [
  {
    attribute: 'request_approval',
    eventName: 'Receive Immediate Notification for Requests Approval',
    value: 'true',
    id: 1,
  },
  {
    attribute: 'request_approval_reminders',
    eventName: 'Receive Periodic Reminder Notifications for Requests Approval',
    value: 'false',
    id: 2,
  },
];

const Settings: FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const isSettingsParamTrue = !!params.get('settings');

  const {
    userProfile: { settings },
    isSettingUserSettings,
  } = useSelector<ApplicationState, UsersState>((pki) => pki.users);

  const [modalOpen, setModalOpen] = useState<boolean>(isSettingsParamTrue);
  const [currentSettings, setCurrentSettings] = useState<UserSettings>(
    eventList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setModalOpen(isSettingsParamTrue);
  }, [isSettingsParamTrue]);

  useEffect(() => {
    // map the eventList with the actual user settings
    const newCurrentSettings = _.map(eventList, (item) => {
      let newValue = _.find(
        settings,
        (setting) => setting.attribute === item.attribute
      )?.value;
      if (_.isNil(newValue)) {
        newValue = 'true';
      }
      return { ...item, value: newValue };
    });
    setCurrentSettings(newCurrentSettings);
  }, [settings]);

  useEffect(() => {
    if (!isSettingUserSettings && isSettingsParamTrue) {
      dispatch(getUserProfile());
    }
  }, [dispatch, isSettingUserSettings, isSettingsParamTrue]);

  const toggleModal = (): void => {
    setModalOpen((prev) => !prev);
    history.goBack();
  };

  const columns = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'eventName', text: 'Event' },
    {
      dataField: 'value',
      text: 'Email',
      headerClasses: 'text-center',
      formatter: (
        value: string,
        { attribute }: { attribute: string }
      ): ReactNode => {
        return (
          <div className="text-center">
            <Toggle
              checked={value === 'true'}
              onChange={(checked: boolean): void => {
                setCurrentSettings((prev) => {
                  const newCurrentSettings = _.map(prev, (item) => {
                    if (attribute === item.attribute) {
                      return { ...item, value: checked ? 'true' : 'false' };
                    }
                    return item;
                  });
                  return newCurrentSettings;
                });
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Modal className="PKIApp" size="lg" isOpen={modalOpen} toggle={toggleModal}>
      <div className="Settings">
        <div className="form-header d-flex">
          <div className="mt-5 ml-5 d-flex">
            <h3 className="text-muted">Settings</h3>
            {isSettingUserSettings && (
              <Spinner className="mt-2 ml-2" size="sm" type="border" />
            )}
          </div>
          <div className="ml-auto m-3">
            <Button
              id="close-form-button"
              outline
              size="sm"
              onClick={(): void => {
                setModalOpen(false);
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
        <div className="form-content mt-4 px-5 pb-5">
          <div>
            <Table
              keyField="id"
              columns={columns}
              data={currentSettings}
              toolbar={
                <div>
                  <Button
                    id="download-transport-key-button"
                    outline
                    size="sm"
                    className="mr-2"
                    onClick={async (): Promise<void> => {
                      const transportKey = await downloadTransportKey();
                      const transportKeyBlob = new Blob([String(transportKey)]);
                      const {
                        PKI_SELECTED_CUSTOMER_CODE: currentCustomer,
                      } = getVars();
                      saveAs(
                        transportKeyBlob,
                        `${currentCustomer}_transport_key.pem`
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} /> Transport Key
                  </Button>
                </div>
              }
            />
          </div>
          <div className="float-right mt-5 pb-5">
            <Button
              id="save-button"
              outline
              onClick={(): void => {
                dispatch(setUserSettings(currentSettings));
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
