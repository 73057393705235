import React, { FC, useEffect, ReactElement } from 'react';
import {
  find,
  findIndex,
  includes,
  isEmpty,
  remove,
  set,
  size,
  upperCase,
} from 'lodash';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIndustry,
  faFileAlt,
  faFileContract,
  faTachometerAlt,
  faUserCog,
  faUser,
  faUserEdit,
  faFileCode,
  faSync,
  faFileSignature,
  faFile,
  faFileInvoice,
  faKey,
  faSignOutAlt,
  faCogs,
  faBook,
  faSitemap,
  faListUl,
  faThList,
  faCopy,
  faPaste,
  faMicrochip,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, syncUsers } from './store/users/actions';
import { ApplicationState } from './store';
import { NotificationsState } from './store/notifications/types';
import { EntityRole, UsersState } from './store/users/types';
import {
  CertificateTemplates,
  CertificateProfiles,
  CertificateRequests,
  CodeSigningRequests,
  CodeSigningCertificateRequests,
  Certificates,
  CertificateDownload,
  CAs,
  Trees,
  Settings,
  Customer,
  CodeSigningProfiles,
  AuditLogs,
  Overview,
  CommonKeys,
  Clients,
  Layout,
  DeviceProvisioning,
  Users,
  ManufacturingStation,
  ManufacturingStationsList,
} from './views';
import { RequestType } from './store/requests/types';
import { Notification } from './components';
import { getVars } from './libs/provisioning';
import { logout } from './libs/auth';
import {
  canSeeAsymmetricViews,
  getCurrentCustomerInfo,
  shouldShowDemoContent,
} from './libs/helpers';
import { ManufacturingStationsState } from './store/manufacturingStations/types';
import './PKIApp.scss';
import { getManufacturingStations } from './store/manufacturingStations/actions';
import { ProductProfiles } from './views/ProductProfiles';
import { UserProfile } from './views/UserProfile';
import AsymmetricKeys from './views/keys/AsymmetricKeys/AsymmetricKeys';
import ProductProfile from './views/ProductProfiles/single/ProductProfile';
import { DeviceBatchRequests } from './views/DeviceBatchRequest';
import { NotFound } from './components/NotFound/NotFound';
import Devices from './views/Devices/Devices';
import Device from './views/Devices/single/Device';

interface StateProps {
  manufacturingStationsList: ManufacturingStationsState;
  notification: NotificationsState;
  users: UsersState;
}

interface Props {
  customers: {
    code: string;
    name: string;
  }[];
}

const isUserCustomerRole = (
  role: string,
  entityRoles: Array<EntityRole>,
  customerUuid?: string
) =>
  !!find(
    entityRoles,
    (item) =>
      item.entityType === 'customer' &&
      item.role === role &&
      item.entityUuid === customerUuid
  );

const isUserRole = (role: string, entityRoles: Array<EntityRole>) =>
  !!find(entityRoles, (item) => item.role === role);

const PKIApp: FC<Props> = ({ customers }) => {
  const {
    manufacturingStationsList,
    users: {
      isSyncUsers,
      userProfile,
      userProfile: { entityRoles, resources },
    },
  } = useSelector<ApplicationState, StateProps>((pki) => ({
    manufacturingStationsList: pki.manufacturingStations,
    notification: pki.notifications,
    users: pki.users,
  }));

  const dispatch = useDispatch();
  const { DOCUMENTATION_URL, ENV } = getVars();
  const currentCustomerUuid = getCurrentCustomerInfo()?.uuid;

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (
      isUserRole('ca_admin', entityRoles) ||
      isUserCustomerRole('auditor', entityRoles, currentCustomerUuid)
    ) {
      dispatch(getManufacturingStations({}));
    }
  }, [dispatch, entityRoles, currentCustomerUuid]);

  useEffect(() => {
    // Update the document title with ENV using the browser API
    if (!isEmpty(ENV) && upperCase(ENV) !== 'LIVE') {
      document.title = `Irdeto Key Central (${upperCase(ENV)})`;
    }
  });

  const canSeeAsymmetric = canSeeAsymmetricViews();
  const isDemoCustomer = shouldShowDemoContent();

  const leftMenuConfig = [
    {
      label: 'Irdeto Key Central',
      path: 'irdeto-key-central',
      options: [
        {
          label: 'Overview',
          path: 'overview',
          icon: <FontAwesomeIcon icon={faTachometerAlt} />,
        },
      ],
    },
    {
      label: 'Reports',
      path: 'reports',
      options: [],
    },
    {
      label: 'Operations',
      path: 'operations',
      options: [
        {
          label: 'Certificate Requests',
          path: 'certificate-requests',
          icon: <FontAwesomeIcon icon={faFileContract} />,
        },
        {
          label: 'Code Signing Requests',
          path: 'code-signing-requests',
          icon: <FontAwesomeIcon icon={faFileCode} />,
        },
        {
          label: 'Code Signing Certificate Requests',
          path: 'code-signing-certificate-requests',
          icon: <FontAwesomeIcon icon={faKey} />,
        },
      ],
    },
    {
      label: 'Inventory',
      path: 'inventory',
      options: [
        {
          label: 'Certificates and Keys',
          path: 'certificates',
          icon: <FontAwesomeIcon icon={faFile} />,
        },
        {
          label: 'Common Keys',
          path: 'common-keys',
          icon: <FontAwesomeIcon icon={faKey} />,
        },
      ],
    },
    {
      label: 'PKI',
      path: 'pki',
      options: [
        {
          label: 'Trees',
          path: 'trees',
          icon: <FontAwesomeIcon icon={faSitemap} />,
        },
        {
          label: 'CAs',
          path: 'cas',
          icon: <FontAwesomeIcon icon={faCogs} />,
        },
      ],
    },
    {
      label: 'Management',
      path: 'management',
      options: [
        {
          label: 'Certificate Templates',
          path: 'certificate-templates',
          icon: <FontAwesomeIcon icon={faFileAlt} />,
        },
        {
          label: 'Certificate Profiles',
          path: 'certificate-profiles',
          icon: <FontAwesomeIcon icon={faFileInvoice} />,
        },
        {
          label: 'Code Signing Profiles',
          path: 'code-signing-profiles',
          icon: <FontAwesomeIcon icon={faFileSignature} />,
        },
      ],
    },
    {
      label: 'Help',
      path: 'help',
      options: [
        {
          label: 'Documentation',
          path: '',
          externalLink: DOCUMENTATION_URL,
          icon: <FontAwesomeIcon icon={faBook} />,
        },
      ],
    },
  ];

  const reportsIndex = findIndex(leftMenuConfig, (i) => i.path === 'reports');

  const auditLogsResource = find(
    resources,
    (item) => item.name === 'audit_log'
  );
  const canSeeAuditLogs = includes(auditLogsResource?.actions, 'read');

  if (canSeeAuditLogs) {
    set(leftMenuConfig, `${reportsIndex}.options`, [
      ...leftMenuConfig[reportsIndex].options,
      {
        label: 'Audit Logs',
        path: 'audit-logs',
        icon: <FontAwesomeIcon icon={faListUl} />,
      },
    ]);
  }

  const certificateStatsResource = find(
    resources,
    (item) => item.name === 'certificate_stats'
  );
  const canSeeCertificateStats = includes(
    certificateStatsResource?.actions,
    'read'
  );

  if (canSeeCertificateStats) {
    set(leftMenuConfig, `${reportsIndex}.options`, [
      ...leftMenuConfig[reportsIndex].options,
      {
        label: 'Device Provisioning',
        path: 'device-provisioning',
        icon: <FontAwesomeIcon icon={faPaste} />,
      },
    ]);
  }

  if (isEmpty(leftMenuConfig[reportsIndex].options)) {
    remove(leftMenuConfig, (item, index) => index === reportsIndex);
  }
  const managementIndex = findIndex(
    leftMenuConfig,
    (i) => i.path === 'management'
  );
  const codeSigningIndex = findIndex(
    leftMenuConfig[managementIndex].options,
    (option) => option.path === 'code-signing-profiles'
  );

  const inventoryIndex = findIndex(
    leftMenuConfig,
    (option) => option.path === 'inventory'
  );

  const operationsIndex = findIndex(
    leftMenuConfig,
    (option) => option.path === 'operations'
  );

  if (canSeeAsymmetric) {
    leftMenuConfig[inventoryIndex].options.splice(
      leftMenuConfig[inventoryIndex].options.length - 1,
      0,
      {
        label: 'Asymmetric Keys',
        path: 'keys/asymmetric',
        icon: <FontAwesomeIcon icon={faCopy} />,
      }
    );
    leftMenuConfig[managementIndex].options.splice(codeSigningIndex + 1, 0, {
      label: 'Product Profiles',
      path: 'product-profiles',
      icon: <FontAwesomeIcon icon={faMicrochip} />,
    });
  }

  if (isDemoCustomer) {
    set(leftMenuConfig, `${inventoryIndex}.options`, [
      ...leftMenuConfig[inventoryIndex].options,
      {
        label: 'Devices',
        path: 'devices',
        icon: <FontAwesomeIcon icon={faMicrochip} />,
      },
    ]);
  }
  const location = useLocation();
  const history = useHistory();
  const userResources = find(resources, (item) => item.name === 'user');
  const manufacturingStationsResources = find(
    resources,
    (item) => item.name === 'manufacturing_station'
  );
  const canSync = includes(userResources?.actions, 'sync');

  const navMenuConfig = [
    {
      label: 'Settings',
      onClick: (): void => {
        history.push({
          pathname: location.pathname,
          search: '?settings=true',
        });
      },
      icon: <FontAwesomeIcon icon={faUserCog} />,
    },
    {
      label: 'Profile',
      onClick: (): void => {
        history.push({
          pathname: '/user',
        });
      },
      icon: <FontAwesomeIcon icon={faUserEdit} />,
    },
    {
      hidden: size(customers) <= 1,
      label: 'Customer',
      onClick: (): void => {
        history.push({
          pathname: location.pathname,
          search: '?customers=true',
        });
      },
      icon: <FontAwesomeIcon icon={faUser} />,
    },
    {
      hidden: !canSync,
      label: 'Sync Users',
      onClick: (): void => {
        dispatch(syncUsers());
      },
      icon: <FontAwesomeIcon spin={isSyncUsers} icon={faSync} />,
    },
    {
      label: 'Logout',
      onClick: (): void => {
        logout();
      },
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
    },
  ];

  const clientResource = find(resources, (item) => item.name === 'client');
  const canSeeClients = includes(clientResource?.actions, 'read');

  const canSeeUsers = includes(userResources?.actions, 'manage');
  const canSeeManufacturingStations =
    isUserCustomerRole('admin', entityRoles, currentCustomerUuid) ||
    (includes(manufacturingStationsResources?.actions, 'read') &&
      manufacturingStationsList.manufacturingStationsList.length &&
      (isUserRole('ca_admin', entityRoles) ||
        isUserCustomerRole('auditor', entityRoles, currentCustomerUuid)));

  if (canSeeClients) {
    set(leftMenuConfig, `${managementIndex}.options`, [
      ...leftMenuConfig[managementIndex].options,
      {
        label: 'API Clients',
        path: 'api-clients',
        icon: <FontAwesomeIcon icon={faThList} />,
      },
    ]);
  }

  if (canSeeUsers) {
    set(leftMenuConfig, `${managementIndex}.options`, [
      ...leftMenuConfig[managementIndex].options,
      {
        label: 'Users',
        path: 'users',
        icon: <FontAwesomeIcon icon={faUserCog} />,
      },
    ]);
  }

  if (canSeeManufacturingStations) {
    set(leftMenuConfig, `${managementIndex}.options`, [
      ...leftMenuConfig[managementIndex].options,
      {
        label: 'Manufacturing Stations',
        path: 'manufacturing-stations',
        icon: <FontAwesomeIcon icon={faIndustry} />,
      },
    ]);
  }

  if (isDemoCustomer) {
    set(leftMenuConfig, `${operationsIndex}.options`, [
      ...leftMenuConfig[operationsIndex].options,
      {
        label: 'Device Batch Requests',
        path: 'device-batch-requests',
        icon: <FontAwesomeIcon icon={faKey} />,
      },
    ]);
  }

  return (
    <div className="PKIApp">
      <Layout
        user={userProfile}
        leftMenuConfig={leftMenuConfig}
        navMenuConfig={navMenuConfig}
        customers={customers}
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/irdeto-key-central/overview" />
          </Route>
          <Route exact path="/management">
            <Redirect to="/management/certificate-templates" />
          </Route>
          <Route
            exact
            path="/management/certificate-templates"
            component={CertificateTemplates}
          />
          <Route
            exact
            path="/management/certificate-profiles"
            component={CertificateProfiles}
          />
          <Route
            exact
            path="/management/code-signing-profiles"
            component={CodeSigningProfiles}
          />
          {canSeeAsymmetric && (
            <Route
              exact
              path="/management/product-profiles"
              component={ProductProfiles}
            />
          )}
          {canSeeAsymmetric && (
            <Route
              exact
              path="/management/product-profiles/:id"
              render={() => <ProductProfile />}
            />
          )}
          {canSeeAsymmetric && (
            <Route
              exact
              path="/management/product-profiles/:id/keys"
              render={() => <ProductProfile tab={'PRODUCT_KEYS'} />}
            />
          )}
          {isDemoCustomer && (
            <Route
              exact
              path="/management/product-profiles/:id/metadata"
              render={() => <ProductProfile tab={'METADATA'} />}
            />
          )}
          {isDemoCustomer && (
            <Route
              exact
              path="/management/product-profiles/:id/secrets"
              render={() => <ProductProfile tab={'SECRETS'} />}
            />
          )}
          {isDemoCustomer && (
            <Route
              exact
              path="/management/product-profiles/:id/certificate-profiles"
              render={() => <ProductProfile tab={'CERTIFICATE_PROFILES'} />}
            />
          )}
          {isDemoCustomer && (
            <Route
              exact
              path="/management/product-profiles/:id/pgp-keys"
              render={() => <ProductProfile tab={'PGP_KEYS'} />}
            />
          )}
          {isDemoCustomer && (
            <Route
              exact
              path="/management/product-profiles/:id/output"
              render={() => (
                <ProductProfile tab={'FACTORY_PROVISIONING_DATA'} />
              )}
            />
          )}
          {canSeeClients && (
            <Route exact path="/management/api-clients" component={Clients} />
          )}
          {canSeeUsers && (
            <Route exact path="/management/users" component={Users} />
          )}
          {canSeeManufacturingStations && (
            <Route
              exact
              path="/management/manufacturing-stations"
              component={ManufacturingStationsList}
            />
          )}
          {canSeeManufacturingStations && (
            <Route
              exact
              path="/management/manufacturing-stations/:id"
              render={(): ReactElement => <ManufacturingStation tab={0} />}
            />
          )}
          {canSeeManufacturingStations && (
            <Route
              exact
              path="/management/manufacturing-stations/:id/provisioning-profiles"
              render={(): ReactElement => <ManufacturingStation tab={1} />}
            />
          )}
          {canSeeManufacturingStations && (
            <Route
              exact
              path="/management/manufacturing-stations/:id/secure-tokens"
              render={(): ReactElement => <ManufacturingStation tab={2} />}
            />
          )}
          <Route exact path="/pki">
            <Redirect to="/pki/trees" />
          </Route>
          <Route exact path="/pki/trees" component={Trees} />
          <Route exact path="/pki/cas">
            <CAs />
          </Route>
          {/* Inventory section */}
          <Route exact path={'/inventory'}>
            <Redirect to="/inventory/certificates" />
          </Route>
          <Route exact path={'/inventory/certificates-and-keys'}>
            <Redirect to="/inventory/certificates" />
          </Route>
          <Route
            exact
            path="/inventory/certificates"
            component={Certificates}
          />
          {isDemoCustomer && (
            <Route exact path="/inventory/devices" component={Devices} />
          )}
          {isDemoCustomer && (
            <Route exact path="/inventory/devices/:uuid" component={Device} />
          )}
          <Route exact path="/inventory/common-keys" component={CommonKeys} />
          <Route exact path="/inventory/keys">
            <Redirect to="/inventory/keys/asymmetric" />
          </Route>
          {canSeeAsymmetric && (
            <Route
              exact
              path="/inventory/keys/asymmetric"
              component={AsymmetricKeys}
            />
          )}
          {/* Operations section */}
          <Route exact path="/operations">
            <Redirect to="/operations/certificate-requests" />
          </Route>
          <Route
            exact
            path="/operations/certificates/:serial/download"
            component={CertificateDownload}
          />
          <Route
            key={RequestType.Certificate}
            exact
            path="/operations/certificate-requests"
            render={(): ReactElement => <CertificateRequests />}
          />
          <Route
            key={RequestType.CodeSigning}
            exact
            path="/operations/code-signing-requests"
            render={(): ReactElement => <CodeSigningRequests />}
          />
          <Route
            key={RequestType.CodeSigningCertificate}
            exact
            path="/operations/code-signing-certificate-requests"
            render={(): ReactElement => <CodeSigningCertificateRequests />}
          />
          {isDemoCustomer && (
            <Route
              key={RequestType.DeviceBatch}
              exact
              path="/operations/device-batch-requests"
              render={(): ReactElement => <DeviceBatchRequests />}
            />
          )}
          {canSeeAuditLogs && (
            <Route exact path="/reports/audit-logs" component={AuditLogs} />
          )}
          {canSeeCertificateStats && (
            <Route
              exact
              path="/reports/device-provisioning"
              component={DeviceProvisioning}
            />
          )}
          <Route exact path="/irdeto-key-central">
            <Redirect to="/irdeto-key-central/overview" />
          </Route>
          <Route
            exact
            path="/irdeto-key-central/overview"
            component={Overview}
          />
          <Route path="/user" exact>
            <UserProfile />
          </Route>
          <Route path={'*'} component={NotFound}></Route>
        </Switch>
        <Route path="/">
          <Settings />
          <Customer customers={customers} />
        </Route>
        <Notification />
      </Layout>
    </div>
  );
};

export default PKIApp;
