import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { map } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { StatisticsActionTypes, StatisticsState } from './types';
import { sendNotification } from '../notifications/actions';
import { deserializeLoginStat, deserializeHSMStat } from './helpers';

import { api } from '../../libs/helpers';

const error = require('debug')('pki-app:error:statistics');

export const getLoginStats = (
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, StatisticsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<StatisticsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: StatisticsActionTypes.GET_LOGIN_STATST_REQUEST,
    });
    try {
      const response = await api().get('stats/event/login/week', {
        cancelToken: tokenSource?.token,
      });
      const payload = map(response.data, (item) => deserializeLoginStat(item));
      dispatch({
        type: StatisticsActionTypes.GET_LOGIN_STATST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      if (!axios.isCancel(err)) {
        dispatch({
          type: StatisticsActionTypes.GET_LOGIN_STATST_ERROR,
          payload: err,
        });
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the Login Statistics!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getLoginStats: ${err}`);
      return false;
    }
  };
};

export const getHSMStats = (
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, StatisticsState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<StatisticsState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: StatisticsActionTypes.GET_HSM_STATST_REQUEST,
    });
    try {
      const response = await api().get('status', {
        cancelToken: tokenSource?.token,
      });
      const payload = deserializeHSMStat(response.data);
      dispatch({
        type: StatisticsActionTypes.GET_HSM_STATST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      if (!axios.isCancel(err)) {
        dispatch({
          type: StatisticsActionTypes.GET_HSM_STATST_ERROR,
          payload: err,
        });
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching the HSM Status!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      }
      error(`getHSMStats: ${err}`);
      return false;
    }
  };
};
