import { faEdit, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, get, includes } from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge, Button, Modal } from 'reactstrap';
import { Table, TableActions } from '../../../components';
import { ApplicationState } from '../../../store';
import {
  createSecureToken,
  getSecureTokens,
  updateSecureToken,
} from '../../../store/manufacturingStations/actions';
import {
  ManufacturingStationsState,
  SecureToken,
} from '../../../store/manufacturingStations/types';
import { UsersState } from '../../../store/users/types';
import SecureTokensModal from './SecureTokensModal';

interface Props {
  manufacturingStationUuid: string;
}

const SecureTokensList: FC<Props> = ({ manufacturingStationUuid }) => {
  const {
    isEditingSecureTokens,
    currentSecureTokens,
    userProfile: { resources },
  } = useSelector<ApplicationState, ManufacturingStationsState & UsersState>(
    (pki) => ({
      ...pki.manufacturingStations,
      ...pki.users,
    })
  );
  const dispatch = useDispatch();
  const [secureTokensModal, setSecureTokensModal] = useState<boolean>(false);
  const [secureToken, setSecureToken] = useState<SecureToken>();
  const history = useHistory();

  const manufacturingStationResources = find(
    resources,
    (item) => item.name === 'secure_token'
  );

  const actions = get(manufacturingStationResources, 'actions', []);
  const canCreate = includes(actions, 'create');

  useEffect(() => {
    if (!isEditingSecureTokens) {
      dispatch(getSecureTokens(manufacturingStationUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingSecureTokens]);

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'serialNumber',
      text: 'Serial Number',
      style: { width: '40%', textAlign: 'left' },
      headerClasses: 'text-left',
    },
    {
      dataField: 'certificateUuid',
      text: 'Certificate UUID',
      style: { width: '50%', textAlign: 'left' },
      headerClasses: 'text-left',
    },
    {
      dataField: 'enabled',
      text: 'Status',
      style: { width: '5%', textAlign: 'center' },
      formatter: (status: boolean): ReactNode => (
        <h6>
          <Badge color={status ? 'success' : 'danger'}>
            {status ? 'Enabled' : 'Disabled'}
          </Badge>
        </h6>
      ),
    },
    {
      dataField: 'serial',
      text: 'Actions',
      csvExport: false,
      _formatter: (_: null, item: SecureToken): ReactNode => {
        const canEdit = includes(item.actions, 'update');

        return (
          <TableActions
            rowId={item.uuid}
            options={[
              {
                label: 'Edit',
                disabled: !canEdit,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: () => {
                  setSecureToken(item);
                  toggleSecureTokensModal();
                },
              },
              {
                label: 'Related Certificate',
                disabled: !item.certificateUuid,
                ico: (
                  <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />
                ),
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();

                  history.push({
                    pathname: '/inventory/certificates',
                    search: `?uuid=${item.certificateUuid}`,
                  });
                },
              },
            ]}
          />
        );
      },
      get formatter() {
        return this._formatter;
      },
      set formatter(value) {
        this._formatter = value;
      },
    },
  ];

  const toggleSecureTokensModal = (): void => {
    setSecureTokensModal(!secureTokensModal);
  };

  return (
    <div id="pki-roles-form" className="ManufacturingStationsSecureTokens">
      <div className="mt-5">
        <Table
          noDataIndication="No Secure Tokens yet"
          keyField="uuid"
          toolbar={
            <Button
              id="add-secure-token-button"
              outline
              disabled={!canCreate}
              style={{ marginRight: 10 }}
              onClick={() => {
                setSecureToken(undefined);
                toggleSecureTokensModal();
              }}
            >
              Add secure token
            </Button>
          }
          columns={columns}
          data={currentSecureTokens}
        />
      </div>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={secureTokensModal}
        toggle={toggleSecureTokensModal}
      >
        <SecureTokensModal
          manufacturingStationUuid={manufacturingStationUuid}
          onCancel={toggleSecureTokensModal}
          defaultValues={secureToken}
          onCreateSecureToken={(item: SecureToken): void => {
            dispatch(createSecureToken(item));

            toggleSecureTokensModal();
          }}
          onUpdateSecureToken={(item: SecureToken): void => {
            dispatch(updateSecureToken(item));
            toggleSecureTokensModal();
          }}
        />
      </Modal>
    </div>
  );
};

export default SecureTokensList;
