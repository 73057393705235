import React, { ChangeEventHandler, FC, useState } from 'react';
import { Form, Row, Col, Input, Label, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { ProductProfileOutputFile } from '../../../../../../types';
import { Select } from '../../../../../../../../components';

interface FileNamePatternProps {
  fileNamePatternConfig: ProductProfileOutputFile | undefined;
  onSubmit: (
    fileNamePatternConfig: ProductProfileOutputFile,
    onSuccess?: Function
  ) => void;
}

export const FileNamePattern: FC<FileNamePatternProps> = ({
  fileNamePatternConfig,
  onSubmit,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const defaultValues = {
    fileFormat: fileNamePatternConfig
      ? fileNamePatternConfig.fileFormat
      : 'csv',
    fileNamePattern: fileNamePatternConfig
      ? fileNamePatternConfig.fileNamePattern
      : '',
    filePathPattern: fileNamePatternConfig
      ? fileNamePatternConfig.filePathPattern
      : '',
  };
  const [fileConfig, setFileConfig] = useState<ProductProfileOutputFile>(
    fileNamePatternConfig || defaultValues
  );
  const saveDisabled =
    Object.keys(fileConfig).some((key) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      isEmpty(fileConfig[key])
    ) ||
    Object.keys(fileConfig).every(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (key) => fileConfig[key] === defaultValues[key]
    );

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setFileConfig({
      ...fileConfig,
      [ev.target.name]: ev.target.value,
    });
  };

  const onConfirmClick = () => {
    if (saveDisabled) return;
    onSubmit(fileConfig, toggleEditMode);
  };

  const toggleEditMode = (newValues?: ProductProfileOutputFile) => {
    setEditMode((prevMode) => {
      if (prevMode) {
        setFileConfig(newValues || defaultValues);
      }
      return !prevMode;
    });
  };

  return (
    <div className={'file-name-pattern'}>
      <Form>
        <Row className="g-3 align-items-end">
          <Col md={3}>
            <Label className="pki-label form-label" for="filePathPattern">
              File Path Pattern
            </Label>
            <Input
              id="filePathPattern"
              name="filePathPattern"
              placeholder="File path pattern"
              disabled={!editMode}
              type="text"
              value={fileConfig.filePathPattern}
              onChange={onInputChange}
            />
          </Col>
          <Col md={5}>
            <Label className="pki-label form-label" for="fileNamePattern">
              File Name Pattern
            </Label>
            <Input
              id="fileNamePattern"
              name="fileNamePattern"
              placeholder="File name pattern"
              type="text"
              disabled={!editMode}
              value={fileConfig.fileNamePattern}
              onChange={onInputChange}
            />
          </Col>
          <Col md={2}>
            <Select
              className="ml-2"
              name={'file-format'}
              selectedKey={fileConfig.fileFormat || 'csv'}
              options={[{ value: 'csv', key: 'csv' }]}
              disabled
              label="File Format"
            />
          </Col>
          <Col md={2} className={'d-inline-flex justify-content-end'}>
            {!editMode ? (
              <>
                <Label className={'pki-label'}> </Label>
                <Button
                  id={`edit-file-name-pattern`}
                  outline
                  onClick={() => toggleEditMode()}
                >
                  Edit
                </Button>
              </>
            ) : (
              <>
                <Label className={'pki-label'}> </Label>
                <div>
                  <Button
                    id={`cancel-file-name-pattern`}
                    outline
                    onClick={() => toggleEditMode()}
                    className={'mr-2'}
                  >
                    Cancel
                  </Button>
                  <Button
                    id={`submit-file-name-pattern`}
                    outline
                    onClick={onConfirmClick}
                    disabled={saveDisabled}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
