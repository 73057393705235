export interface CodeSigningProfile {
  uuid?: string;
  name: string;
  notes: string;
  codeSigningKeyUuid: string;
  signingAlgorithm: string;
  codeSigningProfileId?: string;
  certificateUuid?: string;
  signingHashAlgorithm: string;
  outputFormat: string;
  approvalPolicy: {
    approverUuids: string[];
    notification: boolean;
  };
  actions?: string[];
  certificateProfileUuid?: string;
}

export interface CodeSigningProfilesState {
  readonly isGettingCodeSigningProfileList: boolean;
  readonly isLoadedCodeSigningProfileList: boolean;
  readonly isCreatingCodeSigningProfile: boolean;
  readonly isDeletingCodeSigningProfile: boolean;
  readonly isEditingCodeSigningProfile: boolean;
  readonly codeSigningProfilesList: CodeSigningProfile[];
  readonly codeSigningProfilesErrors: unknown;
  readonly isGettingCodeSignProfileKeySpecs?: boolean;
  readonly isLoadedCodeSignProfileKeySpecs?: boolean;
  readonly codeSigningProfileKeySpecs: {
    signatureAlgorithm: { key: string; value: string }[];
    signatureHashAlgorithm: { key: string; value: string }[];
    outputFormat: { key: string; value: string }[];
  };
}

export enum CodeSigningProfilesActionTypes {
  GET_CODE_SIGNING_PROFILE_LIST_REQUEST = '@@CodeSigningProfiles/GET_CODE_SIGNING_PROFILE_LIST_REQUEST',
  GET_CODE_SIGNING_PROFILE_LIST_SUCCESS = '@@CodeSigningProfiles/GET_CODE_SIGNING_PROFILE_LIST_SUCCESS',
  GET_CODE_SIGNING_PROFILE_LIST_ERROR = '@@CodeSigningProfiles/GET_CODE_SIGNING_PROFILE_LIST_ERROR',

  GET_CODE_SIGNING_PROFILE_KEY_SPECS_REQUEST = '@@CodeSigningProfiles/GET_CODE_SIGNING_PROFILE_KEY_SPECS_REQUEST',
  GET_CODE_SIGNING_PROFILE_KEY_SPECS_SUCCESS = '@@CodeSigningProfiles/GET_CODE_SIGNING_PROFILE_KEY_SPECS_SUCCESS',
  GET_CODE_SIGNING_PROFILE_KEY_SPECS_ERROR = '@@CodeSigningProfiles/GET_CODE_SIGNING_PROFILE_KEY_SPECS_ERROR',

  POST_CODE_SIGNING_PROFILE_REQUEST = '@@CodeSigningProfiles/POST_CODE_SIGNING_PROFILE_REQUEST',
  POST_CODE_SIGNING_PROFILE_SUCCESS = '@@CodeSigningProfiles/POST_CODE_SIGNING_PROFILE_SUCCESS',
  POST_CODE_SIGNING_PROFILE_ERROR = '@@CodeSigningProfiles/POST_CODE_SIGNING_PROFILE_ERROR',

  DELETE_CODE_SIGNING_PROFILE_REQUEST = '@@CodeSigningProfiles/DELETE_CODE_SIGNING_PROFILE_REQUEST',
  DELETE_CODE_SIGNING_PROFILE_SUCCESS = '@@CodeSigningProfiles/DELETE_CODE_SIGNING_PROFILE_SUCCESS',
  DELETE_CODE_SIGNING_PROFILE_ERROR = '@@CodeSigningProfiles/DELETE_CODE_SIGNING_PROFILE_ERROR',

  PUT_CODE_SIGNING_PROFILE_REQUEST = '@@CertificateTemplate/PUT_CODE_SIGNING_PROFILE_REQUEST',
  PUT_CODE_SIGNING_PROFILE_SUCCESS = '@@CertificateTemplate/PUT_CODE_SIGNING_PROFILE_SUCCESS',
  PUT_CODE_SIGNING_PROFILE_ERROR = '@@CertificateTemplate/PUT_CODE_SIGNING_PROFILE_ERROR',
}
