import React, { FC } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface AwesomeCheckboxI {
  id: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: Function;
}

const AwesomeCheckbox: FC<AwesomeCheckboxI> = ({
  checked = false,
  disabled = false,
  onChange = (): null => null,
  id,
  label = '',
}) => {
  return (
    <div className="AwesomeCheckbox">
      <FormGroup check inline className={'custom-checkbox custom-control mr-0'}>
        <Input
          type="checkbox"
          id={id}
          label={label}
          checked={checked}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            onChange(event);
          }}
          className="cursor-pointer custom-control-input CheckboxList"
        />
        <Label check className={'custom-control-label'}>
          {label}
        </Label>
      </FormGroup>
    </div>
  );
};

export default AwesomeCheckbox;
