import _ from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { CancelTokenSource } from 'axios';
import { sendNotification } from '../notifications/actions';
import { Filter } from '../../components/FilterBar/types';

import {
  deserializeCertificateProfile,
  serializeCertificateProfile,
  deserializeProfileKeySpecs,
} from './helpers';
import {
  CertificateProfile,
  CertificateProfilesState,
  CertificateProfileActionTypes,
} from './types';
import { api, getUrlWithFilters } from '../../libs/helpers';

const error = require('debug')('pki-app:error:certificateProfiles');

interface RequestParams {
  action?: string;
  resource?: string;
  removeActions?: boolean;
  page?: number | undefined;
  sizePerPage?: number | undefined;
  uuid?: string | undefined;
  query?: Filter[];
  sortBy?: string;
  sortDir?: string;
}

export const getCertificateProfiles = (
  {
    action,
    resource,
    removeActions,
    sizePerPage,
    page,
    query,
    uuid,
    sortBy,
    sortDir,
  } = {} as RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateProfileActionTypes.GET_PROFILE_LIST_REQUEST });
    try {
      let url = 'certificate/profile';

      url = getUrlWithFilters(url, {
        sizePerPage,
        page,
        sortBy,
        sortDir,
        query,
        uuid,
      });

      if (action && resource) {
        url += `&resource=${resource}&action=${action}`;
      }

      if (removeActions) {
        url += `&with_actions=false`;
      }

      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificateProfile(item)
      );
      dispatch({
        type: CertificateProfileActionTypes.GET_PROFILE_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.GET_PROFILE_LIST_ERROR,
        payload: err,
      });
      if (err?.constructor?.name !== 'Cancel') {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong fetching Certificate Profiles list!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
        error(`getCertificateProfiles: ${err}`);
      }

      return false;
    }
  };
};

export const getProvisioningCertificateProfiles = (
  { action, resource, removeActions } = {} as RequestParams,
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificateProfileActionTypes.GET_PROVISIONING_PROFILE_LIST_REQUEST,
    });
    try {
      let url = 'certificate/profile';
      if (action && resource) {
        url += `?resource=${resource}&action=${action}`;
      }

      if (removeActions) {
        url += `&with_actions=false`;
      }

      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificateProfile(item)
      );
      dispatch({
        type:
          CertificateProfileActionTypes.GET_PROVISIONING_PROFILE_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.GET_PROVISIONING_PROFILE_LIST_ERROR,
        payload: err,
      });

      return false;
    }
  };
};

export const getAllCertificateProfiles = (
  tokenSource?: CancelTokenSource
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificateProfileActionTypes.GET_ALL_PROFILE_LIST_REQUEST,
    });
    try {
      const url = 'certificate/profile?with_actions=false';

      const response = await api().get(url, {
        cancelToken: tokenSource?.token,
      });
      const payload = _.map(response.data, (item) =>
        deserializeCertificateProfile(item)
      );
      dispatch({
        type: CertificateProfileActionTypes.GET_ALL_PROFILE_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.GET_ALL_PROFILE_LIST_ERROR,
        payload: err,
      });

      return false;
    }
  };
};

export const getProfileKeySpecs = (
  CAuuid: string,
  certificateProfileUuid?: string
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: CertificateProfileActionTypes.GET_PROFILE_KEY_SPECS_REQUEST,
    });
    try {
      const params: any = {};
      if (certificateProfileUuid) {
        params.certificate_profile_uuid = certificateProfileUuid;
      }
      const response = await api().get(
        `certificate/profile/key_specs/${CAuuid}`,
        {
          params,
        }
      );
      const payload = deserializeProfileKeySpecs(response.data);
      dispatch({
        type: CertificateProfileActionTypes.GET_PROFILE_KEY_SPECS_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.GET_PROFILE_KEY_SPECS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Certificate Profile Key Specs!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getProfileKeySpecs: ${err}`);
      return false;
    }
  };
};

export const createCertificateProfile = (
  certificateProfile: CertificateProfile
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  const certificateProfileSerialized = serializeCertificateProfile(
    certificateProfile
  );
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateProfileActionTypes.POST_PROFILE_REQUEST });
    try {
      const payload = await api().post('certificate/profile', {
        ...certificateProfileSerialized,
      });
      dispatch({
        type: CertificateProfileActionTypes.POST_PROFILE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Certificate Profile has been successfully created!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.POST_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the Certificate Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createCertificateProfile: ${err}`);
      return false;
    }
  };
};

export const editCertificateProfile = (
  certificateProfile: CertificateProfile
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  const { uuid } = certificateProfile;
  const certificateProfileSerialized = serializeCertificateProfile(
    certificateProfile
  );
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateProfileActionTypes.PUT_PROFILE_REQUEST });
    try {
      const payload = await api().put(`certificate/profile/${uuid}`, {
        ...certificateProfileSerialized,
      });
      dispatch({
        type: CertificateProfileActionTypes.PUT_PROFILE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Certificate Profile has been successfully edited!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.PUT_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong editing the Certificate Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`editCertificateProfile: ${err}`);
      return false;
    }
  };
};

export const deleteCertificateProfiles = (
  uuids: string[]
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateProfileActionTypes.DELETE_PROFILE_REQUEST });
    try {
      const payload = await api().delete(
        `certificate/profile?uuids=${_.join(uuids, ',')}`
      );
      dispatch({
        type: CertificateProfileActionTypes.DELETE_PROFILE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Certificate Profile has been successfully deleted!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.DELETE_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong deleting the Certificate Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`deleteCertificateProfiles: ${err}`);
      return false;
    }
  };
};

export const archiveCertificateProfiles = (
  uuid: string
): ThunkAction<Promise<boolean>, CertificateProfilesState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CertificateProfilesState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CertificateProfileActionTypes.ARCHIVE_PROFILE_REQUEST });
    try {
      const payload = await api().delete(
        `entity/certificate_profile/${uuid}/roles`
      );
      dispatch({
        type: CertificateProfileActionTypes.ARCHIVE_PROFILE_SUCCESS,
        payload,
      });
      sendNotification({
        text: 'The Certificate Profile has been successfully archived!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CertificateProfileActionTypes.ARCHIVE_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong archiving the Certificate Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`deleteCertificateProfiles: ${err}`);
      return false;
    }
  };
};
