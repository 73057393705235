import {
  Button,
  Card,
  Col,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import React, {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import _, { kebabCase } from 'lodash';
import classnames from 'classnames';
import axios, { CancelToken } from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { faChevronLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '../../../components';
import { MetadataSecrets } from './components/MetadataSecrets';
import { Device as DeviceType } from '../types';
import { api } from '../../../libs/helpers';
import { deserializeDevice } from '../helpers';
import { sendNotification } from '../../../store/notifications/actions';
import { GeneralInfo } from './components/GeneralInfo';

const navMenu: any = {
  METADATA: 'Metadata',
  SECRETS: 'Secrets',
};

const Device: FC = () => {
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [quickSearchLoading, setQuickSearchLoading] = useState<boolean>(false);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState(navMenu.METADATA);
  const [device, setDevice] = useState<DeviceType>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { uuid: deviceUuid }: { uuid: string } = useParams();

  const fetchDevice = async (
    cancelToken: CancelToken,
    searchValue?: string
  ) => {
    if (!deviceUuid && !searchValue) return;
    searchValue ? setQuickSearchLoading(true) : setShowSpinner(true);
    const endpoint = searchValue
      ? `?filter=device_id=${searchValue}`
      : `/${deviceUuid}`;
    try {
      const { data } = await api().get(`device${endpoint}`, {
        cancelToken,
      });
      const fetchedDevice = deserializeDevice(searchValue ? data[0] : data);
      setDevice(fetchedDevice);
      if (searchValue) {
        window.history.pushState(
          null,
          '',
          `/inventory/devices/${fetchedDevice.uuid}`
        );
      }
    } catch (err: any) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Something went wrong fetching the Device!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setShowSpinner(false);
      if (searchValue) {
        setQuickSearchValue('');
        setQuickSearchLoading(false);
      }
    }
  };
  useEffect(() => {
    const tokenSource = axios.CancelToken.source().token;
    fetchDevice(tokenSource);
  }, []);

  const renderNav = (): ReactNode => (
    <Nav tabs className="device-tabs">
      {_.map(Object.values(navMenu), (label: string, index: number) => (
        <NavItem
          id={`nav-${kebabCase(label)}`}
          key={index}
          className="cursor-pointer pki-label"
        >
          <NavLink
            className={classnames({ active: activeTab === label })}
            onClick={(): void => {
              setActiveTab(label);
            }}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const onBackButtonClick = () => {
    history.push('/inventory/devices');
  };

  const onChangeQuickSearch: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setQuickSearchValue(ev.target.value);
  };

  const handleQuickSearch: MouseEventHandler<HTMLButtonElement> = async () => {
    if (quickSearchValue) {
      const tokenSource = axios.CancelToken.source().token;
      await fetchDevice(tokenSource, quickSearchValue);
    }
  };

  return (
    <div className="Device">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex align-items-center mb-4">
          <div className="single-page-header">
            <FontAwesomeIcon
              onClick={onBackButtonClick}
              style={{ marginRight: '0.5rem' }}
              size="2x"
              className="pki-ico"
              icon={faChevronLeft}
            />
            <h3 className="text-muted">{device?.deviceId || ''}</h3>
            {showSpinner && (
              <Spinner className="mt-2 ml-2" size="sm" type="border" />
            )}
          </div>
        </div>
        <Collapse isOpen={!showSpinner}>
          <div className="mt-3">
            <Row>
              <Col xs={12} md={3} className="border-right">
                <div className={'d-block mb-4'}>
                  <Label className={'pki-label'} for={'quick-search'}>
                    Device ID (base-mac)
                  </Label>
                  <div className={'d-flex'}>
                    <Input
                      id={'quick-search'}
                      value={quickSearchValue}
                      onChange={onChangeQuickSearch}
                    />
                    <Button
                      size={'sm'}
                      id={'quick-search-submit'}
                      color={'secondary'}
                      outline
                      style={{
                        marginLeft: '5px',
                        border: '1px solid #ced4da',
                      }}
                      onClick={handleQuickSearch}
                    >
                      {quickSearchLoading ? (
                        <Spinner size="sm" type="border" />
                      ) : (
                        <FontAwesomeIcon size="1x" icon={faSearch} />
                      )}
                    </Button>
                  </div>
                </div>
                <GeneralInfo device={device} />
              </Col>
              <Col xs={12} md={9}>
                {renderNav()}
                <TabContent
                  className="pt-3 device-tab-content"
                  activeTab={activeTab}
                >
                  {activeTab === navMenu.METADATA && (
                    <TabPane tabId={navMenu.METADATA}>
                      <MetadataSecrets type={'metadata'} device={device} />
                    </TabPane>
                  )}
                  {activeTab === navMenu.SECRETS && (
                    <TabPane tabId={navMenu.SECRETS}>
                      <MetadataSecrets type={'secrets'} device={device} />
                    </TabPane>
                  )}
                </TabContent>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

export default Device;
