import React, { FC, useRef, useState } from 'react';
import { Button, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import _, { isEmpty, isNil, set, size, split } from 'lodash';
import { FileViolations } from '../../components';
import { getVars } from '../../libs/provisioning';
import { formatBytes } from '../../libs/helpers';
import { Certificate } from '../../store/certificates/types';
import { CertificateProfile } from '../../store/certificateProfiles/types';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificateProfile } from '../../store/certificateProfiles/helpers';

interface Props {
  onClose: Function;
  onUpload: Function;
}

const UploadWrappedKeyCertificate: FC<Props> = ({
  onClose = (): null => null,
  onUpload = (): null => null,
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [currentFile, setCurrentFile] = useState<File>();

  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);

  const [currentCertificateProfile, setCurrentCertificateProfile] = useState<
    CertificateProfile | undefined
  >(undefined);

  const fileViolations = ((
    filename: string | undefined
  ): { [key: string]: string } => {
    if (isNil(filename)) {
      return {};
    }
    const [groupId, customer, env, timestamp] = split(filename, '_');

    const {
      PKI_SELECTED_CUSTOMER_CODE: currentCustomer,
      ENV: currentEnv,
    } = getVars();

    const output: { [key: string]: string } = {};

    const groupIdViolation =
      isEmpty(groupId) || !/^\d+$/.test(groupId) || size(groupId) !== 3;
    const timestampViolation =
      isEmpty(timestamp) || new Date(timestamp).getTime() > 0;
    const customerViolation = isEmpty(customer) || customer !== currentCustomer;
    const envViolation = isEmpty(env) || env !== currentEnv;
    const fileExtension = _((filename as unknown) as string[])
      .takeRight(6)
      .join('');

    const extensionViolation = fileExtension !== 'tar.gz';

    groupIdViolation && (output.groupId = 'Wrong Group ID format!');
    customerViolation && (output.customer = 'Wrong Customer name!');
    envViolation && (output.env = 'Wrong Environment variable!');
    timestampViolation && (output.timestamp = 'Timestamp not valid!');
    extensionViolation &&
      (output.extension = 'File Extension not allowed! Must be .tar.gz');
    return output;
  })(currentFile?.name);

  return (
    <div className="UploadWrappedKeyCertificate">
      <div className="modal-content p-4">
        <span className="text-muted mx-4">
          <h3>Upload Wrapped Key and Certificate</h3>
        </span>
        <span className="description mx-4 mt-4">
          <p>
            Uploading Wrapped Key and Certificate allows externally generated
            key and certificate to be added to Key Central.
          </p>
          <p>
            Key will be marked as online and available for operations after
            upload.
          </p>
          <p>Filename must match the following pattern:</p>
          <p>
            {`<group-id>_<customer>_<env>_<timestamp>_<original-filename-without-ext>_wrapped_key_and_cert.tar.gz`}
          </p>
        </span>
        <div className="my-5 mx-4">
          <Row>
            <Col md={6}>
              <Label className="pki-label">Issuing CA</Label>
              <ServerSideSelect
                onSelectEntity={(ca) => {
                  setCurrentIssuingCA(() => {
                    setCurrentCertificateProfile(undefined);
                    return deserializeCertificate(ca);
                  });
                }}
                formatter={(ca) => ca.cn}
                fetchUrl={`certificate/authority`}
                defaultFilters={'is_key_online=true'}
                searchParam={`cn`}
                value={currentIssuingCA?.commonName || ''}
              />
            </Col>
            <Col md={6}>
              <Label className="pki-label">Certificate Profile</Label>
              <ServerSideSelect
                onSelectEntity={(profile) => {
                  setCurrentCertificateProfile(
                    deserializeCertificateProfile(profile)
                  );
                }}
                formatter={(profile) => profile?.certificate_profile?.name}
                fetchUrl={`certificate/profile`}
                defaultFilters={`issuing_ca_uuid=${currentIssuingCA?.uuid}`}
                searchParam={`certificate_profile.name`}
                wait={!currentIssuingCA}
                value={
                  currentCertificateProfile?.name ||
                  'Select a certificate profile'
                }
                urlParams={[
                  ['resource', 'certificate_request'],
                  ['action', 'create'],
                  ['with_actions', 'false'],
                ]}
                error={
                  isNil(currentCertificateProfile) && showValidation
                    ? 'Cannot be empty, please select one!'
                    : undefined
                }
              />
            </Col>
          </Row>

          <div className="mt-3">
            <Label className="pki-label">Wrapped Key and Certificate</Label>
            <Row>
              <Col className="d-flex" md={3}>
                <Button
                  outline
                  id="upload-key-button"
                  onClick={(): void => {
                    if (inputEl && inputEl.current) {
                      inputEl.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon className="mr-1" icon={faUpload} />
                  Select
                </Button>
                <div className="mt-auto ml-1">
                  <code className="black-code">.tar.gz</code>
                </div>
              </Col>
              {currentFile && (
                <Col className="d-flex" md={8}>
                  <div id="file-description" className="text-truncate mt-auto">
                    {currentFile.name} ({formatBytes(currentFile.size)})
                  </div>
                </Col>
              )}
              {currentFile && (
                <Col>
                  <FontAwesomeIcon
                    className="pki-ico file-delete mt-4"
                    onClick={(): void => {
                      setCurrentFile(undefined);
                      set(inputEl, 'current.value', null);
                    }}
                    icon={faTrash}
                  />
                </Col>
              )}
            </Row>
            {isNil(currentFile) && showValidation && (
              <div className="invalid-text">
                Cannot be empty, please select a file!
              </div>
            )}
            <input
              type="file"
              id="file-input"
              className="d-none"
              ref={inputEl}
              onChange={({ target: { files } }): void => {
                if (files && files.length > 0) {
                  setCurrentFile(files[0]);
                  set(inputEl, 'current.value', null);
                }
              }}
            />
            {!isNil(currentFile) && (
              <div className="file-violations mt-3">
                <FileViolations violations={fileViolations} />
              </div>
            )}
          </div>
        </div>

        <div className="mt-3 d-flex">
          <Button
            outline
            className="ml-auto"
            onClick={(): void => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            id="confirm-button"
            className="ml-3"
            onClick={(): void => {
              setShowValidation(true);
              onUpload({
                error:
                  isNil(currentFile) ||
                  !isEmpty(fileViolations) ||
                  isEmpty(currentCertificateProfile?.uuid),
                data: {
                  wrappedKey: currentFile,
                  certificateProfileUuid: currentCertificateProfile?.uuid,
                },
              });
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
      <input
        type="file"
        id="file-input"
        className="d-none"
        ref={inputEl}
        onChange={({ target: { files } }): void => {
          if (files && files.length > 0) {
            setCurrentFile(files[0]);
          }
          set(inputEl, 'current.value', null);
        }}
      />
    </div>
  );
};

export default UploadWrappedKeyCertificate;
