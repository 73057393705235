import React, { FC, useEffect, useState } from 'react';
import { get, isEmpty, pick } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Select, Spinner } from '../../../../../../../components';
import { Key, ProductKey, ProductKeyUsage } from '../../../../../../keys/types';
import { ServerSideSelect } from '../../../../../../../components/ServerSideSelect/ServerSideSelect';
import { deserializeKey } from '../../../../../../keys/AsymmetricKeys/helpers';

const defaultValues = {
  keyUsage: ProductKeyUsage.SIGN,
  uuid: undefined,
};

const formKeys = ['uuid', 'keyUsage'];

interface ProductKeyFormProps {
  readOnly: boolean;
  createMode?: boolean;
  productKey?: ProductKey;
  onSubmit?: (formValues: Partial<ProductKey>) => void;
  onModeChange?: Function;
  isLoading?: boolean;
  onCancel: Function;
  profileId: string;
}

export const ProductKeyForm: FC<ProductKeyFormProps> = ({
  readOnly,
  productKey,
  onSubmit,
  onModeChange = () => undefined,
  isLoading = false,
  onCancel,
  createMode = false,
  profileId,
}) => {
  const [formValues, setFormValues] = useState<Partial<ProductKey>>(
    defaultValues
  );
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [currentKey, setCurrentKey] = useState<Key>();
  useEffect(() => {
    setFieldsWithError([]);
    if (productKey && !createMode) {
      setFormValues(pick(productKey, formKeys));
    }
  }, [readOnly, productKey, onSubmit, createMode]);

  const { uuid, keyUsage = '' } = formValues;

  const validateAndSubmit = () => {
    const errors: string[] = [];
    ['keyUsage'].forEach((property) => {
      if (isEmpty(get(formValues, property))) {
        errors.push(property);
      }
    });
    setFieldsWithError(errors);
    if (errors.length === 0 && onSubmit !== undefined) {
      onSubmit(formValues);
    }
  };
  const onKeyUsageChange = ({ key }: { key: ProductKeyUsage }) => {
    setFormValues({
      ...formValues,
      keyUsage: key,
    });
  };

  return (
    <div id={'ProductKeyForm'}>
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>{createMode && 'Add '}Product Key</h3>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              setFormValues(pick(productKey, formKeys));
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="label">
                {createMode ? 'Key' : 'Label'}
              </Label>
              {createMode ? (
                <ServerSideSelect
                  onSelectEntity={(key: Key) => {
                    setCurrentKey(() => {
                      setFormValues({
                        ...formValues,
                        uuid: key.uuid,
                      });
                      return deserializeKey(key);
                    });
                  }}
                  formatter={(key) => `${key.label} (${key.key_id})`}
                  fetchUrl={`product-profile/${profileId}/allowed-keys`}
                  searchParam={`label`}
                  id={'product-key-form-label'}
                  value={currentKey?.label || ''}
                />
              ) : (
                <Input
                  id="label"
                  value={(currentKey || productKey)?.label || ''}
                  readOnly={true}
                  plaintext={true}
                  type="text"
                  name="label"
                />
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Select
                selectedKey={formValues.keyUsage}
                options={[
                  { value: 'Sign', key: ProductKeyUsage.SIGN },
                  { value: 'Verify', key: ProductKeyUsage.VERIFY },
                ]}
                onChange={onKeyUsageChange}
                label="Key Usage"
                readOnly={readOnly}
                id={'keyUsage'}
                invalid={fieldsWithError?.includes('keyUsage')}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="pki-label" for="keyId">
                Key ID
              </Label>
              <Input
                id="keyId"
                invalid={fieldsWithError?.includes('keySpec')}
                value={(currentKey || productKey)?.keyId || ''}
                readOnly={true}
                plaintext={true}
                type="text"
                name="keyId"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="pki-label" for="keyDigest">
                Key Digest
              </Label>
              <Input
                id="keyDigest"
                invalid={fieldsWithError?.includes('keyDigest')}
                value={(currentKey || productKey)?.keyDigest || ''}
                readOnly={true}
                plaintext={true}
                type="text"
                name="keyDigest"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label" for="notes">
                Notes
              </Label>
              <Input
                id="notes"
                value={(currentKey || productKey)?.notes || ''}
                placeholder={'N/A'}
                readOnly={true}
                plaintext={true}
                type="textarea"
                name="notes"
              />
            </FormGroup>
          </Col>
        </Row>
        {!readOnly && onSubmit !== undefined && (
          <div className="modal-buttons">
            <div className="float-right mt-5 pb-5 d-flex">
              <>
                <span className="mr-2">
                  <Button
                    id={`close-product-key-form`}
                    outline
                    disabled={readOnly || isLoading}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
                <span>
                  <Button
                    id={`submit-product-key-form`}
                    outline
                    disabled={readOnly || isLoading || !currentKey}
                    onClick={validateAndSubmit}
                  >
                    Confirm
                  </Button>
                </span>
              </>
              {isLoading && (
                <Spinner
                  size={'sm'}
                  className={'ml-2 btn-group-vertical'}
                  type={'border'}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
