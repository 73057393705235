import React, { FC, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './UploadAsymmetricKeys.scss';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Upload } from './components/Upload/Upload';
import { Assign } from './components/Assign/Assign';
import { AsymmetricKey, Key } from '../../../types';
import { deserializeAsymmetricKey } from '../../helpers';
import { sendNotification } from '../../../../../store/notifications/actions';
import { api } from '../../../../../libs/helpers';

interface UploadAsymmetricKeysProps {
  onCancel: () => void;
  fetchDataTable: Function;
}

const idClassPrefix = 'upload-asymmetric-keys';

const steps = {
  UPLOAD: 'upload',
  ASSIGN: 'assign',
};
export const UploadAsymmetricKeys: FC<UploadAsymmetricKeysProps> = ({
  onCancel,
  fetchDataTable,
}) => {
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedKeys, setUploadedKeys] = useState<AsymmetricKey[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<
    typeof steps.UPLOAD | typeof steps.ASSIGN
  >(steps.UPLOAD);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const disabledButtons =
    isLoading || filesToUpload.length === 0 || uploadCompleted;
  const handleUpload = async () => {
    if (uploadCompleted || filesToUpload.length === 0) return;
    setIsLoading(true);
    const axiosConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    filesToUpload?.forEach((file) => {
      formData.append('ext_key_tar_files', file);
    });
    try {
      const {
        data: { keys, errors },
      } = await api().post('key/asymmetric/upload', formData, axiosConfig);
      setUploadCompleted(true);
      setUploadedKeys(
        keys?.map((rawKey: any) => deserializeAsymmetricKey(rawKey))
      );
      sendNotification({
        text: `The key${
          keys.length > 1 ? 's have been' : ' has been'
        } uploaded successfully!`,
        success: true,
      })(dispatch);
      if (errors.length > 0) {
        sendNotification({
          text: errors.join('\n'),
          success: false,
        })(dispatch);
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong uploading the key files!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignKeysToProductProfile = async (
    productProfileId: string,
    keys: string[]
  ) => {
    setIsLoading(true);
    try {
      await api().post(`product-profile/${productProfileId}/keys`, {
        keys: keys.map((keyUuid) => ({
          uuid: keyUuid,
        })),
      });
      onCancel();
      sendNotification({
        text: `The key${
          keys.length > 1 ? 's are' : ' is'
        } successfully assigned to the product profile!`,
        success: true,
      })(dispatch);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong uploading the key files!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  const onUploadButtonClick = async () => {
    await handleUpload();
    await fetchDataTable();
    onCancel();
  };

  const onUploadAndAssignButtonClick = async () => {
    if (filesToUpload.length === 0 || uploadCompleted) return;
    await handleUpload();
    await fetchDataTable();
    setCurrentStep(steps.ASSIGN);
  };

  const onAddFiles = (newFiles: FileList) => {
    const newFilesToAdd: File[] = [];
    Array.from(newFiles).forEach((newFile) => {
      if (
        filesToUpload.findIndex(
          (fileToUpload) => fileToUpload.name === newFile.name
        ) < 0
      ) {
        newFilesToAdd.push(newFile);
      } else {
        sendNotification({
          success: false,
          text: `File "${newFile.name}" is already on the list!`,
        })(dispatch);
      }
    });
    setFilesToUpload(() => {
      return [...filesToUpload, ...newFilesToAdd];
    });
    if (uploadInputRef.current) {
      uploadInputRef.current.value = '';
      uploadInputRef.current.files = null;
    }
  };
  const onClearSelection = () => {
    setFilesToUpload(() => {
      if (uploadInputRef.current) {
        uploadInputRef.current.value = '';
        uploadInputRef.current.files = null;
      }
      return [];
    });
  };

  const onDeleteFile = (index: number) => {
    const newFilesArray = filesToUpload.filter(
      (file, itemIndex) => itemIndex !== index
    );
    setFilesToUpload(() => {
      if (uploadInputRef.current) {
        uploadInputRef.current.value = '';
        uploadInputRef.current.files = null;
      }
      return newFilesArray;
    });
  };

  return (
    <div className="UploadAsymmetricKeys">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>Upload Asymmetric Keys</h3>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
              if (uploadCompleted) fetchDataTable();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <div
          id={`${idClassPrefix}-steps-nav`}
          className="d-flex justify-content-between px-5 pb-5"
        >
          <div
            className={`${idClassPrefix}-step-nav ${steps.UPLOAD} ${
              steps.UPLOAD === currentStep ? 'active' : ''
            }`}
          >
            <h6>Upload</h6>
            <span className="text-muted">Upload your wrapped key files</span>
          </div>
          <div
            className={`${idClassPrefix}-step-nav ${steps.ASSIGN} ${
              steps.ASSIGN === currentStep ? 'active' : ''
            }`}
          >
            <h6>Assign</h6>
            <span className="text-muted">Assign keys to a product profile</span>
          </div>
        </div>
        {currentStep === steps.UPLOAD && (
          <div
            className={`${idClassPrefix}-step ${steps.UPLOAD} ${
              currentStep !== steps.UPLOAD ? 'd-none' : ''
            }`}
            id={`${idClassPrefix}-step-${steps.UPLOAD}`}
          >
            <Upload
              onDeleteFile={onDeleteFile}
              filesToUpload={filesToUpload}
              onAddFiles={onAddFiles}
              inputRef={uploadInputRef}
              onUploadButtonClick={onUploadButtonClick}
              disabledButtons={disabledButtons}
              onUploadAndAssignButtonClick={onUploadAndAssignButtonClick}
              isLoading={isLoading}
              onClearSelection={onClearSelection}
            />
          </div>
        )}
        {currentStep === steps.ASSIGN && (
          <div
            className={`${idClassPrefix}-step ${steps.ASSIGN} ${
              currentStep !== steps.ASSIGN ? 'd-none' : ''
            }`}
            id={`${idClassPrefix}-step-${steps.ASSIGN}`}
          >
            <Assign
              asymmetricKeys={uploadedKeys}
              onCancel={onCancel}
              handleAssignKeysToProductProfile={
                handleAssignKeysToProductProfile
              }
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};
