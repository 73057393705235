import { Reducer } from 'redux';
import {
  CertificateTemplatesState,
  CertificateTemplateActionTypes,
} from './types';

export const initialState: CertificateTemplatesState = {
  certificateTemplateList: [],
  certificateTemplatesErrors: undefined,
  isGettingCertificateTemplateList: false,
  isLoadedCertificateTemplateList: false,
  isCreatingTemplate: false,
  isEditingTemplate: false,
  isDeletingTemplate: false,
};

const reducer: Reducer<CertificateTemplatesState> = (
  state = initialState,
  action
): CertificateTemplatesState => {
  switch (action.type) {
    case CertificateTemplateActionTypes.GET_TEMPLATE_LIST_REQUEST: {
      return {
        ...state,
        isGettingCertificateTemplateList: true,
        isLoadedCertificateTemplateList: false,
        certificateTemplatesErrors: undefined,
      };
    }
    case CertificateTemplateActionTypes.GET_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCertificateTemplateList: false,
        isLoadedCertificateTemplateList: true,
        certificateTemplateList: action.payload,
      };
    }
    case CertificateTemplateActionTypes.GET_TEMPLATE_LIST_ERROR: {
      return {
        ...state,
        isGettingCertificateTemplateList: false,
        isLoadedCertificateTemplateList: false,
        certificateTemplatesErrors: action.payload,
      };
    }

    case CertificateTemplateActionTypes.POST_TEMPLATE_REQUEST: {
      return { ...state, isCreatingTemplate: true };
    }
    case CertificateTemplateActionTypes.POST_TEMPLATE_SUCCESS: {
      return { ...state, isCreatingTemplate: false };
    }
    case CertificateTemplateActionTypes.POST_TEMPLATE_ERROR: {
      return {
        ...state,
        isCreatingTemplate: false,
        certificateTemplatesErrors: action.payload,
      };
    }

    case CertificateTemplateActionTypes.PUT_TEMPLATE_REQUEST: {
      return { ...state, isEditingTemplate: true };
    }
    case CertificateTemplateActionTypes.PUT_TEMPLATE_SUCCESS: {
      return { ...state, isEditingTemplate: false };
    }
    case CertificateTemplateActionTypes.PUT_TEMPLATE_ERROR: {
      return {
        ...state,
        isEditingTemplate: false,
        certificateTemplatesErrors: action.payload,
      };
    }

    case CertificateTemplateActionTypes.DELETE_TEMPLATE_REQUEST: {
      return { ...state, isDeletingTemplate: true };
    }
    case CertificateTemplateActionTypes.DELETE_TEMPLATE_SUCCESS: {
      return { ...state, isDeletingTemplate: false };
    }
    case CertificateTemplateActionTypes.DELETE_TEMPLATE_ERROR: {
      return {
        ...state,
        isDeletingTemplate: false,
        certificateTemplatesErrors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as certificateTemplatesReducer };
