export enum EntityType {
  CertificateProfile = 'CertificateProfile',
  IssuingCA = 'IssuingCA',
  Owner = 'Owner',
  Customer = 'Customer',
  CodeSigningProfile = 'CodeSigningProfile',
  ProductKey = 'ProductKey',
  Key = 'Key',
}

export interface EntityRole {
  entityUuid: string;
  entityType: string;
  role: string;
}

export type UserSettings = { attribute: string; value: string }[];

export interface Resources {
  name: string;
  actions: string[];
}

export interface User {
  readonly uuid: string;
  readonly email: string;
  readonly enabled: boolean;
  readonly username: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly customers: { code: string; name: string }[];
  readonly settings: UserSettings;
  readonly entityRoles: EntityRole[];
  readonly resources: Resources[];
}

export interface UsersState {
  readonly usersList: User[];
  readonly filterUsersList: User[];
  readonly userProfile: User;
  readonly isGettingUserList: boolean;
  readonly isGettingFilterUserList: boolean;
  readonly isCreatingUser: boolean;
  readonly isEditingUser: boolean;
  readonly isLoadedUserList: boolean;
  readonly isLoadedFilterUserList: boolean;
  readonly isGettingUserProfile: boolean;
  readonly isUserProfileLoaded: boolean;
  readonly isSettingUserSettings: boolean;
  readonly isSyncUsers: boolean;
  readonly isEditiningEntityRole: boolean;
  readonly userErrors: unknown;
  readonly filterUserErrors: unknown;
  readonly isResettingOTPCredentials: boolean;
}

export enum UsersActionTypes {
  GET_USER_PROFILE_REQUEST = '@@Users/GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS = '@@Users/GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_ERROR = '@@Users/GET_USER_PROFILE_ERROR',

  POST_USER_REQUEST = '@@Users/POST_USER_REQUEST',
  POST_USER_SUCCESS = '@@Users/POST_USER_SUCCESS',
  POST_USER_ERROR = '@@Users/POST_USER_ERROR',

  PUT_USER_REQUEST = '@@Users/PUT_USER_REQUEST',
  PUT_USER_SUCCESS = '@@Users/PUT_USER_SUCCESS',
  PUT_USER_ERROR = '@@Users/PUT_USER_ERROR',

  POST_USER_SETTINGS_REQUEST = '@@Users/POST_USER_SETTINGS_REQUEST',
  POST_USER_SETTINGS_SUCCESS = '@@Users/POST_USER_SETTINGS_SUCCESS',
  POST_USER_SETTINGS_ERROR = '@@Users/POST_USER_SETTINGS_ERROR',

  GET_USERS_LIST_REQUEST = '@@Users/GET_USERS_LIST_REQUEST',
  GET_USERS_LIST_SUCCESS = '@@Users/GET_USERS_LIST_SUCCESS',
  GET_USERS_LIST_ERROR = '@@Users/GET_USERS_LIST_ERROR',

  GET_FILTER_USERS_LIST_REQUEST = '@@Users/GET_FILTER_USERS_LIST_REQUEST',
  GET_FILTER_USERS_LIST_SUCCESS = '@@Users/GET_FILTER_USERS_LIST_SUCCESS',
  GET_FILTER_USERS_LIST_ERROR = '@@Users/GET_FILTER_USERS_LIST_ERROR',

  GET_SYNC_USERS_REQUEST = '@@Users/GET_SYNC_USERS_REQUEST',
  GET_SYNC_USERS_SUCCESS = '@@Users/GET_SYNC_USERS_SUCCESS',
  GET_SYNC_USERS_ERROR = '@@Users/GET_SYNC_USERS_ERROR',

  GET_CA_USERS_LIST_REQUEST = '@@Users/GET_CA_USERS_LIST_REQUEST',
  GET_CA_USERS_LIST_SUCCESS = '@@Users/GET_CA_USERS_LIST_SUCCESS',
  GET_CA_USERS_LIST_ERROR = '@@Users/GET_CA_USERS_LIST_ERROR',

  POST_USERS_ENTITY_ROLE_REQUEST = '@@Users/POST_USERS_ENTITY_ROLE_REQUEST',
  POST_USERS_ENTITY_ROLE_SUCCESS = '@@Users/POST_USERS_ENTITY_ROLE_SUCCESS',
  POST_USERS_ENTITY_ROLE_ERROR = '@@Users/POST_USERS_ENTITY_ROLE_ERROR',

  DELETE_USER_ENTITY_ROLE_REQUEST = '@@Users/DELETE_USER_ENTITY_ROLE_REQUEST',
  DELETE_USER_ENTITY_ROLE_SUCCESS = '@@Users/DELETE_USER_ENTITY_ROLE_SUCCESS',
  DELETE_USER_ENTITY_ROLE_ERROR = '@@Users/DELETE_USER_ENTITY_ROLE_ERROR',

  CHANGE_CUSTOMER = '@@Users/CHANGE_CUSTOMER',

  RESET_USER_OTP_CREDENTIALS_REQUEST = '@@Users/RESET_USER_OTP_CREDENTIALS_REQUEST',
  RESET_USER_OTP_CREDENTIALS_SUCCESS = '@@Users/RESET_USER_OTP_CREDENTIALS_SUCCESS',
  RESET_USER_OTP_CREDENTIALS_ERROR = '@@Users/RESET_USER_OTP_CREDENTIALS_ERROR',
}

export interface UserOutput {
  readonly uuid: string;
  readonly email: string;
  readonly enabled: boolean;
  readonly username: string;
  readonly firstName?: string;
  readonly lastName?: string;
}
