import { Certificate } from '../certificates/types';
import { CertificateProfile } from '../certificateProfiles/types';

export interface ManufacturingStation {
  readonly uuid: string;
  readonly name: string;
  readonly actions?: string[];
  readonly manufacturer?: string;
  readonly location?: string;
  readonly enabled?: boolean;
  readonly externalId?: string;
  readonly notes?: string;
  readonly issuingCAUuid: string;
  readonly certificateProfileUuid?: string;
  readonly provisioningProfiles?: any[];
}

export interface ProvisioningProfile {
  readonly manufacturingStationUuid: string;
  readonly certificateProfileUuid: string;
  readonly actions: string[];
  readonly name?: string;
}

export interface SecureToken {
  readonly uuid: string;
  readonly manufacturingStationUuid: string;
  readonly serialNumber?: string;
  readonly notes?: string;
  readonly enabled: boolean;
  readonly csr?: string;
  readonly csrFilename?: string;
  readonly certificateUuid?: string;
  readonly actions?: string[];
}

export interface ManufacturingStationsState {
  readonly manufacturingStation?: ManufacturingStation;
  readonly manufacturingStationsList: ManufacturingStation[];
  readonly isGettingManufacturingStationList: boolean;
  readonly isCreatingManufacturingStation: boolean;
  readonly isEditingManufacturingStation: boolean;
  readonly isLoadedManufacturingStationList: boolean;
  readonly manufacturingStationErrors: unknown;
  readonly isGettingOnlineCAList: boolean;
  readonly OnlineCAList: Certificate[];
  readonly isGettingCertificateProfileList: boolean;
  readonly certificateProfileList: CertificateProfile[];
  readonly currentProvisioningProfiles: ProvisioningProfile[];
  readonly isGettingProvisioningProfiles: boolean;
  readonly isLoadedProvisioningProfiles: boolean;
  readonly isEditingProvisioningProfiles: boolean;
  readonly currentSecureTokens: SecureToken[];
  readonly isGettingSecureTokens: boolean;
  readonly isLoadedSecureTokens: boolean;
  readonly isEditingSecureTokens: boolean;
  readonly isGettingManufacturingStation: boolean;
  readonly isLoadedManufacturingStation: boolean;
}

export enum ManufacturingStationsActionTypes {
  POST_MANUFACTURING_STATION_REQUEST = '@@ManufacturingStations/POST_MANUFACTURING_STATION_REQUEST',
  POST_MANUFACTURING_STATION_SUCCESS = '@@ManufacturingStations/POST_MANUFACTURING_STATION_SUCCESS',
  POST_MANUFACTURING_STATION_ERROR = '@@ManufacturingStations/POST_MANUFACTURING_STATION_ERROR',

  PUT_MANUFACTURING_STATION_REQUEST = '@@ManufacturingStations/PUT_MANUFACTURING_STATION_REQUEST',
  PUT_MANUFACTURING_STATION_SUCCESS = '@@ManufacturingStations/PUT_MANUFACTURING_STATION_SUCCESS',
  PUT_MANUFACTURING_STATION_ERROR = '@@ManufacturingStations/PUT_MANUFACTURING_STATION_ERROR',

  GET_MANUFACTURING_STATION_REQUEST = '@@ManufacturingStations/GET_MANUFACTURING_STATION_REQUEST',
  GET_MANUFACTURING_STATION_SUCCESS = '@@ManufacturingStations/GET_MANUFACTURING_STATION_SUCCESS',
  GET_MANUFACTURING_STATION_ERROR = '@@ManufacturingStations/GET_MANUFACTURING_STATION_ERROR',

  GET_MANUFACTURING_STATIONS_LIST_REQUEST = '@@ManufacturingStations/GET_MANUFACTURING_STATIONS_LIST_REQUEST',
  GET_MANUFACTURING_STATIONS_LIST_SUCCESS = '@@ManufacturingStations/GET_MANUFACTURING_STATIONS_LIST_SUCCESS',
  GET_MANUFACTURING_STATIONS_LIST_ERROR = '@@ManufacturingStations/GET_MANUFACTURING_STATIONS_LIST_ERROR',

  GET_PROVISIONING_PROFILES_REQUEST = '@@ManufacturingStations/GET_PROVISIONING_PROFILES_REQUEST',
  GET_PROVISIONING_PROFILES_SUCCESS = '@@ManufacturingStations/GET_PROVISIONING_PROFILES_SUCCESS',
  GET_PROVISIONING_PROFILES_ERROR = '@@ManufacturingStations/GET_PROVISIONING_PROFILES_ERROR',

  POST_PROVISIONING_PROFILE_REQUEST = '@@ManufacturingStations/POST_PROVISIONING_PROFILE_REQUEST',
  POST_PROVISIONING_PROFILE_SUCCESS = '@@ManufacturingStations/POST_PROVISIONING_PROFILE_SUCCESS',
  POST_PROVISIONING_PROFILE_ERROR = '@@ManufacturingStations/POST_PROVISIONING_PROFILE_ERROR',

  DELETE_PROVISIONING_PROFILE_REQUEST = '@@ManufacturingStations/DELETE_PROVISIONING_PROFILE_REQUEST',
  DELETE_PROVISIONING_PROFILE_SUCCESS = '@@ManufacturingStations/DELETE_PROVISIONING_PROFILE_SUCCESS',
  DELETE_PROVISIONING_PROFILE_ERROR = '@@ManufacturingStations/DELETE_PROVISIONING_PROFILE_ERROR',

  GET_SECURE_TOKENS_REQUEST = '@@ManufacturingStations/GET_SECURE_TOKENS_REQUEST',
  GET_SECURE_TOKENS_SUCCESS = '@@ManufacturingStations/GET_SECURE_TOKENS_SUCCESS',
  GET_SECURE_TOKENS_ERROR = '@@ManufacturingStations/GET_SECURE_TOKENS_ERROR',

  POST_SECURE_TOKEN_REQUEST = '@@ManufacturingStations/POST_SECURE_TOKEN_REQUEST',
  POST_SECURE_TOKEN_SUCCESS = '@@ManufacturingStations/POST_SECURE_TOKEN_SUCCESS',
  POST_SECURE_TOKEN_ERROR = '@@ManufacturingStations/POST_SECURE_TOKEN_ERROR',

  PUT_SECURE_TOKEN_REQUEST = '@@ManufacturingStations/PUT_SECURE_TOKEN_REQUEST',
  PUT_SECURE_TOKEN_SUCCESS = '@@ManufacturingStations/PUT_SECURE_TOKEN_SUCCESS',
  PUT_SECURE_TOKEN_ERROR = '@@ManufacturingStations/PUT_SECURE_TOKEN_ERROR',

  CLEAR_MANUFACTURING_STATION = '@@ManufacturingStations/CLEAR_MANUFACTURING_STATION',
}
