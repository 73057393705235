/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { snakeCase, map, set } from 'lodash';
import { EntityType, User, UserOutput, UserSettings } from './types';

export const serializeUserSettings = (
  userSettings: UserSettings
): { attribute: string; value: string }[] =>
  map(userSettings, (setting) => ({
    value: setting.value,
    attribute: setting.attribute,
  }));

export const deserializeUserSettings = (
  userSettings: { attribute: string; value: string }[]
): UserSettings =>
  map(userSettings, (setting) => ({
    value: setting.value,
    attribute: setting.attribute,
  }));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeUser = (raw: any): User => ({
  email: raw.email,
  username: raw.username,
  firstName: raw.first_name,
  lastName: raw.last_name,
  customers: raw.customers,
  uuid: raw.uuid,
  enabled: raw.enabled,
  settings: deserializeUserSettings(raw.settings),
  entityRoles: map(raw.entity_roles, (entity_role) => ({
    entityUuid: entity_role.entity_uuid,
    entityType: entity_role.entity_type,
    role: entity_role.role,
  })),
  resources: raw.resources,
});

export const serializeUserEntityRole = ({
  userUuid,
  userUuids,
  entityRole,
  entityType,
  entityUuid,
  inherit = false,
}: {
  userUuid?: string;
  userUuids?: string[];
  entityRole: string;
  entityUuid: string;
  entityType: EntityType;
  inherit?: boolean;
}): any => {
  const output = {
    entity_uuid: entityUuid,
    entity_type: snakeCase(entityType),
    user_uuids: userUuids,
    role: entityRole,
    inherit,
  };
  userUuids && set(output, 'user_uuids', userUuids);
  userUuid && set(output, 'user_uuid', userUuid);
  return output;
};

export const mapRoleToValue: { [key: string]: string } = {
  ca_admin: 'CA Administrator',
  approver: 'Approver',
  pki_subscriber: 'PKI Subscriber',
  client_owner: 'API Client Owner',
  ca_user: 'CA User',
  product_admin: 'Product Administrator',
  product_user: 'Product User',
  crypto_user: 'Crypto User',
};

export const serializeUser = (user: User): any => ({
  first_name: user.firstName,
  last_name: user.lastName,
  username: user.username,
  email: user.email,
  enabled: user.enabled,
});

export const deserializeUserOutput = (raw: any): UserOutput => {
  return {
    email: raw.email,
    username: raw.username,
    firstName: raw.first_name,
    lastName: raw.last_name,
    uuid: raw.uuid,
    enabled: raw.enabled,
  };
};
