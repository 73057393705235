import React, { FC, useEffect } from 'react';
import { Label, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import useCopy from '@react-hook/copy';
import { kebabCase } from 'lodash';
import './LabelValue.scss';

interface LabelValueProps {
  value: string | null | undefined;
  label?: string;
  plain?: boolean;
  copyable?: boolean;
  maxChars?: number;
}

export const LabelValue: FC<LabelValueProps> = ({
  value,
  label,
  copyable,
  plain,
  maxChars,
}) => {
  const {
    copy: copyValue,
    copied: valueCopied,
    reset: resetValueCopy,
  } = useCopy(value || '');

  useEffect(() => {
    let timerId: any = null;
    if (valueCopied) {
      timerId = setTimeout(() => {
        resetValueCopy();
      }, 5000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [copyValue, valueCopied, resetValueCopy, value]);

  const getPlainText = () => {
    if (!value) return 'N/A';
    return maxChars ? `${value?.substr(0, maxChars)}...` : value;
  };

  const copyTarget = kebabCase(
    `copy-${kebabCase(String(label || value)?.toLowerCase())}`
  );
  return !plain ? (
    <div className="label-value mb-3">
      <Label className="pki-label d-block mb-0">{label}</Label>
      <div className="label-value-value">
        {getPlainText() || 'N/A'}
        {copyable && (
          <span
            id={copyTarget}
            className="client-form-ico ml-2"
            onClick={(): void => {
              copyValue();
            }}
          >
            <FontAwesomeIcon className="pki-ico" icon={faCopy} />
            <UncontrolledPopover trigger="hover" target={copyTarget}>
              <div className="p-2">
                <small>{valueCopied ? 'Copied!' : `Copy ${label}`}</small>
              </div>
            </UncontrolledPopover>
          </span>
        )}
      </div>
    </div>
  ) : (
    <span className="plainTextSpan">
      {getPlainText() || 'N/A'}
      {copyable && (
        <span
          id={copyTarget}
          onClick={(ev): void => {
            ev.preventDefault();
            ev.stopPropagation();
            copyValue();
          }}
        >
          <FontAwesomeIcon className="pki-ico" icon={faCopy} />
          <UncontrolledPopover trigger="hover" target={copyTarget}>
            <div className="p-2">
              <small>{valueCopied ? 'Copied!' : `Copy`}</small>
            </div>
          </UncontrolledPopover>
        </span>
      )}
    </span>
  );
};
