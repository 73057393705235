import { Reducer } from 'redux';
import { AuditLogsState, AuditLogsActionTypes } from './types';

export const initialState: AuditLogsState = {
  isGettingAuditLogsList: false,
  isLoadedAuditLogsList: false,
  auditLogsList: [],
  auditLogsErrors: undefined,
};

const reducer: Reducer<AuditLogsState> = (
  state = initialState,
  action
): AuditLogsState => {
  switch (action.type) {
    case AuditLogsActionTypes.GET_AUDIT_LOGS_LIST_REQUEST: {
      return {
        ...state,
        isGettingAuditLogsList: true,
        isLoadedAuditLogsList: false,
        auditLogsErrors: undefined,
      };
    }
    case AuditLogsActionTypes.GET_AUDIT_LOGS_LIST_SUCCESS: {
      return {
        ...state,
        isGettingAuditLogsList: false,
        isLoadedAuditLogsList: true,
        auditLogsList: action.payload.events,
      };
    }
    case AuditLogsActionTypes.GET_AUDIT_LOGS_LIST_ERROR: {
      return {
        ...state,
        isGettingAuditLogsList: false,
        isLoadedAuditLogsList: false,
        auditLogsErrors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as auditLogsReducer };
