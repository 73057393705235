import React, { FC, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { set } from 'lodash';
import { Certificate } from '../../store/certificates/types';

interface Props {
  ca: Certificate | undefined;
  onClose: Function;
  onUpload: Function;
}

const UploadCAKey: FC<Props> = ({
  ca,
  onClose = (): null => null,
  onUpload = (): null => null,
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [inputBundleFile, setInputBundleFile] = useState<File>();
  const [showValidation, setShowValidation] = useState<boolean>(false);

  return (
    <div className="UploadCAKey">
      <div className="modal-content p-4">
        <span className="text-center text-muted">
          <h3>Upload Key - {ca?.commonName || ''}</h3>
        </span>
        <span className="description mt-4">
          <p>
            Upload a key for the selected CA, to be used when a key was not yet
            loaded or the key protection has changed.
          </p>
        </span>
        <div className="mb-3 mt-2" id="UploadCAKey">
          <Button
            outline
            size="sm"
            onClick={(): void => {
              if (inputEl?.current) {
                set(
                  inputEl,
                  'current.accept',
                  'application/gzip, .tar.gz, .gz'
                );
                inputEl.current.click();
              }
            }}
          >
            Select TAR File <small>( .tar.gz )</small>
          </Button>
          {!inputBundleFile?.name && (
            <p className="text-muted mt-1">No TAR file selected</p>
          )}
          {inputBundleFile && (
            <div className="mt-1 ml-auto">
              {inputBundleFile.name}
              <FontAwesomeIcon
                onClick={(): void => {
                  setInputBundleFile(undefined);
                }}
                className="pki-ico ml-2"
                icon={faTrash}
              />
            </div>
          )}
          {!inputBundleFile && showValidation && (
            <div className="mt-2" id="upload-bundle-invalid">
              <span className="invalid-text">
                Bundle cannot be empty, please select one!
              </span>
            </div>
          )}
        </div>

        <div className="mt-3 d-flex">
          <Button
            outline
            className="ml-auto"
            size="sm"
            onClick={(): void => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            className="ml-3"
            size="sm"
            id="confirm-upload-ca-key"
            onClick={(): void => {
              setShowValidation(true);
              if (inputBundleFile) {
                onUpload({
                  bundle: inputBundleFile,
                });
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
      <input
        type="file"
        id="ca-key-file-input"
        className="d-none"
        ref={inputEl}
        onChange={({ target: { files } }): void => {
          if (files && files.length > 0) {
            setInputBundleFile(files[0]);
            set(inputEl, 'current.value', null);
          }
        }}
      />
    </div>
  );
};

export default UploadCAKey;
