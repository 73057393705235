import _ from 'lodash';
import React, { useState, useRef, FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Collapse, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  Spinner,
  ConfirmationForm,
  ErrorHandler,
} from '../../components';
import { ApplicationState } from '../../store';
import { getCommonKeys, uploadCommonKey } from '../../store/commonKeys/actions';
import { CommonKey, CommonKeysState } from '../../store/commonKeys/types';
import { dateFormatter } from '../../libs/helpers';
import { UsersState } from '../../store/users/types';

interface CommonKeysToolbarProps {
  onUploadKeyClick?: Function;
  actions?: string[];
}
const CommonKeysToolbar: FC<CommonKeysToolbarProps> = ({
  onUploadKeyClick = (): null => null,
  actions = [],
}) => {
  return (
    <div className="KeysToolbar d-flex">
      <span className="mx-2">
        <Button
          outline
          disabled={!_.includes(actions, 'create')}
          id="create-key-button"
          size="sm"
          onClick={(): void => {
            onUploadKeyClick();
          }}
        >
          <FontAwesomeIcon icon={faUpload} /> Common Key{' '}
        </Button>
      </span>
    </div>
  );
};

const CommonKeys: FC = () => {
  const {
    isGettingCommonKeyList,
    isLoadedCommonKeyList,
    isUploadingKey,
    commonKeysErrors,
    commonKeyList,
    userProfile: { resources },
  } = useSelector<ApplicationState, CommonKeysState & UsersState>((pki) => ({
    ...pki.commonKeys,
    ...pki.users,
  }));

  const dispatch = useDispatch();

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [inputFile, setInputFile] = useState<File>();
  const [uploadModal, setUploadModal] = useState<boolean>(false);

  const isLoadingData = isGettingCommonKeyList || isGettingCommonKeyList;
  const inputEl = useRef<HTMLInputElement>(null);

  const toggleUploadModal = (): void => {
    setUploadModal((prev) => !prev);
  };

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }
    if (isLoadedCommonKeyList) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [isLoadedCommonKeyList, isLoadingData]);

  useEffect(() => {
    const isPerformingAction = isUploadingKey;
    if (!isPerformingAction) {
      dispatch(getCommonKeys());
    }
  }, [dispatch, isUploadingKey]);

  const columns = [
    { dataField: 'name', text: 'Name' },
    { dataField: 'sha1', text: 'SHA1' },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Created by',
      formatter: (createdBy: string, current: CommonKey): ReactNode => {
        const isCreatedByUserDeleted = _.get(current, 'isCreatedByUserDeleted');
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
  ];

  const commonKeyResource = _.find(
    resources,
    (item) => item.name === 'common_key'
  );

  return (
    <div className="CommonKeys">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Common Keys</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            {commonKeysErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                keyField="uuid"
                sort={{ dataField: 'updatedAt', order: 'desc' }}
                noDataIndication="No Common Keys"
                data={commonKeyList}
                columns={columns}
                toolbar={
                  <CommonKeysToolbar
                    actions={_.get(commonKeyResource, 'actions', [])}
                    onUploadKeyClick={(): void => {
                      inputEl.current?.click();
                    }}
                  />
                }
              />
            )}
          </div>
          <Modal
            className="PKIApp"
            isOpen={uploadModal}
            toggle={toggleUploadModal}
          >
            <ConfirmationForm
              title={`Upload Common Key Confirmation`}
              content={
                <div className="text-center">
                  {inputFile && (
                    <p>
                      You are about to <strong>upload</strong> {inputFile.name}
                    </p>
                  )}
                </div>
              }
              onCancel={toggleUploadModal}
              onConfirm={async (): Promise<void> => {
                if (inputFile) {
                  dispatch(uploadCommonKey(inputFile));
                  toggleUploadModal();
                }
              }}
            />
          </Modal>
        </Collapse>
      </Card>
      <input
        type="file"
        id="file-input"
        className="d-none"
        ref={inputEl}
        onChange={({ target: { files } }): void => {
          if (files && files.length > 0) {
            setInputFile(files[0]);
            setUploadModal(true);
            _.set(inputEl, 'current.value', null);
          }
        }}
      />
    </div>
  );
};

export default CommonKeys;
