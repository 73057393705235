import React, {
  useEffect,
  useState,
  useRef,
  FC,
  ReactNode,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _, { isEmpty, map } from 'lodash';
import axios from 'axios';
import {
  faUserTag,
  faDownload,
  faUpload,
  faFileDownload,
  faRandom,
} from '@fortawesome/free-solid-svg-icons';
import { Card, Modal, Button, Collapse, Badge } from 'reactstrap';
import { ApplicationState } from '../../store';
import {
  getCertificateAuthorities,
  getPrintedCertificateAuthority,
  uploadCRL,
  uploadCA,
  downloadCRL,
  downloadCertificateAuthority,
} from '../../store/certificates/actions';

import {
  api,
  dateFormatter,
  onRemoveEntityRole,
  onSetEntityRole,
} from '../../libs/helpers';
import { CertificatesState, Certificate } from '../../store/certificates/types';
import { EntityType, UsersState } from '../../store/users/types';
import {
  Table,
  Spinner,
  TableActions,
  ConfirmationForm,
  FilterBar,
  ErrorHandler,
} from '../../components';
import { Filter } from '../../components/FilterBar/types';
import RolePolicy from '../ViewsComponents/RolePolicy';
import Preview from './Preview';
import DownloadCRL, { FormatType } from './DownloadCRL';
import UploadCA from './UploadCA';
import { Role } from '../../libs/constants';
import { sendNotification } from '../../store/notifications/actions';
import UploadCAKey from './UploadCAKey';
import SwitchKeyBackend from './SwitchKeyBackend';

const { saveAs } = require('file-saver');

enum UploadType {
  CRL = 'CRL',
  CA = 'CA',
  KEY = 'KEY',
}

const CAs: FC = () => {
  const {
    isGettingCAList,
    isLoadedCAList,
    isUploadingCA,
    CAList,
    isGettingUserProfile,
    isGettingPrintedCertificate,
    certificatesAuthorityListErrors,
    userProfile: { resources, uuid: currentUserUuid },
    printedCertificate,
  } = useSelector<ApplicationState, CertificatesState & UsersState>((pki) => ({
    ...pki.certificates,
    ...pki.users,
  }));

  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const caUuidParam = params.get('uuid');

  const initialQuery: Filter[] = useMemo(
    () =>
      caUuidParam
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: caUuidParam,
            },
          ]
        : [],
    [caUuidParam]
  );

  const [previewModal, setPreviewModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [canEditRoles, setCanEditRoles] = useState(false);
  const [CRLcommonName, setCRLCommonName] = useState<string>();
  const [inputCRLFile, setInputCRLFile] = useState<File>();
  const [uploadCRLModal, setUploadCRLModal] = useState<boolean>(false);
  const [uploadCAModal, setUploadCAModal] = useState<boolean>(false);
  const [uploadCAKeyModal, setUploadCAKeyModal] = useState<boolean>(false);
  const [currentCA, setCurrentCA] = useState<Certificate>();
  const [uploadType, setUploadType] = useState<UploadType>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [query, setQuery] = useState<Filter[]>(initialQuery);
  const [isShown, setIsShown] = useState(initialQuery.length <= 0);
  const [refetchRolePolicy, setRefetchRolePolicy] = useState(false);
  const [switchKeyBackendModal, setSwitchKeyBackendModal] = useState<boolean>(
    false
  );

  const inputEl = useRef<HTMLInputElement>(null);

  const caSortedByDate = _.orderBy(CAList, ['issuedAt'], ['desc']);
  const caUuidParamIndex = _.findIndex(
    caSortedByDate,
    (item) => item.uuid === caUuidParam
  );
  const caUuidParamPage = _.ceil((caUuidParamIndex + 1) / 10);

  const togglePreviewModal = (): void => {
    setPreviewModal((prev) => !prev);
  };

  const toggleDownloadModal = (): void => {
    setDownloadModal((prev) => !prev);
  };

  const toggleUploadCAModal = (): void => {
    setUploadCAModal((prev) => !prev);
  };

  const toggleUploadModal = (): void => {
    setUploadCRLModal((prev) => !prev);
  };

  const toggleRolesModal = (): void => {
    setRolesModalOpen((prev) => !prev);
  };

  const toggleUploadCAKeyModal = (): void => {
    setUploadCAKeyModal((prev) => {
      if (prev) {
        setCurrentCA(undefined);
      }
      return !prev;
    });
  };

  const toggleSwitchKeyBackendModal = (): void => {
    setSwitchKeyBackendModal((prev) => {
      if (prev) {
        setCurrentCA(undefined);
      }
      return !prev;
    });
  };

  const crlResource = _.find(
    resources,
    (item) => item.name === 'certificate_revocation_list'
  );
  const certificateResource = _.find(
    resources,
    (item) => item.name === 'certificate_authority'
  );

  const isLoadingData =
    isGettingCAList || isUploadingCA || isGettingUserProfile;

  const viewLocation = location.pathname;

  useEffect(() => {
    return function cleanup(): void {
      if (location.pathname !== viewLocation) {
        setShowContent(false);
      }
    };
  }, [location.pathname, viewLocation]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    if (!isUploadingCA) {
      dispatch(
        getCertificateAuthorities({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          query,
        })
      );
    }
    return function cleanup(): void {
      tokenSource.cancel('CAs::getCertificateAuthorities');
    };
  }, [dispatch, isUploadingCA, query, tablePage, tableSizePerPage]);

  useEffect(() => {
    if (currentCA && previewModal) {
      dispatch(getPrintedCertificateAuthority(currentCA.uuid));
    }
  }, [currentCA, dispatch, previewModal]);

  useEffect(() => {
    if (
      isLoadedCAList &&
      !isEmpty(caUuidParam) &&
      CAList.findIndex((client) => client.uuid === caUuidParam) > -1 &&
      initialQuery.findIndex(
        (filter) => filter.key === 'uuid' && filter.value === caUuidParam
      ) > -1 &&
      !isShown
    ) {
      const caToShow = CAList.find(
        (certificateProfile) => certificateProfile.uuid === caUuidParam
      );
      if (caToShow) {
        setCurrentCA(caToShow);
        setPreviewModal(true);
        setIsShown(true);
      }
    }
  }, [caUuidParam, isLoadedCAList]);

  const canUploadCa = _.includes(certificateResource?.actions, 'create');

  const onUploadCAKey = async ({ bundle }: { bundle: File }) => {
    if (!currentCA) {
      sendNotification({
        text: 'No CA is selected!',
        success: false,
      })(dispatch);
      return;
    }
    const formData = new FormData();
    formData.append('ext_key_tar_file', bundle);
    try {
      await api().put(
        `certificate/authority/${currentCA.uuid}/key/upload`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
      sendNotification({
        text: 'The CA Key has been uploaded successfully!',
        success: true,
      })(dispatch);
      toggleUploadCAKeyModal();
      dispatch(
        getCertificateAuthorities({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          query,
        })
      );
    } catch (err) {
      const text =
        JSON.stringify(
          err?.response?.data?.message || err?.response?.data?.error
        ) || 'Oops! Something went wrong uploading the CA Key!';

      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const onSwitchKeyBackend = async (keyBackend: string) => {
    if (!currentCA) {
      sendNotification({
        text: 'No CA is selected!',
        success: false,
      })(dispatch);
      return;
    }
    try {
      await api().patch(`certificate/authority/${currentCA.uuid}`, {
        key_backend: keyBackend,
      });
      sendNotification({
        text: 'The CA Key Backend has been successfully switched!',
        success: true,
      })(dispatch);
      toggleSwitchKeyBackendModal();
      dispatch(
        getCertificateAuthorities({
          page: tablePage,
          sizePerPage: tableSizePerPage,
          query,
        })
      );
    } catch (err) {
      const text =
        JSON.stringify(
          err?.response?.data?.message || err?.response?.data?.error
        ) || 'Oops! Something went wrong switching the CA Key Backend!';

      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const columns = [
    { dataField: 'uuid', text: 'uuid', hidden: true },
    { dataField: 'commonName', text: 'Common Name' },
    {
      dataField: 'issuer',
      text: 'Issuer',
    },
    { dataField: 'state', text: 'State', classes: 'text-capitalize' },
    {
      dataField: 'isKeyOnline',
      text: 'Key',
      formatter: (keyOnline: boolean): ReactNode => (
        <h6>
          <Badge color={keyOnline ? 'primary' : 'secondary'}>
            {keyOnline ? 'Online' : 'Offline'}
          </Badge>
        </h6>
      ),
    },
    {
      dataField: 'issuedAt',
      text: 'Issued',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'startsAt',
      text: 'Starting Date',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'expiresAt',
      text: 'Expiring Date',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'uuid',
      text: 'Actions',
      csvExport: false,
      formatter: (uuid: string, current: Certificate): ReactNode => {
        const canEditPolicy = _.includes(current.actions, 'assign_roles');
        const canUploadKey = _.includes(current.actions, 'create');
        const tableActions = [
          {
            label: 'Download CRL',
            ico: <FontAwesomeIcon className="pki-ico" icon={faDownload} />,
            onClick: (e: MouseEvent): void => {
              e.stopPropagation();
              setCRLCommonName(current.commonName);
              setDownloadModal(true);
            },
          },
          {
            label: 'Download Certificate',
            ico: <FontAwesomeIcon className="pki-ico" icon={faFileDownload} />,
            onClick: async (e: MouseEvent): Promise<void> => {
              e.stopPropagation();
              try {
                const certificate = await dispatch(
                  downloadCertificateAuthority(uuid)
                );
                const certificateBlob = new Blob([String(certificate)]);
                saveAs(
                  certificateBlob,
                  `${current.commonName}_${current.serial}.pem`
                );
              } catch (err) {
                // error already handled in the actions
              }
            },
          },
          {
            label: 'Role Policy',
            ico: <FontAwesomeIcon className="pki-ico" icon={faUserTag} />,
            onClick: async (e: MouseEvent): Promise<void> => {
              e.stopPropagation();
              setCanEditRoles(canEditPolicy);
              setCurrentCA(current);
              setRolesModalOpen(true);
            },
          },
          ...(canUploadKey
            ? [
                {
                  label: 'Upload Key',
                  ico: <FontAwesomeIcon className="pki-ico" icon={faUpload} />,
                  disabled: !canUploadKey,
                  onClick: async (e: MouseEvent): Promise<void> => {
                    e.stopPropagation();
                    setCurrentCA(current);
                    setUploadCAKeyModal(true);
                  },
                },
              ]
            : []),
          {
            label: 'Key Backend',
            ico: <FontAwesomeIcon className="pki-ico" icon={faRandom} />,
            disabled: !current.isKeyOnline,
            onClick: async (e: MouseEvent): Promise<void> => {
              e.stopPropagation();
              setCurrentCA(current);
              setSwitchKeyBackendModal(true);
            },
          },
        ];
        return <TableActions rowId={String(uuid)} options={tableActions} />;
      },
    },
  ];

  const CAListDataTable = map(CAList, (item) => ({
    ...item,
    issuer: item.issuer?.commonName || '',
    actions: [...item.actions, ...(canUploadCa ? ['create'] : [])],
  }));

  return (
    <div className="CAs">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">CAs</h3>
          {isLoadingData && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={!isLoadingData}>
          <div className="filter-bar-container mt-5">
            <FilterBar
              initialFilters={initialQuery}
              filters={[
                {
                  key: 'cn',
                  label: 'Common Name',
                  type: 'filter',
                  operators: ['=', '~'],
                },
                {
                  key: 'state',
                  label: 'State',
                  type: 'filter',
                  options: [
                    { value: 'issued', label: 'Issued' },
                    { value: 'revoked', label: 'Revoked' },
                    {
                      value: 'revocation_requested',
                      label: 'Revocation Requested',
                    },
                  ],
                },
                {
                  key: 'is_key_online',
                  label: 'Key',
                  type: 'filter',
                  options: [
                    { value: 'true', label: 'Online' },
                    { value: 'false', label: 'Offline' },
                  ],
                },
                {
                  key: 'inserted_time',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Issued Date',
                  type: 'filter',
                },
                {
                  key: 'not_before',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Starting Date',
                  type: 'filter',
                },
                {
                  key: 'not_after',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Expiring Date',
                  type: 'filter',
                },
              ]}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="view mt-5">
            {certificatesAuthorityListErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                data={CAListDataTable}
                remote={true}
                keyField="uuid"
                pagination={{
                  page: caUuidParamPage || tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                onTableChange={(
                  valueNotUsed: null,
                  { page, sizePerPage }: { page: number; sizePerPage: number }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                }}
                rowEvents={{
                  onClick: (notUsedValue: null, current: Certificate): void => {
                    setCurrentCA(current);
                    setPreviewModal(true);
                  },
                }}
                rowStyle={(
                  caData: Certificate
                ): { backgroundColor: string } | null => {
                  if (
                    caData.uuid.toLocaleLowerCase() ===
                    caUuidParam?.toLocaleLowerCase()
                  ) {
                    return { backgroundColor: 'rgba(0, 164, 224, 0.32)' };
                  }
                  return null;
                }}
                noDataIndication={
                  tablePage > 1
                    ? 'No more Authorities available'
                    : 'No Authorities available'
                }
                sort={{ dataField: 'issuedAt', order: 'desc' }}
                exportCSV={{
                  fileName: 'CAs.csv',
                  onlyExportSelection: true,
                  exportAll: false,
                }}
                toolbar={
                  <div className="d-flex">
                    <span className="mr-2">
                      <Button
                        outline
                        disabled={!canUploadCa}
                        size="sm"
                        onClick={(): void => {
                          toggleUploadCAModal();
                        }}
                      >
                        <FontAwesomeIcon icon={faUpload} /> Upload CA
                      </Button>
                    </span>
                    <span className="mr-2">
                      <Button
                        outline
                        disabled={!_.includes(crlResource?.actions, 'create')}
                        size="sm"
                        onClick={(): void => {
                          setUploadType(UploadType.CRL);
                          if (inputEl?.current) {
                            inputEl.current.click();
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faUpload} /> Upload CRL{' '}
                      </Button>
                    </span>
                  </div>
                }
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: false,
                }}
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={previewModal}
        toggle={togglePreviewModal}
      >
        <Preview
          isLoadign={isGettingPrintedCertificate}
          onClose={togglePreviewModal}
          commonName={currentCA?.commonName}
          printedCertificate={printedCertificate}
        />
      </Modal>
      <Modal
        className="PKIApp"
        isOpen={uploadCRLModal}
        toggle={toggleUploadModal}
      >
        <ConfirmationForm
          title="Upload CRL Confirmation"
          content={
            <div className="text-center">
              {inputCRLFile && (
                <p>
                  You are about to <strong>upload</strong> {inputCRLFile.name}
                </p>
              )}
            </div>
          }
          onCancel={toggleUploadModal}
          onConfirm={async (): Promise<void> => {
            if (inputCRLFile) {
              try {
                await dispatch(uploadCRL(inputCRLFile));
              } finally {
                setInputCRLFile(undefined);
                toggleUploadModal();
              }
            }
          }}
        />
      </Modal>
      <Modal
        className="PKIApp"
        isOpen={downloadModal}
        toggle={toggleDownloadModal}
      >
        <DownloadCRL
          onDownloadSelect={async ({
            formatType,
          }: {
            formatType: FormatType;
          }): Promise<void> => {
            if (formatType === FormatType.DER) {
              if (CRLcommonName) {
                await dispatch(downloadCRL(CRLcommonName, 'der'));
              }
            }
            if (formatType === FormatType.PEM) {
              if (CRLcommonName) {
                const derCRL = await dispatch(
                  downloadCRL(CRLcommonName, 'pem')
                );
                if (derCRL) {
                  const derCRLBlolb = new Blob([String(derCRL)]);
                  saveAs(derCRLBlolb, `${CRLcommonName}.pem`);
                }
              }
            }
          }}
        />
      </Modal>
      <Modal
        className="PKIApp upload-ca-modal"
        isOpen={uploadCAModal}
        size="lg"
        toggle={toggleUploadCAModal}
      >
        <UploadCA
          onClose={toggleUploadCAModal}
          onUpload={async (
            bundleMode: boolean,
            { ca, key, bundle }: { ca?: File; key?: File; bundle?: File }
          ): Promise<void> => {
            const uploadSucceed = await dispatch(
              uploadCA(bundleMode, {
                ca,
                key,
                bundle,
              })
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (uploadSucceed) {
              if (query.length === 0 && tablePage === 1) {
                dispatch(
                  getCertificateAuthorities({
                    page: tablePage,
                    sizePerPage: tableSizePerPage,
                    query,
                  })
                );
              } else {
                setQuery(() => {
                  setTablePage(1);
                  return [];
                });
              }
              toggleUploadCAModal();
            }
          }}
        />
      </Modal>
      <Modal
        className="PKIApp upload-ca-key-modal"
        isOpen={uploadCAKeyModal && !!currentCA}
        size="lg"
        toggle={toggleUploadCAKeyModal}
      >
        <UploadCAKey
          ca={currentCA}
          onClose={toggleUploadCAKeyModal}
          onUpload={onUploadCAKey}
        />
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={rolesModalOpen}
        toggle={toggleRolesModal}
      >
        {currentCA && (
          <RolePolicy
            currentUserUuid={currentUserUuid}
            readOnly={!canEditRoles}
            entity={currentCA}
            entityType="IssuingCA"
            onCancel={toggleRolesModal}
            shouldFetch={refetchRolePolicy}
            setShouldFetch={setRefetchRolePolicy}
            onSetEntityRole={async (role: {
              entityType: EntityType.IssuingCA;
              userUuids: string[];
              entityRole: string;
              entityUuid: string;
              usersAlreadyEntitled: string[];
            }) => {
              await onSetEntityRole(
                {
                  ...role,
                  type: 'User',
                  inherit: role.entityRole === Role.CAAdmin,
                },
                dispatch,
                setRefetchRolePolicy
              );
            }}
            onRemoveEntityRole={async (role: {
              userUuid: string;
              entityRole: string;
              entityUuid: string;
              entityType: EntityType.IssuingCA;
            }) => {
              await onRemoveEntityRole(role, dispatch, setRefetchRolePolicy);
            }}
          />
        )}
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={switchKeyBackendModal}
        toggle={toggleSwitchKeyBackendModal}
      >
        <SwitchKeyBackend
          ca={currentCA}
          onClose={toggleSwitchKeyBackendModal}
          onSubmit={onSwitchKeyBackend}
        />
      </Modal>
      <input
        type="file"
        id="file-input"
        className="d-none"
        ref={inputEl}
        onChange={({ target: { files } }): void => {
          if (files && files.length > 0) {
            if (uploadType === UploadType.CRL) {
              setInputCRLFile(files[0]);
              setUploadCRLModal(true);
            }
            _.set(inputEl, 'current.value', null);
          }
        }}
      />
    </div>
  );
};

export default CAs;
