import React, { FC, ReactNode, useEffect, useState } from 'react';
import _, { includes, startCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfirmationForm,
  Table,
  TableActions,
} from '../../../../../components';
import { deserializeProductKey } from '../../../../keys/AsymmetricKeys/helpers';
import { ProductKeyForm } from './components/ProductKeyForm/ProductKeyForm';
import RolePolicy from '../../../../ViewsComponents/RolePolicy';
import { EntityType, UsersState } from '../../../../../store/users/types';
import {
  api,
  getUrlWithFilters,
  onRemoveEntityRole,
  onSetEntityRole,
} from '../../../../../libs/helpers';
import { ApplicationState } from '../../../../../store';
import { sendNotification } from '../../../../../store/notifications/actions';
import { LabelValue } from '../../../../../components/LabelValue/LabelValue';
import { ProductKey } from '../../../../keys/types';

interface ProductKeysProps {
  setShowSpinner: (state: boolean) => void;
  profileActions?: string[];
  profileId: string;
}

export const ProductKeys: FC<ProductKeysProps> = ({
  setShowSpinner,
  profileActions,
  profileId,
}) => {
  const [keys, setKeys] = useState<ProductKey[]>([]);
  const [tablePage, setTablePage] = useState(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(true);
  const [formCreateMode, setFormCreateMode] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [rolesModalOpen, setRolesModalOpen] = useState<boolean>(false);
  const [refetchRolePolicy, setRefetchRolePolicy] = useState(false);
  const [canEditRoles, setCanEditRoles] = useState(false);

  const [
    showProductKeyFormModal,
    setShowProductKeyFormModal,
  ] = useState<boolean>(false);

  const [currentProductKey, setCurrentProductKey] = useState<ProductKey>();

  const {
    userProfile: { uuid: currentUserUuid },
  } = useSelector<ApplicationState, UsersState>((pki) => ({
    ...pki.users,
  }));

  const dispatch = useDispatch();

  const fetchKeys = async () => {
    setIsLoading(true);
    setShowSpinner(true);
    try {
      const urlWithParams = getUrlWithFilters(
        `product-profile/${profileId}/keys`,
        {
          page: tablePage,
          sizePerPage: tableSizePerPage,
          sortBy: tableSortField,
          sortDir: tableSortOrder,
        }
      );
      const { data: keysResult } = await api().get(urlWithParams);
      setKeys(keysResult.map((rawKey: any) => deserializeProductKey(rawKey)));
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching Product Keys!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchKeys();
  }, []);

  const toggleProductKeyFormModal = (): void => {
    setShowProductKeyFormModal((previousValue) => {
      if (previousValue) {
        setCurrentProductKey(undefined);
      }
      setFormCreateMode(false);
      return !previousValue;
    });
  };

  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentProductKey(undefined);
      }
      return !previousValue;
    });
  };

  const toggleRolesModal = (): void => {
    setRolesModalOpen((previousValue) => {
      if (previousValue) {
        setCurrentProductKey(undefined);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const onRowClick = (selectedProductKey: ProductKey) => {
    setFormReadOnly(() => {
      setCurrentProductKey(selectedProductKey);
      setShowProductKeyFormModal(true);
      return true;
    });
  };

  const onRemoveFromProductProfile = async (keyUuid: string) => {
    setIsLoading(true);
    try {
      const { data: deletedKey } = await api().delete(
        `product-profile/${profileId}/key/${keyUuid}`
      );
      sendNotification({
        text: 'The Product Key has been removed successfully!',
        success: true,
      })(dispatch);
      await fetchKeys();
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong removing the Product Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  const onCancelProductKeyForm = () => {
    setShowProductKeyFormModal(() => {
      setCurrentProductKey(undefined);
      setFormCreateMode(false);
      return false;
    });
  };

  const onSubmitProductKeyForm = async (
    productKeyToSet: Partial<ProductKey>
  ) => {
    setIsLoading(true);
    const { uuid, keyUsage } = productKeyToSet;
    const url = `product-profile/${profileId}/keys`;
    const body = {
      keys: [
        {
          uuid,
          key_usage: keyUsage,
        },
      ],
    };
    try {
      await api().post(url, body);
      toggleProductKeyFormModal();
      await fetchKeys();
      sendNotification({
        text: 'The Product Profile has been submitted successfully!',
        success: true,
      })(dispatch);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong submitting the Product Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddProductKey = () => {
    setCurrentProductKey(() => {
      setFormCreateMode(true);
      setFormReadOnly(false);
      setShowProductKeyFormModal(true);
      return undefined;
    });
  };

  const onConfirmRemove = async () => {
    if (currentProductKey) {
      setIsLoading(true);
      try {
        await onRemoveFromProductProfile(currentProductKey.uuid);
      } catch (err) {
        const text =
          JSON.stringify(err?.response?.data?.detail) ||
          'Oops! Something went wrong removing the Product Key!';
        sendNotification({
          text,
          success: false,
        })(dispatch);
      } finally {
        setIsLoading(false);
        toggleDeleteModal();
      }
    }
  };

  const keysColumns = [
    {
      dataField: 'keyUuid',
      text: 'uuid',
      hidden: true,
    },
    {
      dataField: 'label',
      text: 'Label',
    },
    {
      dataField: 'keyId',
      text: 'Key ID',
      formatter: (keyId: string) => keyId || 'To be uniquely assigned',
    },
    {
      dataField: 'keyDigest',
      text: 'Key Digest',
      formatter: (digest: string) => {
        return <LabelValue value={digest} plain={true} maxChars={6} copyable />;
      },
    },
    {
      dataField: 'keyUsage',
      text: 'Key Usage',
      formatter: (usage: string) => startCase(usage),
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      formatter: (
        valueNotImportant: null,
        currentKey: ProductKey
      ): ReactNode => {
        const { actions } = currentKey;
        const canDelete = includes(actions, 'delete');
        const canEditPolicy = _.includes(actions, 'assign_roles');

        const options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        options.push({
          label: 'Role Policy',
          ico: <FontAwesomeIcon className="pki-ico" icon={faUsers} />,
          onClick: (e: MouseEvent): void => {
            e.stopPropagation();
            setCanEditRoles(canEditPolicy);
            setCurrentProductKey(currentKey);
            setRolesModalOpen(true);
          },
        });

        options.push({
          label: 'Remove From Profile',
          ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
          onClick: async (e: MouseEvent): Promise<void> => {
            e.stopPropagation();
            setCurrentProductKey(() => {
              setDeleteModal(true);
              return currentKey;
            });
          },
          disabled: !canDelete,
        });

        return (
          <TableActions rowId={String(currentKey.uuid)} options={options} />
        );
      },
    },
  ];
  return (
    <div id={'product-profile-keys'}>
      <div className="d-flex justify-content-end mb-2">
        <Button
          id="add-product-profile-key"
          outline
          disabled={isLoading || !profileActions?.includes('assign_keys')}
          size="sm"
          onClick={onAddProductKey}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Product Key
        </Button>
      </div>
      <Table
        search={false}
        keyField="uuid"
        loading={isLoading}
        remote={false}
        data={keys}
        columns={keysColumns}
        noDataIndication={
          tablePage > 1
            ? 'No more Keys for current Product Profile'
            : 'No Keys has been added to Product Profile'
        }
        pagination={{
          page: tablePage,
          sizePerPage: tableSizePerPage,
        }}
        onTableChange={(
          valueNotUsed: null,
          { page, sizePerPage }: { page: number; sizePerPage: number }
        ): void => {
          setTablePage(page);
          setTableSizePerPage(sizePerPage);
        }}
        rowEvents={{
          onClick: (notUsedValue: null, current: ProductKey): void => {
            onRowClick(current);
          },
        }}
      />
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={showProductKeyFormModal}
        toggle={toggleProductKeyFormModal}
      >
        <ProductKeyForm
          onModeChange={toggleMode}
          readOnly={formReadOnly}
          createMode={formCreateMode}
          profileId={profileId}
          productKey={currentProductKey}
          onCancel={onCancelProductKeyForm}
          onSubmit={onSubmitProductKeyForm}
          isLoading={isLoading}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title="Remove Product Key Confirmation"
          message={''}
          content={
            <div className="text-center">
              <p>
                You are about to <strong>remove</strong> Product Key "
                <strong>{currentProductKey?.label}</strong>" from current
                Product Profile.
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={onConfirmRemove}
        />
      </Modal>

      <Modal
        className="PKIApp"
        size="lg"
        isOpen={rolesModalOpen}
        toggle={toggleRolesModal}
      >
        {currentProductKey && (
          <RolePolicy
            readOnly={!canEditRoles}
            currentUserUuid={currentUserUuid}
            entity={currentProductKey}
            entityType="ProductKey"
            onCancel={toggleRolesModal}
            shouldFetch={refetchRolePolicy}
            setShouldFetch={setRefetchRolePolicy}
            onSetEntityRole={async (role: {
              entityType: EntityType.ProductKey;
              userUuids: string[];
              entityRole: string;
              entityUuid: string;
              usersAlreadyEntitled: string[];
            }) => {
              await onSetEntityRole(
                { ...role, type: 'User', inherit: false },
                dispatch,
                setRefetchRolePolicy
              );
            }}
            onRemoveEntityRole={async (role: {
              entityType: EntityType.ProductKey;
              userUuid: string;
              entityRole: string;
              entityUuid: string;
            }) => {
              await onRemoveEntityRole(role, dispatch, setRefetchRolePolicy);
            }}
          />
        )}
      </Modal>
    </div>
  );
};
