import moment from 'moment/moment';
import _, { cloneDeep } from 'lodash';
import { deserializeUserOutput } from '../../../store/users/helpers';
import {
  DistributionConfig,
  DistributionConfigCredentials,
  DistributionConfigOptions,
  ProductProfile,
  ProductProfileOutputFile,
} from '../types';
import { deserializePGPKey } from '../../../libs/helpers';
import { CertificateProfile } from '../../../store/certificateProfiles/types';
import { deserializeCertificateProfile } from '../../../store/certificateProfiles/helpers';
import { UserOutput } from '../../../store/users/types';

export const serializeProductProfileCertificateProfilesUuids = (
  certificateProfileUuids: ProductProfile['certificateProfileUuids']
) => {
  const results: {
    [key: string]: string;
  } = {};
  (certificateProfileUuids || []).forEach(({ uuid, label }) => {
    results[label] = uuid || '';
  });
  return results;
};

export const serializeProductProfileOutputFiles = (
  outputFiles: ProductProfile['outputFiles']
) => {
  if (!outputFiles) return {};
  const serializedOutputFiles: {
    [key: string]: any;
  } = {};
  Object.keys(outputFiles).forEach((outputFileName) => {
    serializedOutputFiles[outputFileName] = {
      file_format: outputFiles[outputFileName].fileFormat,
      filename_pattern: outputFiles[outputFileName].fileNamePattern,
      filepath_pattern: outputFiles[outputFileName].filePathPattern,
    };
  });
  return serializedOutputFiles;
};

export const deserializeProductProfileOutputFiles = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  outputFiles: any
): ProductProfile['outputFiles'] => {
  const deserializedOutputFiles: ProductProfile['outputFiles'] = {};
  Object.keys(outputFiles).forEach((outputFileName: string) => {
    deserializedOutputFiles[outputFileName] = {
      fileFormat: outputFiles[outputFileName].file_format,
      fileNamePattern: outputFiles[outputFileName].filename_pattern,
      filePathPattern: outputFiles[outputFileName].filepath_pattern,
    };
  });
  return deserializedOutputFiles;
};

export const serializeProductProfileDistributionConfig = (
  distributionConfig: Partial<DistributionConfig>
) => {
  return {
    label: `sftp://${distributionConfig?.credentials?.username}@${distributionConfig.hostname}:${distributionConfig.port}`,
    protocol: 'sftp',
    credentials: {
      username: distributionConfig?.credentials?.username || '',
      ...(distributionConfig.credentials?.keyFiles && {
        key_files: distributionConfig.credentials.keyFiles.map((rawString) =>
          btoa(rawString)
        ),
      }),
    },
    hostname: distributionConfig.hostname || '',
    port: distributionConfig.port || 22,
    options: {
      key_spec: distributionConfig.options?.keySpec || '',
      key_value: distributionConfig.options?.keyValue || '',
    },
  };
};

export const deserializeProductProfileDistributionConfigs = (
  raw: any
): DistributionConfig => {
  return {
    path: raw.path,
    ...(raw.date_started && {
      startedAt: moment(raw.date_updated || _.get(raw, 'date_started')).format(
        'LLL'
      ),
    }),
    updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format(
      'LLL'
    ),
    createdAt: moment(raw.date_created).format('LLL'),
    ...(raw?.updated_by_user || raw?.created_by_user
      ? {
          updatedBy: deserializeUserOutput(
            raw?.updated_by_user || raw?.created_by_user
          ),
        }
      : {}),
    ...(raw?.created_by_user
      ? {
          createdBy: deserializeUserOutput(raw.created_by_user),
        }
      : {}),
    credentials: {
      username: raw.credentials?.username || '',
      keyFiles:
        raw.credentials?.key_files?.map((encoded: string) => atob(encoded)) ||
        [],
      passphrase: raw.credentials?.passphrase || '',
      password: raw.credentials?.password || '',
    },
    port: raw.port,
    id: raw.id,
    uuid: raw.uuid,
    version: raw.version,
    options: {
      keySpec: raw.options?.key_spec || '',
      keyValue: raw.options?.key_value || '',
    },
    protocol: 'sftp',
    hostname: raw.hostname,
    label: raw.label,
    customerUuid: raw.customer_uuid,
  };
};

export const serializeProductProfile = (
  productProfile: Partial<ProductProfile>
) => {
  return {
    ...(productProfile?.uuid && { uuid: productProfile.uuid }),
    ...(productProfile?.name && { name: productProfile.name }),
    ...(productProfile?.notes && { notes: productProfile.notes }),
    ...(productProfile?.productProfileId && {
      product_profile_id: productProfile.productProfileId,
    }),
    ...(productProfile?.metadata && { metadata: productProfile.metadata }),
    ...(productProfile?.secrets && { secret_data: productProfile.secrets }),
    ...(productProfile?.output && { output: productProfile.output }),
    ...(productProfile.outputFiles && {
      output_files: serializeProductProfileOutputFiles(
        productProfile.outputFiles
      ),
    }),
    ...(productProfile?.certificateProfiles && {
      certificate_profile_uuids: serializeProductProfileCertificateProfilesUuids(
        productProfile.certificateProfileUuids
      ),
    }),
    ...(productProfile?.productIdentifier && {
      product_identifier: productProfile?.productIdentifier,
    }),
    ...(productProfile?.productNamespace && {
      product_namespace: productProfile?.productNamespace,
    }),
    ...(productProfile?.report && {
      report: productProfile?.report,
    }),
    ...(productProfile?.pgpKeys && {
      pgp_key_uuids: productProfile.pgpKeys.map((pgpKey) => pgpKey.uuid),
    }),
  };
};

export const deserializeProductProfile = (raw: any): ProductProfile => {
  const certificateProfileUuids: {
    label: string;
    uuid: string;
  }[] = (Object.entries(raw.certificate_profile_uuids || {}) as [
    label: string,
    uuid: string
  ][]).map(([label, uuid]) => {
    return {
      label,
      uuid,
    };
  });
  const certificateProfiles: {
    label: string;
    certificateProfile: CertificateProfile;
  }[] = Object.entries(raw.certificate_profiles || {}).map(
    ([label, certificateProfile]: [label: string, certificateProfile: any]) => {
      return {
        label,
        certificateProfile: deserializeCertificateProfile(certificateProfile),
      };
    }
  );
  return {
    productProfileId: raw.product_profile_id,
    name: raw.name,
    uuid: raw.uuid,
    notes: raw.notes || '',
    actions: raw.actions,
    metadata: raw.metadata || [],
    secrets: raw.secret_data || [],
    output: raw.output || [],
    outputFiles: deserializeProductProfileOutputFiles(raw.output_files || {}),
    destinationUuid: raw.destination_uuid,
    destination: raw.destination
      ? deserializeProductProfileDistributionConfigs(raw.destination)
      : undefined,
    certificateProfiles: certificateProfiles || [],
    certificateProfileUuids,
    productIdentifier: raw?.product_identifier || '',
    productNamespace: raw?.product_namespace || '',
    report: raw?.report,
    pgpKeys: raw?.pgp_keys
      ? raw.pgp_keys.map((pgpKey: any) => deserializePGPKey(pgpKey))
      : [],
    updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format(
      'LLL'
    ),
    createdAt: moment(raw.date_created).format('LLL'),
    ...(raw?.updated_by_user || raw?.created_by_user
      ? {
          updatedBy: deserializeUserOutput(
            raw?.updated_by_user || raw?.created_by_user
          ),
        }
      : {}),
    ...(raw?.created_by_user
      ? {
          createdBy: deserializeUserOutput(raw.created_by_user),
        }
      : {}),
  };
};

export const swapArrayLoc = (arr: any[], from: number, to: number) => {
  const newArr = cloneDeep(arr);
  newArr.splice(from, 1, newArr.splice(to, 1, newArr[from])[0]);
  return newArr;
};
