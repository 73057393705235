import {
  Input,
  FormGroup,
  Label,
  Col,
  UncontrolledPopover,
  Row,
} from 'reactstrap';
import { isEmpty, find, get } from 'lodash';
import React, { FC, useEffect, useState, useRef } from 'react';
import SmartSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ApprovalPolicy from './ApprovalPolicy';
import { Toggle } from '../../components';
import { getVars } from '../../libs/provisioning';

interface Props {
  onChange: Function;
  commonKeyOptions: { value: string | number; label: string }[];
  commonKeyUuid: string;
  extendedKeyUsage: string[];
  // keyPolicy: string;
  isLoading?: boolean;
  readOnly: boolean;
  approversRequired?: boolean;
  approversIdList: string[];
  id?: string;
}

const Policies: FC<Props> = ({
  onChange = (): null => null,
  approversIdList,
  // keyPolicy,
  commonKeyOptions,
  commonKeyUuid,
  approversRequired,
  extendedKeyUsage,
  readOnly = false,
  id = '',
}) => {
  const customerCode = (localStorage.getItem('pki_customer') || '').trim();
  const SHOW_TOGGLE_FOR = ['polsat', 'canary'];
  const { ENV } = getVars();
  if (ENV.toUpperCase() === 'LOCAL') {
    SHOW_TOGGLE_FOR.push('knorrbremse');
  }
  const [showToggle, setShowToggle] = useState(
    customerCode !== '' && SHOW_TOGGLE_FOR.includes(customerCode)
  );
  const [approversRequiredToggle, setApproversRequiredToggle] = useState(
    approversRequired
  );
  const [enabledToggle, setEnabledToggle] = useState(
    extendedKeyUsage.includes('code_signing')
  );
  const hadCodeSigningRef = useRef(extendedKeyUsage.includes('code_signing'));

  useEffect(() => {
    setShowToggle(
      customerCode !== '' && SHOW_TOGGLE_FOR.includes(customerCode)
    );
  }, []);
  // The use of had and has values is used to capture the current and previous
  // state of the Code Signing checkbox
  useEffect(() => {
    const hasCodeSigning = extendedKeyUsage.includes('code_signing');
    const hadCodeSigning = hadCodeSigningRef.current;
    if (!showToggle) {
      onChange({
        property: 'approversRequired',
        value: false,
      });
      setApproversRequiredToggle(false);
      setEnabledToggle(false);
    } else if (hadCodeSigning && !hasCodeSigning) {
      onChange({
        property: 'approversRequired',
        value: false,
      });
      setApproversRequiredToggle(false);
      setEnabledToggle(false);
    } else if (hasCodeSigning && !hadCodeSigning) {
      setApproversRequiredToggle(approversRequired);
      setEnabledToggle(true);
    } else if (hasCodeSigning && hadCodeSigning) {
      setEnabledToggle(true);
      setApproversRequiredToggle(approversRequired);
    } else {
      setEnabledToggle(false);
      setApproversRequiredToggle(false);
    }
    hadCodeSigningRef.current = hasCodeSigning;
  }, [extendedKeyUsage, approversRequired]);
  const selectedCommonKey =
    find(commonKeyOptions, (item) => item.value === commonKeyUuid) || undefined;
  const isCodeSigningEnabled = extendedKeyUsage.some(
    (item) => item === 'code_signing'
  );
  const isRequestInvalid = !!(approversRequired && isEmpty(approversIdList));
  let toggleInfo;
  if (isCodeSigningEnabled) {
    toggleInfo =
      'Require at least one approver for' +
      '\n' +
      'use in a Code Signing Profile' +
      '\n' +
      'during a Code Signing Request';
  } else {
    toggleInfo =
      'To enable this toggle' +
      '\n' +
      'check the Code Signing' +
      '\n' +
      'extension under Extended Key Usage' +
      '\n' +
      'in the Basic Extensions';
  }

  return (
    <div id={id} className="Polices mt-4">
      <FormGroup>
        <Label className="pki-label" for="authority">
          Common Key
        </Label>
        {readOnly && (
          <Input
            value={get(selectedCommonKey, 'label') || 'No value'}
            readOnly={true}
            plaintext={true}
            type="text"
            name="commonKey"
            id="commonKey"
          />
        )}
        {!readOnly && (
          <SmartSelect
            isSearchable={true}
            isClearable={true}
            isDisabled={readOnly || isEmpty(commonKeyOptions)}
            isMulti={false}
            value={selectedCommonKey}
            placeholder={
              !isEmpty(commonKeyOptions)
                ? 'Select Common Key...'
                : 'No Common Keys Available...'
            }
            options={commonKeyOptions}
            onChange={(selectedValue): void => {
              onChange({
                property: 'commonKeyUuid',
                value: get(selectedValue, 'value', undefined),
              });
            }}
          />
        )}
      </FormGroup>
      {showToggle && (
        <div className="mt-4">
          <Row>
            <Col md={12}>
              <Label className="pki-label">
                Code Signing Approver Required
              </Label>
              <FontAwesomeIcon
                id="approval-required-enabled-hint"
                icon={faInfoCircle}
                className="pki-ico ml-1"
              />
              <UncontrolledPopover
                trigger="hover"
                target="approval-required-enabled-hint"
              >
                <div className="p-2">
                  <small>{toggleInfo}</small>
                </div>
              </UncontrolledPopover>
              <FormGroup>
                <Toggle
                  id="enabled"
                  disabled={!enabledToggle || readOnly}
                  onChange={(checked: boolean): void => {
                    onChange({
                      property: 'approversRequired',
                      value: checked,
                    });
                  }}
                  checked={enabledToggle && approversRequiredToggle}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
      <ApprovalPolicy
        id="approval-policy"
        isRequestInvalid={isRequestInvalid}
        onChange={(approverUuids: string[]): void => {
          onChange({
            property: 'approvalPolicy',
            value: { approverUuids },
          });
        }}
        readOnly={readOnly}
        approversIdList={approversIdList}
      />
    </div>
  );
};

export default Policies;
