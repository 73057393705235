import { Reducer } from 'redux';
import { UsersState, UsersActionTypes } from './types';

export const initialState: UsersState = {
  userProfile: {
    uuid: '',
    email: '',
    enabled: true,
    username: '',
    firstName: '',
    lastName: '',
    entityRoles: [],
    settings: [],
    resources: [],
    customers: [],
  },
  filterUsersList: [],
  usersList: [],
  isGettingFilterUserList: false,
  isLoadedFilterUserList: false,
  isGettingUserList: false,
  isLoadedUserList: false,
  isSettingUserSettings: false,
  isGettingUserProfile: false,
  isCreatingUser: false,
  isEditingUser: false,
  isUserProfileLoaded: false,
  isSyncUsers: false,
  isEditiningEntityRole: false,
  userErrors: undefined,
  filterUserErrors: undefined,
  isResettingOTPCredentials: false,
};

const reducer: Reducer<UsersState> = (
  state = initialState,
  action
): UsersState => {
  switch (action.type) {
    case UsersActionTypes.GET_USER_PROFILE_REQUEST: {
      return {
        ...state,
        isGettingUserProfile: true,
        isUserProfileLoaded: false,
      };
    }
    case UsersActionTypes.GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isGettingUserProfile: false,
        isUserProfileLoaded: true,
        userProfile: action.payload,
      };
    }
    case UsersActionTypes.GET_USER_PROFILE_ERROR: {
      return {
        ...state,
        isGettingUserProfile: false,
        isUserProfileLoaded: false,
        userErrors: action.payload,
      };
    }

    case UsersActionTypes.POST_USER_REQUEST: {
      return {
        ...state,
        isCreatingUser: true,
      };
    }
    case UsersActionTypes.POST_USER_SUCCESS: {
      return {
        ...state,
        isCreatingUser: false,
      };
    }
    case UsersActionTypes.POST_USER_ERROR: {
      return {
        ...state,
        isCreatingUser: false,
      };
    }

    case UsersActionTypes.PUT_USER_REQUEST: {
      return {
        ...state,
        isEditingUser: true,
      };
    }
    case UsersActionTypes.PUT_USER_SUCCESS: {
      return {
        ...state,
        isEditingUser: false,
      };
    }
    case UsersActionTypes.PUT_USER_ERROR: {
      return {
        ...state,
        isEditingUser: false,
      };
    }

    case UsersActionTypes.POST_USER_SETTINGS_REQUEST: {
      return { ...state, isSettingUserSettings: true };
    }
    case UsersActionTypes.POST_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        isSettingUserSettings: false,
      };
    }
    case UsersActionTypes.POST_USER_SETTINGS_ERROR: {
      return {
        ...state,
        isSettingUserSettings: false,
      };
    }

    case UsersActionTypes.GET_USERS_LIST_REQUEST: {
      return {
        ...state,
        isGettingUserList: true,
        isLoadedUserList: false,
        userErrors: undefined,
      };
    }
    case UsersActionTypes.GET_USERS_LIST_SUCCESS: {
      return {
        ...state,
        isGettingUserList: false,
        isLoadedUserList: true,
        usersList: action.payload,
      };
    }
    case UsersActionTypes.GET_USERS_LIST_ERROR: {
      return {
        ...state,
        isGettingUserList: false,
        isLoadedUserList: false,
        userErrors: action.payload,
      };
    }
    case UsersActionTypes.GET_FILTER_USERS_LIST_REQUEST: {
      return {
        ...state,
        isGettingFilterUserList: true,
        isLoadedFilterUserList: false,
        filterUserErrors: undefined,
      };
    }
    case UsersActionTypes.GET_FILTER_USERS_LIST_SUCCESS: {
      return {
        ...state,
        isGettingFilterUserList: false,
        isLoadedFilterUserList: true,
        filterUsersList: action.payload,
      };
    }
    case UsersActionTypes.GET_FILTER_USERS_LIST_ERROR: {
      return {
        ...state,
        isGettingFilterUserList: false,
        isLoadedFilterUserList: false,
        filterUserErrors: action.payload,
      };
    }

    case UsersActionTypes.GET_SYNC_USERS_REQUEST: {
      return { ...state, isSyncUsers: true };
    }
    case UsersActionTypes.GET_SYNC_USERS_SUCCESS: {
      return { ...state, isSyncUsers: false };
    }
    case UsersActionTypes.GET_SYNC_USERS_ERROR: {
      return { ...state, isSyncUsers: false };
    }

    case UsersActionTypes.POST_USERS_ENTITY_ROLE_REQUEST: {
      return { ...state, isEditiningEntityRole: true };
    }
    case UsersActionTypes.POST_USERS_ENTITY_ROLE_SUCCESS: {
      return { ...state, isEditiningEntityRole: false };
    }
    case UsersActionTypes.POST_USERS_ENTITY_ROLE_ERROR: {
      return { ...state, isEditiningEntityRole: false };
    }

    case UsersActionTypes.DELETE_USER_ENTITY_ROLE_REQUEST: {
      return { ...state, isEditiningEntityRole: true };
    }
    case UsersActionTypes.DELETE_USER_ENTITY_ROLE_SUCCESS: {
      return { ...state, isEditiningEntityRole: false };
    }
    case UsersActionTypes.DELETE_USER_ENTITY_ROLE_ERROR: {
      return { ...state, isEditiningEntityRole: false };
    }

    case UsersActionTypes.RESET_USER_OTP_CREDENTIALS_REQUEST: {
      return { ...state, isResettingOTPCredentials: true };
    }
    case UsersActionTypes.RESET_USER_OTP_CREDENTIALS_SUCCESS: {
      return { ...state, isResettingOTPCredentials: false };
    }
    case UsersActionTypes.RESET_USER_OTP_CREDENTIALS_ERROR: {
      return { ...state, isResettingOTPCredentials: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as usersReducer };
