import React, { FC, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

interface Props {
  message?: string;
  className?: string;
  cleanUp?: Function;
}

const ErrorHandler: FC<Props> = ({
  message,
  className,
  cleanUp = (): null => null,
}) => {
  const history = useHistory();
  useEffect(() => {
    return function cleanup(): void {
      cleanUp();
    };
  });
  return (
    <div className={`ErrorHandler${className && ` ${className}`}`}>
      <div className="text-center">
        <FontAwesomeIcon
          className="pki-ico"
          size="8x"
          icon={faExclamationTriangle}
        />
        <h4 className="text-muted mt-2">An error has occurred</h4>
        {message && <p>{message}</p>}
        <p className="mb-1">
          Please reload the page and try again{' '}
          <FontAwesomeIcon
            className="pki-ico ml-2"
            onClick={(): void => {
              history.go(0);
            }}
            icon={faRedoAlt}
          />
        </p>
        <p>or contact the customer service.</p>
      </div>
    </div>
  );
};

export default ErrorHandler;
