/* eslint-disable no-plusplus */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import { AnyAction } from 'redux';
import { sendNotification } from '../notifications/actions';
import { deserializeKey } from './helpers';
import { CommonKeysState, CommonKeysActionTypes } from './types';
import { api, toBase64 } from '../../libs/helpers';

const error = require('debug')('pki-app:error:keys');

export const getCommonKeys = (): ThunkAction<
  Promise<boolean>,
  CommonKeysState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<CommonKeysState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CommonKeysActionTypes.GET_KEY_LIST_REQUEST });
    try {
      const response = await api().get('common-key');
      const payload = _.map(response.data, (item) => deserializeKey(item));
      dispatch({
        type: CommonKeysActionTypes.GET_KEY_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: CommonKeysActionTypes.GET_KEY_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Common Key list!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getKeys: ${err}`);
      return false;
    }
  };
};

export const uploadCommonKey = (
  encryptedKeyFile: File
): ThunkAction<Promise<boolean>, CommonKeysState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<CommonKeysState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({ type: CommonKeysActionTypes.POST_KEY_REQUEST });
    try {
      // eslint-disable-next-line camelcase
      const encrypted_key = await toBase64(encryptedKeyFile); // toDo
      await api().post('common-key', { encrypted_key });
      dispatch({
        type: CommonKeysActionTypes.POST_KEY_SUCCESS,
      });
      sendNotification({
        text: 'The new Common Key has been successfully uploaded!',
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: CommonKeysActionTypes.POST_KEY_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong uploading the new Common Key!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`uploadCommonKey: ${err}`);
      return false;
    }
  };
};
