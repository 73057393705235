import React, { ChangeEventHandler, FC, MutableRefObject, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { FilesTable } from './FilesTable/FilesTable';
import { Spinner } from '../../../../../../../components';

const allowedFileExtensions = ['.tar', '.tar.gz', '.gz'];

interface UploadProps {
  onAddFiles: Function;
  filesToUpload: File[];
  onClearSelection: Function;
  isLoading?: boolean;
  onDeleteFile: (index: number) => void;
  disabledButtons: boolean;
  onUploadButtonClick: () => void;
  onUploadAndAssignButtonClick: () => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

const idClassPrefix = 'upload-asymmetric-keys';
export const Upload: FC<UploadProps> = ({
  onAddFiles,
  filesToUpload,
  onClearSelection,
  isLoading = false,
  onDeleteFile,
  disabledButtons,
  onUploadButtonClick,
  onUploadAndAssignButtonClick,
  inputRef,
}) => {
  const onAddFilesButtonClick = () => {
    inputRef.current?.click();
  };

  const onFilesChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    onAddFiles(ev.target.files);
  };
  return (
    <>
      <FilesTable files={filesToUpload} onDeleteFile={onDeleteFile} />
      <label htmlFor={`upload-asymmetric-key-file-input`}>
        <Button
          onClick={onAddFilesButtonClick}
          color={'secondary'}
          outline
          size={'sm'}
        >
          <>
            <FontAwesomeIcon icon={faUpload} /> Add Files
          </>
        </Button>
      </label>
      <input
        ref={inputRef}
        hidden
        onChange={onFilesChange}
        type="file"
        multiple
        id={`upload-asymmetric-key-file-input`}
        accept={allowedFileExtensions.join(',')}
      />
      <Button
        onClick={() => onClearSelection()}
        color={'secondary'}
        id={'upload-asymmetric-key-clear-selection'}
        className={'ml-2'}
        disabled={filesToUpload.length === 0}
        outline
        size={'sm'}
      >
        <>
          <FontAwesomeIcon icon={faTrash} /> Clear Selection
        </>
      </Button>
      <div className="modal-buttons">
        <div className="float-right mt-5 pb-5 d-flex">
          <>
            <span className="mr-2">
              <Button
                id={`${idClassPrefix}-just-upload`}
                outline
                disabled={disabledButtons}
                onClick={onUploadButtonClick}
              >
                Upload & Close
              </Button>
            </span>
            <span>
              <Button
                id={`${idClassPrefix}-upload-and-assign`}
                outline
                disabled={disabledButtons}
                onClick={onUploadAndAssignButtonClick}
              >
                Upload & Assign
              </Button>
            </span>
          </>
          {isLoading && (
            <Spinner
              size={'sm'}
              className={'ml-2 btn-group-vertical'}
              type={'border'}
            />
          )}
        </div>
      </div>
    </>
  );
};
