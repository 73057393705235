import React, { useState, FC, useEffect, ReactNode, useMemo } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Card, Input, UncontrolledPopover, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamation,
  faEdit,
  faTimesCircle,
  faDownload,
  faExternalLink,
} from '@fortawesome/free-solid-svg-icons';
import CodeSigningRequestForm from './CodeSigningRequestForm';

import {
  Table,
  Spinner,
  TableActions,
  ConfirmationForm,
  FilterBar,
  ErrorHandler,
} from '../../components';
import {
  RequestsState,
  CertificateRequest,
  CodeSigningRequest,
  Request,
  RequestType,
} from '../../store/requests/types';
import {
  getRequest,
  createRequest,
  editRequestStatus,
  editRequest,
} from '../../store/requests/actions';
import { sendNotification } from '../../store/notifications/actions';
import { ApplicationState } from '../../store';
import { UsersState } from '../../store/users/types';
import { unhexlify, dateFormatter } from '../../libs/helpers';
import { outputFormatOptions } from '../../libs/constants';
import RequestsToolbar from '../ViewsComponents/RequestsToolbar';
import { Filter } from '../../components/FilterBar/types';

import './CodeSigningRequests.scss';

const { saveAs } = require('file-saver');

enum confirmationType {
  Approve = 'Approve',
  Reject = 'Reject',
}

const CodeSigningRequests: FC = () => {
  const {
    isGettingCodeSigningRequestList,
    isLoadedCodeSigningRequestList,
    codeSigningRequestList,
    isCreatingRequest,
    isEditingRequest,
    isEditingRequestStatus,
    userProfile: { resources, uuid: userUuid },
    requestsListErrors,
  } = useSelector<ApplicationState, RequestsState & UsersState>((pki) => ({
    ...pki.requests,
    ...pki.users,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const [formModal, setFormModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [editRequestReason, setEditRequestReason] = useState<string>('');
  const [requestForm, setRequestForm] = useState<Request>();
  const [requestFormType, setRequestFormType] = useState<RequestType>();
  const [
    confirmationFormType,
    setConfirmationFormType,
  ] = useState<confirmationType>();
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);

  const isPerformingAction =
    isCreatingRequest || isEditingRequestStatus || isEditingRequest;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const requestUuidParam = params.get('uuid');
  const initialQuery: Filter[] = useMemo(
    () =>
      requestUuidParam
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: requestUuidParam,
            },
          ]
        : [],
    [requestUuidParam]
  );
  const [query, setQuery] = useState<Filter[]>(initialQuery);
  const viewLocation = location.pathname;

  useEffect(() => {
    if (isLoadedCodeSigningRequestList || requestsListErrors) {
      setShowContent(true);
    }
    if (isGettingCodeSigningRequestList || isPerformingAction) {
      setShowSpinner(true);
    }
    if (
      (!isGettingCodeSigningRequestList && !isPerformingAction) ||
      requestsListErrors
    ) {
      setShowSpinner(false);
    }
    return function cleanup(): void {
      if (location.pathname !== viewLocation) {
        setShowContent(false);
      }
    };
  }, [
    isGettingCodeSigningRequestList,
    isLoadedCodeSigningRequestList,
    isPerformingAction,
    location.pathname,
    requestsListErrors,
    viewLocation,
  ]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    if (!isPerformingAction) {
      dispatch(
        getRequest(
          {
            page: tablePage,
            sizePerPage: tableSizePerPage,
            query,
            requestType: RequestType.CodeSigning,
          },
          tokenSource
        )
      );
    }
    return function cleanup(): void {
      tokenSource.cancel(`Request::getCodeSigningRequest`);
    };
  }, [dispatch, isPerformingAction, query, tablePage, tableSizePerPage]);

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setRequestForm(undefined);
        setConfirmationFormType(undefined);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const codeSigningRequestResource = _.find(
    resources,
    (item) => item.name === 'code_signing_signature'
  );

  const columns = [
    {
      dataField: 'noData1',
      text: '',
      formatter: (
        valueNotImportant: null,
        currentRequest: Request
        // eslint-disable-next-line consistent-return
      ): ReactNode | void => {
        const { actions, status } = currentRequest;
        if (status === 'Awaiting Approval' && _.includes(actions, 'approve')) {
          return (
            <div id={`alert-${currentRequest.uuid}`}>
              <FontAwesomeIcon className="pki-ico" icon={faExclamation} />
              <UncontrolledPopover
                trigger="hover"
                target={`alert-${currentRequest.uuid}`}
              >
                <div className="p-1">
                  <small>This request requires your action</small>
                </div>
              </UncontrolledPopover>
            </div>
          );
        }
      },
    },
    {
      dataField: 'uuid',
      text: 'Request ID',
      formatter: (uuid: string, { notes }: { notes: string }): ReactNode => {
        return (
          <>
            <div>{uuid?.toUpperCase()}</div>
            {!_.isEmpty(notes) && (
              <div
                id={`request-${uuid}`}
                style={{ maxWidth: '600px' }}
                className="d-inline-block text-truncate"
              >
                <small> {notes}</small>
                <UncontrolledPopover trigger="hover" target={`request-${uuid}`}>
                  <div className="p-2">
                    <small>{notes}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    { dataField: 'status', text: 'Status' },
    {
      dataField: 'outputFormat',
      text: 'Output',
      headerClasses: '',
      formatter: (valueNotUsed: null, request: Request): ReactNode => {
        const { outputFormat: outputFormatKey } = request as CodeSigningRequest;
        const output = _.find(
          outputFormatOptions,
          (item) => item.key === outputFormatKey
        )?.value;
        return <>{output}</>;
      },
    },
    {
      dataField: 'issuedAt',
      text: 'Request Date',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Requested by',
      formatter: (createdBy: string, currentRequest: Request): ReactNode => {
        const isCreatedByUserDeleted = _.get(
          currentRequest,
          'isCreatedByUserDeleted'
        );
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      formatter: (
        valueNotImportant: null,
        currentRequest: Request
      ): ReactNode => {
        const { actions } = currentRequest;
        const canUpdate = _.includes(actions, 'update');
        const canApprove = _.includes(actions, 'approve');
        const canReject = _.includes(actions, 'reject');
        const certificateUuid = _.get(currentRequest, 'certificateUuid');

        if (
          _.includes(
            ['Rejected', 'Failed', 'In Progress'],
            currentRequest.status
          )
        ) {
          return <div className="text-center"> No actions available </div>;
        }

        let options: {
          label?: string;
          hidden?: boolean;
          ico: ReactNode;
          disabled?: boolean;
          onClick: Function;
        }[] = [];

        if (_.includes(['Completed'], currentRequest.status)) {
          if (
            certificateUuid &&
            currentRequest.type !== RequestType.CodeSigning
          ) {
            options = [
              {
                label: 'Related Certificate',
                ico: (
                  <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />
                ),
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();

                  history.push({
                    pathname: '/inventory/certificates',
                    search: `?uuid=${certificateUuid}`,
                  });
                },
              },
            ];
          }
          if (currentRequest.type === RequestType.CodeSigning) {
            options = [
              {
                label: 'Download Signature',
                ico: <FontAwesomeIcon className="pki-ico" icon={faDownload} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  const {
                    signature,
                    codeSigningProfileUuid,
                    uuid,
                  } = currentRequest as CodeSigningRequest;

                  const u8 = unhexlify(String(signature));
                  const blob = new Blob([u8], {
                    type: 'application/octet-stream',
                  });
                  saveAs(blob, `${codeSigningProfileUuid}-${uuid}.bin`);
                },
              },
              {
                label: 'Related Certificate',
                ico: (
                  <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />
                ),
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();

                  history.push({
                    pathname: '/inventory/certificates',
                    search: `?uuid=${certificateUuid}`,
                  });
                },
              },
            ];
          }
        }

        if (currentRequest.status === 'Awaiting Approval') {
          options = [
            {
              label: 'Edit',
              disabled: !canUpdate,
              ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
              onClick: (e: MouseEvent): void => {
                e.stopPropagation();
                if (canUpdate) {
                  setRequestFormType(currentRequest.type);
                  setRequestForm(currentRequest);
                  setFormReadOnly(false);
                  toggleFormModal();
                }
              },
            },
            {
              label: 'Approve',
              disabled: !canApprove,
              ico: <FontAwesomeIcon className="pki-ico" icon={faCheckCircle} />,
              onClick: (e: MouseEvent): void => {
                e.stopPropagation();
                if (canApprove) {
                  setConfirmationFormType(confirmationType.Approve);
                  setRequestForm(currentRequest);
                  setFormModal(true);
                }
              },
            },
            {
              label: 'Reject',
              disabled: !canReject,
              ico: <FontAwesomeIcon className="pki-ico" icon={faTimesCircle} />,
              onClick: (e: MouseEvent): void => {
                e.stopPropagation();
                if (canReject) {
                  setConfirmationFormType(confirmationType.Reject);
                  setRequestForm(currentRequest);
                  setFormModal(true);
                }
              },
            },
          ];
        }
        return (
          <TableActions rowId={String(currentRequest.uuid)} options={options} />
        );
      },
    },
  ];

  return (
    <div className="Requests">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Code Signing Requests</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              initialFilters={initialQuery}
              filters={[
                { key: 'uuid', label: 'Request ID', type: 'filter' },
                {
                  key: 'status',
                  label: 'Status',
                  type: 'filter',
                  options: [
                    { value: 'in_progress', label: 'In Progress' },
                    { value: 'completed', label: 'Completed' },
                    { value: 'rejected', label: 'Rejected' },
                    {
                      value: 'awaiting_approval',
                      label: 'Awaiting Approval',
                    },
                    {
                      value: 'failed',
                      label: 'Failed',
                    },
                  ],
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Request Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Updated',
                  type: 'filter',
                },
              ]}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {requestsListErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                keyField="uuid"
                noDataIndication={
                  tablePage > 1
                    ? 'No more Requests available'
                    : 'No Requests available'
                }
                remote={true}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                onTableChange={(
                  valueNotUsed: null,
                  { page, sizePerPage }: { page: number; sizePerPage: number }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                }}
                search={false}
                data={codeSigningRequestList}
                columns={columns}
                rowEvents={{
                  onClick: (notUsedValue: null, current: Request): void => {
                    setRequestFormType(current.type);
                    setRequestForm(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                toolbar={
                  <RequestsToolbar
                    type={RequestType.CodeSigning}
                    actions={_.get(codeSigningRequestResource, 'actions', [])}
                    onCreateRequestClick={(requestType: RequestType): void => {
                      setRequestFormType(requestType);
                      toggleFormModal();
                      setFormReadOnly(false);
                    }}
                  />
                }
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        {!confirmationFormType && requestFormType === RequestType.CodeSigning && (
          <CodeSigningRequestForm
            readOnly={formReadOnly}
            onCancel={toggleFormModal}
            onChangeMode={toggleMode}
            defaultValues={requestForm as CodeSigningRequest}
            onSubmit={({
              values,
              isValid,
            }: {
              values: CertificateRequest;
              isValid: boolean;
            }): void => {
              if (isValid) {
                values.uuid
                  ? dispatch(editRequest(values))
                  : dispatch(createRequest(values));
                toggleFormModal();
              } else {
                dispatch(
                  sendNotification({
                    text: 'Some fields are mandatory, please fill them all.',
                    success: false,
                  })
                );
              }
            }}
          />
        )}

        {confirmationFormType && requestForm && (
          <ConfirmationForm
            title={`${_.capitalize(confirmationFormType)} Confirmation`}
            content={
              <div className="px-5 text-center">
                <p>
                  You are about to {confirmationFormType}{' '}
                  <strong>
                    {requestForm.uuid &&
                      requestForm.uuid.slice(0, 8).toLocaleUpperCase()}
                  </strong>
                  , requested by <strong>{requestForm.updatedBy}</strong>
                </p>
                <p>
                  Add notes to include in the notification mail to{' '}
                  {requestForm.updatedBy}
                </p>
                <Input
                  className="my-2"
                  value={editRequestReason}
                  rows={3}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    setEditRequestReason(event.target.value);
                  }}
                  type="textarea"
                />
              </div>
            }
            onCancel={toggleFormModal}
            onConfirm={(): void => {
              if (requestForm && requestForm.uuid && confirmationFormType) {
                dispatch(
                  editRequestStatus({
                    notes: editRequestReason,
                    uuid: requestForm.uuid,
                    action: confirmationFormType,
                    type: requestForm.type,
                  })
                );
              }
              setFormModal(false);
              setEditRequestReason('');
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default CodeSigningRequests;
