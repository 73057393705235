import { Reducer } from 'redux';
import { RequestsState, RequestActionTypes } from './types';

export const initialState: RequestsState = {
  requestList: [],
  awaitingRequestList: [],
  certificateRequestList: [],
  codeSigningKeyRequestList: [],
  codeSigningRequestList: [],
  requestsListErrors: undefined,
  isGettingAwaitingRequestList: false,
  isLoadedAwaitingRequestList: false,
  isGettingCertificateRequestList: false,
  isLoadedCertificateRequestList: false,
  isGettingCodeSigningRequestList: false,
  isLoadedCodeSigningRequestList: false,
  isGettingCodeSigningKeyRequestList: false,
  isLoadedCodeSigningKeyRequestList: false,
  isGettingRequestCounter: false,
  isLoadedRequestCounter: false,
  requestCounter: {},
  isEditingRequestStatus: false,
  isEditingRequest: false,
  isCreatingRequest: false,
  certificateBatchInDownload: String(0),
};

const reducer: Reducer<RequestsState> = (
  state = initialState,
  action
): RequestsState => {
  switch (action.type) {
    case RequestActionTypes.GET_CERTIFICATE_REQUEST_LIST_REQUEST: {
      return {
        ...state,
        isGettingCertificateRequestList: true,
        isLoadedCertificateRequestList: false,
        requestsListErrors: undefined,
      };
    }

    case RequestActionTypes.GET_CERTIFICATE_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCertificateRequestList: false,
        isLoadedCertificateRequestList: true,
        certificateRequestList: action.payload,
      };
    }
    case RequestActionTypes.GET_CERTIFICATE_REQUEST_LIST_ERROR: {
      return {
        ...state,
        isGettingCertificateRequestList: false,
        isLoadedCertificateRequestList: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.GET_CODE_SIGNING_REQUEST_LIST_REQUEST: {
      return {
        ...state,
        isGettingCodeSigningRequestList: true,
        isLoadedCodeSigningRequestList: false,
        requestsListErrors: undefined,
      };
    }
    case RequestActionTypes.GET_CODE_SIGNING_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCodeSigningRequestList: false,
        isLoadedCodeSigningRequestList: true,
        codeSigningRequestList: action.payload,
      };
    }
    case RequestActionTypes.GET_CODE_SIGNING_REQUEST_LIST_ERROR: {
      return {
        ...state,
        isGettingCodeSigningRequestList: false,
        isLoadedCodeSigningRequestList: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.GET_CODE_SIGNING_KEY_REQUEST_LIST_REQUEST: {
      return {
        ...state,
        isGettingCodeSigningKeyRequestList: true,
        isLoadedCodeSigningKeyRequestList: false,
        requestsListErrors: undefined,
      };
    }
    case RequestActionTypes.GET_CODE_SIGNING_KEY_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        isGettingCodeSigningKeyRequestList: false,
        isLoadedCodeSigningKeyRequestList: true,
        codeSigningKeyRequestList: action.payload,
      };
    }
    case RequestActionTypes.GET_CODE_SIGNING_KEY_REQUEST_LIST_ERROR: {
      return {
        ...state,
        isGettingCodeSigningKeyRequestList: false,
        isLoadedCodeSigningKeyRequestList: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.GET_AWAITING_REQUEST_LIST_REQUEST: {
      return {
        ...state,
        isGettingAwaitingRequestList: true,
        isLoadedAwaitingRequestList: false,
      };
    }
    case RequestActionTypes.GET_AWAITING_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        isGettingAwaitingRequestList: false,
        isLoadedAwaitingRequestList: true,
        awaitingRequestList: action.payload,
      };
    }
    case RequestActionTypes.GET_AWAITING_REQUEST_LIST_ERROR: {
      return {
        ...state,
        isGettingAwaitingRequestList: false,
        isLoadedAwaitingRequestList: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.GET_REQUEST_COUNTER_REQUEST: {
      return {
        ...state,
        isGettingRequestCounter: true,
        isLoadedRequestCounter: false,
      };
    }
    case RequestActionTypes.GET_REQUEST_COUNTER_SUCCESS: {
      return {
        ...state,
        isGettingRequestCounter: false,
        isLoadedRequestCounter: true,
        requestCounter: action.payload,
      };
    }
    case RequestActionTypes.GET_REQUEST_COUNTER_ERROR: {
      return {
        ...state,
        isGettingRequestCounter: false,
        isLoadedRequestCounter: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.POST_REQUEST_REQUEST: {
      return { ...state, isCreatingRequest: true };
    }
    case RequestActionTypes.POST_REQUEST_SUCCESS: {
      return { ...state, isCreatingRequest: false };
    }
    case RequestActionTypes.POST_REQUEST_ERROR: {
      return {
        ...state,
        isCreatingRequest: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.PUT_REQUEST_REQUEST: {
      return { ...state, isEditingRequest: true };
    }
    case RequestActionTypes.PUT_REQUEST_SUCCESS: {
      return { ...state, isEditingRequest: false };
    }
    case RequestActionTypes.PUT_REQUEST_ERROR: {
      return {
        ...state,
        isEditingRequest: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.PUT_REQUEST_STATUS_REQUEST: {
      return { ...state, isEditingRequestStatus: true };
    }
    case RequestActionTypes.PUT_REQUEST_STATUS_SUCCESS: {
      return { ...state, isEditingRequestStatus: false };
    }
    case RequestActionTypes.PUT_REQUEST_STATUS_ERROR: {
      return {
        ...state,
        isEditingRequestStatus: false,
        requestsListErrors: action.payload,
      };
    }

    case RequestActionTypes.DOWLOAD_BATCH_CERTIFICATES_REQUEST: {
      return { ...state, certificateBatchInDownload: action.payload };
    }
    case RequestActionTypes.DOWLOAD_BATCH_CERTIFICATES_SUCCESS: {
      return {
        ...state,
        certificateBatchInDownload: String(0),
      };
    }
    case RequestActionTypes.DOWLOAD_BATCH_CERTIFICATES_ERROR: {
      return {
        ...state,
        certificateBatchInDownload: String(0),
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as requestsReducer };
