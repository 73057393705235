import { TreeItem } from 'react-sortable-tree';

export interface Certificate {
  serial: string;
  uuid: string;
  commonName: string;
  state: string;
  certificateRequestId?: string;
  deviceRequestUuid?: string;
  deviceUuid?: string;
  keyIdentifier: string;
  issuer?: {
    uuid: string;
    commonName: string;
  };
  issuingCaUuid: string | null;
  issuedAt: string;
  isKeyOnline: boolean;
  startsAt: string;
  depth?: 0 | 1 | 2;
  caUrls: string[];
  crlUrls: string[];
  expiresAt: string;
  actions: string[];
  references: {
    profileUuid: string;
    requestUuid: string;
    requestType: 'Certificate' | 'CodeSigningCertificate' | 'DeviceBatch';
  };
  resources: {
    name: string;
    actions: string[];
  }[];
}

export interface CertificatesState {
  readonly isGettingCertificateList: boolean;
  readonly isLoadedCertificateList: boolean;
  readonly isGettingExpiringCertificateList: boolean;
  readonly isLoadedExpiringCertificateList: boolean;
  readonly isGettingIssuedCertificateList: boolean;
  readonly isLoadedIssuiedCertificateList: boolean;
  readonly isGettingCAList: boolean;
  readonly isLoadedCAList: boolean;
  readonly isGettingOnlineCAList: boolean;
  readonly isLoadedOnlineCAList: boolean;
  readonly isGettingCertificateTreeList: boolean;
  readonly isLoadedCertificateTreeList: boolean;
  readonly certificateSerialInDownload: string; // 0 means no download
  readonly isGettingPrintedCertificate: boolean;
  readonly isUploadingCA: boolean;
  readonly isUploadingCertificateKey: boolean;
  readonly isCertificateKeyUploaded: boolean;
  readonly printedCertificate: string;
  readonly certificateList: Certificate[];
  readonly expiringCertificateList: Certificate[];
  readonly issuedCertificateList: Certificate[];
  readonly certificateTreeList: TreeItem[];
  readonly CAList: Certificate[];
  readonly OnlineCAList: Certificate[];
  readonly certificatesListErrors: unknown;
  readonly certificatesAuthorityListErrors: unknown;
  readonly treeErrors: unknown;
  readonly isRevokingCertificates: boolean;
  readonly isGettingCodeSigningProfileOptions: boolean;
  readonly isLoadedCodeSigningProfileOptions: boolean;
}

export enum CertificatesActionTypes {
  GET_CERTIFICATE_LIST_REQUEST = '@@Certificates/GET_CERTIFICATE_LIST_REQUEST',
  GET_CERTIFICATE_LIST_SUCCESS = '@@Certificates/GET_CERTIFICATE_LIST_SUCCESS',
  GET_CERTIFICATE_LIST_ERROR = '@@Certificates/GET_CERTIFICATE_LIST_ERROR',

  GET_EXPIRING_CERTIFICATES_LIST_REQUEST = '@@Certificates/GET_EXPIRING_CERTIFICATES_LIST_REQUEST',
  GET_EXPIRING_CERTIFICATES_LIST_SUCCESS = '@@Certificates/GET_EXPIRING_CERTIFICATES_LIST_SUCCESS',
  GET_EXPIRING_CERTIFICATES_LIST_ERROR = '@@Certificates/GET_EXPIRING_CERTIFICATES_LIST_ERROR',

  GET_ISSUED_CERTIFICATES_LIST_REQUEST = '@@Certificates/GET_ISSUED_CERTIFICATES_LIST_REQUEST',
  GET_ISSUED_CERTIFICATES_LIST_SUCCESS = '@@Certificates/GET_ISSUED_CERTIFICATES_LIST_SUCCESS',
  GET_ISSUED_CERTIFICATES_LIST_ERROR = '@@Certificates/GET_ISSUED_CERTIFICATES_LIST_ERROR',

  GET_PRINTED_CERTIFICATE_REQUEST = '@@Certificates/GET_PRINTED_CERTIFICATE_REQUEST',
  GET_PRINTED_CERTIFICATE_SUCCESS = '@@Certificates/GET_PRINTED_CERTIFICATE_SUCCESS',
  GET_PRINTED_CERTIFICATE_ERROR = '@@Certificates/GET_PRINTED_CERTIFICATE_ERROR',

  POST_REVOKE_CERTIFICATES_REQUEST = '@@Certificates/POST_REVOKE_CERTIFICATES_REQUEST',
  POST_REVOKE_CERTIFICATES_SUCCESS = '@@Certificates/POST_REVOKE_CERTIFICATES_SUCCESS',
  POST_REVOKE_CERTIFICATES_ERROR = '@@Certificates/POST_REVOKE_CERTIFICATES_ERROR',

  POST_UPLOAD_CA_REQUEST = '@@Certificates/POST_UPLOAD_CA_REQUEST',
  POST_UPLOAD_CA_SUCCESS = '@@Certificates/POST_UPLOAD_CA_SUCCESS',
  POST_UPLOAD_CA_ERROR = '@@Certificates/POST_UPLOAD_CA_ERROR',

  POST_UPLOAD_CA_BUNDLE_REQUEST = '@@Certificates/POST_UPLOAD_CA_BUNDLE_REQUEST',
  POST_UPLOAD_CA_BUNDLE_SUCCESS = '@@Certificates/POST_UPLOAD_CA_BUNDLE_SUCCESS',
  POST_UPLOAD_CA_BUNDLE_ERROR = '@@Certificates/POST_UPLOAD_CA_BUNDLE_ERROR',

  POST_UPLOAD_CERTIFICATE_AND_KEY_REQUEST = '@@Certificates/POST_UPLOAD_CERTIFICATE_AND_KEY_REQUEST',
  POST_UPLOAD_CERTIFICATE_AND_KEY_SUCCESS = '@@Certificates/POST_UPLOAD_CERTIFICATE_AND_KEY_SUCCESS',
  POST_UPLOAD_CERTIFICATE_AND_KEY_ERROR = '@@Certificates/POST_UPLOAD_CERTIFICATE_AND_KEY_ERROR',

  GET_CERTIFICATE_AUTHORITY_LIST_REQUEST = '@@Certificates/GET_CERTIFICATE_AUTHORITY_LIST_REQUEST',
  GET_CERTIFICATE_AUTHORITY_LIST_SUCCESS = '@@Certificates/GET_CERTIFICATE_AUTHORITY_LIST_SUCCESS',
  GET_CERTIFICATE_AUTHORITY_LIST_ERROR = '@@Certificates/GET_CERTIFICATE_AUTHORITY_LIST_ERROR',

  GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_REQUEST = '@@Certificates/GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_REQUEST',
  GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_SUCCESS = '@@Certificates/GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_SUCCESS',
  GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_ERROR = '@@Certificates/GET_ONLINE_CERTIFICATE_AUTHORITY_LIST_ERROR',

  DOWLOAD_CERTIFICATE_REQUEST = '@@Certificates/DOWLOAD_CERTIFICATE_REQUEST',
  DOWLOAD_CERTIFICATE_SUCCESS = '@@Certificates/DOWLOAD_CERTIFICATE_SUCCESS',
  DOWLOAD_CERTIFICATE_ERROR = '@@Certificates/DOWLOAD_CERTIFICATE_ERROR',

  GET_CERTIFICATE_TREE_LIST_REQUEST = '@@Certificates/GET_CERTIFICATE_TREE_LIST_REQUEST',
  GET_CERTIFICATE_TREE_LIST_SUCCESS = '@@Certificates/GET_CERTIFICATE_TREE_LIST_SUCCESS',
  GET_CERTIFICATE_TREE_LIST_ERROR = '@@Certificates/GET_CERTIFICATE_TREE_LIST_ERROR',
}
