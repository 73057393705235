import { Reducer } from 'redux';
import { StatisticsState, StatisticsActionTypes } from './types';

export const initialState: StatisticsState = {
  loginStats: [],
  HSMStats: undefined,
  isGettingLoginStats: false,
  isLoadedLoginStats: false,
  isGettingHSMStats: false,
  isLoadedLHSMStats: false,
  error: {},
};

const reducer: Reducer<StatisticsState> = (
  state = initialState,
  action
): StatisticsState => {
  switch (action.type) {
    case StatisticsActionTypes.GET_LOGIN_STATST_REQUEST: {
      return {
        ...state,
        isGettingLoginStats: true,
        isLoadedLoginStats: false,
      };
    }
    case StatisticsActionTypes.GET_LOGIN_STATST_SUCCESS: {
      return {
        ...state,
        isGettingLoginStats: false,
        isLoadedLoginStats: true,
        loginStats: action.payload,
      };
    }
    case StatisticsActionTypes.GET_LOGIN_STATST_ERROR: {
      return {
        ...state,
        isGettingLoginStats: false,
        isLoadedLoginStats: false,
        error: action.payload,
      };
    }

    case StatisticsActionTypes.GET_HSM_STATST_REQUEST: {
      return {
        ...state,
        isGettingHSMStats: true,
        isLoadedLHSMStats: false,
      };
    }
    case StatisticsActionTypes.GET_HSM_STATST_SUCCESS: {
      return {
        ...state,
        isGettingHSMStats: false,
        isLoadedLHSMStats: true,
        HSMStats: action.payload,
      };
    }
    case StatisticsActionTypes.GET_HSM_STATST_ERROR: {
      return {
        ...state,
        isGettingHSMStats: false,
        isLoadedLHSMStats: false,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as statisticsReducer };
