import { Moment } from 'moment';

export interface AuditLog {
  id: string;
  eventName: string;
  description: string;
  timestamp: Moment;
  eventOutcome?: string;
  authorizedUser: string;
  details: object;
}

export interface AuditLogsState {
  readonly isGettingAuditLogsList: boolean;
  readonly isLoadedAuditLogsList: boolean;
  readonly auditLogsList: AuditLog[];
  readonly auditLogsErrors: unknown;
}

export enum AuditLogsActionTypes {
  GET_AUDIT_LOGS_LIST_REQUEST = '@@AuditLogs/GET_AUDIT_LOGS_LIST_REQUEST',
  GET_AUDIT_LOGS_LIST_SUCCESS = '@@AuditLogs/GET_AUDIT_LOGS_LIST_SUCCESS',
  GET_AUDIT_LOGS_LIST_ERROR = '@@AuditLogs/GET_AUDIT_LOGS_LIST_ERROR',
}
