import { AnyAction } from 'redux';
import _, { isEmpty, size } from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  UsersActionTypes,
  UsersState,
  UserSettings,
  User,
  EntityType,
} from './types';
import { sendNotification } from '../notifications/actions';
import {
  deserializeUser,
  serializeUserSettings,
  serializeUserEntityRole,
  serializeUser,
} from './helpers';
import { api, getUrlWithFilters } from '../../libs/helpers';
import { Params } from '../../libs/types';

const error = require('debug')('pki-app:error:users');

export const getUserProfile = (): ThunkAction<
  Promise<boolean>,
  UsersState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.GET_USER_PROFILE_REQUEST,
    });
    try {
      const response = await api().get('user/profile');
      const payload = deserializeUser(response.data);
      dispatch({
        type: UsersActionTypes.GET_USER_PROFILE_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.GET_USER_PROFILE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching User Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getUserProfile: ${err}`);
      return false;
    }
  };
};

export const setUserSettings = (
  settings: UserSettings
): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.POST_USER_SETTINGS_REQUEST,
    });
    try {
      await api().post('user/settings', {
        settings: serializeUserSettings(settings),
      });
      sendNotification({
        text: 'User Settings have been successfully updated!',
      })(dispatch);
      dispatch({
        type: UsersActionTypes.POST_USER_SETTINGS_SUCCESS,
      });
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.POST_USER_SETTINGS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong updating User Settings!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`setUserSettings: ${err}`);
      return false;
    }
  };
};

export const getUsers = (
  { sizePerPage, page, sortBy, sortDir, query } = {} as Params
): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.GET_USERS_LIST_REQUEST,
    });
    try {
      let url = 'user';

      url = getUrlWithFilters(url, {
        sizePerPage,
        page,
        sortBy,
        sortDir,
        query,
      });

      const response = await api().get(url);
      const payload = _.map(response.data, (rawUser) =>
        deserializeUser(rawUser)
      );
      dispatch({
        type: UsersActionTypes.GET_USERS_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.GET_USERS_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Users list!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getUsers: ${err}`);
      return false;
    }
  };
};

export const getFilterUsers = (
  { sizePerPage, page, query } = {} as Params
): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.GET_FILTER_USERS_LIST_REQUEST,
    });
    try {
      let url = 'user';

      url = getUrlWithFilters(url, {
        sizePerPage,
        page,
        query,
      });

      const response = await api().get(url);
      const payload = _.map(response.data, (rawUser) =>
        deserializeUser(rawUser)
      );
      dispatch({
        type: UsersActionTypes.GET_FILTER_USERS_LIST_SUCCESS,
        payload,
      });
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.GET_FILTER_USERS_LIST_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching the Users list!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`getUsers: ${err}`);
      return false;
    }
  };
};

export const createUser = (
  user: User
): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.POST_USER_REQUEST,
    });
    try {
      await api().post('user', { ...serializeUser(user) });
      dispatch({
        type: UsersActionTypes.POST_USER_SUCCESS,
      });
      sendNotification({
        text: `The User has been successfully created.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.POST_USER_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the new User!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createUser: ${err}`);
      return false;
    }
  };
};

export const resetUserCredentialsOTP = (
  user: User
): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.RESET_USER_OTP_CREDENTIALS_REQUEST,
    });
    try {
      await api().post(`user/${user.uuid}/credentials/reset_otp`, {
        ...serializeUser(user),
      });
      dispatch({
        type: UsersActionTypes.RESET_USER_OTP_CREDENTIALS_SUCCESS,
      });
      sendNotification({
        text: `The User OTP credentials have been reset.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.RESET_USER_OTP_CREDENTIALS_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong resetting the User OTP credentials!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`resetUserCredentialsOTP: ${err}`);
      return false;
    }
  };
};

export const editUser = (
  user: User
): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.PUT_USER_REQUEST,
    });
    try {
      await api().put(`user/${user.uuid}`, { ...serializeUser(user) });
      dispatch({
        type: UsersActionTypes.PUT_USER_SUCCESS,
      });
      sendNotification({
        text: `The User has been successfully edited.`,
        success: true,
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.PUT_USER_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong updating the User!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`createUser: ${err}`);
      return false;
    }
  };
};

export const syncUsers = (): ThunkAction<
  Promise<boolean>,
  UsersState,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.GET_SYNC_USERS_REQUEST,
    });
    try {
      await api().get('user/sync');
      sendNotification({
        text: 'User synchronization request has been successfully submitted.',
      })(dispatch);
      dispatch({
        type: UsersActionTypes.GET_SYNC_USERS_SUCCESS,
      });
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.GET_SYNC_USERS_ERROR,
        payload: err,
      });
      const text =
        err.message ||
        'Oops! Something went wrong while submitting the user synchronization request!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`syncUsers: ${err}`);
      return false;
    }
  };
};

export const setUsersEntityRole = ({
  userUuids,
  entityRole,
  entityType,
  entityUuid,
  usersAlreadyEntitled,
  type = 'User',
  inherit = false,
}: {
  userUuids: string[];
  entityRole: string;
  entityType: EntityType;
  entityUuid: string;
  usersAlreadyEntitled?: string[];
  type: string;
  inherit?: boolean;
}): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    if (!isEmpty(usersAlreadyEntitled)) {
      const moreThanOne = size(usersAlreadyEntitled) > 1;
      const text = `${
        moreThanOne ? `${type}s` : type
      } ${usersAlreadyEntitled?.join(', ')} ${
        moreThanOne ? 'are' : 'is'
      } already entitled.`;
      sendNotification({
        text,
      })(dispatch);
    }

    if (isEmpty(userUuids)) {
      return true;
    }
    dispatch({
      type: UsersActionTypes.POST_USERS_ENTITY_ROLE_REQUEST,
    });
    try {
      await api().post('user/entity_role', {
        ...serializeUserEntityRole({
          userUuids,
          entityRole,
          entityType,
          entityUuid,
          inherit,
        }),
      });
      dispatch({
        type: UsersActionTypes.POST_USERS_ENTITY_ROLE_SUCCESS,
      });
      sendNotification({
        text: 'Entitlements have been successfully set!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.POST_USERS_ENTITY_ROLE_ERROR,
        payload: err,
      });

      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong, entitlements not set!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`setUsersEntityRole: ${err}`);
      return false;
    }
  };
};

export const removeUserEntityRole = ({
  userUuid,
  entityRole,
  entityType,
  entityUuid,
}: {
  userUuid: string;
  entityRole: string;
  entityType: EntityType;
  entityUuid: string;
}): ThunkAction<Promise<boolean>, UsersState, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<UsersState, {}, AnyAction>
  ): Promise<boolean> => {
    dispatch({
      type: UsersActionTypes.DELETE_USER_ENTITY_ROLE_REQUEST,
    });

    try {
      await api().delete('user/entity_role', {
        data: {
          ...serializeUserEntityRole({
            userUuid,
            entityRole,
            entityType,
            entityUuid,
          }),
        },
      });
      dispatch({
        type: UsersActionTypes.DELETE_USER_ENTITY_ROLE_SUCCESS,
      });
      sendNotification({
        text: 'Entitlement have been successfully updated!',
      })(dispatch);
      return true;
    } catch (err) {
      dispatch({
        type: UsersActionTypes.DELETE_USER_ENTITY_ROLE_ERROR,
        payload: err,
      });
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong, entitlement not updated!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
      error(`removeUserEntityRole: ${err}`);
      return false;
    }
  };
};
