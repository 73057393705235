export interface CommonKey {
  uuid: string;
  encryptedKey?: string;
  name?: string;
  sha1?: string;
  createdBy?: string;
  isCreatedByUserDeleted?: boolean;
  issuedAt?: string;
  updatedAt?: string;
}
export interface CommonKeysState {
  readonly commonKeyList: CommonKey[];
  readonly isGettingCommonKeyList: boolean;
  readonly isLoadedCommonKeyList: boolean;
  readonly isUploadingKey: boolean;
  readonly commonKeysErrors: unknown;
}

export enum CommonKeysActionTypes {
  GET_KEY_LIST_REQUEST = '@@CommonKeys/GET_KEY_LIST_REQUEST',
  GET_KEY_LIST_SUCCESS = '@@CommonKeys/GET_KEY_LIST_SUCCESS',
  GET_KEY_LIST_ERROR = '@@CommonKeys/GET_KEY_LIST_ERROR',

  POST_KEY_REQUEST = '@@CommonKeys/POST_KEY_REQUEST',
  POST_KEY_SUCCESS = '@@CommonKeys/POST_KEY_SUCCESS',
  POST_KEY_ERROR = '@@CommonKeys/POST_KEY_ERROR',
}
