import React, { FC, useState, useEffect, useRef } from 'react';
import useCopy from '@react-hook/copy';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { isNil, isEmpty, set } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faTrash,
  faDownload,
  faInfoCircle,
  faClipboard,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { secureTokenDefaultValues } from '../constants';
import { SecureToken } from '../../../store/manufacturingStations/types';
import { formatBytes, readFileAsText } from '../../../libs/helpers';
import { Toggle } from '../../../components';

const { saveAs } = require('file-saver');

interface Props {
  onCancel: Function;
  onCreateSecureToken: Function;
  onUpdateSecureToken: Function;
  defaultValues?: SecureToken;
  manufacturingStationUuid: string;
}

const SecureTokensModal: FC<Props> = ({
  onCancel = (): null => null,
  onCreateSecureToken = (): null => null,
  onUpdateSecureToken = (): null => null,
  defaultValues = secureTokenDefaultValues,
  manufacturingStationUuid,
}) => {
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );

  const requestValues = {
    ...defaultValues,
    manufacturingStationUuid,
  };

  const [currentFile, setCurrentFile] = useState<File>();
  const [secureToken, setFormValues] = useState<SecureToken>(requestValues);

  useEffect(() => {
    if (defaultValues) {
      setFormValues({ ...defaultValues, manufacturingStationUuid });
    } else {
      setFormValues({
        ...secureTokenDefaultValues,
        manufacturingStationUuid,
      });
    }
  }, [defaultValues, manufacturingStationUuid]);

  const inputEl = useRef<HTMLInputElement>(null);
  const readOnly = false;

  const {
    serialNumber,
    notes,
    csr,
    csrFilename,
    enabled,
    certificateUuid,
  } = secureToken;

  const editMode = !isEmpty(secureToken.uuid);

  const opensslFirstTip =
    'openssl req -new -newkey rsa:2048 -subj /CN=xyz -keyout priv_key.pem -out csr.pem';
  const {
    copied: firstTipCopied,
    copy: copyFirstTip,
    reset: resetFirstTip,
  } = useCopy(opensslFirstTip);
  const opensslSecondTip =
    'openssl req -new -newkey ec:<(openssl ecparam -name secp384r1) -subj /CN=xyz -keyout cert.key -out csr.pem';
  const {
    copied: secondTipCopied,
    copy: copySecondTip,
    reset: resetSecondTip,
  } = useCopy(opensslSecondTip);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setFormValues((current: SecureToken) => ({
      ...current,
      [property]: value,
    }));
  };

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  return (
    <div id="pki-roles-form" className="ManufacturingStationsSecureTokens">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <span>
            <h3 className="text-muted">Secure Token</h3>
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {
          <div className="mt-2">
            {!readOnly && (
              <>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="pki-label">Token Serial Number</Label>
                      <Input
                        id="token-serial-number"
                        readOnly={readOnly}
                        plaintext={readOnly}
                        invalid={highlightMandatoryFields}
                        value={serialNumber}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          setProfileFormProperty({
                            property: 'serialNumber',
                            value: event.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="pki-label">Notes</Label>
                    <FormGroup>
                      <Input
                        readOnly={readOnly}
                        plaintext={readOnly}
                        type="textarea"
                        name="notes"
                        id="notes"
                        value={notes}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          setProfileFormProperty({
                            property: 'notes',
                            value: event.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {readOnly && (
                    <Col md={3}>
                      <FormGroup>
                        <Label className="pki-label">CSR</Label>
                        <div className="d-flex">
                          <Input
                            id="csr-filename"
                            readOnly
                            value={csrFilename}
                            plaintext
                          />
                          <span
                            onClick={(): void => {
                              const csrBlob = new Blob([String(csr)]);
                              saveAs(csrBlob, csrFilename);
                            }}
                            className="form-control-plaintext ml-3"
                          >
                            <FontAwesomeIcon
                              className="pki-ico"
                              icon={faDownload}
                            />
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                {!readOnly && (
                  <div className="mb-3">
                    <Label className="pki-label">
                      CSR
                      <>
                        <FontAwesomeIcon
                          id="tips"
                          className="pki-ico tips-ico mt-2 ml-1"
                          icon={faInfoCircle}
                        />
                        <UncontrolledPopover
                          className="PKIApp"
                          trigger="hover"
                          target="tips"
                        >
                          <div className="p-3">
                            <p>
                              CSRs can be generated using OpenSSL; for example:
                            </p>
                            <p className="d-flex">
                              <code>{opensslFirstTip}</code>{' '}
                              <FontAwesomeIcon
                                className="pki-ico my-auto"
                                onClick={(): void => {
                                  copyFirstTip();
                                  setTimeout(() => {
                                    resetFirstTip();
                                  }, 500);
                                }}
                                icon={faClipboard}
                              />
                            </p>
                            <p>or</p>
                            <p className="d-flex">
                              <code>{opensslSecondTip}</code>
                              <FontAwesomeIcon
                                className="pki-ico my-auto"
                                onClick={(): void => {
                                  copySecondTip();
                                  setTimeout(() => {
                                    resetSecondTip();
                                  }, 500);
                                }}
                                icon={faClipboard}
                              />
                            </p>
                            {(firstTipCopied || secondTipCopied) && 'Copied!'}
                          </div>
                        </UncontrolledPopover>
                      </>
                    </Label>
                    <Row>
                      <Col className="d-flex" md={3}>
                        <Button
                          outline
                          id="upload-csr-button"
                          onClick={(): void => {
                            if (inputEl && inputEl.current) {
                              inputEl.current.click();
                            }
                          }}
                        >
                          <FontAwesomeIcon className="mr-1" icon={faUpload} />
                          Select
                        </Button>
                        <div className="mt-auto ml-1">
                          <code className="black-code"> (PEM)</code>
                        </div>
                      </Col>
                      {currentFile && (
                        <Col className="d-flex" md={8}>
                          <div
                            id="file-description"
                            className="text-truncate mt-auto"
                          >
                            {currentFile.name} ({formatBytes(currentFile.size)})
                          </div>
                        </Col>
                      )}
                      {currentFile && (
                        <Col>
                          <FontAwesomeIcon
                            className="pki-ico file-delete mt-4"
                            onClick={(): void => {
                              setCurrentFile(undefined);
                              setProfileFormProperty({
                                property: 'enabled',
                                value: false,
                              });
                              set(inputEl, 'current.value', null);
                            }}
                            icon={faTrash}
                          />
                        </Col>
                      )}
                    </Row>
                    {isNil(currentFile) && highlightMandatoryFields && (
                      <div className="invalid-text">
                        CSR cannot be empty, please select a file!
                      </div>
                    )}
                    <input
                      type="file"
                      id="file-input"
                      className="d-none"
                      ref={inputEl}
                      onChange={({ target: { files } }): void => {
                        if (files && files.length > 0) {
                          setCurrentFile(files[0]);
                          set(inputEl, 'current.value', null);

                          setProfileFormProperty({
                            property: 'enabled',
                            value: true,
                          });
                        }
                      }}
                    />
                    {!isNil(currentFile) && (
                      <div className="file-violations mt-3">
                        CSR cannot be empty
                      </div>
                    )}
                  </div>
                )}
                <Row>
                  <Col md={3}>
                    <Label className="pki-label">
                      Allow use of secure token
                    </Label>
                    <FormGroup>
                      <Toggle
                        id="enabled"
                        disabled={!currentFile && !certificateUuid}
                        onChange={(checked: boolean): void => {
                          setProfileFormProperty({
                            property: 'enabled',
                            value: checked,
                          });
                        }}
                        checked={enabled}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="button-container float-right">
                      {editMode && (
                        <Button
                          id="cancel-button"
                          outline
                          disabled={readOnly}
                          onClick={() => {
                            setFormValues(requestValues);
                            setCurrentFile(undefined);
                            onCancel();
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        id={editMode ? 'confirm-button' : 'add-button'}
                        outline
                        style={{ marginLeft: 10 }}
                        disabled={readOnly}
                        onClick={async (): Promise<void> => {
                          const onAction = editMode
                            ? onUpdateSecureToken
                            : onCreateSecureToken;

                          if (currentFile) {
                            const content = await readFileAsText(currentFile);
                            onAction({
                              ...secureToken,
                              csrFilename: currentFile.name,
                              csr: content,
                            });
                          } else {
                            onAction({
                              ...secureToken,
                            });
                          }
                        }}
                      >
                        {editMode ? 'Confirm' : 'Add'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default SecureTokensModal;
