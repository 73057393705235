export const manufacturingStationDefaultValues = {
  uuid: '',
  name: '',
  manufacturer: '',
  location: '',
  externalId: '',
  notes: '',
  issuingCAUuid: '',
  certificateProfileUuid: '',
};

export const secureTokenDefaultValues = {
  uuid: '',
  serialNumber: '',
  notes: '',
  enabled: false,
  csr: '',
  csrFilename: '',
};
