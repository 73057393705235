import moment from 'moment/moment';
import _ from 'lodash';
import { deserializeUserOutput } from '../../../store/users/helpers';
import { Device } from '../types';
import { deserializeProductProfile } from '../../ProductProfiles/helpers';

const getSecret = (secret: { [key: string]: string } | null | undefined) => {
  if (_.isEmpty(secret)) return { 'No Secret': '' };
  return secret;
};
const UNDEFINED_DATE = '-';
const getValidDate = (date: Date) =>
  date ? moment(date).format('LLL') : UNDEFINED_DATE;

export const deserializeDevice = (raw: any): Device => {
  return {
    uuid: raw.uuid,
    productProfileUuid: raw.product_profile_uuid,
    productProfileName: raw.product_profile_name || raw.product_profile_uuid,
    productProfile: raw.product_profile
      ? deserializeProductProfile(raw.product_profile)
      : undefined,
    actions: raw.actions || [],
    deviceRequestUuid: raw.device_request_uuid,
    deviceId: raw.device_id,
    metadata: raw.metadata,
    secrets: getSecret(raw.secret_data) as { [key: string]: string },
    createdAt: moment(raw.date_created).format('LLL'),
    activatedAt: getValidDate(raw.date_activated),
    reportedAt: getValidDate(raw.date_reported),
    updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format(
      'LLL'
    ),
    createdBy: deserializeUserOutput(raw.created_by_user),
    updatedBy: deserializeUserOutput(
      raw?.updated_by_user || raw.created_by_user
    ),
    certificateUuid: raw?.certificates?.[0]?.uuid || null,
  };
};
