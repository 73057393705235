export const userDefaultValues = {
  uuid: '',
  username: '',
  enabled: true,
  email: '',
  firstName: '',
  lastName: '',
  roles: [],
  customers: [],
  settings: [],
  entityRoles: [],
  resources: [],
};
