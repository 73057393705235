import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Label, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faPrint,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';

import { Spinner } from '../../components';
import {
  getPrintedCertificateAuthority,
  downloadCertificateAuthority,
} from '../../store/certificates/actions';
import { Certificate, CertificatesState } from '../../store/certificates/types';
import { ApplicationState } from '../../store';

const { saveAs } = require('file-saver');

interface Props {
  certificate: Certificate;
  showCRLandAIA: boolean;
}

const CertificateInfo: FC<Props> = ({ certificate, showCRLandAIA }) => {
  const { isGettingPrintedCertificate, printedCertificate } = useSelector<
    ApplicationState,
    CertificatesState
  >((pki) => pki.certificates);

  const dispatch = useDispatch();
  const [showPrettyPrint, setShowPrettyPrint] = useState(false);

  const toggleModal = (): void => {
    setShowPrettyPrint((prev) => !prev);
  };

  return (
    <div className="CertificateInfo">
      <div className="form-header">
        <div className="ml-5 d-flex">
          <h3 className="text-muted">{certificate.commonName}</h3>{' '}
        </div>
        <div className="ml-5 actions">
          <Button
            id="close-form-button"
            outline
            size="sm"
            className="mr-3"
            onClick={async (): Promise<void> => {
              const certificateToDownlaod = await dispatch(
                downloadCertificateAuthority(certificate.uuid)
              );
              const certificateBlob = new Blob([String(certificateToDownlaod)]);
              saveAs(
                certificateBlob,
                `${certificate.commonName}_${certificate.serial}.pem`
              );
            }}
          >
            <FontAwesomeIcon className="mr-2" icon={faDownload} />
            Download Certificate
          </Button>
          <Button
            id="pretty-print-button"
            outline
            size="sm"
            onClick={(): void => {
              dispatch(getPrintedCertificateAuthority(certificate.uuid));
              setShowPrettyPrint(true);
            }}
          >
            <FontAwesomeIcon className="mr-2" icon={faPrint} />
            Show Certificate
          </Button>
        </div>
      </div>

      <div className="content p-5">
        <div>
          <Label className="pki-label">Key</Label>
          <h6>
            <Badge color={certificate.isKeyOnline ? 'primary' : 'secondary'}>
              {certificate.isKeyOnline ? 'Online' : 'Offline'}
            </Badge>
          </h6>
        </div>
        {certificate.crlUrls && showCRLandAIA && (
          <div className="mt-4">
            <Label className="pki-label">CRL URLs</Label>
            {map(certificate.crlUrls, (item, itemIndex) => (
              <span key={itemIndex}>
                <br />- {item}
              </span>
            ))}
          </div>
        )}
        {certificate.caUrls && showCRLandAIA && (
          <div className="mt-4">
            <Label className="pki-label">CA URLs</Label>
            {map(certificate.caUrls, (item, itemIndex) => (
              <span key={itemIndex}>
                <br />- {item}
              </span>
            ))}
          </div>
        )}
      </div>
      <Modal
        className="PKIApp"
        style={{ maxWidth: '600px' }}
        isOpen={showPrettyPrint}
        toggle={toggleModal}
      >
        <div className="form-header d-flex">
          <div className="mt-5 ml-5 d-flex text-muted">
            <h3>{certificate.commonName}</h3>
          </div>
          <div className="ml-auto m-3">
            <Button
              id="close-form-button"
              outline
              size="sm"
              onClick={(): void => {
                toggleModal();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
        {isGettingPrintedCertificate && <Spinner className="m-4" />}
        {printedCertificate && (
          <code className="p-5 white-space-pre-wrap black-code balc">
            {printedCertificate}
          </code>
        )}
      </Modal>
    </div>
  );
};

export default CertificateInfo;
