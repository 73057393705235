import React, { FC } from 'react';

interface OptionItemsProps {
  onSelectEntity: (entity: any) => void;
  formatter: (entity: any) => string;
  data: any[];
  isFetching: boolean;
  infinite: boolean;
  hasNext: boolean;
  focusIndex: number;
  setFocusIndex: Function;
}

export const OptionItems: FC<OptionItemsProps> = ({
  onSelectEntity,
  formatter,
  data,
  isFetching,
  infinite,
  hasNext,
  focusIndex,
  setFocusIndex,
}) => {
  const items = data.map((entity, index) => (
    <div
      key={entity?.uuid ?? entity?.id}
      className={`menuItem ${index === focusIndex ? 'focused' : ''} `}
      onClick={() => {
        onSelectEntity(entity);
        setFocusIndex(index);
      }}
    >
      {formatter(entity)}
    </div>
  ));
  return (
    <>
      {items}
      {isFetching && infinite && (
        <div className={'menuItem disabled'} onClick={() => 1}>
          Fetching...
        </div>
      )}
      {!isFetching && infinite && !hasNext && (
        <div
          role="menuitem"
          className={'menuItem disabled text-center'}
          onClick={() => 1}
        >
          No more records!
        </div>
      )}
    </>
  );
};
