import React, { useState, useEffect, FC, ReactNode, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import _, { isNil, kebabCase, startCase } from 'lodash';
import {
  Label,
  Input,
  FormGroup,
  Card,
  Modal,
  Button,
  Collapse,
  UncontrolledPopover,
} from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faSpinner,
  faBan,
  faTimes,
  faUpload,
  faFileInvoice,
  faExternalLink,
} from '@fortawesome/free-solid-svg-icons';
import { ApplicationState } from '../../store';
import {
  downloadCertificate,
  getCertificates,
  revokeCertificates,
  getPrintedCertificate,
  uploadWrappedKeyAndCertificate,
} from '../../store/certificates/actions';
import { CertificatesState, Certificate } from '../../store/certificates/types';
import {
  Table,
  Spinner,
  TableActions,
  Select,
  ConfirmationForm,
  FilterBar,
  ErrorHandler,
} from '../../components';
import { Filter } from '../../components/FilterBar/types';
import { dateFormatter } from '../../libs/helpers';
import { certificateRevokeOptions } from '../../libs/constants';
import { sendNotification } from '../../store/notifications/actions';
import UploadWrappedKeyCertificate from './UploadWrappedKeyCertificate';
import './Certificates.scss';
import { UsersState } from '../../store/users/types';

const { saveAs } = require('file-saver');

const Certificates: FC = () => {
  const {
    certificateList,
    isLoadedCertificateList,
    isGettingCertificateList,
    certificateSerialInDownload,
    isUploadingCertificateKey,
    isGettingPrintedCertificate,
    printedCertificate,
    isRevokingCertificates,
    certificatesListErrors,
    userProfile: { resources },
  } = useSelector<ApplicationState, CertificatesState & UsersState>((pki) => ({
    ...pki.certificates,
    ...pki.users,
  }));

  const [previewModal, setPreviewModal] = useState(false);
  const [revokeModal, setRevokeModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [certificateUuids, setCertificateUuids] = useState<string[]>([]);
  const [reasonCode, setReasonCode] = useState<string>(
    certificateRevokeOptions[0].key
  );
  const [reasonComment, setReasonComment] = useState<string>('');
  const [currentCertificate, setCurrentCertificate] = useState<Certificate>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('id');

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const certificateUuidParam = params.get('uuid');
  const certificateRequestIdParam = params.get('certificate_request_id');
  const revocationStatus = ['revocation_requested', 'revoked'];

  const initialQuery: Filter[] = useMemo(() => {
    if (certificateUuidParam) {
      return [
        {
          key: 'uuid',
          label: 'UUID',
          operator: '=',
          type: 'filter',
          value: certificateUuidParam,
        },
      ];
    }

    if (certificateRequestIdParam) {
      return [
        {
          key: 'certificate_request_id',
          label: 'Request ID',
          operator: '=',
          type: 'filter',
          value: certificateRequestIdParam,
        },
      ];
    }

    return [];
  }, [certificateUuidParam, certificateRequestIdParam]);
  const [query, setQuery] = useState<Filter[]>(initialQuery);

  const isLoadingData = isGettingCertificateList;
  const isDataLoaded = isLoadedCertificateList;
  const viewLocation = location.pathname;

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }
    if (isLoadedCertificateList || certificatesListErrors) {
      setShowContent(true);
    }
    if (isDataLoaded || certificatesListErrors) {
      setShowSpinner(false);
    }

    return function cleanup(): void {
      if (location.pathname !== viewLocation) {
        setShowContent(false);
      }
    };
  }, [
    certificatesListErrors,
    isDataLoaded,
    isLoadedCertificateList,
    isLoadingData,
    location,
    viewLocation,
  ]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();

    if (!isRevokingCertificates && !isUploadingCertificateKey) {
      dispatch(
        getCertificates(
          {
            page: tablePage,
            sizePerPage: tableSizePerPage,
            sortBy: tableSortField,
            sortDir: tableSortOrder,
            query,
          },
          tokenSource
        )
      );
    }
    return function cleanup(): void {
      tokenSource.cancel('Certificates::getCertificates');
    };
  }, [
    certificateRequestIdParam,
    certificateUuidParam,
    dispatch,
    isRevokingCertificates,
    query,
    tablePage,
    tableSizePerPage,
    tableSortField,
    tableSortOrder,
    isUploadingCertificateKey,
  ]);

  useEffect(() => {
    if (currentCertificate) {
      dispatch(getPrintedCertificate(currentCertificate.uuid));
    }
  }, [previewModal, dispatch, currentCertificate]);

  const togglePreviewModal = (): void => {
    setPreviewModal((prev) => !prev);
  };

  const toggleUploadModal = (): void => {
    setUploadModal((prev) => !prev);
  };

  const toggleRevokeModal = (): void => {
    setRevokeModal((prev) => {
      if (prev) {
        setReasonComment('');
        setReasonCode(certificateRevokeOptions[0].key);
        setCertificateUuids([]);
      }
      return !prev;
    });
  };

  const columns = [
    {
      dataField: 'uuid',
      text: 'Certificate ID',
      classes: 'text-uppercase',
    },
    { dataField: 'commonName', text: 'Common Name' },
    {
      dataField: 'state',
      text: 'State',
      formatter: (state: string): string => startCase(state),
    },
    {
      dataField: 'issuedAt',
      text: 'Issued',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'startsAt',
      text: 'Starting Date',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'expiresAt',
      text: 'Expiring Date',
      formatter: (date: number): string => dateFormatter(date),
      csvFormatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'serial',
      text: 'Actions',
      csvExport: false,
      formatter: (
        serial: string,
        selectedCertificate: Certificate
      ): ReactNode => {
        const {
          commonName,
          deviceRequestUuid,
          deviceUuid,
          references: { requestUuid, profileUuid, requestType },
        } = selectedCertificate;
        const isDownloading = certificateSerialInDownload === serial;
        const isRevokeStatus =
          revocationStatus.indexOf(selectedCertificate?.state) === 0;
        return (
          <TableActions
            rowId={selectedCertificate.uuid}
            options={[
              {
                label: 'Download',
                ico: (
                  <FontAwesomeIcon
                    className="pki-ico"
                    spin={isDownloading}
                    icon={isDownloading ? faSpinner : faDownload}
                  />
                ),
                onClick: async (e: MouseEvent): Promise<void> => {
                  try {
                    const certificate = await dispatch(
                      downloadCertificate(serial)
                    );
                    const certificateBlob = new Blob([String(certificate)]);
                    saveAs(certificateBlob, `${commonName}_${serial}.pem`);
                  } catch (err) {
                    // error already handled in the actions
                  }
                },
              },
              {
                label: 'Revoke',
                disabled: isRevokeStatus,
                ico: (
                  <FontAwesomeIcon
                    className={classNames({
                      'pki-ico': true,
                    })}
                    icon={faBan}
                  />
                ),
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  setCurrentCertificate(selectedCertificate);
                  setCertificateUuids([selectedCertificate.uuid]);
                  toggleRevokeModal();
                },
              },
              {
                label: 'Related Certificate Profile',
                disabled: isNil(profileUuid),
                ico: (
                  <FontAwesomeIcon
                    className={classNames({
                      'pki-ico': true,
                    })}
                    icon={faExternalLink}
                  />
                ),
                onClick: (e: MouseEvent): void => {
                  history.push({
                    pathname: '/management/certificate-profiles',
                    search: `?uuid=${profileUuid}`,
                  });
                },
              },
              {
                label: 'Related Certificate Request',
                disabled: isNil(requestUuid),
                ico: (
                  <FontAwesomeIcon
                    className={classNames({
                      'pki-ico': true,
                    })}
                    icon={faExternalLink}
                  />
                ),
                onClick: (e: MouseEvent): void => {
                  history.push({
                    pathname: `/operations/${kebabCase(requestType)}-requests`,
                    search: `?uuid=${requestUuid}`,
                  });
                },
              },
              {
                label: 'Related Device',
                disabled: !deviceUuid,
                ico: (
                  <FontAwesomeIcon
                    className={classNames({
                      'pki-ico': true,
                    })}
                    icon={faExternalLink}
                  />
                ),
                onClick: (e: MouseEvent): void => {
                  history.push(`/inventory/devices/${deviceUuid}`);
                },
              },
              {
                label: 'Related Device Batch Request',
                disabled: !deviceRequestUuid,
                ico: (
                  <FontAwesomeIcon
                    className={classNames({
                      'pki-ico': true,
                    })}
                    icon={faExternalLink}
                  />
                ),
                onClick: (e: MouseEvent): void => {
                  history.push({
                    pathname: `/operations/device-batch-requests`,
                    search: `?uuid=${deviceRequestUuid}`,
                  });
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const certificateResource = _.find(
    resources,
    (item) => item.name === 'certificate'
  );

  const disableUpload = !_.includes(certificateResource?.actions, 'create');

  return (
    <div className="Certificates">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Certificates and Keys</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="filter-bar-container mt-5">
            <FilterBar
              filters={[
                {
                  key: 'issuing_ca_uuid',
                  label: 'Issuing CA',
                  serverSideConfig: {
                    defaultFilters: 'is_key_online=true',
                    searchParam: 'cn',
                    fetchUrl: '/certificate/authority',
                    formatter: (entity: any) => entity?.cn || 'N/A',
                    id: 'issuing-ca-filter',
                  },
                  type: 'filter',
                  serverSide: true,
                  placeholder: 'Select an Issuing CA',
                },
                {
                  key: 'uuid',
                  label: 'Certificate ID',
                  type: 'filter',
                },
                {
                  key: 'certificate_request_id',
                  label: 'Request ID',
                  type: 'filter',
                },
                {
                  key: 'cn',
                  label: 'Common Name',
                  type: 'filter',
                  operators: ['=', '~'],
                },
                {
                  key: 'state',
                  label: 'State',
                  type: 'filter',
                  options: [
                    { value: 'issued', label: 'Issued' },
                    { value: 'revoked', label: 'Revoked' },
                    {
                      value: 'revocation_requested',
                      label: 'Revocation Requested',
                    },
                  ],
                },
                {
                  key: 'inserted_time',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Issued',
                  type: 'filter',
                },
                {
                  key: 'not_before',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Starting Date',
                  type: 'filter',
                },
                {
                  key: 'not_after',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Expiring Date',
                  type: 'filter',
                },
              ]}
              initialFilters={initialQuery}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="view mt-5">
            {certificatesListErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                data={certificateList}
                keyField="uuid"
                remote={true}
                search={false}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                onTableChange={(
                  valueNotUsed: null,
                  { page, sizePerPage }: { page: number; sizePerPage: number }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                }}
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: false,
                  onSelect: (
                    { uuid }: { uuid: string },
                    isSelected: boolean
                  ): void => {
                    if (isSelected) {
                      setCertificateUuids((current) => [...current, uuid]);
                    } else {
                      setCertificateUuids((current) =>
                        _.filter(current, (item) => item !== uuid)
                      );
                    }
                  },
                  onSelectAll: (
                    isSelected: boolean,
                    rows: { uuid: string }[]
                  ): void => {
                    if (isSelected) {
                      setCertificateUuids(_.map(rows, (item) => item.uuid));
                    } else {
                      setCertificateUuids([]);
                    }
                  },
                }}
                toolbar={
                  <div className="d-flex">
                    <span className="mr-2">
                      <div
                        style={
                          disableUpload
                            ? { display: 'inline-block', cursor: 'not-allowed' }
                            : {}
                        }
                        id="button-container"
                      >
                        <Button
                          outline
                          size="sm"
                          style={disableUpload ? { pointerEvents: 'none' } : {}}
                          disabled={disableUpload}
                          id="upload-wrapped-key-and-certificate-button"
                          onClick={toggleUploadModal}
                        >
                          <FontAwesomeIcon icon={faUpload} /> Upload Wrapped Key
                          and Certificate{' '}
                        </Button>
                      </div>
                      {disableUpload && (
                        <UncontrolledPopover
                          trigger="hover"
                          target="button-container"
                        >
                          <div className="p-1">
                            <small>
                              This option is only available to the Irdeto
                              operator responsible for secure key management.
                            </small>
                          </div>
                        </UncontrolledPopover>
                      )}
                    </span>
                    <span className="mx-2">
                      <Button
                        outline
                        disabled={!_.size(certificateUuids)}
                        size="sm"
                        onClick={(): void => {
                          toggleRevokeModal();
                        }}
                      >
                        <FontAwesomeIcon icon={faBan} /> Revoke{' '}
                      </Button>
                    </span>
                  </div>
                }
                noDataIndication={
                  tablePage > 1
                    ? 'No more Certificates available'
                    : 'No Certificates available'
                }
                rowEvents={{
                  onClick: (notUsedValue: null, current: Certificate): void => {
                    setCurrentCertificate(current);
                    setPreviewModal(true);
                  },
                }}
                exportCSV={{
                  fileName: 'certificates.csv',
                  onlyExportSelection: true,
                  exportAll: false,
                }}
                columns={columns}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        size="lg"
        isOpen={previewModal}
        toggle={togglePreviewModal}
      >
        <div className="form-header d-flex">
          <div className="mt-5 ml-5 d-flex text-muted">
            <h3>{currentCertificate?.commonName}</h3>
            {isGettingPrintedCertificate && (
              <Spinner className="mt-2 ml-2" size="sm" type="border" />
            )}
          </div>
          <div className="ml-auto m-3">
            <Button
              id="close-form-button"
              outline
              size="sm"
              onClick={(): void => {
                togglePreviewModal();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
        <code className="p-5 white-space-pre-wrap black-code">
          {printedCertificate}
        </code>
      </Modal>
      <Modal className="PKIApp" isOpen={revokeModal} toggle={toggleRevokeModal}>
        <ConfirmationForm
          title="Revoke Confirmation"
          content={
            <div>
              <FormGroup>
                <Select
                  label="Reason"
                  selectedKey={reasonCode}
                  onChange={({ key }: { key: string }): void => {
                    setReasonCode(key);
                  }}
                  options={certificateRevokeOptions}
                />
              </FormGroup>
              <Label className="pki-label">Comment</Label>
              <FormGroup>
                <Input
                  value={reasonComment}
                  type="textarea"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    setReasonComment(event.target.value);
                  }}
                  name="comment"
                  id="comment"
                />
              </FormGroup>
            </div>
          }
          onCancel={toggleRevokeModal}
          onConfirm={(): void => {
            dispatch(
              revokeCertificates({
                certificateUuids,
                reasonComment,
                reasonCode,
              })
            );
            toggleRevokeModal();
          }}
        />
      </Modal>
      <Modal
        className="PKIApp"
        size="lg"
        unmountOnClose={true}
        isOpen={uploadModal}
        toggle={toggleUploadModal}
      >
        <UploadWrappedKeyCertificate
          onUpload={({
            error,
            data: { wrappedKey, certificateProfileUuid },
          }: {
            error: boolean;
            data: {
              wrappedKey: File;
              certificateProfileUuid: string;
            };
          }): void => {
            if (error) {
              dispatch(
                sendNotification({
                  text: 'One or more errors have been detected!',
                  success: false,
                })
              );
            } else {
              dispatch(
                uploadWrappedKeyAndCertificate({
                  wrappedKey,
                  certificateProfileUuid,
                })
              );
              toggleUploadModal();
            }
          }}
          onClose={toggleUploadModal}
        />
      </Modal>
    </div>
  );
};

export default Certificates;
