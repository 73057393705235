import React, { FC, ReactNode, useState } from 'react';
import _ from 'lodash';
import './Assign.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, UncontrolledPopover } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AsymmetricKey, Key } from '../../../../../types';
import { Spinner, Table } from '../../../../../../../components';
import { ServerSideSelect } from '../../../../../../../components/ServerSideSelect/ServerSideSelect';
import { ProductProfile } from '../../../../../../ProductProfiles/types';
import { ProductProfileForm } from '../../../../../../ProductProfiles/single/components/ProductProfileForm/ProductProfileForm';
import { api } from '../../../../../../../libs/helpers';
import { serializeProductProfile } from '../../../../../../ProductProfiles/helpers';
import { sendNotification } from '../../../../../../../store/notifications/actions';

interface AssignProps {
  asymmetricKeys: AsymmetricKey[];
  isLoading: boolean;
  onCancel: () => void;
  handleAssignKeysToProductProfile: (
    productProfileId: string,
    keyIds: string[]
  ) => void;
}

const idClassPrefix = 'assign-keys-to-product-profile';
export const Assign: FC<AssignProps> = ({
  asymmetricKeys,
  isLoading,
  onCancel,
  handleAssignKeysToProductProfile,
}) => {
  const tableData: Array<Key & { alreadyExists: boolean }> = [];
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);
  const [
    currentProductProfile,
    setCurrentProductProfile,
  ] = useState<ProductProfile>();
  const [
    showProductProfileFormModal,
    setShowProductProfileFormModal,
  ] = useState<boolean>(false);
  const [gettingNewList, setGettingNewList] = useState(false);

  const dispatch = useDispatch();

  const confirmDisabled =
    selectedRowsIds.length === 0 || !currentProductProfile;

  const onAssignClick = async () => {
    if (!currentProductProfile?.uuid || selectedRowsIds.length === 0) return;
    await handleAssignKeysToProductProfile(
      currentProductProfile?.uuid,
      selectedRowsIds
    );
  };

  const toggleProductProfileFormModal = (): void => {
    setShowProductProfileFormModal((previousValue) => !previousValue);
  };

  const onCancelProductProfileForm = () => {
    setShowProductProfileFormModal(false);
  };
  const onSubmitProductProfileForm = async (
    profileToSubmit: Partial<ProductProfile>
  ) => {
    try {
      setGettingNewList(true);
      await api().post(
        'product-profile',
        serializeProductProfile(profileToSubmit)
      );
      sendNotification({
        text: 'Product Profile has been created successfully!',
        success: true,
      })(dispatch);
      setGettingNewList(false);
      onCancelProductProfileForm();
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong creating the Product Profile!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  asymmetricKeys.forEach((asymmetricKey) => {
    asymmetricKey?.privateKey &&
      tableData.push({
        ...asymmetricKey.privateKey,
        alreadyExists: asymmetricKey.alreadyExists,
      });
    asymmetricKey?.publicKey &&
      tableData.push({
        ...asymmetricKey.publicKey,
        alreadyExists: asymmetricKey.alreadyExists,
      });
  });

  const nonSelectableRows: any[] = [];
  // tableData.forEach((row, index) => {
  //   if (row.alreadyExists) nonSelectableRows.push(row.uuid);
  // });

  const columns = [
    {
      dataField: 'uuid',
      text: '',
      hidden: true,
    },
    {
      dataField: 'label',
      text: 'Label',
      formatter: (label: string, { uuid }: Key): ReactNode => {
        return <span>{label}</span>;
      },
    },
    {
      dataField: 'keyDigest',
      text: 'Digest',
    },
    {
      dataField: 'NoData',
      text: '',
      formatter: (
        unused: string,
        { alreadyExists }: Key & { alreadyExists: boolean }
      ): ReactNode => {
        return (
          <>
            {alreadyExists && (
              <>
                <FontAwesomeIcon
                  id="duplicated-key-hint"
                  className="pki-ico mr-2"
                  icon={faInfoCircle}
                />
                <UncontrolledPopover
                  target="duplicated-key-hint"
                  placement="top"
                  trigger="hover"
                  title="Duplicated Key!"
                >
                  <div className="p-2">
                    <span>
                      Duplicated Key! <br /> Key with the same digest already
                      exists in the system.
                    </span>
                  </div>
                </UncontrolledPopover>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div id="AssignKeyToProductProfile">
      <Table
        search={false}
        keyField={'uuid'}
        remote={false}
        pagination={false}
        data={tableData}
        id={'assign-keys-to-product-profile-table'}
        columns={columns}
        selectRow={{
          mode: 'checkbox',
          clickToSelect: false,
          nonSelectable: nonSelectableRows,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          nonSelectableClasses: 'duplicated-key',
          onSelect: ({ uuid }: { uuid: string }, isSelected: boolean): void => {
            if (isSelected) {
              setSelectedRowsIds((current) => [...current, uuid]);
            } else {
              setSelectedRowsIds((current) =>
                _.filter(current, (item) => item !== uuid)
              );
            }
          },
          onSelectAll: (
            isSelected: boolean,
            rows: { uuid: string }[]
          ): void => {
            if (isSelected) {
              setSelectedRowsIds(_.map(rows, (item) => item.uuid));
            } else {
              setSelectedRowsIds([]);
            }
          },
        }}
      />
      <div className="mt-5 d-flex align-items-center" style={{ gap: '10px' }}>
        <span className={'pki-label'}>Assign keys to product profile</span>
        <div className={'flex-grow-1'}>
          <ServerSideSelect
            onSelectEntity={(selectedProfile) => {
              setCurrentProductProfile(selectedProfile);
            }}
            value={currentProductProfile}
            infinite={true}
            wait={gettingNewList}
            searchPlaceholder={'Search by name'}
            id={'select-product-profile-for-keys'}
            placeholder={'Select a product profile'}
            formatter={(productProfile) => productProfile.name}
            fetchUrl={'product-profile'}
            searchParam={'name'}
          />
        </div>
        <Button
          outline
          id="create-product-profile"
          size="sm"
          className="h-100"
          onClick={(): void => {
            toggleProductProfileFormModal();
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Product Profile
        </Button>
        <Modal
          className="PKIApp"
          style={{ width: '1200px', maxWidth: '4000px' }}
          isOpen={showProductProfileFormModal}
          toggle={toggleProductProfileFormModal}
        >
          <ProductProfileForm
            onCancel={onCancelProductProfileForm}
            onSubmit={onSubmitProductProfileForm}
          />
        </Modal>
      </div>
      <div className="modal-buttons">
        <div className="float-right mt-5 pb-5 d-flex">
          <span className="mr-2">
            <Button
              id={`${idClassPrefix}-close`}
              outline
              disabled={isLoading}
              onClick={onCancel}
            >
              Close
            </Button>
          </span>
          <span>
            <Button
              id={`${idClassPrefix}-assign`}
              outline
              disabled={isLoading || confirmDisabled}
              onClick={onAssignClick}
            >
              Assign
            </Button>
          </span>
          {isLoading && (
            <Spinner
              size={'sm'}
              className={'ml-2 btn-group-vertical'}
              type={'border'}
            />
          )}
        </div>
      </div>
    </div>
  );
};
