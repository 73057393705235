import React, { FC, useEffect, useState, ReactNode } from 'react';
import { Collapse, Badge } from 'reactstrap';
import _, { kebabCase } from 'lodash';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { dateFormatter } from '../../../libs/helpers';
import { Table, Spinner, TableActions } from '../../../components';
import { ApplicationState } from '../../../store';
import { getAwaitingRequests } from '../../../store/requests/actions';
import {
  RequestsState,
  RequestType,
  Request,
} from '../../../store/requests/types';

interface Props {
  isView?: boolean;
}

const AwaitingRequest: FC<Props> = ({ isView = false }) => {
  const {
    awaitingRequestList,
    isGettingAwaitingRequestList,
    isLoadedAwaitingRequestList,
  } = useSelector<ApplicationState, RequestsState>((pki) => ({
    ...pki.requests,
  }));

  const [showCertificateSpinner, setShowCertificateSpinner] = useState<boolean>(
    false
  );
  const [showContent, setShowContent] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingAwaitingRequestList) {
      setShowCertificateSpinner(true);
    }
    if (isLoadedAwaitingRequestList) {
      setShowContent(true);
      setShowCertificateSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [isGettingAwaitingRequestList, isLoadedAwaitingRequestList]);

  useEffect(() => {
    const tokenSourceOne = axios.CancelToken.source();
    const tokenSourceTwo = axios.CancelToken.source();
    const tokenSourceThree = axios.CancelToken.source();
    dispatch(
      getAwaitingRequests([tokenSourceOne, tokenSourceTwo, tokenSourceThree])
    );
    return function cleanup(): void {
      tokenSourceOne.cancel(`Request::getAwaitingRequests`);
      tokenSourceTwo.cancel(`Request::getAwaitingRequests`);
      tokenSourceThree.cancel(`Request::getAwaitingRequests`);
    };
  }, [dispatch]);

  const requestColumns = [
    {
      dataField: 'uuid',
      text: 'Request ID',
      formatter: (uuid: string, { notes }: { notes: string }): ReactNode => {
        return (
          <>
            <div>{uuid.substring(0, 8).toUpperCase()}</div>
          </>
        );
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      formatter: (requestType: RequestType): ReactNode => (
        <Badge>{_.startCase(requestType)}</Badge>
      ),
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Requested by',
      formatter: (createdBy: string, currentRequest: Request): ReactNode => {
        const isCreatedByUserDeleted = _.get(
          currentRequest,
          'isCreatedByUserDeleted'
        );
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
    {
      dataField: '',
      text: 'Actions',
      formatter: (
        valueNotImportant: null,
        currentRequest: Request
      ): ReactNode => (
        <TableActions
          rowId={String(currentRequest.uuid)}
          options={[
            {
              label: 'Related Request',
              ico: (
                <FontAwesomeIcon className="pki-ico" icon={faExternalLink} />
              ),
              onClick: (e: MouseEvent): void => {
                e.stopPropagation();
                history.push({
                  pathname: `/operations/${kebabCase(
                    currentRequest.type
                  )}-requests`,
                  search: `?uuid=${currentRequest.uuid}`,
                });
              },
            },
          ]}
        />
      ),
    },
  ];
  const awaitingYourApprovalRequest = _.filter(awaitingRequestList, (item) =>
    _.includes(item.actions, 'approve')
  );

  const pagination = isView ? null : { sizePerPage: 5 };

  return (
    <div className="AwaitingRequest overflow-auto">
      <div className="header-contanier d-flex">
        <h5 className="text-muted">Requests Awaiting Your Approval</h5>
        {showCertificateSpinner && (
          <Spinner className="mt-1 ml-2" size="sm" type="border" />
        )}
      </div>
      <small>
        There are {_.size(awaitingYourApprovalRequest)} requests awaiting your
        approval. The requests shown may also be approved by other users.
      </small>
      <Collapse isOpen={showContent}>
        <Table
          id="awaiting-requests"
          keyField="uuid"
          search={!!isView}
          pagination={pagination}
          noDataIndication="No Awaiting Requests"
          columns={requestColumns}
          data={awaitingYourApprovalRequest}
        />
      </Collapse>
    </div>
  );
};

export default AwaitingRequest;
