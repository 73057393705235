import React, { useState, useEffect, FC } from 'react';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import _, { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  ManufacturingStation,
  ManufacturingStationsState,
} from '../../store/manufacturingStations/types';
import { Certificate } from '../../store/certificates/types';
import { CertificateProfile } from '../../store/certificateProfiles/types';
import { ApplicationState } from '../../store';
import { Toggle } from '../../components';
import { api } from '../../libs/helpers';
import { deserializeCertificate } from '../../store/certificates/helpers';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';
import { deserializeCertificateProfile } from '../../store/certificateProfiles/helpers';

interface Props {
  onSubmit?: Function;
  actions?: string[];
}

const ManufacturingStationInfo: FC<Props> = ({
  onSubmit = (): null => null,
}) => {
  const { manufacturingStation } = useSelector<
    ApplicationState,
    ManufacturingStationsState
  >((pki) => ({
    ...pki.manufacturingStations,
  }));

  const [formValues, setFormValues] = useState<
    ManufacturingStation | undefined
  >();

  const [selectProfileFlag, setSelectProfileFlag] = useState<boolean>(false);
  const [actualIssuingCaUuid, setActualIssuingCaUuid] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (manufacturingStation) {
      setFormValues(manufacturingStation);
    }
  }, [manufacturingStation]);

  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );

  const [readOnly, setReadOnly] = useState(true);

  const [currentIssuingCA, setCurrentIssuingCA] = useState<
    Certificate | undefined
  >(undefined);

  const [currentCertificateProfile, setCurrentCertificateProfile] = useState<
    CertificateProfile | undefined
  >(undefined);

  const {
    name,
    manufacturer,
    location,
    externalId,
    notes,
    issuingCAUuid,
    certificateProfileUuid,
    enabled,
  } = formValues || {};

  const canEdit = _.includes(manufacturingStation?.actions, 'update');

  const isValid = !isEmpty(name);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues((current: any) => ({
      ...current,
      [property]: value,
    }));
  };

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  const fetchCurrentCertificateProfile = async () => {
    if (certificateProfileUuid) {
      const { data: profile } = await api().get(
        `certificate/profile/${certificateProfileUuid}`
      );
      if (profile) {
        const profileToSet = deserializeCertificateProfile(profile);
        setCurrentCertificateProfile(profileToSet);
      }
    }
  };

  useEffect(() => {
    fetchCurrentCertificateProfile();
  }, [certificateProfileUuid]);

  let caIdToFetch = issuingCAUuid || currentCertificateProfile?.issuingCaUuid;
  const fetchCurrentIssuingCA = async (idToFetch?: string) => {
    if (caIdToFetch) {
      const { data: ca } = await api().get(
        `certificate/authority/${idToFetch ?? caIdToFetch}`
      );
      if (ca) {
        const caToSet = deserializeCertificate(ca);
        setCurrentIssuingCA(() => {
          if (!currentIssuingCA) {
            setActualIssuingCaUuid(caToSet.uuid);
            caIdToFetch = caToSet.uuid;
          }
          return caToSet;
        });
      }
    }
  };

  useEffect(() => {
    fetchCurrentIssuingCA();
  }, [caIdToFetch]);

  return (
    <div id="manufacturing-station-form" className="ManufacturingStationForm">
      <div className="form-content mt-4 px-0 pb-5">
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label">Name</Label>
              <Input
                id="name"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields && isEmpty(name)}
                value={name || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'name',
                    value: event.target.value,
                  });
                }}
              />
              <FormFeedback>Cannot be empty</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">External ID</Label>
              <Input
                id="external-id"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields}
                value={externalId || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'externalId',
                    value: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="pki-label">Manufacturer</Label>
              <Input
                id="manufacturer"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields}
                value={manufacturer || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'manufacturer',
                    value: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="pki-label">Location</Label>
              <Input
                id="location"
                readOnly={readOnly}
                plaintext={readOnly}
                invalid={highlightMandatoryFields}
                value={location || (readOnly && 'N/A') || ''}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'location',
                    value: event.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label className="pki-label">Notes</Label>
            <FormGroup>
              <Input
                value={isEmpty(notes) && readOnly ? 'N/A' : notes}
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'notes',
                    value: event.target.value,
                  });
                }}
                name="notes"
                id="notes"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label className="pki-label">
              Allow use of manufacturing station
            </Label>
            <FormGroup>
              <Toggle
                id="status"
                disabled={readOnly}
                onChange={(checked: boolean): void => {
                  setProfileFormProperty({
                    property: 'enabled',
                    value: checked,
                  });
                }}
                checked={!!enabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <Label className="pki-header">
              TLS Certificate Profile used for new Secure Token Certificate
              Requests
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label className="pki-label">Issuing CA</Label>
            {readOnly && (
              <Input
                id="issuer-ca-uuid"
                readOnly
                value={currentIssuingCA?.commonName ?? ''}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(ca) => {
                    if (selectProfileFlag) {
                      setSelectProfileFlag(false);
                    }
                    setProfileFormProperty({
                      property: 'issuingCAUuid',
                      value: ca?.uuid,
                    });
                    setCurrentIssuingCA(() => {
                      setCurrentCertificateProfile(undefined);
                      setProfileFormProperty({
                        property: 'certificateProfileUuid',
                        value: null,
                      });
                      return deserializeCertificate(ca);
                    });
                  }}
                  formatter={(ca) => ca.cn}
                  fetchUrl={`certificate/authority`}
                  defaultFilters={'is_key_online=true'}
                  searchParam={`cn`}
                  value={currentIssuingCA?.commonName || ''}
                />
              </>
            )}
          </Col>
          <Col>
            <Label className="pki-label">Certificate Profile</Label>
            {readOnly && (
              <Input
                id="certificate-profile"
                readOnly
                value={currentCertificateProfile?.name ?? ''}
                plaintext
              />
            )}
            {!readOnly && (
              <>
                <ServerSideSelect
                  onSelectEntity={(profile) => {
                    setProfileFormProperty({
                      property: 'certificateProfileUuid',
                      value: profile.uuid,
                    });
                    setCurrentCertificateProfile(
                      deserializeCertificateProfile(profile)
                    );
                  }}
                  formatter={(profile) => profile?.certificate_profile?.name}
                  fetchUrl={`certificate/profile`}
                  defaultFilters={`issuing_ca_uuid=${caIdToFetch}`}
                  searchParam={`certificate_profile.name`}
                  wait={!caIdToFetch}
                  value={
                    currentCertificateProfile?.name ||
                    'Select a certificate profile'
                  }
                  urlParams={[
                    ['resource', 'certificate_request'],
                    ['action', 'create'],
                    ['with_actions', 'false'],
                  ]}
                  error={
                    !currentCertificateProfile && highlightMandatoryFields
                      ? 'Cannot be empty, please select one!'
                      : undefined
                  }
                />
              </>
            )}
          </Col>
        </Row>
      </div>

      <div className="float-right pb-5">
        {readOnly && (
          <span className="mr-2">
            <Button
              id="edit-button"
              outline
              disabled={!canEdit}
              onClick={(): void => {
                setReadOnly(false);
              }}
            >
              Edit
            </Button>
          </span>
        )}

        {!readOnly && (
          <span className="mr-2">
            <Button
              id="cancel-button"
              outline
              onClick={(): void => {
                setReadOnly(true);
                setFormValues(manufacturingStation);
                if (actualIssuingCaUuid) {
                  fetchCurrentCertificateProfile();
                }
                setProfileFormProperty({
                  property: 'issuingCAUuid',
                  value: actualIssuingCaUuid,
                });
              }}
            >
              Cancel
            </Button>
          </span>
        )}
        {!readOnly && (
          <span className="mr-1">
            <Button
              id="confirm-button"
              outline
              disabled={false}
              onClick={(): void => {
                if (!isValid) {
                  setHighlightMandatoryFields(true);
                }
                onSubmit({ values: formValues, isValid });
                setReadOnly(true);
              }}
            >
              Confirm
            </Button>
          </span>
        )}
      </div>
    </div>
  );
};

export default ManufacturingStationInfo;
