import {
  General,
  Extensions,
  CustomExtensions,
  CertificatePolicies,
} from '../../libs/types';

interface Actions {
  actions?: string[];
}

export type CertificateTemplate = General &
  Extensions &
  CustomExtensions &
  CertificatePolicies &
  Actions;

export interface CertificateTemplatesState {
  readonly isGettingCertificateTemplateList: boolean;
  readonly isLoadedCertificateTemplateList: boolean;
  readonly isCreatingTemplate: boolean;
  readonly isDeletingTemplate: boolean;
  readonly isEditingTemplate: boolean;
  readonly certificateTemplateList: CertificateTemplate[];
  readonly certificateTemplatesErrors: unknown;
}

export enum CertificateTemplateActionTypes {
  GET_TEMPLATE_LIST_REQUEST = '@@CertificateTemplate/GET_TEMPLATE_LIST_REQUEST',
  GET_TEMPLATE_LIST_SUCCESS = '@@CertificateTemplate/GET_TEMPLATE_LIST_SUCCESS',
  GET_TEMPLATE_LIST_ERROR = '@@CertificateTemplate/GET_TEMPLATE_LIST_ERROR',

  POST_TEMPLATE_REQUEST = '@@CertificateTemplate/POST_TEMPLATE_REQUEST',
  POST_TEMPLATE_SUCCESS = '@@CertificateTemplate/POST_TEMPLATE_SUCCESS',
  POST_TEMPLATE_ERROR = '@@CertificateTemplate/POST_TEMPLATE_ERROR',

  DELETE_TEMPLATE_REQUEST = '@@CertificateTemplate/DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS = '@@CertificateTemplate/DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_ERROR = '@@CertificateTemplate/DELETE_TEMPLATE_ERROR',

  PUT_TEMPLATE_REQUEST = '@@CertificateTemplate/PUT_TEMPLATE_REQUEST',
  PUT_TEMPLATE_SUCCESS = '@@CertificateTemplate/PUT_TEMPLATE_SUCCESS',
  PUT_TEMPLATE_ERROR = '@@CertificateTemplate/PUT_TEMPLATE_ERROR',

  // SELECT_TEMPLATE = '@@CertificateTemplate/SELECT_TEMPLATE',
  // SELECTED = '@@CertificateTemplate/SELECTED'
}
