import React, { FC, useEffect, useState } from 'react';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  UncontrolledAccordion,
} from 'reactstrap';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IPatchProductProfile,
  ProductProfile,
  ProductProfileCertificateProfile,
  ProductProfileOutput,
} from '../../../types';
import OutputItem from './components/OutputItem/OutputItem';
import { swapArrayLoc } from '../../../helpers';
import './FactoryProvisioningData.scss';
import { uuidv4 } from '../../../../../libs/helpers';
import { DistributionConfig } from './components/DistributionConfig/DistributionConfig';

interface FactoryProvisioningDataProps {
  isLoading: boolean;
  productProfile: ProductProfile;
  clientCertProfile?: ProductProfileCertificateProfile[];
  onSubmitData: IPatchProductProfile;
}

export const FactoryProvisioningData: FC<FactoryProvisioningDataProps> = ({
  isLoading,
  onSubmitData,
  productProfile,
  clientCertProfile,
}) => {
  const outputWithIds =
    productProfile.output?.map((output) => {
      return {
        ...output,
        id: uuidv4(),
      };
    }) || [];
  const [outputData, setOutputData] = useState(outputWithIds);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const outPutNames = outputData.map((output) => output.name);
  const aliases = [
    {
      name: 'boot-pass',
      value:
        '{autogen:hash:sha256crypt|640000|{autogen:random:alpha_numeric16}|{ref:secrets.boot-pwd}}',
    },
    {
      name: 'root-pass',
      value:
        '{autogen:hash:sha256crypt|640000|{autogen:random:alpha_numeric16}|{ref:secrets.root-pwd}}',
    },
  ];

  if (clientCertProfile?.[0]?.label) {
    const clientCertAliases = [
      {
        name: 'client-cert',
        value: `{ref:certificate.${clientCertProfile[0].label}.full_chain:pem:b64encode}`,
      },
      {
        name: 'client-cert-key',
        value: `{ref:certificate.${clientCertProfile[0].label}.key:pem:b64encode}`,
      },
    ];

    aliases.push(...clientCertAliases);
  }

  const availableSuggestions =
    [...(productProfile.metadata || []), ...aliases]
      ?.filter(({ name }) => {
        return !outPutNames.includes(name);
      })
      .map(({ name }) => {
        const value =
          aliases.find((alias) => alias.name === name)?.value ||
          `{ref:data.${name}}`;
        return {
          name,
          value,
        };
      }) || [];

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    setOutputData(swapArrayLoc(outputData, dragIndex, hoverIndex));
  };

  const onChangeOutput = (output: ProductProfileOutput & { id: string }) => {
    const newOutput = cloneDeep(outputData);
    const outputIndex = newOutput.findIndex((item) => item.id === output.id);
    newOutput[outputIndex] = { ...newOutput[outputIndex], ...output };
    setOutputData(newOutput);
  };

  const onDeleteItem = async (id: string) => {
    const outputItems = outputData.filter((item) => item.id !== id);
    setOutputData(outputItems);
  };

  const onAddOutputItem = () => {
    const newOutput = [
      ...cloneDeep(outputData),
      {
        id: uuidv4(),
        name: '',
        value: '',
      },
    ];
    setOutputData(newOutput);
  };

  const onConfirm = () => {
    const dataToSend = outputData
      .filter(({ name, value }) => {
        return !isEmpty(name) || !isEmpty(value);
      })
      .map(({ name, value }) => ({
        name,
        value,
      }));
    onSubmitData({
      output: dataToSend,
    });
    setEditMode(false);
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const headerStyle = {
    color: '#611b79db',
    fontSize: '14.4px',
    fontWeight: 600,
    padding: '.75rem',
  };

  useEffect(() => {
    const outputsToCheck = outputData
      .filter((output) => output.name && output.value)
      .map(({ name, value }) => ({
        name,
        value,
      }));
    setConfirmDisabled(isEqual(productProfile.output, outputsToCheck));
  }, [outputData, productProfile.output]);
  return (
    <div id={`product-profile-factory-provisioning-data`}>
      <UncontrolledAccordion
        toggle={() => null}
        defaultOpen={['output-items']}
        stayOpen
      >
        <AccordionItem>
          <AccordionHeader targetId="configuration">
            <h5 className={'purple-text'}>Output File</h5>
            <h6 className={'text-muted pr-5'}>
              Configure the file path, file name and destination (SFTP) for the
              Device Batch Output file. Values provided by Metadata and the
              Device Batch Request can be defined with reference variables in
              the file path and file name patterns.
            </h6>
          </AccordionHeader>
          <AccordionBody accordionId="configuration">
            <DistributionConfig
              productProfile={productProfile}
              onPatchProductProfile={onSubmitData}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="output-items">
            <h5 className={'purple-text'}>Output Items</h5>
            <h6 className={'text-muted pr-5'}>
              Configure the device data fields to be included in the Device
              Batch Output file, as well as their order in that file.
            </h6>
          </AccordionHeader>
          <AccordionBody accordionId="output-items">
            <div className="d-flex justify-content-end mb-2">
              <Button
                id="link-certificate-profile"
                outline
                disabled={!editMode}
                size="sm"
                onClick={onAddOutputItem}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Output Field
              </Button>
            </div>
            <div className={'output-items-container'}>
              {outputData.length > 0 ? (
                <div
                  className={`d-table w-100 ${editMode ? 'edit' : 'readonly'}`}
                  style={{ tableLayout: 'fixed' }}
                >
                  <div style={{ display: 'table-header-group' }}>
                    {editMode && (
                      <div
                        className={'d-table-cell'}
                        style={{
                          ...headerStyle,
                          width: '25px',
                          paddingLeft: 0,
                        }}
                      >
                        {' '}
                      </div>
                    )}
                    <div
                      className={'d-table-cell'}
                      style={{ ...headerStyle, width: '25%' }}
                    >
                      Name
                    </div>
                    <div className={'d-table-cell'} style={headerStyle}>
                      Value
                    </div>
                    {editMode && (
                      <div
                        className={'d-table-cell'}
                        style={{ ...headerStyle, width: '50px' }}
                      >
                        {' '}
                      </div>
                    )}
                  </div>
                  {outputData.map((item, itemIndex) => {
                    return (
                      <OutputItem
                        editMode={editMode}
                        item={item}
                        key={item.id}
                        onDeleteItem={onDeleteItem}
                        availableSuggestions={availableSuggestions}
                        onChangeOutput={onChangeOutput}
                        moveItem={moveItem}
                        index={itemIndex}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  className={'p-3 text-center'}
                  style={{ backgroundColor: 'rgba(0,0,0,.05)' }}
                >
                  No output field has been added to Product Profile
                </div>
              )}
            </div>
            {!editMode && (
              <div className="mt-3 d-flex">
                <Button
                  outline
                  className="ml-auto"
                  onClick={(): void => {
                    toggleEditMode();
                  }}
                >
                  Edit
                </Button>
              </div>
            )}
            {editMode && (
              <>
                <div className="mt-3 d-flex">
                  <Button
                    outline
                    className="ml-auto"
                    onClick={(): void => {
                      setEditMode(false);
                      setOutputData(outputWithIds);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id={`save-product-profile-output`}
                    className={'ml-3'}
                    onClick={onConfirm}
                    outline
                    disabled={confirmDisabled}
                  >
                    Confirm
                  </Button>
                </div>
                <div className="d-flex justify-content-end mt-2">
                  {!confirmDisabled && (
                    <Alert color={'info'}>
                      Don't forget to confirm your changes!
                    </Alert>
                  )}
                </div>
              </>
            )}
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </div>
  );
};
