import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isEmpty, get } from 'lodash';

import { Card, Row, Col, Collapse, Button, Input } from 'reactstrap';
import SortableTree, { TreeItem } from 'react-sortable-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import SmartSelect from 'react-select'; // This only needs to be imported once in your app
import axios from 'axios';
import { ApplicationState } from '../../store';
import { getCertificateTreeList } from '../../store/certificates/actions';
import { api, shouldCRLandAIABeShown } from '../../libs/helpers';
import { CertificatesState, Certificate } from '../../store/certificates/types';
import { ErrorHandler, Spinner } from '../../components';
import CertificateInfo from './CertificateInfo';

import './Trees.scss';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { sendNotification } from '../../store/notifications/actions';

const { saveAs } = require('file-saver');

const Trees: FC = () => {
  const {
    certificateTreeList,
    isGettingCertificateTreeList,
    isLoadedCertificateTreeList,
    treeErrors,
  } = useSelector<ApplicationState, CertificatesState>(
    (pki) => pki.certificates
  );

  const [currentCertificate, setCurrentCertificate] = useState<Certificate>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showTree, setShowTree] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<TreeItem[]>([]);
  const [search, setSearch] = useState<{
    searchString: string;
    searchFocusIndex: number;
    searchFoundCount: number;
  }>({ searchString: '', searchFocusIndex: 0, searchFoundCount: 0 });

  const { searchString, searchFocusIndex, searchFoundCount } = search;

  const [downloadFile, setDownloadFile] = useState<{
    value: string;
    label: string;
  }>();
  const [cpsFileList, setCpsFileList] = useState<string[]>();
  const [options, setOptions] = useState<any | null>(null);

  const downloadCPSFile = async () => {
    try {
      const {
        data: { download_url: downloadUrl },
      } = await api().get(
        `certificate/authority/cps/downloadurl?file_name=${downloadFile?.label}`
      );
      const { data: cpsFileContent } = await axios.get(downloadUrl, {
        responseType: 'blob',
      });
      saveAs(cpsFileContent, downloadFile?.label || 'CPS-DOC.pdf');
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong downloading file!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (isGettingCertificateTreeList) {
      setShowSpinner(true);
    }
    if (isLoadedCertificateTreeList || treeErrors) {
      setShowTree(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowTree(false);
    };
  }, [isGettingCertificateTreeList, isLoadedCertificateTreeList, treeErrors]);

  useEffect(() => {
    dispatch(getCertificateTreeList());
  }, [dispatch]);

  useEffect(() => {
    if (cpsFileList) {
      let opt: string[] = [];
      for (const [key, value] of Object.entries(cpsFileList)) {
        opt = opt.concat(Object.values(value));
      }
      const output = opt.map((item) => ({
        label: item.split('/')[1],
        value: item,
      }));
      setOptions(output);
    }
  }, [cpsFileList]);

  useEffect(() => {
    if (
      isLoadedCertificateTreeList &&
      isNil(currentCertificate) &&
      !isEmpty(certificateTreeList)
    ) {
      setCurrentCertificate(certificateTreeList[0].certificate);
      setTreeData(certificateTreeList);
    }
  }, [certificateTreeList, currentCertificate, isLoadedCertificateTreeList]);

  useEffect(() => {
    try {
      if (!cpsFileList) {
        const fetchData = async () => {
          const response = await api()
            .get('/certificate/authority/cps')
            .then((request) => request.data);
          setCpsFileList(response);
        };
        fetchData();
      }
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong fetching documents!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  }, []);

  const customSearchMethod = ({
    node,
    searchQuery,
  }: {
    node: TreeItem;
    searchQuery: string;
  }): boolean =>
    !isEmpty(searchQuery) &&
    (node.title as string).toLowerCase().indexOf(searchQuery.toLowerCase()) >
      -1;

  const selectPrevMatch = (): void => {
    setImmediate(() => {
      setSearch({
        ...search,
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1,
      });
    });
  };

  const selectNextMatch = (): void => {
    setImmediate(() => {
      setSearch({
        ...search,
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0,
      });
    });
  };

  const searchCalback = ({ length }: { length: number }): void => {
    setImmediate(() => {
      setSearch({
        ...search,
        searchFoundCount: length,
        searchFocusIndex: length > 0 ? searchFocusIndex % length : 0,
      });
    });
  };

  const showCRLandAIA = shouldCRLandAIABeShown();

  return (
    <div className="Trees">
      <Card className="rounded p-5">
        <Row className="align-items-center">
          <Col>
            <div className="header-contanier d-flex">
              <h3 className="text-muted">Trees</h3>
              {showSpinner && (
                <Spinner className="mt-2 ml-2" size="sm" type="border" />
              )}
            </div>
          </Col>
          <Col md={15}>
            <h6 className="text-muted mb-0">Documentation</h6>
          </Col>
          <Col>
            <div className="float-end">
              <SmartSelect
                isSearchable={true}
                onChange={(selectedValue): void => {
                  setDownloadFile({
                    value: get(selectedValue, 'value') as string,
                    label: get(selectedValue, 'label') as string,
                  });
                }}
                value={downloadFile}
                options={options}
              />
            </div>
          </Col>
          <div className="col-1">
            <Button
              id="download-form-button"
              disabled={
                options == null || options.length == 0 || downloadFile == null
              }
              outline
              onClick={downloadCPSFile}
            >
              Download
            </Button>
          </div>
        </Row>
        <Collapse isOpen={showTree}>
          {isEmpty(treeData) && !treeErrors && isLoadedCertificateTreeList && (
            <div className="mt-5">There are no Trees Yet</div>
          )}
          {treeErrors && <ErrorHandler />}
          {!isEmpty(treeData) && !treeErrors && (
            <>
              <div className="search-bar mt-3 d-flex">
                <Input
                  placeholder="Search..."
                  id="find-box"
                  type="text"
                  value={searchString}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    setSearch({
                      ...search,
                      searchString: event.target.value,
                    });
                  }}
                />

                <Button
                  outline
                  className="ml-1"
                  size="sm"
                  disabled={!searchFoundCount}
                  onClick={selectPrevMatch}
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                </Button>

                <Button
                  outline
                  className="ml-1"
                  size="sm"
                  disabled={!searchFoundCount}
                  onClick={selectNextMatch}
                >
                  <FontAwesomeIcon icon={faChevronCircleRight} />
                </Button>
                <span className="counter mt-2 text-muted">
                  &nbsp;
                  {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                  &nbsp;/&nbsp;
                  {searchFoundCount || 0}
                </span>
              </div>
              <div className="tree-view mt-5">
                <Row>
                  <Col md={7}>
                    <div style={{ height: window.innerHeight - 340 }}>
                      <SortableTree
                        canDrag={false}
                        treeData={treeData}
                        searchMethod={customSearchMethod}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        searchFinishCallback={searchCalback}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        generateNodeProps={(rowInfo): any => {
                          const {
                            node: { certificate },
                          } = rowInfo;
                          const isSelected =
                            currentCertificate?.uuid === certificate.uuid;
                          const selectedIco = (
                            <span className="mr-3">
                              <FontAwesomeIcon
                                className="pki-ico"
                                icon={faEye}
                              />
                            </span>
                          );
                          return {
                            buttons: isSelected ? [selectedIco] : [],
                            onClick: (e: MouseEvent): void => {
                              if (get(e, 'target.type') !== 'button') {
                                setCurrentCertificate(certificate);
                              }
                            },
                          };
                        }}
                        onChange={(newData): void => {
                          setImmediate(() => {
                            setTreeData(newData);
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="certificate-info">
                      {currentCertificate && (
                        <CertificateInfo
                          showCRLandAIA={showCRLandAIA}
                          certificate={currentCertificate}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>{' '}
            </>
          )}
        </Collapse>
      </Card>
    </div>
  );
};

export default Trees;
