import React, { useState, useEffect, FC } from 'react';
import useCopy from '@react-hook/copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faUnlock,
  faTimes,
  faCopy,
  faRetweet,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  FormGroup,
  FormFeedback,
  InputGroup,
  InputGroupText,
  UncontrolledPopover,
  Badge,
  Alert,
} from 'reactstrap';
import _ from 'lodash';
import { getVars } from '../../libs/provisioning';
import {
  getClientSecret,
  generateClientSecret,
  ForbiddenClientSecretError,
} from '../../store/clients/actions';
import { defaultValuesClient } from './constants';
import { Client } from '../../store/clients/types';
import './ClientForm.scss';

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onChangeMode?: Function;
  actions: string[];
  defaultValues?: Client;
  isAdmin?: boolean;
  readOnly: boolean;
}

const ClientForm: FC<Props> = ({
  onCancel = (): null => null,
  onSubmit = (): null => null,
  onChangeMode = (): null => null,
  actions = [],
  isAdmin = false,
  defaultValues = defaultValuesClient,
  readOnly = false,
}) => {
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [formValues, setFormValues] = useState<Client>(defaultValues);
  const [clientSecret, setClientSecret] = useState('');
  const [showSecret, setShowSecret] = useState(false);
  const [
    forbiddenClientMessage,
    setForbiddenClientMessage,
  ] = useState<ForbiddenClientSecretError | null>(null);

  const {
    code,
    description,
    clientID,
    enabled,
    uuid,
    serviceAccount,
  } = formValues;
  const dispatch = useDispatch();

  const {
    copy: copyClientSecret,
    copied: clientSecretCopied,
    reset: resetClientSecretCopy,
  } = useCopy(clientSecret);

  const {
    copy: copyClientId,
    copied: clientIdCopied,
    reset: resetClientIdCopy,
  } = useCopy(clientID as string);

  const {
    copy: copyUserUuid,
    copied: userUuidCopied,
    reset: resetUserUuidCopy,
  } = useCopy(serviceAccount?.uuid as string);

  useEffect(() => {
    if (clientSecretCopied || clientIdCopied) {
      setTimeout(() => {
        resetClientSecretCopy();
        resetClientIdCopy();
        resetUserUuidCopy();
      }, 5000);
    }
  }, [
    clientIdCopied,
    clientSecretCopied,
    userUuidCopied,
    resetClientIdCopy,
    resetClientSecretCopy,
    resetUserUuidCopy,
  ]);

  useEffect(() => {
    const getAndSetClientSecret = async (): Promise<void> => {
      try {
        const result = await getClientSecret(formValues.uuid, dispatch);
        setClientSecret(result);
      } catch (err) {
        setForbiddenClientMessage(err as ForbiddenClientSecretError);
      }
    };
    if (readOnly && _.includes(actions, 'get_secret')) {
      getAndSetClientSecret();
    }
  }, [actions, dispatch, formValues.uuid, readOnly]);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setFormValues((current: Client) => ({
      ...current,
      [property]: value,
    }));
  };

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  const isCodeValid =
    (_.isEqual(code, defaultValues.code) ||
      !_.chain(code).trim().isEmpty().value()) &&
    !(_.chain(code).trim().isEmpty().value() && highlightMandatoryFields);

  const isValid = !_.chain(code).trim().isEmpty().value();
  const canEdit = _.includes(actions, 'update');
  const hasUuid = !_.isEmpty(uuid);

  const { PKI_SELECTED_CUSTOMER_CODE } = getVars();

  return (
    <div id="certificate-request-form" className="ClientForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <div>
            <h3 className="text-muted">API Client</h3>
            {hasUuid && (
              <h6>
                <Badge color={enabled ? 'success' : 'danger'}>
                  {enabled ? 'Active' : 'Blocked'}
                </Badge>
              </h6>
            )}
          </div>
          {canEdit && (
            <span
              onClick={(): void => {
                onChangeMode();
              }}
              className="ml-3 mt-2 cursor-pointer"
            >
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            </span>
          )}
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        <Row>
          <Col md={12}>
            <div
              className={classNames({
                'd-flex': readOnly,
              })}
            >
              <FormGroup>
                <Label className="pki-label">Client ID</Label>
                {readOnly && (
                  <div className="d-flex">
                    <span>{clientID}</span>{' '}
                    <span
                      id="copy-client-id"
                      className="ml-4"
                      onClick={(): void => {
                        copyClientId();
                      }}
                    >
                      <FontAwesomeIcon className="pki-ico" icon={faCopy} />
                      <UncontrolledPopover
                        trigger="hover"
                        target="copy-client-id"
                      >
                        <div className="p-2">
                          {clientIdCopied ? 'Copied!' : 'Copy ID'}
                        </div>
                      </UncontrolledPopover>
                    </span>
                  </div>
                )}
                {!readOnly && (
                  <InputGroup>
                    <InputGroupText>{`api-${PKI_SELECTED_CUSTOMER_CODE}-`}</InputGroupText>
                    <Input
                      id="code-input"
                      value={(readOnly ? clientID : code) as string}
                      readOnly={readOnly}
                      plaintext={readOnly}
                      className="rounded-right"
                      invalid={!isCodeValid}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        setProfileFormProperty({
                          property: 'code',
                          value: event.target.value,
                        });
                      }}
                      type="text"
                    />

                    <FormFeedback>Cannot be empty</FormFeedback>
                  </InputGroup>
                )}
              </FormGroup>
            </div>
          </Col>
        </Row>
        {readOnly && isAdmin && (
          <Row className="mb-3">
            <Col>
              <Label className="pki-label">User UUID</Label>
              <div className="d-flex">
                <span>{serviceAccount?.uuid}</span>{' '}
                <span
                  id="copy-user-uuid"
                  className="ml-4"
                  onClick={(): void => {
                    copyUserUuid();
                  }}
                >
                  <FontAwesomeIcon className="pki-ico" icon={faCopy} />
                  <UncontrolledPopover trigger="hover" target="copy-user-uuid">
                    <div className="p-2">
                      {userUuidCopied ? 'Copied!' : 'Copy User UUID'}
                    </div>
                  </UncontrolledPopover>
                </span>
              </div>
            </Col>
          </Row>
        )}
        <Label className="pki-label">Description</Label>
        <FormGroup>
          <Input
            value={readOnly && _.isEmpty(description) ? 'N/A' : description}
            readOnly={readOnly}
            plaintext={readOnly}
            type="textarea"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setProfileFormProperty({
                property: 'description',
                value: event.target.value,
              });
            }}
            name="description"
            id="description"
          />
        </FormGroup>
        {readOnly && _.includes(actions, 'get_secret') && (
          <div className="client-secret">
            <Label className="pki-label">Client Secret</Label>
            <div className="d-flex secret-actions">
              <Input
                plaintext={true}
                readOnly={true}
                type={showSecret ? 'text' : 'password'}
                value={clientSecret || '-------------------------------'}
                className="w-50"
              />
              <span
                id="show-hide-secret"
                className="client-form-ico"
                onClick={(): void => {
                  if (!clientSecret) return;
                  setShowSecret((prev) => !prev);
                }}
              >
                <FontAwesomeIcon
                  className="pki-ico"
                  style={{
                    cursor: clientSecret ? 'pointer' : 'not-allowed',
                    color: clientSecret ? '#d87548' : '#CCC',
                  }}
                  icon={!showSecret ? faEye : faEyeSlash}
                />
                <UncontrolledPopover
                  trigger="hover"
                  disabled={!clientSecret}
                  target="show-hide-secret"
                >
                  <div className="p-2">
                    <small>{showSecret ? 'Hide Secret' : 'Show Secret'}</small>
                  </div>
                </UncontrolledPopover>
              </span>
              <span
                id="copy-secret"
                className="client-form-ico ml-4"
                onClick={(): void => {
                  if (!clientSecret) return;
                  copyClientSecret();
                }}
              >
                <FontAwesomeIcon
                  className="pki-ico"
                  style={{
                    cursor: clientSecret ? 'pointer' : 'not-allowed',
                    color: clientSecret ? '#d87548' : '#CCC',
                  }}
                  icon={faCopy}
                />
                <UncontrolledPopover
                  trigger="hover"
                  disabled={!clientSecret}
                  target="copy-secret"
                >
                  <div className="p-2">
                    <small>
                      {clientSecretCopied ? 'Copied!' : 'Copy Secret'}
                    </small>
                  </div>
                </UncontrolledPopover>
              </span>
            </div>
            <Button
              id="copy-client-secret-button"
              outline
              className="mt-1"
              size="sm"
              disabled={!_.includes(actions, 'reset_secret') || !clientSecret}
              onClick={async (): Promise<void> => {
                if (_.includes(actions, 'reset_secret')) {
                  await generateClientSecret(formValues.uuid, dispatch);
                  const result = await getClientSecret(
                    formValues.uuid,
                    dispatch
                  );
                  setClientSecret(result);
                }
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faRetweet} />
              Generate New Secret
            </Button>
            {forbiddenClientMessage && (
              <Alert
                color={'info'}
                className={'mt-2'}
                style={{ maxHeight: '300px', overflowY: 'auto' }}
              >
                <p>{forbiddenClientMessage?.text}</p>
                {forbiddenClientMessage?.missingMessage && (
                  <>
                    <p className={'mb-1'}>
                      {forbiddenClientMessage?.missingMessage}
                    </p>
                    <ul>
                      {forbiddenClientMessage?.missingEntitlements?.map(
                        (message) => (
                          <li key={message} className={'mb-1'}>
                            {message}
                          </li>
                        )
                      )}
                    </ul>
                  </>
                )}
              </Alert>
            )}
          </div>
        )}
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-button"
                outline
                disabled={false}
                onClick={(): void => {
                  onSubmit({ values: formValues, isValid });
                  if (!isValid) {
                    setHighlightMandatoryFields(true);
                  }
                }}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientForm;
