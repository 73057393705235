export interface LoginStats {
  date: string;
  count: number;
}

export interface HSMStats {
  active: boolean;
  hsmUtcTime: string;
  available: boolean;
}

export interface StatisticsState {
  readonly loginStats: LoginStats[];
  readonly HSMStats: HSMStats | undefined;
  readonly isGettingLoginStats: boolean;
  readonly isLoadedLoginStats: boolean;
  readonly isGettingHSMStats: boolean;
  readonly isLoadedLHSMStats: boolean;
  readonly error: {};
}

// convention `@@context/ACTION_TYPE`
export enum StatisticsActionTypes {
  GET_LOGIN_STATST_REQUEST = '@@statistics/GET_LOGIN_STATST_REQUEST',
  GET_LOGIN_STATST_SUCCESS = '@@statistics/GET_LOGIN_STATST_SUCCESS',
  GET_LOGIN_STATST_ERROR = '@@statistics/GET_LOGIN_STATST_ERROR',
  GET_HSM_STATST_REQUEST = '@@statistics/GET_HSM_STATST_REQUEST',
  GET_HSM_STATST_SUCCESS = '@@statistics/GET_HSM_STATST_SUCCESS',
  GET_HSM_STATST_ERROR = '@@statistics/GET_HSM_STATST_ERROR',
}
