/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import moment from 'moment';
import { CertificateTemplate } from './types';
import { subjectKeysSorted, AIAAccessType } from '../../libs/constants';
import { formatSubject } from '../../libs/helpers';
import { CertificatePolicies } from '../../libs/types';

export const serializeCertificateTemplate = (
  certificateTemplate: CertificateTemplate
): any => {
  const subject = formatSubject(certificateTemplate.subject);

  const key_usage = _.transform(
    certificateTemplate.keyUsage,
    (result, value: string) => {
      _.set(result, _.snakeCase(value), true);
    },
    {}
  );
  const extended_key_usage = _.transform(
    certificateTemplate.extendedKeyUsage,
    (result, value: string) => {
      _.set(result, _.snakeCase(value), true);
    },
    {}
  );
  const subject_alt_name = _.transform(
    certificateTemplate.subjectAlternativeNames,
    (
      result: { [key: string]: string }[],
      { values, key }: { values: string[]; key: string }
    ) => {
      _.forEach(values, (value: string): void => {
        result.push({ [key]: value });
      });
    },
    []
  );
  const crl_distribution_points = [
    {
      full_name: _.map(
        certificateTemplate.CRLDistributionPointURIs,
        (value: string) => ({
          uniform_resource_identifier: value,
        })
      ),
    },
  ];
  const authority_information_access = _.transform(
    certificateTemplate.authorityInformationAccess,
    (
      result: {
        access_method: string;
        access_location: { uniform_resource_identifier: string };
      }[],
      { values, key }: { key: string; values: string[] }
    ) => {
      _.forEach(values, (value: string): void => {
        result.push({
          access_method: AIAAccessType[key],
          access_location: { uniform_resource_identifier: value },
        });
      });
    },
    []
  );
  const certificate_policies = {
    policy_informations: _.transform(
      certificateTemplate.certificatePolicies,
      (
        result: {
          policy_identifier: string;
          policy_qualifiers: { text: string }[];
        }[],
        { values, key }: { values: string[]; key: string }
      ) => {
        const policy_qualifiers = _.map(values, (value: string) => ({
          text: value,
        }));
        result.push({ policy_identifier: key, policy_qualifiers });
      },
      []
    ),
  };

  const customExtensions = _.map(
    certificateTemplate.customExtensions,
    (customExtension) => ({
      custom: {
        object_identifier: customExtension.objectIdentifier,
        value: customExtension.value,
        encoding_name: customExtension.encoding,
      },
      critical: false,
    })
  );

  const extensions: any[] = [
    ...customExtensions,
    { key_usage, critical: true },
    { extended_key_usage, critical: false },
    { subject_alt_name, critical: false },
    { authority_information_access, critical: false },
  ];

  if (!_.isEmpty(certificateTemplate.CRLDistributionPointURIs)) {
    extensions.push({ crl_distribution_points, critical: false });
  }

  extensions.push({
    certificate_policies,
    critical: certificateTemplate.critical,
  });

  const output = {
    certificate_config: {
      cert_props: {
        extensions,
        subject,
        ttl: certificateTemplate.validity,
      },
      key_spec: certificateTemplate.keySpec,
      include_ski: true,
      name: certificateTemplate.name,
    },
    name: certificateTemplate.name,
    notes: certificateTemplate.description,
    batch: {
      pgp_upload_keys: [
        ...(certificateTemplate.pgpKeys?.map((item) => item.publicKey) || []),
        ...(certificateTemplate.pgpFiles || []),
      ],
      enabled: certificateTemplate.batchEnabled,
    },
  };
  return output;
};

export const deserializeCertificateTemplate = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): CertificateTemplate => {
  const key_usage: any[] = [];
  const extended_key_usage: any[] = [];
  const subject_alt_name: any[] = [];
  let crl_distribution_points: any[] = [];
  const authority_information_access: any[] = [];
  let certificate_policies: CertificatePolicies = {} as CertificatePolicies;
  let pgpKeys: any[] = [];
  let pgpKeyUuids: any[] = [];

  if (raw.batch) {
    if (raw.batch.pgp_keys) {
      pgpKeys = [
        ...raw.batch.pgp_keys.map((item: any) => ({
          publicKey: item.public_key,
          pgpKeyId: item.pgp_key_id,
          pgpKeyIdV4: item.pgp_key_id_v4,
          fingerprint: item.fingerprint,
          createdAt: moment(_.get(item, 'date_created')).format('x'),
          uuid: item.uuid,
          name: item.name,
          enabled: item.enabled,
        })),
      ];
      pgpKeyUuids = [...raw.batch.pgp_key_uuids];
    }
  }

  _.forEach(raw.certificate_config.cert_props.extensions, (extension) => {
    const extensionKeys = _.keys(extension);
    if (_.includes(extensionKeys, 'key_usage')) {
      _.each(extension.key_usage, (value, key) => {
        if (value) {
          key_usage.push(key);
        }
      });
    }
    if (_.includes(extensionKeys, 'extended_key_usage')) {
      _.each(extension.extended_key_usage, (value, key) => {
        if (value) {
          extended_key_usage.push(key);
        }
      });
    }
    if (_.includes(extensionKeys, 'subject_alt_name')) {
      const subject_alt_nameAux = _.map(
        extension.subject_alt_name,
        (value) => ({
          key: _.keys(value)[0],
          value: value[_.keys(value)[0]],
        })
      );
      const keysToAdd = _(subject_alt_nameAux)
        .map((item) => item.key)
        .uniq()
        .value();
      _.forEach(keysToAdd, (key) => {
        subject_alt_name.push({
          key,
          values: _(subject_alt_nameAux)
            .filter((item) => item.key === key)
            .map((item) => item.value)
            .value(),
        });
      });
    }
    if (_.includes(extensionKeys, 'crl_distribution_points')) {
      crl_distribution_points = _.map(
        extension.crl_distribution_points[0].full_name,
        (value) => value.uniform_resource_identifier
      );
    }

    if (_.includes(extensionKeys, 'authority_information_access')) {
      const keysToAdd = _(extension.authority_information_access)
        .map((value) => value.access_method)
        .uniq()
        .value();
      _.forEach(keysToAdd, (key) => {
        const AIAkey = Object.keys(AIAAccessType).find(
          (keyValue) => AIAAccessType[keyValue] === key
        );
        authority_information_access.push({
          key: AIAkey,
          values: _(extension.authority_information_access)
            .filter((value) => value.access_method === key)
            .map((value) => value.access_location.uniform_resource_identifier)
            .value(),
        });
      });
    }

    if (_.includes(extensionKeys, 'certificate_policies')) {
      certificate_policies = {
        critical: extension.critical,
        certificatePolicies: _.map(
          extension.certificate_policies.policy_informations,
          (value) => ({
            key: value.policy_identifier,
            values: _.map(value.policy_qualifiers, (item) => item.text),
          })
        ),
      };
    }
  });

  const customExtensions = _(raw.certificate_config.cert_props.extensions)
    .filter((extension) => {
      const keys = _.keys(extension);
      return _.includes(keys, 'custom');
    })
    .map((extension) => ({
      objectIdentifier: extension.custom.object_identifier,
      value: extension.custom.value,
      encoding: extension.custom.encoding_name,
    }))
    .value();

  const createdBy = raw.created_by_user?.email || raw.created_by_user?.username;
  const isCreatedByUserDeleted = raw.created_by_user?.deleted;
  const updatedBy = raw.updated_by_user?.email || createdBy;

  const output = {
    uuid: raw.uuid,
    name: raw.certificate_config.name,
    authority: ['Option One'],
    description: raw.notes,
    validity: raw.certificate_config.cert_props.ttl,
    keySpec: raw.certificate_config.key_spec,
    actions: raw.actions,
    subject: _.transform(
      subjectKeysSorted,
      (out: { key: string; value: string }[], key) => {
        const values = _.get(
          raw,
          `certificate_config.cert_props.subject[${key}]`
        );

        if (!_.isNil(values) && _.isString(values)) {
          out.push({ key, value: values });
        }

        if (_.isArray(values) && !_.isEmpty(values)) {
          _.each(values, (value) => {
            out.push({ key, value });
          });
        }
      },
      []
    ),
    keyUsage: [...key_usage],
    extendedKeyUsage: [...extended_key_usage],
    subjectAlternativeNames: [...subject_alt_name],
    CRLDistributionPointURIs: [...crl_distribution_points],
    authorityInformationAccess: [...authority_information_access],
    customExtensions,
    pgpKeys: [...pgpKeys],
    pgpKeyUuids: [...pgpKeyUuids],
    batchEnabled: raw.batch?.enabled,
    certificatePolicies: certificate_policies.certificatePolicies,
    critical: certificate_policies.critical,
    createdBy,
    isCreatedByUserDeleted,
    updatedBy,
    issuedAt: moment(raw.date_created).format('x'),
    updatedAt: moment(raw.date_updated || _.get(raw, 'date_created')).format(
      'x'
    ),
  };
  return output;
};
