import React, { useState, ReactNode, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UncontrolledPopover,
  Row,
  Col,
  Label,
  Input,
  Button,
  TabContent,
  TabPane,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
} from 'reactstrap';
import { usePreviousString } from 'react-hooks-use-previous';
import {
  faTimes,
  faLock,
  faUnlock,
  faInfoCircle,
  faFile,
  faExternalLink,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import _, { find, isNil, kebabCase } from 'lodash';
import SmartSelect from 'react-select';
import { ApplicationState } from '../../store';
import {
  CodeSigningProfilesState,
  CodeSigningProfile,
} from '../../store/codeSigningProfiles/types';

import { api } from '../../libs/helpers';

import { defaultValuesCodeSigningProfiles, navMenu } from './constants';
import { Select, Spinner } from '../../components';
import {
  outputFormatOptionsForNoDigestInfo,
  outputFormatOptionsForNoPadding,
  outputFormatOptionsForPss,
  signatureAlgorithmNoDigestInfoKey,
  signatureAlgorithmNoPadding,
  signatureAlgorithmPss,
} from '../../libs/constants';
import { getCodeSigningProfileKeySpecs } from '../../store/codeSigningProfiles/actions';
import ApprovalPolicyApprover from '../ViewsComponents/ApprovalPolicyApprover';
import { CodeSigningCertificateRequest } from '../../store/requests/types';
import { deserializeCodeSigningKeyRequest } from '../../store/requests/helpers';
import { sendNotification } from '../../store/notifications/actions';
import { ServerSideSelect } from '../../components/ServerSideSelect/ServerSideSelect';

interface Props {
  onCancel?: Function;
  onSubmit?: Function;
  onChangeMode?: Function;
  defaultValues?: CodeSigningProfile;
  readOnly: boolean;
}

const CodeSigningProfileForm: FC<Props> = ({
  onCancel = (): null => null,
  onChangeMode = (): null => null,
  onSubmit = (): null => null,
  defaultValues = defaultValuesCodeSigningProfiles,
  readOnly = false,
}) => {
  const {
    isGettingCodeSignProfileKeySpecs,
    codeSigningProfileKeySpecs,
  } = useSelector<ApplicationState, CodeSigningProfilesState>((pki) => ({
    ...pki.codeSigningProfiles,
    ...pki.requests,
  }));

  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [activeTab, setActiveTab] = useState('0');
  const [formValues, setFormValues] = useState<CodeSigningProfile>(
    defaultValues
  );
  const [currentCodeSigningKey, setCurrentCodeSigningKey] = useState<
    | {
        value: {
          codeSigningKeyUuid: string;
          certificateProfileUuid: string;
          certificateProfileCN: string;
        };
        label: string;
      }
    | undefined
  >(undefined);
  const [
    codeSigningKey,
    setCodeSigningKey,
  ] = useState<CodeSigningCertificateRequest>();

  const [approversRequired, setApproversRequired] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    name,
    notes,
    signingAlgorithm,
    signingHashAlgorithm,
    codeSigningKeyUuid,
    approvalPolicy,
    outputFormat,
    codeSigningProfileId,
  } = formValues;

  const fetchCodeSigningKeysUrl = '/code-signing/profile/allowed-keys'; // This URL may change
  const formatCodeSigningKey = (entity: any) => {
    return `${entity?.certificate_cn} (Request ID: ${String(entity?.uuid)})`;
  };

  const fetchCurrentCodeSigningKey = async () => {
    if (codeSigningKeyUuid) {
      const { data: key } = await api().get(
        `code-signing/key/${codeSigningKeyUuid}`
      );
      if (key?.uuid) {
        const currentKey = deserializeCodeSigningKeyRequest(key);
        const keyOptionToSet = {
          value: {
            codeSigningKeyUuid: String(currentKey.uuid),
            certificateProfileUuid: String(currentKey.certificateProfileUuid),
            certificateProfileCN: String(currentKey.certificateCN),
          },
          label: `${currentKey.certificateCN} (Request ID: ${String(
            currentKey.uuid
          )})`,
        };
        setCurrentCodeSigningKey(keyOptionToSet);
      }
    }
  };

  useEffect(() => {
    fetchCurrentCodeSigningKey();
  }, [codeSigningKeyUuid]);

  const toggleTab = (tab: string): void => {
    activeTab !== tab && setActiveTab(tab);
  };

  const renderNav = (): ReactNode => (
    <Nav tabs>
      {_.map(navMenu, (label: string, index: number) => (
        <NavItem
          id={`nav-${kebabCase(label)}`}
          key={index}
          className="cursor-pointer pki-label"
        >
          <NavLink
            className={classnames({ active: activeTab === String(index) })}
            onClick={(): void => {
              toggleTab(String(index));
            }}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setFormValues((current: CodeSigningProfile) => ({
      ...current,
      [property]: value,
    }));
  };

  const prevCodeSignKeyUuid = usePreviousString(
    String(codeSigningKeyUuid),
    codeSigningKeyUuid
  );

  const prevSigningAlgorithm = usePreviousString(
    String(signingAlgorithm),
    signingAlgorithm
  );

  useEffect(() => {
    const getCertificateProfile = async () => {
      const certificateProfileUuid = currentCodeSigningKey?.value
        .certificateProfileUuid as string;
      try {
        const certApiId = certificateProfileUuid.replaceAll('-', '');
        const { data: certificateProfiles } = await api().get(
          `certificate/profile`,
          {
            params: {
              filter: `certificate_profile_id=${certApiId}`,
            },
          }
        );
        const certificateProfile =
          certificateProfiles.length > 0 ? certificateProfiles[0] : null;
        setApproversRequired(
          certificateProfile ? certificateProfile?.approvers_required : false
        );
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };
    getCertificateProfile();
  }, []);

  useEffect(() => {
    if (codeSigningKeyUuid) {
      const fetchData = async () => {
        const response = await api()
          .get(`code-signing/key/${codeSigningKeyUuid}`)
          .then((request) => request.data);
        setCodeSigningKey(deserializeCodeSigningKeyRequest(response));
      };
      fetchData();
    }
  }, [codeSigningKeyUuid]);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  useEffect(() => {
    if (codeSigningKeyUuid) {
      dispatch(getCodeSigningProfileKeySpecs(codeSigningKeyUuid));
    }
  }, [codeSigningKeyUuid, dispatch, readOnly]);

  useEffect(() => {
    if (!readOnly && prevCodeSignKeyUuid !== codeSigningKeyUuid) {
      const justRaw =
        signingAlgorithm === signatureAlgorithmNoDigestInfoKey ||
        signingAlgorithm === signatureAlgorithmNoPadding ||
        signingAlgorithm === signatureAlgorithmPss;
      const firstOutputFormat =
        codeSigningProfileKeySpecs?.outputFormat[0]?.key;
      setProfileFormProperty({
        value: justRaw ? 'raw' : firstOutputFormat,
        property: 'outputFormat',
      });
      setProfileFormProperty({
        value: codeSigningProfileKeySpecs?.signatureAlgorithm[0]?.key,
        property: 'signingAlgorithm',
      });
      setProfileFormProperty({
        value: codeSigningProfileKeySpecs?.signatureHashAlgorithm[0]?.key,
        property: 'signingHashAlgorithm',
      });
    }
  }, [
    codeSigningKeyUuid,
    codeSigningProfileKeySpecs,
    prevCodeSignKeyUuid,
    readOnly,
  ]);

  useEffect(() => {
    if (prevSigningAlgorithm !== signingAlgorithm) {
      if (signingAlgorithm === signatureAlgorithmNoDigestInfoKey) {
        setProfileFormProperty({
          property: 'outputFormat',
          value: outputFormatOptionsForNoDigestInfo[0].key,
        });
        setProfileFormProperty({
          value: codeSigningProfileKeySpecs?.signatureHashAlgorithm[0]?.key,
          property: 'signingHashAlgorithm',
        });
      } else if (signingAlgorithm === signatureAlgorithmPss) {
        setProfileFormProperty({
          property: 'outputFormat',
          value: outputFormatOptionsForPss[0].key,
        });
        setProfileFormProperty({
          value: codeSigningProfileKeySpecs?.signatureHashAlgorithm[0]?.key,
          property: 'signingHashAlgorithm',
        });
      } else if (signingAlgorithm === signatureAlgorithmNoPadding) {
        setProfileFormProperty({
          property: 'signingHashAlgorithm',
          value: undefined,
        });
        setProfileFormProperty({
          property: 'outputFormat',
          value: outputFormatOptionsForNoPadding[0].key,
        });
      } else {
        setProfileFormProperty({
          value: codeSigningProfileKeySpecs?.signatureHashAlgorithm[0]?.key,
          property: 'signingHashAlgorithm',
        });
      }
    }
  }, [signingAlgorithm, codeSigningProfileKeySpecs, prevSigningAlgorithm]);

  const isEditable = _.includes(
    (defaultValues as CodeSigningProfile).actions,
    'update'
  );

  const isValid =
    !_.chain(name).trim().isEmpty().value() && !isNil(currentCodeSigningKey);

  const onSelectCodeSigning = (key: CodeSigningCertificateRequest): void => {
    const valueToSet = {
      value: {
        codeSigningKeyUuid: String(key.uuid),
        certificateProfileUuid: String(key.certificateProfileUuid),
        certificateProfileCN: String(key.certificateCN),
      },
      label: `${key.certificateCN} (Request ID: ${String(key.uuid)})`,
    };
    setProfileFormProperty({
      property: 'codeSigningKeyUuid',
      value: _.get(valueToSet, 'value.codeSigningKeyUuid'),
    });
  };

  return (
    <div id="certificate-request-form" className="CodeSigningRequestForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3 className="text-muted">Code Signing Profile</h3>
          <span
            onClick={(): void => {
              onChangeMode();
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {renderNav()}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <Label className="pki-label">Name</Label>
                  <Input
                    value={name}
                    readOnly={readOnly}
                    plaintext={readOnly}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      setProfileFormProperty({
                        property: 'name',
                        value: event.target.value,
                      });
                    }}
                    name="name"
                    id="name"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label className="pki-label" for="profileId">
                    REST API ID
                  </Label>
                  <FontAwesomeIcon
                    id="profileId-hint"
                    icon={faInfoCircle}
                    className="pki-ico ml-1"
                  />
                  <UncontrolledPopover trigger="hover" target="profileId-hint">
                    <div className="p-2">
                      <small>
                        You can specify the sign_profile_id to be used in the
                        REST API.
                        <br />
                        If not specified, an identifier (uuid) will be
                        generated.
                      </small>
                    </div>
                  </UncontrolledPopover>
                  <Input
                    id="profileId"
                    value={codeSigningProfileId}
                    readOnly={readOnly}
                    plaintext={readOnly}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      const value = _.isEmpty(event.target.value)
                        ? undefined
                        : event.target.value;
                      setProfileFormProperty({
                        property: 'codeSigningProfileId',
                        value,
                      });
                    }}
                    type="text"
                    name="profileId"
                    placeholder="(Optional) sign_profile_id to be used in the REST API"
                  />
                  <FormFeedback>
                    Only <code>{`A-Za-z0-9-_.!~*'()`}</code> characters allowed
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Label className="pki-label">Notes</Label>
            <FormGroup>
              <Input
                value={_.isEmpty(notes) && readOnly ? 'N/A' : notes || ''}
                readOnly={readOnly}
                plaintext={readOnly}
                type="textarea"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  setProfileFormProperty({
                    property: 'notes',
                    value: event.target.value,
                  });
                }}
                name="notes"
                id="notes"
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="pki-label">
                    Code Signing Key{' '}
                    {readOnly && currentCodeSigningKey !== undefined && (
                      <FontAwesomeIcon
                        onClick={(): void => {
                          const pathname = `/operations/code-signing-certificate-requests`;
                          history.push({
                            pathname,
                            search: `?uuid=${currentCodeSigningKey.value.codeSigningKeyUuid}`,
                          });
                        }}
                        id="related-code-sign-key"
                        className="ml-1 pki-ico"
                        icon={faExternalLink}
                      />
                    )}
                  </Label>
                  {readOnly && currentCodeSigningKey !== undefined && (
                    <UncontrolledPopover
                      trigger="hover"
                      target="related-code-sign-key"
                    >
                      <div className="p-1">
                        <small>Related Code Signing Key</small>
                      </div>
                    </UncontrolledPopover>
                  )}
                  {readOnly && (
                    <Input
                      value={
                        currentCodeSigningKey
                          ? currentCodeSigningKey?.label
                          : 'Loading...'
                      }
                      readOnly
                      plaintext
                      name="code-signing-key"
                      id="code-signing-key"
                    />
                  )}
                  {!readOnly && (
                    <ServerSideSelect
                      value={
                        currentCodeSigningKey
                          ? currentCodeSigningKey?.label
                          : 'Select an option'
                      }
                      searchPlaceholder={'Search Common Name'}
                      defaultFilters={'status=completed'}
                      fetchUrl={fetchCodeSigningKeysUrl}
                      onSelectEntity={onSelectCodeSigning}
                      pageUrlParam={'page'}
                      searchParam={'certificate.cn'}
                      pageSize={50}
                      searchOperator={'~'}
                      formatter={formatCodeSigningKey}
                      isParentLoading={false}
                      error={
                        isNil(currentCodeSigningKey) && highlightMandatoryFields
                          ? 'Cannot be empty, please select one!'
                          : undefined
                      }
                    />
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Select
                    id="signing-algorithm"
                    label={
                      <div className="d-flex">
                        Signature Algorithm{' '}
                        {isGettingCodeSignProfileKeySpecs && (
                          <Spinner
                            className="ml-1 pki-ico"
                            style={{
                              width: '11px',
                              height: '11px',
                              marginBottom: '2px',
                            }}
                          />
                        )}
                      </div>
                    }
                    selectedKey={signingAlgorithm}
                    readOnly={readOnly}
                    onChange={({ key }: { key: string }): void => {
                      setProfileFormProperty({
                        property: 'signingAlgorithm',
                        value: key,
                      });
                    }}
                    options={
                      codeSigningProfileKeySpecs?.signatureAlgorithm || []
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <div className="d-flex">
                    <Label className="pki-label">
                      Signature Hash Algorithm{' '}
                    </Label>
                    {isGettingCodeSignProfileKeySpecs && (
                      <Spinner
                        className="ml-1 pki-ico"
                        style={{
                          width: '11px',
                          height: '11px',
                          marginBottom: '2px',
                        }}
                      />
                    )}
                  </div>
                  {readOnly ? (
                    <Input
                      value={
                        signingAlgorithm === signatureAlgorithmNoPadding
                          ? 'N/A'
                          : find(
                              codeSigningProfileKeySpecs?.signatureHashAlgorithm,
                              (item) => item.key === signingHashAlgorithm
                            )?.value ?? 'N/A'
                      }
                      readOnly
                      plaintext
                      name="signing-hash-algorithm"
                      id="signing-hash-algorithm"
                    />
                  ) : (
                    <SmartSelect
                      id="signing-hash-algorithm"
                      isSearchable={true}
                      isDisabled={
                        signingAlgorithm === signatureAlgorithmNoPadding ||
                        readOnly
                      }
                      value={{
                        value: find(
                          codeSigningProfileKeySpecs?.signatureHashAlgorithm,
                          (item) => item.key === signingHashAlgorithm
                        )?.key,
                        label:
                          signingAlgorithm === signatureAlgorithmNoPadding
                            ? 'N/A'
                            : find(
                                codeSigningProfileKeySpecs?.signatureHashAlgorithm,
                                (item) => item.key === signingHashAlgorithm
                              )?.value,
                      }}
                      options={_.map(
                        codeSigningProfileKeySpecs?.signatureHashAlgorithm,
                        (item) => ({
                          value: item.key,
                          label: item.value,
                        })
                      )}
                      onChange={(selectedValue): void => {
                        setProfileFormProperty({
                          property: 'signingHashAlgorithm',
                          value: selectedValue?.value,
                        });
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Select
                    id="output-format"
                    selectedKey={outputFormat}
                    readOnly={readOnly}
                    onChange={({ key }: { key: string }): void => {
                      setProfileFormProperty({
                        property: 'outputFormat',
                        value: key,
                      });
                    }}
                    options={
                      signingAlgorithm === signatureAlgorithmNoDigestInfoKey ||
                      signingAlgorithm === signatureAlgorithmNoPadding ||
                      signingAlgorithm === signatureAlgorithmPss
                        ? outputFormatOptionsForNoDigestInfo
                        : codeSigningProfileKeySpecs?.outputFormat
                    }
                    label={
                      <div className="d-flex">
                        Output Format{' '}
                        {isGettingCodeSignProfileKeySpecs && (
                          <Spinner
                            className="ml-1 pki-ico"
                            style={{
                              width: '11px',
                              height: '11px',
                              marginBottom: '2px',
                            }}
                          />
                        )}
                      </div>
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="1">
            <ApprovalPolicyApprover
              id="approval-policy"
              approversRequired={approversRequired}
              notification={approvalPolicy.notification}
              certificateProfileUuid={
                currentCodeSigningKey?.value.certificateProfileUuid
              }
              onChange={(
                approverUuids: string[],
                notification: boolean
              ): void => {
                setProfileFormProperty({
                  property: 'approvalPolicy',
                  value: { approverUuids, notification },
                });
              }}
              readOnly={readOnly}
              approversIdList={approvalPolicy.approverUuids || []}
            />
          </TabPane>
        </TabContent>
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-button"
                outline
                disabled={false}
                onClick={(): void => {
                  if (
                    approversRequired &&
                    approvalPolicy.approverUuids.length === 0
                  ) {
                    setActiveTab('1');
                    sendNotification({
                      text: 'Please select at least one Approval Policy User',
                      success: false,
                    })(dispatch);
                    setHighlightMandatoryFields(true);
                    return;
                  }
                  onSubmit({ values: formValues, isValid });
                  if (!isValid) {
                    setHighlightMandatoryFields(true);
                  }
                }}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeSigningProfileForm;
