import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Col, Collapse, Modal, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationForm, Select, Table } from '../../../components';
import { sendNotification } from '../../../store/notifications/actions';
import { ProductProfile } from '../types';
import './ProvisioningProfiles.scss';
import { getDemoDummyData } from '../helpers/getDemoDummyData';
import { ProvisioningProfile } from '../../../store/manufacturingStations/types';
import ProvisioningProfilesList from '../../ManufacturingStations/ProvisioningProfiles/ProvisioningProfilesList';
import {
  CertificateProfile,
  CertificateProfilesState,
} from '../../../store/certificateProfiles/types';
import { ApplicationState } from '../../../store';

interface Props {
  onDeleteProvisioningProfile: Function;
  onCreateProvisioningProfile: Function;
  manufacturingStationUuid: string;
  provisioningProfiles: ProvisioningProfile[];
}

const ProvisioningProfiles: FC<Props> = ({
  onDeleteProvisioningProfile = (): null => null,
  provisioningProfiles,
  manufacturingStationUuid,
  onCreateProvisioningProfile,
}) => {
  const { certificateProfileList } = useSelector<
    ApplicationState,
    CertificateProfilesState
  >((pki) => ({
    ...pki.certificateProfiles,
  }));

  const [showSpinner, setShowSpinner] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [certificateProfiles, setCertificateProfiles] = useState<
    CertificateProfile[]
  >([]);
  const [activeProfiles, setActiveProfiles] = useState<string[]>([
    '738bc691-bb5b-4742-ae9f-849dd5567115',
  ]);
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [profileToAdd, setProfileToAdd] = useState<undefined | string>(
    undefined
  );
  const [onClickDeleteConfirm, setOnClickDeleteConfirm] = useState<
    Function | undefined
  >(undefined);

  const [profileType, setProfileType] = useState<undefined | string>(
    'product_profile'
  );

  const dispatch = useDispatch();

  const selectableProfiles = () => {
    return certificateProfiles.filter(
      (profile) => !activeProfiles.includes(`${profile.uuid}`)
    );
  };

  const selectableProfilesOptions = () => {
    if (selectableProfiles().length === 0) {
      return [
        {
          key: '0',
          value: 'No More Product Profiles To Select',
        },
      ];
    }
    return selectableProfiles().map((profile) => {
      return {
        key: profile.uuid,
        value: profile.name,
      };
    });
  };

  const profilesToShow = () => {
    return certificateProfiles.filter((profile) =>
      activeProfiles.includes(`${profile.uuid}`)
    );
  };

  useEffect(() => {
    if (selectableProfiles().length > 0) {
      // eslint-disable-next-line no-console
      setProfileToAdd(selectableProfiles()[0]?.uuid);
    }
  }, [certificateProfiles]);

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'NoData1',
      text: 'Type',
      formatter: () => 'Product Profiles',
    },
    {
      dataField: 'noData2',
      text: 'Actions',
      sort: false,
      formatter: (
        valueNotImportant: null,
        currentProfile: ProductProfile
      ): ReactNode => {
        return (
          <>
            <FontAwesomeIcon
              className="pki-ico"
              icon={faTrash}
              onClick={(event) => {
                event.stopPropagation();
                setOnClickDeleteConfirm(() => {
                  return () => {
                    onDeleteProfile(currentProfile.uuid);
                  };
                });
              }}
            />
          </>
        );
      },
    },
  ];

  const fetchData = async () => {
    setCertificateProfiles([]);
    setShowContent(false);
    setShowSpinner(true);
    try {
      setCertificateProfiles(certificateProfileList);
      setProfileType('certificate_profile');
      setShowContent(true);
    } catch (e) {
      sendNotification({
        text: 'Failed to fetch Product Profiles',
        success: false,
      })(dispatch);
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onAddClick = () => {
    if (profileToAdd) {
      const newActiveProfiles = [...activeProfiles, profileToAdd];
      setActiveProfiles(() => {
        const profilesToSelect = certificateProfiles.filter(
          (profile) => !newActiveProfiles.includes(`${profile.uuid}`)
        );
        if (profilesToSelect.length > 0) {
          // eslint-disable-next-line no-console
          setProfileToAdd(profilesToSelect[0]?.uuid);
        } else {
          setProfileToAdd(undefined);
        }
        // eslint-disable-next-line no-console
        return newActiveProfiles;
      });
    }
  };

  const onDeleteProfile = (id: string) => {
    setActiveProfiles((prevState) => {
      const newActiveProfiles = prevState.filter(
        (profileUUID) => profileUUID !== id
      );
      setOnClickDeleteConfirm(undefined);
      return newActiveProfiles;
    });
  };

  useEffect(() => {
    if (selectableProfiles().length > 0 && !profileToAdd) {
      setProfileToAdd(selectableProfiles()[0].uuid);
    }
  }, [activeProfiles]);

  return (
    <div className="demo-product-profiles-provisioning">
      <Collapse isOpen={showContent}>
        <Row className="pt-3">
          <Col md={2} />
        </Row>
        {profileType === 'product_profile' ? (
          <>
            <Row className="pt-3 justify-content-between">
              <Col md={3} />
              <Col md={1}>
                <label className="pki-label d-block">&nbsp;</label>
                <Button
                  id="add-demo-provisioning-profile"
                  disabled={profileToAdd === undefined}
                  outline
                  onClick={onAddClick}
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Table
                  classes="demo-provisioning-profiles-table"
                  search={false}
                  keyField="uuid"
                  noDataIndication={
                    tablePage > 1
                      ? 'No more Product Profiles available'
                      : 'No Product Profiles available'
                  }
                  remote={false}
                  pagination={{
                    page: tablePage,
                    sizePerPage: tableSizePerPage,
                  }}
                  onTableChange={(
                    valueNotUsed: null,
                    { page, sizePerPage }: { page: number; sizePerPage: number }
                  ): void => {
                    setTablePage(page);
                    setTableSizePerPage(sizePerPage);
                  }}
                  data={profilesToShow()}
                  columns={columns}
                />
              </Col>
            </Row>
            <p className="text-muted mt-5">
              * Because this is for demonstration purposes, all the above
              content is temporary. If you switch or refresh the page, then
              content will be reset to default values.
            </p>
          </>
        ) : (
          <ProvisioningProfilesList
            manufacturingStationUuid={manufacturingStationUuid}
            onDeleteProvisioningProfile={onDeleteProvisioningProfile}
            provisioningProfiles={provisioningProfiles}
            onCreateProvisioningProfile={onCreateProvisioningProfile}
          />
        )}
      </Collapse>
      {onClickDeleteConfirm !== undefined && (
        <Modal
          className="PKIApp"
          isOpen={true}
          style={{ marginTop: '10%' }}
          toggle={() => setOnClickDeleteConfirm(undefined)}
        >
          <ConfirmationForm
            title="Remove Product Profile"
            content={
              <div className="text-center">
                Are you sure you want to delete this product profile?
              </div>
            }
            onCancel={() => setOnClickDeleteConfirm(undefined)}
            onConfirm={onClickDeleteConfirm}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProvisioningProfiles;
