import { certificateProfileDefaultValues } from '../CertificateProfiles/constants';
import { RequestType } from '../../store/requests/types';

export const defaultValuesRequest = {
  notes: '',
  uuid: '',
  createdBy: '',
  updatedBy: '',
  requestorUuid: '',
  issuedAt: '',
  updatedAt: '',
};

export const defaultValuesCertificateRequest = {
  ...defaultValuesRequest,
  type: RequestType.Certificate,
  issuingCAUuid: '',
  certificateProfile: { ...certificateProfileDefaultValues },
  csr: '',
  csrFilename: '',
};

export const defaultValuesCodeSigningRequest = {
  ...defaultValuesRequest,
  type: RequestType.CodeSigning,
  digest: '',
  codeSigningProfileUuid: '',
};

export const defaultValuesCodeSigningKeyRequest = {
  ...defaultValuesRequest,
  type: RequestType.CodeSigningCertificate,
  certificateProfileUuid: '',
  issuingCAUuid: '',
};
