import _ from 'lodash';
import axios from 'axios';
import React, {
  useState,
  FC,
  ReactNode,
  useEffect,
  MouseEvent,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, Button, Card, UncontrolledPopover, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faPlusCircle,
  faMinusCircle,
  faEdit,
  faClone,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CertificateTemplateForm from './CertificateTemplateForm';
import {
  TableActions,
  Spinner,
  Table,
  ConfirmationForm,
  ErrorHandler,
  FilterBar,
} from '../../components';
import {
  CertificateTemplatesState,
  CertificateTemplate,
} from '../../store/certificateTemplates/types';
import {
  getCertificateTemplates,
  createCertificateTemplate,
  editCertificateTemplate,
  deleteCertificateTemplates,
} from '../../store/certificateTemplates/actions';
import { sendNotification } from '../../store/notifications/actions';
import { UsersState } from '../../store/users/types';
import { ApplicationState } from '../../store';
import { dateFormatter } from '../../libs/helpers';

import { defaultState } from './constants';
import { Filter } from '../../components/FilterBar/types';

interface CertificateTemplatesToolbarProps {
  onCreateTemplateClick?: Function;
  onBulkDeleteClick?: Function;
  bulkDeleteDisabled?: boolean;
  actions: string[];
}

const CertificateTemplatesToolbar: FC<CertificateTemplatesToolbarProps> = ({
  onCreateTemplateClick = (): null => null,
  onBulkDeleteClick = (): null => null,
  bulkDeleteDisabled = true,
  actions = [],
}) => {
  return (
    <div className="CertificateTemplatesToolbar d-flex">
      <span className="mx-2">
        <Button
          id="add-template-button"
          outline
          size="sm"
          disabled={!_.includes(actions, 'create')}
          onClick={(): void => {
            onCreateTemplateClick();
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Certificate Template{' '}
        </Button>
      </span>
      <span className="mr-2">
        <Button
          id="bulk-delete-button"
          disabled={bulkDeleteDisabled || !_.includes(actions, 'delete')}
          outline
          size="sm"
          onClick={(): void => {
            onBulkDeleteClick();
          }}
        >
          <FontAwesomeIcon icon={faMinusCircle} /> Bulk Delete{' '}
        </Button>
      </span>
    </div>
  );
};

const CertificateTemplates: FC = () => {
  const {
    isGettingCertificateTemplateList,
    isLoadedCertificateTemplateList,
    isCreatingTemplate,
    isDeletingTemplate,
    isEditingTemplate,
    certificateTemplateList,
    certificateTemplatesErrors,
    userProfile: { resources },
  } = useSelector<ApplicationState, CertificateTemplatesState & UsersState>(
    (pki) => ({
      ...pki.certificateTemplates,
      ...pki.users,
    })
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const [formModal, setFormModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [formReadOnly, setFormReadOnly] = useState<boolean>(false);
  const [isCloning, setIsCloning] = useState<boolean>(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);
  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [
    currentCertificateTemplate,
    setCurrentCertificateTemplate,
  ] = useState<CertificateTemplate>(defaultState);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);

  const params = new URLSearchParams(location.search);
  const certificateTemplateUuidParam = params.get('uuid');
  const initialQuery: Filter[] = useMemo(
    () =>
      certificateTemplateUuidParam
        ? [
            {
              key: 'uuid',
              label: 'UUID',
              operator: '=',
              type: 'filter',
              value: certificateTemplateUuidParam,
            },
          ]
        : [],
    [certificateTemplateUuidParam]
  );
  const [query, setQuery] = useState<Filter[]>(initialQuery);

  const isLoadingData =
    isCreatingTemplate ||
    isEditingTemplate ||
    isDeletingTemplate ||
    isGettingCertificateTemplateList;

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }
    if (isLoadedCertificateTemplateList || certificateTemplatesErrors) {
      setShowContent(true);
      setShowSpinner(false);
    }
    return function cleanup(): void {
      setShowContent(false);
    };
  }, [
    certificateTemplatesErrors,
    isLoadedCertificateTemplateList,
    isLoadingData,
  ]);

  useEffect(() => {
    const isPerformingAction =
      isCreatingTemplate || isEditingTemplate || isDeletingTemplate;
    const tokenSource = axios.CancelToken.source();
    if (!isPerformingAction) {
      dispatch(
        getCertificateTemplates({
          tokenSource,
          sizePerPage: tableSizePerPage,
          query,
          page: tablePage,
          sortBy: 'date_updated',
          sortDir: 'desc',
        })
      );
    }
    return function cleanup(): void {
      tokenSource.cancel('CertificateTemplates::getCertificateTemplates');
    };
  }, [
    dispatch,
    isCreatingTemplate,
    isDeletingTemplate,
    isEditingTemplate,
    tablePage,
    query,
    tableSizePerPage,
  ]);

  const toggleFormModal = (): void => {
    setFormModal((previousValue) => {
      if (previousValue) {
        setCurrentCertificateTemplate(defaultState);
      }
      return !previousValue;
    });
  };
  const toggleDeleteModal = (): void => {
    setDeleteModal((previousValue) => {
      if (previousValue) {
        setCurrentCertificateTemplate(defaultState);
      }
      return !previousValue;
    });
  };

  const toggleMode = (): void => {
    setFormReadOnly((prev) => !prev);
  };

  const certificateTemplateResource = _.find(
    resources,
    (item) => item.name === 'certificate_template'
  );

  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (
        name: string,
        { description, uuid }: { uuid: string; description: string }
      ): ReactNode => {
        return (
          <>
            <div>{name}</div>
            {!_.isEmpty(description) && (
              <div
                id={`template-${uuid}`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small> {description}</small>
                <UncontrolledPopover
                  target={`template-${uuid}`}
                  trigger="hover"
                >
                  <div className="p-2">
                    <small>{description}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (date: number): string => dateFormatter(date),
    },
    {
      dataField: 'createdBy',
      text: 'Created by',
      formatter: (
        createdBy: string,
        current: CertificateTemplate
      ): ReactNode => {
        const isCreatedByUserDeleted = _.get(current, 'isCreatedByUserDeleted');
        if (isCreatedByUserDeleted) {
          return (
            <span title="User is deleted">
              <del>{createdBy}</del>
            </span>
          );
        }
        return createdBy;
      },
    },
    {
      dataField: 'uuid',
      text: 'Actions',
      formatter: (
        uuid: string,
        { actions }: { actions: string[] }
      ): ReactNode => {
        const canUpdate = _.includes(actions, 'update');
        const canClone = _.includes(actions, 'create');
        const canDelete = _.includes(actions, 'delete');
        return (
          <TableActions
            rowId={String(uuid)}
            options={[
              {
                label: 'Edit',
                disabled: !canUpdate,
                ico: <FontAwesomeIcon className="pki-ico" icon={faEdit} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canUpdate) {
                    const selected = _.find(
                      certificateTemplateList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCertificateTemplate(selected);
                      setFormReadOnly(false);
                      toggleFormModal();
                    }
                  }
                },
              },
              {
                label: 'Clone',
                disabled: !canClone,
                ico: <FontAwesomeIcon className="pki-ico" icon={faClone} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canClone) {
                    const selected = _.find(
                      certificateTemplateList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCertificateTemplate({
                        ...selected,
                        uuid: '',
                        profileId: '',
                        name: '',
                      });
                      setIsCloning(true);
                      setFormReadOnly(false);
                      toggleFormModal();
                    }
                  }
                },
              },
              {
                label: 'Delete',
                disabled: !canDelete,
                ico: <FontAwesomeIcon className="pki-ico" icon={faTrash} />,
                onClick: (e: MouseEvent): void => {
                  e.stopPropagation();
                  if (canDelete) {
                    const selected = _.find(
                      certificateTemplateList,
                      (item) => item.uuid === uuid
                    );
                    if (selected) {
                      setCurrentCertificateTemplate(selected);
                      toggleDeleteModal();
                    }
                  }
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div className="CertificateTemplates">
      <Card className="rounded p-5">
        <div className="header-contanier d-flex">
          <h3 className="text-muted">Certificate Templates</h3>
          {showSpinner && (
            <Spinner className="mt-2 ml-2" size="sm" type="border" />
          )}
        </div>
        <Collapse isOpen={showContent}>
          <div className="mt-5">
            <FilterBar
              filters={[
                {
                  key: 'name',
                  label: 'Name',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'notes',
                  label: 'Description',
                  type: 'like',
                  operators: ['~'],
                },
                {
                  key: 'created_by_user_uuid',
                  label: 'Created By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    fetchUrl: '/user',
                    searchPlaceholder: 'Type username...',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'created_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'updated_by_user_uuid',
                  label: 'Updated By',
                  type: 'filter',
                  placeholder: 'Select a user',
                  serverSideConfig: {
                    searchParam: 'username',
                    searchPlaceholder: 'Type username...',
                    fetchUrl: '/user',
                    formatter: (user: any) =>
                      `${user?.username} ${
                        user?.email && `(${user?.email})`
                      }` || 'N/A',
                    id: 'updated_by_user_uuid',
                  },
                  serverSide: true,
                },
                {
                  key: 'date_created',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Created Date',
                  type: 'filter',
                },
                {
                  key: 'date_updated',
                  operators: ['=', '>', '<'],
                  suggestion: 'Date must be filled in YYYY-MM-DD format',
                  label: 'Modified Date',
                  type: 'filter',
                },
              ]}
              initialFilters={initialQuery}
              onFiltersChange={(newFilters: Filter[]): void => {
                if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                  setQuery(() => {
                    setTablePage(1);
                    return newFilters;
                  });
                }
              }}
            />
          </div>
          <div className="mt-5">
            {certificateTemplatesErrors ? (
              <ErrorHandler />
            ) : (
              <Table
                remote={true}
                search={false}
                keyField="uuid"
                sort={{ dataField: 'date_updated', order: 'desc' }}
                pagination={{
                  page: tablePage,
                  sizePerPage: tableSizePerPage,
                }}
                rowStyle={(
                  certificateTemplateData: CertificateTemplate
                ): { backgroundColor: string } | null => {
                  if (
                    certificateTemplateData.uuid?.toLocaleLowerCase() ===
                    certificateTemplateUuidParam?.toLocaleLowerCase()
                  ) {
                    return { backgroundColor: 'rgba(0, 164, 224, 0.32)' };
                  }
                  return null;
                }}
                noDataIndication="No Certificates Templates"
                toolbar={
                  <CertificateTemplatesToolbar
                    actions={_.get(certificateTemplateResource, 'actions', [])}
                    bulkDeleteDisabled={_.isEmpty(selectedRowsIds)}
                    onCreateTemplateClick={(): void => {
                      toggleFormModal();
                      setFormReadOnly(false);
                    }}
                    onBulkDeleteClick={toggleDeleteModal}
                  />
                }
                selectRow={{
                  mode: 'checkbox',
                  clickToSelect: false,
                  onSelect: (
                    { uuid }: { uuid: string },
                    isSelected: boolean
                  ): void => {
                    if (isSelected) {
                      setSelectedRowsIds((current) => [...current, uuid]);
                    } else {
                      setSelectedRowsIds((current) =>
                        _.filter(current, (item) => item !== uuid)
                      );
                    }
                  },
                  onSelectAll: (
                    isSelected: boolean,
                    rows: { uuid: string }[]
                  ): void => {
                    if (isSelected) {
                      setSelectedRowsIds(_.map(rows, (item) => item.uuid));
                    } else {
                      setSelectedRowsIds([]);
                    }
                  },
                }}
                data={certificateTemplateList}
                columns={columns}
                rowEvents={{
                  onClick: (
                    notUsedValue: null,
                    current: CertificateTemplate
                  ): void => {
                    setCurrentCertificateTemplate(current);
                    setFormReadOnly(true);
                    setFormModal(true);
                  },
                }}
                onTableChange={(
                  valueNotUsed: null,
                  {
                    page,
                    sizePerPage,
                  }: {
                    page: number;
                    sizePerPage: number;
                  }
                ): void => {
                  setTablePage(page);
                  setTableSizePerPage(sizePerPage);
                }}
              />
            )}
          </div>
        </Collapse>
      </Card>
      <Modal
        className="PKIApp"
        style={{ width: '1200px', maxWidth: '4000px' }}
        isOpen={formModal}
        toggle={toggleFormModal}
      >
        <CertificateTemplateForm
          readOnly={formReadOnly}
          onCancel={toggleFormModal}
          onModeChange={toggleMode}
          defaultValues={currentCertificateTemplate}
          onSubmit={({
            values,
            isValid,
          }: {
            values: CertificateTemplate;
            isValid: boolean;
          }): void => {
            if (isValid) {
              if (!_.isNil(values.uuid) && !isCloning) {
                dispatch(editCertificateTemplate(values));
              } else {
                dispatch(createCertificateTemplate(values));
                setIsCloning(false);
              }
              toggleFormModal();
            } else {
              dispatch(
                sendNotification({
                  text: 'Some fields are mandatory, please fill them all.',
                  success: false,
                })
              );
            }
          }}
        />
      </Modal>
      <Modal className="PKIApp" isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ConfirmationForm
          title="Delete Confirmation"
          content={
            <div className="text-center">
              <p>
                You are about to <strong>delete</strong> the selected
                Template/s.
              </p>
            </div>
          }
          onCancel={toggleDeleteModal}
          onConfirm={(): void => {
            let ids;
            if (currentCertificateTemplate.uuid) {
              ids = [currentCertificateTemplate.uuid];
            } else {
              ids = selectedRowsIds;
            }
            dispatch(deleteCertificateTemplates(ids));
            toggleDeleteModal();
          }}
        />
      </Modal>
    </div>
  );
};

export default CertificateTemplates;
