/* eslint-disable @typescript-eslint/no-explicit-any */
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { CodeSigningProfile } from './types';

export const serializeCodeSigningProfile = (
  codeSigningProfile: CodeSigningProfile
): any => {
  const output = {
    code_signing_key_uuid: codeSigningProfile.codeSigningKeyUuid,
    signature_algorithm: codeSigningProfile.signingAlgorithm,
    signature_hash_algorithm: codeSigningProfile.signingHashAlgorithm,
    name: codeSigningProfile.name,
    code_signing_profile_id: isEmpty(codeSigningProfile.codeSigningProfileId)
      ? undefined
      : codeSigningProfile.codeSigningProfileId,
    output_format: codeSigningProfile.outputFormat,
    approval_policy: {
      approver_uuids: codeSigningProfile.approvalPolicy.approverUuids || [],
      notification: codeSigningProfile.approvalPolicy.notification,
    },
    notes: codeSigningProfile.notes || '',
  };
  return output;
};

export const deserializeCodeSigningProfileKeySpecs = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  raw: any
): {
  outputFormat: { key: string; value: string }[];
  signatureAlgorithm: { key: string; value: string }[];
  signatureHashAlgorithm: { key: string; value: string }[];
} => ({
  outputFormat: raw.output_format,
  signatureAlgorithm: raw.signature_algorithm,
  signatureHashAlgorithm: raw.signature_hash_algorithm,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deserializeCodeSigningProfile = (raw: any): CodeSigningProfile => {
  const createdBy = raw.created_by_user?.email || raw.created_by_user?.username;
  const isCreatedByUserDeleted = raw.created_by_user?.deleted;
  const updatedBy = raw.updated_by_user?.email || createdBy;

  const output = {
    uuid: raw.uuid,
    actions: raw.actions,
    createdBy,
    isCreatedByUserDeleted,
    updatedBy,
    issuedAt: moment(_.get(raw, 'date_created')).format('x'),
    updatedAt: moment(_.get(raw, 'date_updated') || _.get(raw, 'date_created')),
    name: raw.name,
    notes: raw.notes,
    codeSigningProfileId: raw.code_signing_profile_id,
    issuingCaUuid: raw.issuing_ca_uuid,
    codeSigningKeyUuid: raw.code_signing_key_uuid,
    certificateUuid: raw.certificate_uuid,
    outputFormat: raw.output_format === 'pkcsv15' ? 'raw' : raw.output_format,
    signingAlgorithm: raw.signature_algorithm,
    signingHashAlgorithm: raw.signature_hash_algorithm,
    approvalPolicy: {
      approverUuids: raw.approval_policy.approver_uuids || [],
      notification: raw.approval_policy.notification || false,
    },
    certificateProfileUuid: raw.certificate_profile_uuid,
  };
  return output;
};
