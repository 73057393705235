import _, { isEmpty, kebabCase } from 'lodash';
import React, { useEffect, useState, FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { ApplicationState } from '../../store';
import { Spinner } from '../../components';
import {
  ManufacturingStation as ManufacturingStationType,
  ManufacturingStationsState,
  ProvisioningProfile,
} from '../../store/manufacturingStations/types';
import {
  getManufacturingStation,
  createManufacturingStation,
  editManufacturingStation,
  createProvisioningProfile,
} from '../../store/manufacturingStations/actions';
import { UsersState } from '../../store/users/types';
import { sendNotification } from '../../store/notifications/actions';
import ManufacturingStationInfo from './ManufacturingStationInfo';
import ProvisioningProfilesList from './ProvisioningProfiles/ProvisioningProfilesList';
import SecureTokensList from './SecureTokens/SecureTokensList';
import ProvisioningProfiles from '../ProductProfiles/ProvisioningProfiles/ProvisioningProfiles';
import { api, shouldShowDemoContent } from '../../libs/helpers';
import { deserializeProvisioningProfile } from '../../store/manufacturingStations/helpers';

const ManufacturingStationTabs = {
  MANUFACTURING_STATION: '0',
  PROVISIONING_PROFILES: '1',
  SECURE_TOKENS: '2',
};

interface Props {
  tab: number;
}

export const ManufacturingStation: FC<Props> = ({ tab }) => {
  const {
    isGettingManufacturingStation,
    isLoadedManufacturingStation,
    manufacturingStationErrors,
    manufacturingStation,
    isCreatingManufacturingStation,
    isEditingManufacturingStation,
    userProfile: { resources },
  } = useSelector<ApplicationState, ManufacturingStationsState & UsersState>(
    (pki) => ({
      ...pki.manufacturingStations,
      ...pki.users,
    })
  );

  const dispatch = useDispatch();
  const { state } = useLocation() as Location<{
    manufacturingStation: ManufacturingStationType;
  }>;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [
    fetchingProvisioningProfiles,
    setFetchingProvisioningProfiles,
  ] = useState(true);
  const [
    currentManufacturingStation,
    setCurrentManufacturingStation,
  ] = useState<ManufacturingStationType | undefined>(
    state?.manufacturingStation
  );

  const [provisioningProfiles, setProvisioningProfiles] = useState<
    ProvisioningProfile[]
  >();

  const fetchProvisioningProfiles = async (force = false) => {
    setFetchingProvisioningProfiles(true);
    try {
      const { data: profiles } = await api().get(
        `manufacturing/station/${id}/certificate/profile`
      );
      const profilesToSet = profiles.map((profile: any) =>
        deserializeProvisioningProfile(profile)
      );
      setProvisioningProfiles(profilesToSet);
    } catch (e) {
      sendNotification({
        text: 'failed to load provisioning profiles!',
        success: false,
      })(dispatch);
    } finally {
      setFetchingProvisioningProfiles(false);
    }
  };

  const isPerformingAction =
    isCreatingManufacturingStation || isEditingManufacturingStation;

  useEffect(() => {
    if (manufacturingStation && id) {
      setCurrentManufacturingStation(manufacturingStation);
      fetchProvisioningProfiles(true);
    }
  }, [manufacturingStation, id]);

  useEffect(() => {
    if (id) {
      dispatch(getManufacturingStation(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isGettingManufacturingStation) {
      setShowSpinner(true);
    }
    if (isLoadedManufacturingStation || manufacturingStationErrors) {
      setShowSpinner(false);
    }
  }, [
    isGettingManufacturingStation,
    isLoadedManufacturingStation,
    manufacturingStationErrors,
    isPerformingAction,
  ]);

  const manufacturingStationResources = _.find(
    resources,
    (item) => item.name === 'manufacturing_station'
  );

  const actions = _.get(manufacturingStationResources, 'actions', []);

  const navMenu = [
    'Manufacturing Station',
    'Provisioning Profiles',
    'Secure Tokens',
  ];

  const [activeTab, setActiveTab] = useState(`${tab}`);

  const toggleTab = (item: string): void => {
    activeTab !== item && setActiveTab(item);

    if (item === ManufacturingStationTabs.MANUFACTURING_STATION) {
      window.history.replaceState(
        null,
        '',
        `/management/manufacturing-stations/${manufacturingStation?.uuid}`
      );
    }

    if (item === ManufacturingStationTabs.PROVISIONING_PROFILES) {
      window.history.replaceState(
        null,
        '',
        `/management/manufacturing-stations/${manufacturingStation?.uuid}/provisioning-profiles`
      );
    }

    if (item === ManufacturingStationTabs.SECURE_TOKENS) {
      window.history.replaceState(
        null,
        '',
        `/management/manufacturing-stations/${manufacturingStation?.uuid}/secure-tokens`
      );
    }
  };

  const onDeleteProvisioningProfile = async ({
    manufacturingStationUuid,
    certificateProfileUuid,
  }: {
    manufacturingStationUuid: string;
    certificateProfileUuid: string;
  }) => {
    try {
      await api().delete(
        `manufacturing/station/${manufacturingStationUuid}/certificate/profile/${certificateProfileUuid}`
      );
      fetchProvisioningProfiles(true);
    } catch (e) {
      sendNotification({
        text: 'Failed to delete the provisioning profile',
        success: false,
      })(dispatch);
    }
  };

  const onCreateProvisioningProfile = async (
    certificateProfileUuid: string
  ) => {
    if (manufacturingStation) {
      dispatch(
        createProvisioningProfile({
          manufacturingStationUuid: manufacturingStation.uuid,
          certificateProfileUuid,
        })
      );
      await fetchProvisioningProfiles(true);
    }
  };

  const renderNav = (): ReactNode => (
    <Nav tabs>
      {_.map(navMenu, (label: string, index: number) => (
        <NavItem
          id={`nav-${kebabCase(label)}`}
          key={index}
          className="cursor-pointer pki-label"
        >
          <NavLink
            className={classnames({ active: activeTab === String(index) })}
            onClick={(): void => {
              toggleTab(String(index));
            }}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  return (
    <Card className="rounded p-5">
      <div className="CertificateProfileForm">
        <div className="form-header d-flex">
          <div
            id="back-button"
            onClick={() => history.push('/management/manufacturing-stations')}
            className="mt-1 ml-1 d-flex align-items-center text-muted cursor-pointer"
          >
            <FontAwesomeIcon
              style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
              size="2x"
              className="pki-ico"
              icon={faChevronLeft}
            />
          </div>
          <h3 className="m-auto">
            {currentManufacturingStation?.name}
            {showSpinner && !state?.manufacturingStation && (
              <Spinner
                style={{ verticalAlign: 'middle' }}
                className="d-inline"
                size="sm"
                type="border"
              />
            )}
          </h3>
        </div>
        <div className="form-content mt-4 px-1 pb-5">
          {renderNav()}
          <TabContent activeTab={activeTab}>
            <TabPane tabId={ManufacturingStationTabs.MANUFACTURING_STATION}>
              <ManufacturingStationInfo
                actions={actions}
                onSubmit={({
                  values,
                  isValid,
                }: {
                  values: ManufacturingStationType;
                  isValid: boolean;
                }): void => {
                  if (isValid) {
                    if (isEmpty(values?.uuid)) {
                      dispatch(createManufacturingStation(values));
                    } else {
                      dispatch(editManufacturingStation(values));
                    }
                  } else {
                    dispatch(
                      sendNotification({
                        text:
                          'Some fields are mandatory, please fill them all.',
                        success: false,
                      })
                    );
                  }
                }}
              />
            </TabPane>
            <TabPane tabId={ManufacturingStationTabs.PROVISIONING_PROFILES}>
              {shouldShowDemoContent() ? (
                <ProvisioningProfiles
                  onCreateProvisioningProfile={onCreateProvisioningProfile}
                  manufacturingStationUuid={id}
                  onDeleteProvisioningProfile={onDeleteProvisioningProfile}
                  provisioningProfiles={provisioningProfiles || []}
                />
              ) : (
                <ProvisioningProfilesList
                  manufacturingStationUuid={id}
                  isLoading={fetchingProvisioningProfiles}
                  onCreateProvisioningProfile={onCreateProvisioningProfile}
                  onDeleteProvisioningProfile={onDeleteProvisioningProfile}
                  provisioningProfiles={provisioningProfiles || []}
                />
              )}
            </TabPane>
            <TabPane tabId={ManufacturingStationTabs.SECURE_TOKENS}>
              <SecureTokensList manufacturingStationUuid={id} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Card>
  );
};
