import React, { useState, useEffect, FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import _, { kebabCase } from 'lodash';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { usePreviousString } from 'react-hooks-use-previous';
import {
  General,
  Policies,
  Extensions,
  CustomExtensions,
  CertificatePolicies,
  Batch,
} from '../ViewsComponents';
import { getProfileKeySpecs } from '../../store/certificateProfiles/actions';
import {
  CertificateProfile,
  CertificateProfilesState,
} from '../../store/certificateProfiles/types';
import { Certificate, CertificatesState } from '../../store/certificates/types';
import { CommonKeysState } from '../../store/commonKeys/types';
import { getCommonKeys } from '../../store/commonKeys/actions';
import { CustomExtension } from '../../libs/types';

import { ApplicationState } from '../../store';

import { certificateProfileDefaultValues, navMenu } from './constants';
import { getSubjectSorted } from '../../libs/helpers';

interface Props {
  closeModal?: Function;
  onChangeMode?: Function;
  defaultValues?: CertificateProfile;
  readOnly: boolean;
  onSubmit: Function;
  onCancel: Function;
}

const CertificateProfileForm: FC<Props> = ({
  onChangeMode = (): null => null,
  onCancel = (): null => null,
  onSubmit = (): null => null,
  defaultValues = {
    ...certificateProfileDefaultValues,
  },
  readOnly = false,
}) => {
  const {
    commonKeyList,
    isGettingProfileKeySpecs,
    profileKeySpecs,
  } = useSelector<
    ApplicationState,
    CertificateProfilesState & CertificatesState & CommonKeysState
  >((pki) => ({
    ...pki.certificateProfiles,
    ...pki.certificates,
    ...pki.commonKeys,
  }));

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('0');
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(
    false
  );
  const [profileForm, setProfileForm] = useState(defaultValues);

  // sets the default value of CDP & AIA based on the IssuingCA
  const [selectedIssuingCA, setSelectedIssuingCa] = useState<Certificate>();

  const {
    name,
    uuid: certificateProfileUuid,
    profileId,
    validity,
    keySpec,
    signatureAlgorithm,
    signatureHashAlgorithm,
    subject,
    keyUsage,
    description,
    extendedKeyUsage,
    subjectAlternativeNames,
    authorityInformationAccess,
    issuingCaUuid,
    issuerCN,
    commonKeyUuid,
    CRLDistributionPointURIs,
    certificatePolicies,
    approvalPolicy,
    customExtensions,
    critical,
    pgpKeys,
    batchEnabled,
    approversRequired,
  } = profileForm;

  const generalValues = {
    name,
    profileId,
    validity,
    keySpec,
    issuingCaUuid,
    issuerCN,
    commonKeyUuid,
    approversRequired,
    signatureAlgorithm,
    signatureHashAlgorithm,
    subject,
    description,
  };

  const extensionsValues = {
    keyUsage,
    extendedKeyUsage,
    subjectAlternativeNames,
    authorityInformationAccess,
    CRLDistributionPointURIs,
  };

  useEffect(() => {
    dispatch(getCommonKeys());
  }, [dispatch]);

  const prevIssuingCAUuid = usePreviousString(
    String(issuingCaUuid),
    issuingCaUuid
  );

  useEffect(() => {
    if (issuingCaUuid) {
      dispatch(getProfileKeySpecs(issuingCaUuid, certificateProfileUuid));
    }
  }, [dispatch, issuingCaUuid, prevIssuingCAUuid]);

  useEffect(() => {
    if (highlightMandatoryFields) {
      setTimeout(() => {
        setHighlightMandatoryFields(false);
      }, 5000);
    }
  }, [highlightMandatoryFields]);

  useEffect(() => {
    if (!readOnly && prevIssuingCAUuid !== issuingCaUuid) {
      setProfileFormProperty({
        value: profileKeySpecs?.keySpec[0]?.key,
        property: 'keySpec',
      });
      setProfileFormProperty({
        value: profileKeySpecs?.signatureAlgorithm[0]?.key,
        property: 'signatureAlgorithm',
      });
      setProfileFormProperty({
        value: profileKeySpecs?.signatureHashAlgorithm[0]?.key,
        property: 'signatureHashAlgorithm',
      });
    }
  }, [issuingCaUuid, prevIssuingCAUuid, profileKeySpecs, readOnly]);

  useEffect(() => {
    // Trim leading and trailing whitespace from all fields in subject when data is loaded
    const sanitizedSubject = subject.map((item) => ({
      ...item,
      value: typeof item.value === 'string' ? item.value.trim() : item.value,
    }));
    setProfileForm((current) => ({
      ...current,
      subject: sanitizedSubject,
    }));
  }, []);

  const setProfileFormProperty = ({
    property,
    value,
  }: {
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }): void => {
    setProfileForm((current: CertificateProfile) => ({
      ...current,
      [property]: value,
    }));
  };

  const handleFormSubmit = (): void => {
    // Trim whitespace for all necessary fields before submission
    const trimmedProfileForm = {
      ...profileForm,
      subject: profileForm.subject.map((item) => ({
        ...item,
        value: typeof item.value === 'string' ? item.value.trim() : item.value,
      })),
    };

    onSubmit({ values: trimmedProfileForm, isValid });
    if (!isValid) {
      setHighlightMandatoryFields(true);
    }
  };

  const CRLSuggestions = selectedIssuingCA?.crlUrls || [];
  const AIASuggestions = selectedIssuingCA?.caUrls || [];
  let isApproversPolicyValid = true;
  if (approversRequired) {
    if (approvalPolicy.approverUuids.length === 0) {
      isApproversPolicyValid = false;
    }
  }

  const isValid =
    !_.chain(name).trim().isEmpty().value() &&
    _.size(subject) >= 1 &&
    !!_.find(subject, (item) => item.key === 'common_name') &&
    isApproversPolicyValid;

  const commonKeyOptions = _.map(commonKeyList, (item) => ({
    value: item.uuid,
    label: `${item.name}-${item.sha1}`,
  }));

  const toggleTab = (tab: string): void => {
    activeTab !== tab && setActiveTab(tab);
  };

  const isEditable = _.includes(
    (defaultValues as CertificateProfile).actions,
    'update'
  );
  const [currentReformattedSan, setCurrentReformattedSan] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const subjectSorted = getSubjectSorted(subject);

  const renderNav = (): ReactNode => (
    <Nav tabs>
      {_.map(navMenu, (label: string, index: number) => (
        <NavItem
          id={`nav-${kebabCase(label)}`}
          key={index}
          className="cursor-pointer pki-label"
        >
          <NavLink
            className={classnames({ active: activeTab === String(index) })}
            onClick={(): void => {
              toggleTab(String(index));
            }}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  return (
    <div className="CertificateProfileForm">
      <div className="form-header d-flex">
        <div className="mt-5 ml-5 d-flex text-muted">
          <h3>Certificate Profile</h3>
          <span
            id="edit-form-button"
            onClick={(): void => {
              onChangeMode();
            }}
            className="ml-3 mt-2 cursor-pointer"
          >
            {isEditable && (
              <FontAwesomeIcon icon={readOnly ? faLock : faUnlock} />
            )}
          </span>
        </div>
        <div className="ml-auto m-3">
          <Button
            id="close-form-button"
            outline
            size="sm"
            onClick={(): void => {
              onCancel();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div className="form-content mt-4 px-5 pb-5">
        {renderNav()}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <General
              type="PROFILE"
              id="general"
              setSelectedIssuingCa={setSelectedIssuingCa}
              readOnly={readOnly}
              isGettingKeySpecs={isGettingProfileKeySpecs}
              keySpecs={profileKeySpecs}
              subjectSorted={subjectSorted}
              reformattedSan={currentReformattedSan}
              highlightMandatoryFields={highlightMandatoryFields}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setProfileFormProperty({ value, property: key });
              }}
              values={generalValues}
            />
          </TabPane>
          <TabPane tabId="1">
            <Policies
              readOnly={readOnly}
              commonKeyUuid={commonKeyUuid || ''}
              commonKeyOptions={commonKeyOptions}
              approversIdList={approvalPolicy.approverUuids || []}
              approversRequired={approversRequired}
              extendedKeyUsage={extendedKeyUsage}
              onChange={({
                property,
                value,
              }: {
                property: string;
                value: string | string[];
              }): void => {
                setProfileFormProperty({ property, value });
              }}
            />
          </TabPane>
          <TabPane tabId="2">
            <Extensions
              id="extensions"
              readOnly={readOnly}
              CRLSuggestions={CRLSuggestions}
              AIASuggestions={AIASuggestions}
              reformattedSan={currentReformattedSan}
              onReformattedSanChange={setCurrentReformattedSan}
              subjectSorted={subjectSorted}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setProfileFormProperty({ value, property: key });
              }}
              values={extensionsValues}
            />
          </TabPane>
          <TabPane tabId="3">
            <CustomExtensions
              id="custom-extensions"
              readOnly={readOnly}
              onChange={({ value }: { value: CustomExtension[] }): void => {
                setProfileFormProperty({
                  value,
                  property: 'customExtensions',
                });
              }}
              values={customExtensions}
            />
          </TabPane>
          <TabPane tabId="4">
            <CertificatePolicies
              id="certificate-policies"
              readOnly={readOnly}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setProfileFormProperty({ value, property: key });
              }}
              values={{ certificatePolicies, critical }}
            />
          </TabPane>
          <TabPane tabId="5">
            <Batch
              id="batch"
              readOnly={readOnly}
              onChange={({
                key,
                value,
              }: {
                key: string;
                value: string | number;
              }): void => {
                setProfileFormProperty({ value, property: key });
              }}
              values={{ pgpKeys, batchEnabled }}
            />
          </TabPane>
        </TabContent>
        {!readOnly && (
          <div className="float-right mt-5 pb-5">
            <span className="mr-2">
              <Button
                id="cancel-form-button"
                outline
                onClick={(): void => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                id="confirm-button"
                outline
                disabled={false}
                onClick={handleFormSubmit}
              >
                Confirm
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CertificateProfileForm;
