import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import moment from 'moment';
import { Badge } from 'reactstrap';
import axios from 'axios';
import { Spinner } from '../../../components';
import { ApplicationState } from '../../../store';
import { getHSMStats } from '../../../store/statistics/actions';
import { StatisticsState } from '../../../store/statistics/types';

const HSMStatus: FC = () => {
  const { isGettingHSMStats, isLoadedLHSMStats, HSMStats } = useSelector<
    ApplicationState,
    StatisticsState
  >((pki) => ({
    ...pki.statistics,
  }));

  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (isGettingHSMStats) {
      setShowSpinner(true);
    }
    if (isLoadedLHSMStats) {
      setShowSpinner(false);
    }
  }, [isGettingHSMStats, isLoadedLHSMStats]);

  useEffect(() => {
    const tokenSource = axios.CancelToken.source();
    dispatch(getHSMStats(tokenSource));
    return function cleanup(): void {
      tokenSource.cancel('HSMStatus::getHSMStats');
    };
  }, [dispatch]);

  const HSMDateTime = moment(HSMStats?.hsmUtcTime);

  return (
    <div className="HSMStatus overflow-auto">
      <div className="header-contanier d-flex">
        <h5 className="text-muted">
          HSM Status <small>(Hoofddorp, Netherlands)</small>
        </h5>
        {showSpinner && (
          <Spinner className="mt-1 ml-2" size="sm" type="border" />
        )}
      </div>
      <div className="content d-flex">
        {!isNil(HSMStats) && HSMDateTime.isValid() && (
          <>
            <h4>
              <Badge color={HSMStats?.active ? 'success' : 'danger'}>
                {HSMStats?.active ? 'Active' : 'Not Active'}
              </Badge>
            </h4>{' '}
            <span className="ml-2 mt-2">at {HSMDateTime.format('LLL')}</span>
          </>
        )}
        {(isNil(HSMStats) || !HSMStats.available) && 'Not Available'}
      </div>
    </div>
  );
};

export default HSMStatus;
