import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import _, { startCase } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Collapse, UncontrolledPopover } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { User, UsersState } from '../../../store/users/types';
import { ApplicationState } from '../../../store';
import { CertificateProfile } from '../../../store/certificateProfiles/types';
import {
  Certificate,
  CertificatesState,
} from '../../../store/certificates/types';
import { useUsersSelect } from '../../../libs/hooks';
import { Filter } from '../../../components/FilterBar/types';
import { getCertificateAuthorities } from '../../../store/certificates/actions';
import { Resource } from '../../../libs/constants';
import { FilterBar, Spinner, Table } from '../../../components';
import { api } from '../../../libs/helpers';
import { sendNotification } from '../../../store/notifications/actions';
import { deserializeCertificateProfile } from '../../../store/certificateProfiles/helpers';
import { mapRoleToValue } from '../../../store/users/helpers';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'name':
      return 'certificate_profile.name';
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

interface CertificateProfilesProps {
  user: User | null;
}

interface EntitlementProfile {
  entity: CertificateProfile;
  entityRoles: string[];
}

export const CertificateProfiles: FC<CertificateProfilesProps> = ({ user }) => {
  const {
    userProfile: { resources, uuid: currentUserUuid },
    OnlineCAList,
    isGettingOnlineCAList,
    isLoadedOnlineCAList,
  } = useSelector<ApplicationState, UsersState & CertificatesState>((pki) => ({
    ...pki.certificates,
    ...pki.users,
  }));
  const dispatch = useDispatch();
  const { loading, users, onMore, onFilter } = useUsersSelect();
  const history = useHistory();

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<EntitlementProfile[]>();
  const isLoadingData = isGettingOnlineCAList;
  const fetchSourceRef = useRef<CancelTokenSource>();

  const isDataLoaded = isLoadedOnlineCAList;

  useEffect(() => {
    if (isLoadingData) {
      setShowSpinner(true);
    }
    if (isDataLoaded) {
      setShowSpinner(false);
    }
  }, [isDataLoaded, isLoadingData]);

  const fetchData = async () => {
    fetchSourceRef.current = axios.CancelToken.source();
    if (user) {
      // setShowContent(false);
      setShowSpinner(true);
      try {
        const {
          data: certificateProfiles,
        } = await api().get(
          `/user/entitlements/summary?filter=entity_type=certificate_profile,user_uuid=${user?.uuid}&page=${tablePage}&count=${tableSizePerPage}`,
          { cancelToken: fetchSourceRef.current.token }
        );
        const profilesToSet = certificateProfiles.map(
          // eslint-disable-next-line camelcase
          (profile: { entity: CertificateProfile; entity_roles: string[] }) => {
            return {
              entity: deserializeCertificateProfile(profile.entity),
              entityRoles: profile.entity_roles,
            };
          }
        );
        setProfiles(() => {
          setShowContent(true);
          setShowSpinner(false);
          return profilesToSet;
        });
      } catch (e) {
        if (!axios.isCancel(e)) {
          sendNotification({
            text: 'Failed to get certificate profiles!',
            success: false,
          })(dispatch);
          setShowSpinner(false);
          setShowContent(false);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    query,
    user,
  ]);
  useEffect(() => {
    return () => {
      fetchSourceRef.current?.cancel();
    };
  }, []);

  useEffect(() => {
    const tokenSourceCAs = axios.CancelToken.source();

    dispatch(
      getCertificateAuthorities(
        {
          isKeyOnline: true,
          action: 'create',
          resource: Resource.CertificateProfile,
        },
        tokenSourceCAs
      )
    );
    return function cleanup(): void {
      tokenSourceCAs.cancel('CertificateProfiles::getCertificateAuthorities');
    };
  }, [dispatch]);

  const columnsProfiles = [
    { dataField: 'uuid', text: '', hidden: true },
    {
      dataField: 'entity.name',
      text: 'Name',
      formatter: (
        name: string,
        {
          entity: { description, uuid },
        }: { entity: { uuid: string; description: string } }
      ): ReactNode => {
        return (
          <>
            <div>{name}</div>
            {!_.isEmpty(description) && (
              <div
                id={`profile-${uuid}`}
                style={{ width: '200px' }}
                className="d-inline-block text-truncate"
              >
                <small> {description}</small>
                <UncontrolledPopover trigger="hover" target={`profile-${uuid}`}>
                  <div className="p-2">
                    <small>{description}</small>
                  </div>
                </UncontrolledPopover>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'noData',
      text: 'Entitlements',
      formatter: (
        unused: string,
        { entityRoles }: { entityRoles: string[] }
      ): ReactNode => {
        return (
          <>
            {entityRoles.map((role) => (
              <span key={role} className={'m-1 d-inline-block'}>
                <Badge color={'primary'} className={'p-2'}>
                  {mapRoleToValue?.[role] ?? startCase(role)}
                </Badge>
              </span>
            ))}
          </>
        );
      },
    },
  ];

  return (
    <div className="user-profile-certificate-profiles">
      {showSpinner && <Spinner className="mb-2" size="sm" type="border" />}
      <Collapse isOpen={showContent}>
        <div className="filters-container d-none">
          <FilterBar
            filters={[
              {
                key: 'certificate_profile.name',
                label: 'Name',
                type: 'like',
                operators: ['~'],
              },
              {
                key: 'notes',
                label: 'Description',
                type: 'like',
                operators: ['~'],
              },
              // {
              //   key: 'certificate_profile_id',
              //   label: 'REST API ID',
              //   type: 'filter',
              // },
              // {
              //   key: 'issuing_ca_uuid',
              //   label: 'Issuing CA',
              //   type: 'filter',
              //   options: _.map(OnlineCAList, (certificate: Certificate) => ({
              //     value: certificate.uuid,
              //     label: certificate.commonName,
              //   })),
              // },
              // {
              //   key: 'created_by_user_uuid',
              //   label: 'Created By',
              //   type: 'filter',
              //   placeholder: 'Type username...',
              //   onSelectInput: onFilter,
              //   loading,
              //   onMore,
              //   options: _.map(users, (customerUser: User) => ({
              //     value: customerUser.uuid,
              //     label: `${customerUser.username} (${customerUser.email})`,
              //   })),
              // },
              // {
              //   key: 'updated_by_user_uuid',
              //   label: 'Updated By',
              //   type: 'filter',
              //   placeholder: 'Type username...',
              //   onSelectInput: onFilter,
              //   loading,
              //   onMore,
              //   options: _.map(users, (customerUser: User) => ({
              //     value: customerUser.uuid,
              //     label: `${customerUser.username} (${customerUser.email})`,
              //   })),
              // },
              // {
              //   key: 'date_created',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Created Date',
              //   type: 'filter',
              // },
              // {
              //   key: 'date_updated',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Modified Date',
              //   type: 'filter',
              // },
            ]}
            initialFilters={[]}
            onFiltersChange={(newFilters: Filter[]): void => {
              if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                setQuery(() => {
                  setTablePage(1);
                  return newFilters;
                });
              }
            }}
          />
        </div>
        <div className="table-container">
          <Table
            remote={true}
            search={false}
            pagination={{
              page: tablePage,
              sizePerPage: tableSizePerPage,
            }}
            keyField="entity.uuid"
            sort={{
              dataField: tableSortField,
              order: tableSortOrder as SortOrder,
            }}
            noDataIndication={
              tablePage > 1
                ? 'No more Certificate Profiles available'
                : 'No Certificate Profiles available'
            }
            data={profiles ?? []}
            columns={columnsProfiles}
            onTableChange={(
              valueNotUsed: null,
              {
                page,
                sizePerPage,
                sortOrder,
                sortField,
              }: {
                page: number;
                sizePerPage: number;
                sortOrder: string;
                sortField: string;
              }
            ): void => {
              setTablePage(page);
              setTableSizePerPage(sizePerPage);
              setTableSortOrder(sortOrder);
              setTableSortField(mapSortField(sortField));
            }}
            rowEvents={{
              onClick: (
                notUsedValue: null,
                current: EntitlementProfile
              ): void => {
                history.push({
                  pathname: '/management/certificate-profiles',
                  search: `?uuid=${current.entity.uuid}`,
                });
              },
            }}
          />
        </div>
      </Collapse>
    </div>
  );
};
