import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Badge, Collapse } from 'reactstrap';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { User, UsersState } from '../../../store/users/types';
import { ApplicationState } from '../../../store';
import { Client } from '../../../store/clients/types';
import { Filter } from '../../../components/FilterBar/types';
import { getUsers } from '../../../store/users/actions';
import { FilterBar, Spinner, Table } from '../../../components';
import { api } from '../../../libs/helpers';
import { sendNotification } from '../../../store/notifications/actions';
import { deserializeClient } from '../../../store/clients/helpers';
import { mapRoleToValue } from '../../../store/users/helpers';

const mapSortField = (sortField: string) => {
  switch (sortField) {
    case 'clientID':
      return 'client_id';
    case 'updatedAt':
      return 'date_updated';
    case 'createdBy':
      return 'created_by_user_uuid';
    default:
      return sortField;
  }
};

interface ApiClientsProps {
  user: User | null;
}

interface EntitlementApiClient {
  entity: Client;
  entityRoles: string[];
}

export const ApiClients: FC<ApiClientsProps> = ({ user }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    userProfile: { resources },
    usersList,
  } = useSelector<ApplicationState, UsersState>((pki) => ({
    ...pki.users,
  }));
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const [tablePage, setTablePage] = useState<number>(1);
  const [tableSizePerPage, setTableSizePerPage] = useState<number>(10);
  const [tableSortOrder, setTableSortOrder] = useState<string>('desc');
  const [tableSortField, setTableSortField] = useState<string>('date_updated');
  const [query, setQuery] = useState<Filter[]>([]);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [apiClients, setApiClients] = useState<EntitlementApiClient[]>();
  const fetchSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const fetchData = async () => {
    fetchSourceRef.current = axios.CancelToken.source();
    if (user) {
      setShowSpinner(true);
      setShowContent(false);
      try {
        const {
          data: clients,
        } = await api().get(
          `/user/entitlements/summary?filter=entity_type=user,user_uuid=${user?.uuid}&page=${tablePage}&count=${tableSizePerPage}`,
          { cancelToken: fetchSourceRef.current.token }
        );
        const clientsToSet = clients.map(
          // eslint-disable-next-line camelcase
          (client: { entity: Client; entity_roles: string[] }) => {
            return {
              entity: deserializeClient(client.entity),
              entityRoles: client.entity_roles,
            };
          }
        );
        setApiClients(() => {
          setShowSpinner(false);
          setShowContent(true);
          return clientsToSet;
        });
      } catch (e) {
        if (!axios.isCancel(e)) {
          sendNotification({
            text: 'Failed to get API Clients!',
            success: false,
          })(dispatch);
          setShowSpinner(false);
          setShowContent(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    tablePage,
    tableSizePerPage,
    tableSortOrder,
    tableSortField,
    query,
    user,
  ]);
  useEffect(() => {
    return () => {
      fetchSourceRef.current?.cancel();
    };
  }, []);
  const columns = [
    { dataField: 'uuid', text: '', hidden: true },
    { dataField: 'entity.clientID', text: 'Client ID' },
    {
      dataField: 'noData',
      text: 'Entitlements',
      formatter: (
        unused: string,
        { entityRoles }: { entityRoles: string[] }
      ): ReactNode => {
        return (
          <>
            {entityRoles.map((role) => (
              <span key={role} className={'m-1 d-inline-block'}>
                <Badge color={'primary'} className={'p-2'}>
                  {mapRoleToValue?.[role] ?? startCase(role)}
                </Badge>
              </span>
            ))}
          </>
        );
      },
    },
  ];
  return (
    <div className="user-profile-api-clients">
      {showSpinner && <Spinner className="mb-2" size="sm" type="border" />}
      <Collapse isOpen={showContent}>
        <div className="filters-container d-none">
          <FilterBar
            filters={[
              {
                key: 'client_id',
                label: 'Client ID',
                type: 'like',
                operators: ['~'],
              },
              // {
              //   key: 'description',
              //   label: 'Description',
              //   type: 'like',
              //   operators: ['~'],
              // },
              // {
              //   key: 'enabled',
              //   label: 'Status',
              //   type: 'filter',
              //   options: [
              //     { value: 'Active', label: 'Active' },
              //     { value: 'Blocked', label: 'Blocked' },
              //   ],
              // },
              // {
              //   key: 'created_by_user_uuid',
              //   label: 'Created By',
              //   type: 'filter',
              //   options: _.map(usersList, (customerUser: User) => ({
              //     value: customerUser.uuid,
              //     label: `${customerUser.username} (${customerUser.email})`,
              //   })),
              // },
              // {
              //   key: 'updated_by_user_uuid',
              //   label: 'Updated By',
              //   type: 'filter',
              //   options: _.map(usersList, (customerUser: User) => ({
              //     value: customerUser.uuid,
              //     label: `${customerUser.username} (${customerUser.email})`,
              //   })),
              // },
              // {
              //   key: 'date_created',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Created Date',
              //   type: 'filter',
              // },
              // {
              //   key: 'date_updated',
              //   operators: ['=', '>', '<'],
              //   suggestion: 'Date must be filled in YYYY-MM-DD format',
              //   label: 'Modified Date',
              //   type: 'filter',
              // },
            ]}
            onFiltersChange={(newFilters: Filter[]): void => {
              if (JSON.stringify(newFilters) !== JSON.stringify(query)) {
                let mapValues = JSON.stringify(newFilters);
                mapValues = mapValues.replace('Active', 'true');
                mapValues = mapValues.replace('Blocked', 'false');
                setQuery(() => {
                  setTablePage(1);
                  return JSON.parse(mapValues);
                });
              }
            }}
          />
        </div>
        <div className="table-container">
          <Table
            keyField="entity.uuid"
            rowEvents={{
              onClick: (
                notUsedValue: null,
                current: EntitlementApiClient
              ): void => {
                history.push({
                  pathname: '/management/api-clients',
                  search: `?uuid=${current.entity.uuid}`,
                });
              },
            }}
            remote={true}
            search={false}
            pagination={{
              page: tablePage,
              sizePerPage: tableSizePerPage,
            }}
            sort={{
              dataField: tableSortField,
              order: tableSortOrder as SortOrder,
            }}
            noDataIndication={
              tablePage > 1
                ? 'No more API Clients available'
                : 'No API Clients available'
            }
            onTableChange={(
              valueNotUsed: null,
              {
                page,
                sizePerPage,
                sortOrder,
                sortField,
              }: {
                page: number;
                sizePerPage: number;
                sortOrder: string;
                sortField: string;
              }
            ): void => {
              // sortField set to date_updated as default if not defined
              setTableSortField(mapSortField(sortField || 'date_updated'));
              setTablePage(page);
              setTableSizePerPage(sizePerPage);
              setTableSortOrder(sortOrder);
            }}
            data={apiClients ?? []}
            columns={columns}
          />
        </div>
      </Collapse>
    </div>
  );
};
