import { UserOutput } from '../../store/users/types';

export interface Key {
  uuid: string;
  keyId: string | null;
  label: string;
  keyFile: string;
  keyType: 'private_key' | 'public_key';
  keyDigest: string;
  updatedAt: string;
  notes: string;
  createdAt: string;
  updatedBy?: UserOutput | null;
  createdBy: UserOutput;
  actions?: string[];
}

export enum ProductKeyUsage {
  SIGN = 'sign',
  VERIFY = 'verify',
}

export interface ProductKey {
  label: string;
  uuid: string;
  productProfileUuid: string;
  keyUsage: ProductKeyUsage;
  createdAt: string;
  updatedAt: string;
  createdBy: UserOutput;
  updatedBy: UserOutput;
  notes: string;
  keyDigest: string;
  actions?: string[];
  keyFile?: string;
  keyId: string;
}

export interface AsymmetricKey {
  label: string;
  keySpec: string;
  uuid: string;
  asymmetricKeyId: string;
  alreadyExists: boolean;
  notes: string;
  privateKey?: Key;
  publicKey?: Key;
  updatedAt?: string | null;
  createdAt: string;
  updatedBy: UserOutput | null;
  createdBy: UserOutput;
  actions?: string[];
}
